import { ReactNode, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    fetchForm,
    selectState as selectEditFormState,
    EditStoreFormType,
} from '@Entities/forms/seller/stores/model/editStoreFormSlice';
import {
    selectState as selectIntegrationFormState,
    SetupIntegrationFormType,
} from '@Entities/forms/seller/stores/model/setupIntegration';
import { FormKeysEnum } from '@Shared/model/seller/types';
import { MarketplaceStoreDto2 } from '@OpenApi/typescript-axios';
import { Drawer } from '@Shared/ui';
import { ModalKeys, DrawerKeys } from '@Shared/model/seller/stores/storesPageTypes';

import { StoreDetailsForm, EditStoreForm, SetupIntegrationForm } from './drawerContent';

import './styles.scss';

type StoreDrawerProps = {
    isDrawerOpen: boolean;
    setDrawerOpen: (arg1: boolean) => void;
    setDrawerContentType: (arg1: DrawerKeys) => void;
    contentType: DrawerKeys;
    storeId: string;
    setModalOpen: (arg1: boolean) => void;
    setModalType: (arg1: ModalKeys) => void;
};

const StoreDrawer = ({
    isDrawerOpen,
    setDrawerOpen,
    contentType,
    storeId,
    setDrawerContentType,
    setModalOpen,
    setModalType,
}: StoreDrawerProps) => {
    const dispatch = useDispatch();
    const [selectedMarketplace, setSelectedMarketplace] = useState<MarketplaceStoreDto2 | null>(null);
    const { isLoading: isEditFormLoading }: EditStoreFormType = useSelector(selectEditFormState);
    const { isLoading: isIntegrationFormLoading }: SetupIntegrationFormType = useSelector(selectIntegrationFormState);

    const [drawerExtraContent, setDrawerExtraContent] = useState<ReactNode>(<></>);
    const [drawerTitle, setDrawerTitle] = useState<ReactNode>(<></>);

    useEffect(() => {
        if (storeId) {
            dispatch(
                fetchForm({
                    key: FormKeysEnum.sellerEditStoreForm,
                    params: {
                        storeId,
                    },
                }),
            );
        }
    }, [dispatch, storeId]);

    const isDrawerLoading =
        (isEditFormLoading && (contentType === 'storeDetails' || contentType === 'editStore')) ||
        (isIntegrationFormLoading && contentType === 'setupIntegration');

    const renderDrawer: Record<DrawerKeys, ReactNode> = {
        storeDetails: (
            <StoreDetailsForm
                setDrawerContentType={setDrawerContentType}
                setSelectedMarketplace={setSelectedMarketplace}
                storeId={storeId}
                setModalOpen={setModalOpen}
                setModalType={setModalType}
                setDrawerExtraContent={setDrawerExtraContent}
                setDrawerTitle={setDrawerTitle}
            />
        ),
        editStore: (
            <EditStoreForm
                setOpen={setDrawerOpen}
                setDrawerContentType={setDrawerContentType}
                storeId={storeId}
                setModalOpen={setModalOpen}
                setModalType={setModalType}
                setDrawerExtraContent={setDrawerExtraContent}
                setDrawerTitle={setDrawerTitle}
            />
        ),
        setupIntegration: (
            <SetupIntegrationForm
                open={isDrawerOpen}
                setOpen={setDrawerOpen}
                setDrawerContentType={setDrawerContentType}
                storeId={storeId}
                selectedMarketplace={selectedMarketplace}
                setDrawerExtraContent={setDrawerExtraContent}
            />
        ),
        '': undefined,
    };

    return (
        <Drawer
            open={isDrawerOpen}
            loading={isDrawerLoading}
            onClose={() => setDrawerOpen(false)}
            title={drawerTitle}
            className="stores-table__drawer"
            extra={drawerExtraContent}
        >
            {renderDrawer[contentType]}
        </Drawer>
    );
};

export default StoreDrawer;
