import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    selectState,
    fetchForm,
    submitForm,
    OnboardingFormType,
} from '@Entities/forms/seller/onboarding/onboardingSlice';
import { Button, Input, Select, Typography, Form } from '@Shared/ui';
import { FormKeysEnum } from '@Shared/model/seller/types';
import { ModalForm } from '@Features/shared';
import { SellerInfoSellersGoalEnum, RevenueRangeEnum2 } from '@OpenApi/typescript-axios';

const { Text, Title } = Typography;

const revenueOptions = {
    first_level: '0 - $200',
    second_level: '$200 - $1000',
    third_level: '$1000 - $5000',
    fourth_level: '$5000+',
};

const goalOptions = {
    increase_sales: 'Увеличение продаж',
    obtaining_statistics: 'Получение релевантной статистики по заказам',
    order_tracking: 'Отслеживание заказов',
    analytics: 'Аналитика',
    communication_with_webmasters: 'Ищу новый способ коммуникации с веб-мастерами',
    increasing_reviews: 'Увеличение количества обзоров и отзывов на товары',
    other: 'Другое',
};

const OnboardingModal = () => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [openOnboarding, setOpenOnboarding] = useState<boolean>(false);
    const { isLoading, isSubmitting, isSubmitted, errors }: OnboardingFormType = useSelector(selectState);

    useEffect(() => {
        setTimeout(() => {
            setOpenOnboarding(true);
        }, 5 * 60000);
    }, [setOpenOnboarding]);

    useEffect(() => {
        dispatch(
            fetchForm({
                key: FormKeysEnum.sellerOnboardingForm,
            }),
        );
    }, [dispatch]);

    useEffect(() => {
        if (isSubmitted) {
            setOpenOnboarding(false);
        }
    }, [isSubmitted, setOpenOnboarding]);

    const handleSubmit = (formData) => {
        const modifiedFormData = { ...formData, website: formData.website ? `https://${formData.website}` : '' };
        dispatch(
            submitForm({
                key: FormKeysEnum.sellerOnboardingForm,
                withNotification: true,
                notificationOptions: { successMessage: 'Успешно отправлено', errorMessage: 'Ошибка отправки' },
                formData: modifiedFormData,
            }),
        );
    };

    const revenueRangeOptions = Object.values(RevenueRangeEnum2 || {}).map((range) => ({
        value: range,
        label: revenueOptions[range],
    }));

    const sellersGoalOptions = Object.values(SellerInfoSellersGoalEnum || {}).map((goal) => ({
        value: goal,
        label: goalOptions[goal],
    }));

    return (
        <ModalForm
            isLoading={isLoading}
            formKey={FormKeysEnum.sellerOnboardingForm}
            setOpen={setOpenOnboarding}
            modalProps={{
                open: openOnboarding,
                title: (
                    <Title
                        level={4}
                        style={{ marginTop: 0 }}
                    >
                        Добро пожаловать!
                    </Title>
                ),
                footer: null,
            }}
            formProps={{ form: form }}
            handleSubmit={handleSubmit}
        >
            <Text
                type="secondary"
                style={{ marginBottom: 8 }}
            >
                Заполните, пожалуйста, дополнительную информацию о своем бизнесе
            </Text>
            <Form.Item
                hasFeedback
                label="Веб-сайт"
                name="website"
                validateTrigger="onBlur"
                validateStatus={errors?.website ? 'error' : ''}
                help={errors?.website || null}
            >
                <Input
                    addonBefore="https://"
                    placeholder="Укажите ссылку"
                />
            </Form.Item>
            <Form.Item
                hasFeedback
                label="Ежегодный доход"
                name="revenue_range"
                rules={[{ required: true, message: 'Это поле обязательно к заполнению' }]}
                validateStatus={errors?.revenue_range ? 'error' : ''}
                help={errors?.revenue_range || null}
            >
                <Select
                    style={{ width: '100%' }}
                    options={revenueRangeOptions}
                />
            </Form.Item>

            <Form.Item
                hasFeedback
                label="Причины подключения клиента к нам "
                name="sellers_goal"
                rules={[{ required: true, message: 'Это поле обязательно к заполнению' }]}
                validateStatus={errors?.sellers_goal ? 'error' : ''}
                help={errors?.sellers_goal || null}
            >
                <Select
                    mode="multiple"
                    style={{ width: '100%' }}
                    options={sellersGoalOptions}
                />
            </Form.Item>
            <Form.Item style={{ marginBottom: 0 }}>
                <Button
                    type="primary"
                    htmlType="submit"
                    loading={isSubmitting}
                >
                    Отправить
                </Button>
            </Form.Item>
        </ModalForm>
    );
};

export default OnboardingModal;
