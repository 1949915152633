import { FC } from 'react';
import { RouterProvider } from 'react-router-dom';

import { OnboardingModal } from '@Features/modals';
import { useGetTheUserInformationQuery } from '@Shared/api/rtkQueryApis/user';

import SellerJoyride from './joyride/SellerJoyride';

import { seller_app_router } from '../routes';
import Theme from '../providers/SellerThemeProvider';

const SellerApp: FC = () => {
    const { data: user, isSuccess } = useGetTheUserInformationQuery();
    const showOnboarding = isSuccess && !user?.onboarded && window.location.pathname !== '/welcome';

    return (
        <Theme>
            <SellerJoyride />
            <RouterProvider
                router={seller_app_router}
                fallbackElement={<div>Page not found</div>}
            />
            {showOnboarding && <OnboardingModal />}
        </Theme>
    );
};

export default SellerApp;
