import { PageContent, Breadcrumbs } from '@Features/shared';
import { PersonalPromotionForm } from '@Features/seller/promotions/editPersonalPromotion';

const pageBreadcrumbs = [
    {
        path: '/promotions',
        title: 'Скидки на товары',
    },
    {
        path: '/personal-promotions',
        title: 'Мои акции и промокоды',
    },
    {
        path: '/add-personal-promotion',
        title: 'Добавить персональное предложение',
    },
];

const EditPersonalPromotionPage = () => {
    return (
        <PageContent>
            <Breadcrumbs items={pageBreadcrumbs} />

            <PersonalPromotionForm isAddPersonalPromotion={true} />
        </PageContent>
    );
};
export default EditPersonalPromotionPage;
