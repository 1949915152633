export function getBalanceInfoText(balance: number, credit: number) {
    const creditText = credit ? `Вам предоставлен кредит ${credit} ₽. \n` : '';
    const positiveBalanceText = 'Вы можете работь с паблишерами и продвигать товары.';
    const negativeBalanceText = 'Паблишеры не видят ваши товары, пополните баланс, чтобы продолжить продвижение.';

    if (balance > 0) {
        return `${creditText}${positiveBalanceText}`;
    } else if (balance === 0) {
        if (creditText) {
            return `${creditText}${positiveBalanceText}`;
        } else {
            return negativeBalanceText;
        }
    } else {
        return negativeBalanceText;
    }
}
