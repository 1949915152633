import { theme } from 'antd';
import Icon from '@ant-design/icons';

import { Row, Typography } from '@Shared/ui';
import { InfoInformationCircle } from '@Shared/assets/images/icons';

const { Text } = Typography;

const AlertInfoBlock = () => {
    const {
        token: { colorBgLayout },
    } = theme.useToken();

    return (
        <Row
            wrap={false}
            style={{
                gap: 20,
                padding: '10px 20px',
                background: colorBgLayout,
                borderRadius: 12,
                marginTop: 12,
            }}
            align="middle"
        >
            <Icon component={() => <InfoInformationCircle />} />
            <Text>
                ИНН сверяется с платежными данными при выводе средств. Пожалуйста, убедитесь в актуальности введенных
                данных.
            </Text>
        </Row>
    );
};

export default AlertInfoBlock;
