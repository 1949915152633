import { closeModal } from '@Entities/modals/model/modalsSlice';
import { selectModals } from '@Entities/keyValue/selectors';
import { useAppDispatch, useAppSelector } from '@Shared/hooks';
import { Modal, Typography, Row, Button, Grid } from '@Shared/ui';
import { ModalKeysType } from '@Shared/model/modalTypes';
import {
    useViewThePromotionQuery,
    useDeleteThePersonalPromotionMutation,
} from '@Shared/api/rtkQueryApis/sellerPromotions';

const { Title, Text } = Typography;
const { useBreakpoint } = Grid;

const DeletePromotionModal = () => {
    const dispatch = useAppDispatch();
    const breakpoints = useBreakpoint();
    const modalState = useAppSelector(selectModals);
    const promotionId = modalState?.params?.promotionId;
    const { data: promotion, isLoading } = useViewThePromotionQuery({ id: promotionId }, { skip: !promotionId });
    const [deletePromotion, { isLoading: isDeleting }] = useDeleteThePersonalPromotionMutation();
    const isOpen = modalState.modalKey === ModalKeysType.deletePersonalPromotion && modalState.open;

    const handleCancel = () => {
        dispatch(closeModal());
    };

    const handleDelete = async () => {
        if (promotionId) {
            try {
                await deletePromotion({
                    id: promotionId,
                    notificationOptions: {
                        successMessage: 'Персональное предложение успешно удалено',
                    },
                }).unwrap();
                handleCancel();
            } catch (err) {
                console.error(err);
            }
        }
    };

    return (
        <Modal
            title={
                promotion?.title ? (
                    <Title
                        level={4}
                        style={{ marginTop: 0 }}
                    >
                        Удалить персональное предложение?
                    </Title>
                ) : null
            }
            open={isOpen}
            loading={isLoading}
            onCancel={handleCancel}
            footer={
                <Row
                    justify="space-between"
                    style={{ flexDirection: breakpoints.sm ? 'row' : 'column', gap: 8 }}
                >
                    <Button
                        onClick={handleCancel}
                        style={{ width: breakpoints.sm ? 'auto' : '100%' }}
                    >
                        Отменить
                    </Button>
                    <Button
                        type="primary"
                        loading={isDeleting}
                        onClick={handleDelete}
                        style={{ width: breakpoints.sm ? 'auto' : '100%' }}
                    >
                        Удалить
                    </Button>
                </Row>
            }
        >
            <Text>Персональное предложение нельзя будет восстановить. Все равно хотите удалить?</Text>
        </Modal>
    );
};
export default DeletePromotionModal;
