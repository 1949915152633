import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { theme } from 'antd';
import Icon, { UserOutlined } from '@ant-design/icons';

import {
    fetchData as fetchStores,
    selectState as selectStoresState,
    StoresKeyValueType,
} from '@Entities/keyValue/seller/stores/model/storesSlice';
import {
    fetchData as fetchMarketplaces,
    selectState as selectMarketplacesState,
    MarketplacesKeyValueType,
} from '@Entities/keyValue/seller/marketplaces/model/marketplacesSlice';
import { KeyValueKeysEnum } from '@Shared/model/seller/types';
import { Row, Avatar, Select, Col, Grid, Spin } from '@Shared/ui';
import { InteractionIcon } from '@Shared/assets/images/icons';
import { getMarketPlaceIconBySlug } from '@Shared/utils/stores/getMarkeplaceIconBySlug';

import './styles.scss';

const { useBreakpoint } = Grid;

type SetupIntegrationFormProps = {
    storeId: string;
    marketplace: string;
    setSelectedMarketplace: (arg1: string) => void;
    setSelectedStoreId: (arg1: string) => void;
    disabled?: boolean;
};

const IntegrationFormSelectors = ({
    storeId,
    marketplace,
    setSelectedStoreId,
    setSelectedMarketplace,
    disabled,
}: SetupIntegrationFormProps) => {
    const dispatch = useDispatch();
    const breakpoints = useBreakpoint();

    const { isLoading: isStoresLoading, value: stores }: StoresKeyValueType = useSelector(selectStoresState);
    const { isLoading: isMarketplacesLoading, value: marketplaces }: MarketplacesKeyValueType =
        useSelector(selectMarketplacesState);

    useEffect(() => {
        if (!stores) {
            dispatch(
                fetchStores({
                    key: KeyValueKeysEnum.getStoresList,
                }),
            );
        }
    }, [dispatch, stores]);

    useEffect(() => {
        if (!marketplaces) {
            dispatch(
                fetchMarketplaces({
                    key: KeyValueKeysEnum.getMarketplacesList,
                }),
            );
        }
    }, [dispatch, marketplaces]);

    useEffect(() => {
        if (!storeId && stores?.items?.length) {
            setSelectedStoreId(stores.items[0].id);
        }
    }, [setSelectedStoreId, storeId, stores?.items]);

    useEffect(() => {
        if (!marketplace && marketplaces?.items?.length) {
            setSelectedMarketplace(marketplaces.items[0].slug);
        }
    }, [marketplace, marketplaces?.items, setSelectedMarketplace]);

    const storesOptions = (stores?.items || []).map((store) => ({
        value: store.id,
        label: store.title,
    }));

    const marketplaceOptions = (marketplaces?.items || []).map((marketplace) => ({
        value: marketplace.slug,
        label: marketplace.title,
    }));

    const handleStoreChange = (value: string) => {
        setSelectedStoreId(value);
    };

    const handleMarketplaceChange = (value: string) => {
        setSelectedMarketplace(value);
    };

    const selectedStore = stores?.items?.find((store) => store.id === storeId);

    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const logoSlug = marketplaces?.items?.find((el) => el.slug === marketplace)?.slug;
    const MPlogo = logoSlug ? getMarketPlaceIconBySlug(logoSlug) : null;

    const content = (
        <Row
            gutter={[16, 8]}
            style={{
                padding: 16,
                backgroundColor: colorBgContainer,
                borderRadius: 8,
                marginTop: 16,
                flexDirection: breakpoints.md ? 'row' : 'column',
            }}
        >
            <Col className="setup-integration-page__select-col">
                <Avatar
                    shape="square"
                    size={40}
                    icon={<Icon component={() => (MPlogo ? <MPlogo style={{ width: 40, height: 40 }} /> : <></>)} />}
                />
                <Select
                    value={marketplace}
                    disabled={disabled}
                    options={marketplaceOptions}
                    onChange={handleMarketplaceChange}
                />
            </Col>
            <Col style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Icon component={() => <InteractionIcon />} />
            </Col>
            <Col className="setup-integration-page__select-col">
                <Avatar
                    shape="square"
                    size={40}
                    icon={
                        selectedStore?.logo ? (
                            <Icon
                                style={{ width: 38, height: 38 }}
                                component={() => (
                                    <img
                                        style={{ objectFit: 'fill', width: '100%', height: '100%' }}
                                        src={selectedStore.logo}
                                        alt="store-logo"
                                    />
                                )}
                            />
                        ) : (
                            <UserOutlined />
                        )
                    }
                />
                <Select
                    value={storeId}
                    disabled={disabled}
                    options={storesOptions}
                    onChange={handleStoreChange}
                    style={{ maxWidth: '100%' }}
                />
            </Col>
        </Row>
    );

    return isMarketplacesLoading || isStoresLoading ? <Spin tip="Загрузка...">{content}</Spin> : content;
};
export default IntegrationFormSelectors;
