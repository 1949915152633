import { type MessageArgsProps } from 'antd';
import { createSlice } from '@reduxjs/toolkit';

export enum MessageType {
    Success = 'success',
    Error = 'error',
    Warning = 'warning',
    Info = 'info',
}

type ModalStateProps = {
    open: boolean;
    componentProps: MessageArgsProps;
};

const initialState: ModalStateProps = {
    open: false,
    componentProps: {
        content: '',
    },
};

const messagesSlice = createSlice({
    name: 'messages',
    initialState,
    reducers: {
        showMessage: (state, action) => {
            state.open = true;
            state.componentProps = action?.payload.componentProps;
        },
        closeMessage: (state) => {
            state.open = false;
            state.componentProps = { content: '' };
        },
    },
});

export const { showMessage, closeMessage } = messagesSlice.actions;
export default messagesSlice.reducer;
