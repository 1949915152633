import { FileApi } from '@OpenApi/typescript-axios';

const fileApi = new FileApi();

export const uploadImageRequest = async (options) => {
    const { onSuccess, onError, file } = options;

    const fmData = new FormData();
    fmData.append('file', file);
    try {
        const res = await fileApi.uploadANewFile({
            data: fmData,
            headers: { 'Content-Type': 'multipart/form-data' },
        });
        onSuccess(res.data.fileName);
    } catch (err) {
        onError({ err });
    }
};
