import { PromotionTypeEnum2 } from '@Shared/api/rtkQueryApis/sellerPromotions';

export enum FilterEnum {
    LIMIT = 'limit',
    PAGE = 'page',
    STORE_ID = 'storeId',
    ACTIVE = 'active',
    PROMOTION_TYPES = 'promotionTypes[]',
}

export type FilterObjectType = {
    page: number;
    limit: number;
    sku: string;
    storeId: string;
    active: boolean;
    promotionTypes: string[];
};

export const DEFAULT_PAGE_SIZE = 50;
export const DEFAULT_PAGE = 1;

export const defaultFilterObject: Partial<FilterObjectType> = {
    page: DEFAULT_PAGE,
    limit: DEFAULT_PAGE_SIZE,
};

export const PromotionTypeOptions = [
    {
        label: 'Промокод',
        value: PromotionTypeEnum2.Coupon,
    },
    {
        label: 'Акция',
        value: PromotionTypeEnum2.Sale,
    },
];
