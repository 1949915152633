import { SourceStatusEnum } from '@Shared/api/rtkQueryApis/publisherSources';

export function getSourceStatusBadgeType(status: SourceStatusEnum) {
    let type = 'default';
    switch (status) {
        case SourceStatusEnum.Approved:
            type = 'success';
            break;
        case SourceStatusEnum.Declined:
            type = 'error';
            break;
        case SourceStatusEnum.Verification:
            type = 'warning';
            break;
    }

    return type;
}
