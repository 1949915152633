import { emptySplitApi as api } from '../../full/emptyApi';

const injectedRtkApi = api.injectEndpoints({
    endpoints: (build) => ({
        viewPublisherLegalInfo: build.query<ViewPublisherLegalInfoApiResponse, ViewPublisherLegalInfoApiArg>({
            query: () => ({ url: '/api/publisher/v1/legal-info/' }),
        }),
        addPublisherLegalInfo: build.mutation<AddPublisherLegalInfoApiResponse, AddPublisherLegalInfoApiArg>({
            query: (queryArg) => ({
                url: '/api/publisher/v1/legal-info/',
                method: 'POST',
                body: queryArg.legalInfoDto,
            }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as PublisherLegalInfoApi };
export type ViewPublisherLegalInfoApiResponse = /** status 200 Resource response */ LegalInfoDto2;
export type ViewPublisherLegalInfoApiArg = void;
export type AddPublisherLegalInfoApiResponse = /** status 201 Resource created */ LegalInfoDto2;
export type AddPublisherLegalInfoApiArg = {
    legalInfoDto: LegalInfoDto;
};
export type LegalInfoDto2 = {
    id?: string | null;
    country: string;
    legal_type: LegalTypeEnum2 | null;
    title: string | null;
    inn?: string | null;
    tax_number?: string | null;
    mobile_phone?: string | null;
};
export type LegalInfoDto = {
    country: string;
    legal_type: LegalTypeEnum | null;
    title: string | null;
    inn?: string | null;
    tax_number?: string | null;
    mobile_phone?: string | null;
};
export enum LegalTypeEnum2 {
    NaturalPerson = 'natural_person',
    IndividualEntrepreneur = 'individual_entrepreneur',
    Company = 'company',
}
export enum LegalTypeEnum {
    NaturalPerson = 'natural_person',
    IndividualEntrepreneur = 'individual_entrepreneur',
    Company = 'company',
}
export const { useViewPublisherLegalInfoQuery, useAddPublisherLegalInfoMutation } = injectedRtkApi;
