import { Key, useCallback, useContext, useState, Dispatch, SetStateAction } from 'react';
import { Link } from 'react-router-dom';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { Button, type TableProps } from 'antd';

import { renderEmpty } from '@Features/shared';
import { Flex, InputNumber, Select, Space, Table, Typography, Grid, Row, QuestionTooltip } from '@Shared/ui';
import { type ProductCampaign, GetProductsListForCampaignApiResponse } from '@Shared/api/rtkQueryApis/sellerCampaigns';
import { formatPrice } from '@Shared/utils/formatPrice';
import { sendYmEvent } from '@Shared/utils/analytics/sendYmEvent';
import { useGetTheUserInformationQuery } from '@Shared/api/rtkQueryApis/user';
import { ProductNameCell } from '@Features/shared/TableCells';
import {
    ActionType,
    actionOptions,
    FilterObjectType,
} from '@Features/seller/productPromotion/campaignsTab/campaignTypeSelection/types';

import { CommissionCell } from './CommissionCell';
import { CampaignContext } from './campaignContext';
// import { ActionType, actionOptions, FilterObjectType } from '../../../campaignTypeSelection/types';

const { Text } = Typography;
const { useBreakpoint } = Grid;

type TableRowSelection<T extends object = object> = TableProps<T>['rowSelection'];

type CampaignProductsTableProps = {
    data: GetProductsListForCampaignApiResponse | undefined;
    isLoading?: boolean;
    setFilterObject: Dispatch<SetStateAction<FilterObjectType>>;
};

const columns = [
    {
        title: 'Название товара',
        dataIndex: 'title',
        key: 'title',
        ellipsis: true,
        width: 300,
        render: (_: unknown, { product }: ProductCampaign) => (
            <ProductNameCell
                product={product}
                showCategory
            />
        ),
    },
    {
        title: 'Артикул',
        dataIndex: 'sku',
        key: 'sku',
        render: (_: unknown, { product }: ProductCampaign) => product?.sku || '',
    },
    {
        title: (
            <>
                Стоимость &nbsp;
                <QuestionTooltip
                    title="Цена товара на маркетплейсе может отличаться. 
                    Вы получите комиссию от той цены, по которой товар будет куплен."
                />
            </>
        ),
        dataIndex: 'price',
        key: 'price',
        width: 150,
        render: (_: unknown, { product }: ProductCampaign) =>
            formatPrice({ price: product.price, currency: product.currency || {} }),
    },
    {
        title: 'Ссылка на МП',
        dataIndex: 'external_link',
        key: 'external_link',
        ellipsis: true,
        width: 'max(30%, 240px)',
        render: (_: unknown, { product }: ProductCampaign) => (
            <a
                target="_blank"
                rel="noopener noreferrer"
                href={product.external_link}
            >
                {product.external_link}
            </a>
        ),
    },
];

const CampaignProductsTable = ({ data, isLoading, setFilterObject }: CampaignProductsTableProps) => {
    const breakpoints = useBreakpoint();
    const { fee_setting, applyAction, storeId } = useContext(CampaignContext);
    const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
    const [selectedAction, setAction] = useState<string>(ActionType.changeCommission);
    const [selectedCommission, setCommission] = useState<number | null>(Math.floor(fee_setting?.fee_value * 100) || 10);

    const onSelectChange = (newSelectedRowKeys: Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection: TableRowSelection<ProductCampaign> = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const { data: user } = useGetTheUserInformationQuery();

    const handleApplyAction = useCallback(() => {
        if (selectedCommission === null) {
            return;
        }

        if (selectedAction === ActionType.changeCommission) {
            sendYmEvent({
                name: 'send_change_commission',
                o1: 'account;advertiser;ads campaign settings',
                o2: 'commissions',
                o4: selectedCommission.toString(),
                advertiserId: user?.seller?.id,
                keycloakId: user?.id,
                advcampaignId: storeId,
            });
        }

        applyAction({
            producstsIds: selectedRowKeys as string[],
            action: selectedAction as ActionType,
            commission: selectedCommission / 100,
        });
        setSelectedRowKeys([]);
    }, [selectedCommission, selectedAction, applyAction, selectedRowKeys, user?.seller?.id, user?.id, storeId]);

    const showColumn = {
        title: (
            <>
                Видимость &nbsp;
                <QuestionTooltip title="Вы можете остановить продвижение товара, скрыв его из каталога товаров" />
            </>
        ),
        dataIndex: 'showCell',
        key: 'showCell',
        width: 140,
        render: (text: unknown, { id, visible }: ProductCampaign) => (
            <Button
                type="link"
                style={{ color: visible ? 'var(--ant-color-link)' : 'var(--ant-color-text-description)' }}
                icon={visible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                onClick={() => {
                    applyAction({
                        producstsIds: [id],
                        action: visible ? ActionType.showStop : ActionType.showStart,
                    });
                }}
            />
        ),
    };

    const commissionColumn = {
        title: 'Коммисия',
        dataIndex: 'commissionCell',
        key: 'commissionCell',
        width: 240,
        render: (text: unknown, product: ProductCampaign) => (
            <CommissionCell
                product={product}
                campaign_fee_setting={fee_setting}
                applyAction={applyAction}
            />
        ),
    };

    const tableFooter = useCallback(() => {
        return (
            <Flex
                style={{ flexDirection: breakpoints.lg ? 'row' : 'column' }}
                justify="space-between"
            >
                <Space>
                    {selectedRowKeys.length > 0 ? (
                        <>
                            <Text type="secondary">Выбрано {selectedRowKeys.length}</Text>
                            <Button
                                type="link"
                                onClick={() => setSelectedRowKeys([])}
                            >
                                Отменить выбор
                            </Button>
                        </>
                    ) : (
                        <>&nbsp;</>
                    )}
                </Space>

                <Row
                    style={{ gap: 12, width: breakpoints.lg ? 'auto' : '100%' }}
                    wrap={breakpoints.md ? false : true}
                >
                    <Select
                        options={actionOptions}
                        onChange={setAction}
                        value={selectedAction}
                        style={{ flex: breakpoints.lg ? 'unset' : 'auto', width: breakpoints.md ? '225px' : '100%' }}
                    />
                    <InputNumber
                        disabled={false}
                        value={selectedCommission}
                        min={2}
                        max={100}
                        onChange={setCommission}
                        addonAfter="%"
                        step="1"
                        style={{ flex: breakpoints.lg ? 'unset' : 'auto', width: breakpoints.md ? '100px' : '100%' }}
                    />
                    <Button
                        type="primary"
                        ghost
                        onClick={handleApplyAction}
                        style={{ width: breakpoints.md ? 'auto' : '100%' }}
                    >
                        Подтвердить
                    </Button>
                </Row>
            </Flex>
        );
    }, [breakpoints, handleApplyAction, selectedAction, selectedCommission, selectedRowKeys.length]);

    return (
        <Table
            footer={tableFooter}
            scroll={{ x: '1000px' }}
            dataSource={data?.items || []}
            rowKey="id"
            columns={[...columns, showColumn, commissionColumn]}
            rowSelection={rowSelection}
            loading={isLoading}
            pagination={{
                showSizeChanger: true,
                hideOnSinglePage: true,
                pageSizeOptions: ['10', '25', '50'],
                pageSize: data?.limit,
                position: ['bottomLeft'],
                current: data?.page,
                total: data?.totalCount,
                onChange: (page, pageSize) =>
                    setFilterObject((prevFilterObject) => {
                        const hasPageSizeChanged = pageSize !== +prevFilterObject.limit;
                        return {
                            ...prevFilterObject,
                            page: hasPageSizeChanged ? 1 : page,
                            limit: pageSize,
                        };
                    }),
            }}
            locale={{
                emptyText: renderEmpty({
                    content: (
                        <>
                            Добавьте свой магазин и настройте хотя бы одну интеграцию
                            <br />
                            <Link to="/stores">Перейти в раздел “Магазины”</Link>
                        </>
                    ),
                }),
            }}
        />
    );
};

export default CampaignProductsTable;
