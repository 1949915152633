import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { fetchData } from '@Entities/keyValue/user/userSlice';
import { KeyValueKeysEnum } from '@Shared/model/seller/types';
import { Button, Typography, Modal } from '@Shared/ui';

const { Title } = Typography;

const ChooseUserRoleModal: FC = () => {
    const dispatch = useDispatch();

    const handleSelectRole = (role: string) => {
        dispatch(
            fetchData({
                key: KeyValueKeysEnum.postUser,
                body: {
                    [role]: true,
                },
            }),
        );
    };

    return (
        <Modal
            open={true}
            closable={false}
            title={
                <Title
                    level={4}
                    style={{ marginTop: 0 }}
                >
                    Выберите, пожалуйста, вашу роль
                </Title>
            }
            footer={[
                <Button
                    key="back"
                    type="primary"
                    onClick={() => handleSelectRole('is_seller')}
                >
                    Seller
                </Button>,
                <Button
                    key="submit"
                    type="primary"
                    onClick={() => handleSelectRole('is_publisher')}
                >
                    Publisher
                </Button>,
            ]}
        />
    );
};

export default ChooseUserRoleModal;
