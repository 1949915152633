import { useState, useCallback } from 'react';

import { useAppDispatch } from '@Shared/hooks';
import { showNotification, NotificationType } from '@Entities/notifications/model/notificationSlice';

export const useExport = () => {
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(false);

    const handleDownload = useCallback(
        async (url: string, fileName: string = 'products_export.csv') => {
            setIsLoading(true);

            try {
                const response = await fetch(url, {
                    credentials: 'include',
                });

                if (!response.ok) {
                    dispatch(
                        showNotification({
                            componentProps: {
                                type: NotificationType.Error,
                                message: 'Произошла ошибка при попытке экспорта продуктов',
                            },
                        }),
                    );
                }

                const blob = await response.blob();
                const downloadUrl = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.download = fileName;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(downloadUrl);
            } catch (err) {
                dispatch(
                    showNotification({
                        componentProps: {
                            type: NotificationType.Error,
                            message: 'Произошла ошибка при попытке экспорта продуктов',
                        },
                    }),
                );
            } finally {
                setIsLoading(false);
            }
        },
        [dispatch],
    );

    return { handleDownload, isLoading };
};
