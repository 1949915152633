import { Row, Grid } from '@Shared/ui';
import { FilterObjectType } from '@Features/seller/productPromotion/productsTab/model/types';
import { MarketplaceFilter, SellerStoreFilter } from '@Features/shared/filters';

const { useBreakpoint } = Grid;

type PromotionsFilterProps = {
    filterObject: FilterObjectType;
    setFilterObject: (arg1: FilterObjectType) => void;
};

const PromotionsFilters = (props: PromotionsFilterProps) => {
    const breakpoints = useBreakpoint();

    return (
        <Row
            justify="space-between"
            style={{ gap: 8, flexDirection: breakpoints.md ? 'row' : 'column' }}
        >
            <Row
                wrap
                align="middle"
                style={{
                    gap: 12,
                    flexDirection: breakpoints.md ? 'row' : 'column',
                    alignItems: breakpoints.md ? 'center' : 'flex-start',
                }}
            >
                <SellerStoreFilter<FilterObjectType>
                    {...props}
                    selectProps={{ mode: 'multiple' }}
                    propName="stores"
                />
                <MarketplaceFilter<FilterObjectType>
                    {...props}
                    selectProps={{ mode: 'multiple' }}
                    propName="marketplaces"
                />
            </Row>
        </Row>
    );
};

export default PromotionsFilters;
