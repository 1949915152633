import { Row, Grid } from '@Shared/ui';
import { FilterObjectType } from '@Features/seller/productPromotion/campaignsTab/model/types';
import { CampaignPaymentTypesFilter } from '@Features/shared/filters';

const { useBreakpoint } = Grid;

type CampaignsFiltersProps = {
    filterObject: FilterObjectType;
    setFilterObject: (arg1: FilterObjectType) => void;
};

const CampaignsFilters = (props: CampaignsFiltersProps) => {
    const breakpoints = useBreakpoint();

    return (
        <Row
            justify="space-between"
            style={{ gap: 8, flexDirection: breakpoints.md ? 'row' : 'column' }}
        >
            <Row
                wrap
                align="middle"
                style={{
                    gap: 12,
                    flexDirection: breakpoints.md ? 'row' : 'column',
                    alignItems: breakpoints.md ? 'center' : 'flex-start',
                }}
            >
                <CampaignPaymentTypesFilter<FilterObjectType>
                    {...props}
                    selectProps={{ mode: 'multiple' }}
                />
            </Row>
        </Row>
    );
};

export default CampaignsFilters;
