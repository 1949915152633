import { theme } from 'antd';

import { Typography } from '@Shared/ui';
import { type PromotionProductDto } from '@Shared/api/rtkQueryApis/publisherPromotions';
import { formatPrice } from '@Shared/utils/formatPrice';

const { Text } = Typography;

const ProductNameCell = ({ promotionProduct }: { promotionProduct: PromotionProductDto }) => {
    const {
        token: { fontSizeSM },
    } = theme.useToken();
    return (
        <>
            <Text>
                {formatPrice({
                    price: promotionProduct.price_discount || 0,
                    currency: promotionProduct?.product?.currency || {},
                })}
            </Text>
            <br />
            <Text
                type="secondary"
                style={{ fontSize: fontSizeSM }}
            >
                {formatPrice({
                    price: promotionProduct.price || 0,
                    currency: promotionProduct?.product?.currency || {},
                })}{' '}
                до акции
            </Text>
        </>
    );
};

export default ProductNameCell;
