import { theme } from 'antd';

import { Typography } from '@Shared/ui';
import { CommissionDto } from '@Shared/api/rtkQueryApis/sellerCommissions/SellerCommissionsApi';
import { formatPrice } from '@Shared/utils/formatPrice';

const { Text } = Typography;

const TotalCartCell = ({ commission }: { commission: CommissionDto }) => {
    const {
        token: { colorTextTertiary },
    } = theme.useToken();

    return (
        <>
            <Text>
                {commission.order_product
                    ? formatPrice({
                          price: commission.total_cart || 0,
                          currency: commission.order_product?.currency || {},
                      })
                    : ''}
            </Text>
            {commission.change_total_cart_reason ? (
                <Text style={{ color: colorTextTertiary, display: 'block' }}>Изменена</Text>
            ) : null}
        </>
    );
};

export default TotalCartCell;
