import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { PageContent, Breadcrumbs } from '@Features/shared';
import { Space, Skeleton } from '@Shared/ui';
import {
    PromotionSelectorCard,
    PromotionDetailsCard,
    ActionButtons,
    PromotionProductsTableCard,
} from '@Features/seller/promotions/editPromotion';
import { useViewThePromotionQuery } from '@Shared/api/rtkQueryApis/sellerPromotions';

const pageBreadcrumbs = [
    {
        path: '/promotions',
        title: 'Скидки на товары',
    },
    {
        path: '/promotions/edit-promotion',
        title: 'Добавление акции',
    },
];

const EditPromotionPage = () => {
    const { promotionId = '' } = useParams();
    const { data: promotion, isLoading } = useViewThePromotionQuery({ id: promotionId }, { skip: !promotionId });
    const [promotionInfoFormData, setPromotionFormData] = useState({});

    return (
        <PageContent>
            <Breadcrumbs items={pageBreadcrumbs} />

            {isLoading ? (
                <Skeleton active />
            ) : (
                <>
                    <Space
                        direction="vertical"
                        style={{ width: '100%' }}
                    >
                        <PromotionSelectorCard promotion={promotion} />
                        <PromotionDetailsCard
                            promotion={promotion}
                            setPromotionFormData={setPromotionFormData}
                        />
                        <PromotionProductsTableCard promotionId={promotionId} />
                    </Space>
                    <ActionButtons
                        promotion={promotion}
                        promotionInfoFormData={promotionInfoFormData}
                        promotionId={promotionId}
                    />
                </>
            )}
        </PageContent>
    );
};
export default EditPromotionPage;
