import { object, setLocale, type ObjectShape } from 'yup';
import { Rule } from 'antd/es/form';
import { ru } from 'yup-locales';

setLocale(ru);

export function useYup(formSchema: ObjectShape) {
    const schema = object().shape(formSchema);

    const yupSync = {
        async validator({ field }: { field: string }, value: unknown) {
            const fieldPath = field.split('.') || [];

            if (fieldPath.length > 1) {
                await schema.validateSyncAt(field, { [fieldPath[0]]: { [fieldPath[1]]: value } });
            } else {
                await schema.validateSyncAt(field, { [field]: value });
            }
        },
    } as unknown as Rule;

    return [yupSync];
}
