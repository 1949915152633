import { emptySplitApi as api } from '../../full/emptyApi';

const injectedRtkApi = api.injectEndpoints({
    endpoints: (build) => ({
        getGlobalPromotionsProductsListWithLinksBySource: build.query<
            GetGlobalPromotionsProductsListWithLinksBySourceApiResponse,
            GetGlobalPromotionsProductsListWithLinksBySourceApiArg
        >({
            query: (queryArg) => ({
                url: `/api/publisher/v1/source/${queryArg.source}/promotion/global/product/`,
                params: {
                    page: queryArg.page,
                    limit: queryArg.limit,
                    search_string: queryArg.searchString,
                    marketplaces: queryArg.marketplaces,
                    categories: queryArg.categories,
                    favorite: queryArg.favorite,
                },
            }),
        }),
        getGlobalPromotionsProductsListWithLinksBySourceToCsvFile: build.query<
            GetGlobalPromotionsProductsListWithLinksBySourceToCsvFileApiResponse,
            GetGlobalPromotionsProductsListWithLinksBySourceToCsvFileApiArg
        >({
            query: (queryArg) => ({
                url: `/api/publisher/v1/source/${queryArg.source}/promotion/global/product/export/`,
            }),
        }),
        getGlobalPromotionsProductsDetailsWithLinksBySource: build.query<
            GetGlobalPromotionsProductsDetailsWithLinksBySourceApiResponse,
            GetGlobalPromotionsProductsDetailsWithLinksBySourceApiArg
        >({
            query: (queryArg) => ({
                url: `/api/publisher/v1/source/${queryArg.source}/promotion/global/product/${queryArg.promotionProduct}/`,
            }),
        }),
        getPersonalPromotionsListWithLinksBySource: build.query<
            GetPersonalPromotionsListWithLinksBySourceApiResponse,
            GetPersonalPromotionsListWithLinksBySourceApiArg
        >({
            query: (queryArg) => ({
                url: `/api/publisher/v1/source/${queryArg.source}/promotion/personal/`,
                params: {
                    page: queryArg.page,
                    limit: queryArg.limit,
                    marketplaces: queryArg.marketplaces,
                    promotionTypes: queryArg.promotionTypes,
                    favorite: queryArg.favorite,
                },
            }),
        }),
        exportPersonalPromotionsListWithLinksBySourceToCsvFile: build.query<
            ExportPersonalPromotionsListWithLinksBySourceToCsvFileApiResponse,
            ExportPersonalPromotionsListWithLinksBySourceToCsvFileApiArg
        >({
            query: (queryArg) => ({ url: `/api/publisher/v1/source/${queryArg.source}/promotion/personal/export/` }),
        }),
        exportPersonalPromotionProductsListWithLinksBySourceToCsvFile: build.query<
            ExportPersonalPromotionProductsListWithLinksBySourceToCsvFileApiResponse,
            ExportPersonalPromotionProductsListWithLinksBySourceToCsvFileApiArg
        >({
            query: (queryArg) => ({
                url: `/api/publisher/v1/source/${queryArg.source}/promotion/personal/${queryArg.promotion}/export/`,
            }),
        }),
        getPersonalPromotionsDetailsWithLinksBySource: build.query<
            GetPersonalPromotionsDetailsWithLinksBySourceApiResponse,
            GetPersonalPromotionsDetailsWithLinksBySourceApiArg
        >({
            query: (queryArg) => ({
                url: `/api/publisher/v1/source/${queryArg.source}/promotion/personal/${queryArg.promotion}/`,
            }),
        }),
        addAPersonalPromotionToFavorites: build.mutation<
            AddAPersonalPromotionToFavoritesApiResponse,
            AddAPersonalPromotionToFavoritesApiArg
        >({
            query: (queryArg) => ({
                url: `/api/publisher/v1/promotion/personal/${queryArg.promotion}/favorites/`,
                method: 'POST',
            }),
        }),
        removePersonalPromotionFromFavorites: build.mutation<
            RemovePersonalPromotionFromFavoritesApiResponse,
            RemovePersonalPromotionFromFavoritesApiArg
        >({
            query: (queryArg) => ({
                url: `/api/publisher/v1/promotion/personal/${queryArg.promotion}/favorites/`,
                method: 'DELETE',
            }),
        }),
        addAGlobalPromotionProductToFavorites: build.mutation<
            AddAGlobalPromotionProductToFavoritesApiResponse,
            AddAGlobalPromotionProductToFavoritesApiArg
        >({
            query: (queryArg) => ({
                url: `/api/publisher/v1/promotion/global/product/${queryArg.promotionProduct}/favorites/`,
                method: 'POST',
            }),
        }),
        removeGlobalPromotionProductFromFavorites: build.mutation<
            RemoveGlobalPromotionProductFromFavoritesApiResponse,
            RemoveGlobalPromotionProductFromFavoritesApiArg
        >({
            query: (queryArg) => ({
                url: `/api/publisher/v1/promotion/global/product/${queryArg.promotionProduct}/favorites/`,
                method: 'DELETE',
            }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as PublisherPromotionsApi };
export type GetGlobalPromotionsProductsListWithLinksBySourceApiResponse =
    /** status 200 Get global promotions products list with links by source */ {
        totalCount?: number;
        page?: number;
        limit?: number;
        items?: PromotionProductDto[];
    };
export type GetGlobalPromotionsProductsListWithLinksBySourceApiArg = {
    source: string;
    page?: number;
    limit?: number;
    searchString?: string | null;
    marketplaces?: {
        ''?: string[];
    };
    categories?: {
        ''?: number[];
    };
    favorite?: boolean;
};
export type GetGlobalPromotionsProductsListWithLinksBySourceToCsvFileApiResponse = unknown;
export type GetGlobalPromotionsProductsListWithLinksBySourceToCsvFileApiArg = {
    source: string;
};
export type GetGlobalPromotionsProductsDetailsWithLinksBySourceApiResponse =
    /** status 200 Get global promotions products details with links by source */ PromotionProductDto;
export type GetGlobalPromotionsProductsDetailsWithLinksBySourceApiArg = {
    source: string;
    promotionProduct: string;
};
export type GetPersonalPromotionsListWithLinksBySourceApiResponse =
    /** status 200 Get personal promotions list with links by source */ {
        totalCount?: number;
        page?: number;
        limit?: number;
        items?: PromotionDto2[];
    };
export type GetPersonalPromotionsListWithLinksBySourceApiArg = {
    source: string;
    page?: number;
    limit?: number;
    marketplaces?: {
        ''?: string[];
    };
    promotionTypes?: {
        ''?: PromotionTypeEnum[];
    };
    favorite?: boolean;
};
export type ExportPersonalPromotionsListWithLinksBySourceToCsvFileApiResponse = unknown;
export type ExportPersonalPromotionsListWithLinksBySourceToCsvFileApiArg = {
    source: string;
};
export type ExportPersonalPromotionProductsListWithLinksBySourceToCsvFileApiResponse = unknown;
export type ExportPersonalPromotionProductsListWithLinksBySourceToCsvFileApiArg = {
    source: string;
    promotion: string;
};
export type GetPersonalPromotionsDetailsWithLinksBySourceApiResponse =
    /** status 200 Get personal promotions details with links by source */ PromotionDto2;
export type GetPersonalPromotionsDetailsWithLinksBySourceApiArg = {
    source: string;
    promotion: string;
};
export type AddAPersonalPromotionToFavoritesApiResponse = /** status 204 Empty response */ void;
export type AddAPersonalPromotionToFavoritesApiArg = {
    promotion: string;
};
export type RemovePersonalPromotionFromFavoritesApiResponse = /** status 204 Resource removed */ void;
export type RemovePersonalPromotionFromFavoritesApiArg = {
    promotion: string;
};
export type AddAGlobalPromotionProductToFavoritesApiResponse = /** status 204 Empty response */ void;
export type AddAGlobalPromotionProductToFavoritesApiArg = {
    promotionProduct: string;
};
export type RemoveGlobalPromotionProductFromFavoritesApiResponse = /** status 204 Resource removed */ void;
export type RemoveGlobalPromotionProductFromFavoritesApiArg = {
    promotionProduct: string;
};
export type MarketplaceDto2 = {
    id: string;
    title: string;
    slug: MarketPlaceSlugEnum4;
    description?: string | null;
};
export type StoreDto3 = {
    id: string;
    title: string;
    comment?: string | null;
    logo?: string | null;
    marketplaceStores?: MarketplaceStoreDto2[];
    active: boolean;
};
export type MarketplaceStoreDto2 = {
    id: string;
    marketplace: MarketplaceDto2;
    store: StoreDto3;
};
export type PromotionDto3 = {
    id: string;
    type: PromotionTypeEnum4;
    title: string;
    description: string | null;
    marketplace_store: MarketplaceStoreDto2;
    start_date: string;
    end_date: string;
    active?: boolean;
    visible?: boolean;
    orderAmount?: number | null;
    coupon?: string | null;
    discount_type?: DiscountTypeEnum3 | null;
    discountValue?: string | null;
};
export type Store3 = {
    id: string;
    title: string;
    logo?: string | null;
};
export type Marketplace2 = {
    id: string;
    title: string;
    slug: MarketPlaceSlugEnum4;
    description?: string | null;
};
export type MarketplaceStore3 = {
    id: string;
    store?: Store3 | null;
    marketplace?: Marketplace2 | null;
};
export type Currency = {
    shortCode: string;
    symbol: string;
};
export type ProductCategory = {
    id: number;
    title: string;
};
export type ProductDto = {
    id: string;
    marketplace_store: MarketplaceStore3 | null;
    title: string;
    sku: string;
    description: string;
    price: number | null;
    currency: Currency | null;
    product_category?: ProductCategory | null;
    external_link: string;
    external_id: string;
    image_url?: string | null;
};
export type FeeSettingDto = {
    fee_type?: FeeTypeEnum | null;
    fee_value?: number | null;
};
export type PromotionProductDto = {
    id: string;
    price?: number | null;
    price_discount?: number | null;
    price_percent?: number | null;
    promotion?: PromotionDto3 | null;
    product?: ProductDto | null;
    legal_text?: string | null;
    tracking_link?: string | null;
    fee_setting?: FeeSettingDto | null;
    favorite?: boolean;
    tracking_short_link?: string | null;
};
export type PromotionDto2 = {
    id?: number | null;
    title?: string | null;
    description?: string | null;
    marketplace_store?: MarketplaceStore3 | null;
    discount_type?: DiscountTypeEnum3 | null;
    discount_value?: string | null;
    start_date?: string | null;
    end_date?: string | null;
    coupon?: string | null;
    landing_link?: string | null;
    favorite?: boolean;
    legal_text?: string | null;
};
export enum PromotionTypeEnum4 {
    GlobalSale = 'global_sale',
    Sale = 'sale',
    Coupon = 'coupon',
}
export enum MarketPlaceSlugEnum4 {
    Ozon = 'ozon',
    Wildberries = 'wildberries',
}
export enum DiscountTypeEnum3 {
    Fixed = 'fixed',
    Percentage = 'percentage',
}
export enum FeeTypeEnum {
    Fixed = 'fixed',
    Percentage = 'percentage',
}
export enum PromotionTypeEnum {
    GlobalSale = 'global_sale',
    Sale = 'sale',
    Coupon = 'coupon',
}
export const {
    useGetGlobalPromotionsProductsListWithLinksBySourceQuery,
    useGetGlobalPromotionsProductsListWithLinksBySourceToCsvFileQuery,
    useGetGlobalPromotionsProductsDetailsWithLinksBySourceQuery,
    useGetPersonalPromotionsListWithLinksBySourceQuery,
    useExportPersonalPromotionsListWithLinksBySourceToCsvFileQuery,
    useExportPersonalPromotionProductsListWithLinksBySourceToCsvFileQuery,
    useGetPersonalPromotionsDetailsWithLinksBySourceQuery,
    useAddAPersonalPromotionToFavoritesMutation,
    useRemovePersonalPromotionFromFavoritesMutation,
    useAddAGlobalPromotionProductToFavoritesMutation,
    useRemoveGlobalPromotionProductFromFavoritesMutation,
} = injectedRtkApi;
