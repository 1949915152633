import { FC } from 'react';
import Icon from '@ant-design/icons';

import { Avatar } from '@Shared/ui';
import { getMarketPlaceIconBySlug } from '@Shared/utils/stores/getMarkeplaceIconBySlug';

type MPAvatarProps = {
    isActive?: boolean;
    slug?: string;
};

const MPAvatar: FC<MPAvatarProps> = ({ isActive = true, slug }) => {
    const MPlogo = slug ? getMarketPlaceIconBySlug(slug) : null;

    return (
        <Avatar
            style={{ filter: isActive ? 'unset' : 'grayscale(1)' }}
            shape="square"
            size={36}
            icon={<Icon component={() => (MPlogo ? <MPlogo style={{ width: 40, height: 40 }} /> : null)} />}
        />
    );
};

export default MPAvatar;
