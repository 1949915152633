import { formSliceGeneric } from '@Shared/utils/redux/seller/formsGenericSlice';
import { Store } from '@OpenApi/typescript-axios';

export type EditStoreFormType = {
    isLoading: boolean;
    isError: boolean;
    isSubmitting: boolean;
    isSubmitted: boolean;
    initialData: Store;
    formData: Store;
    errors: Store;
};

export const editStoreFormSlice = formSliceGeneric({
    name: 'editStore',
    stateSelector: (state) => state.forms.seller.stores.editStore,
});

export const { updateFormValue, resetForm } = editStoreFormSlice.actions;
export const { fetchForm, submitForm, selectState } = editStoreFormSlice;

export default editStoreFormSlice.reducer;
