import { MouseEvent } from 'react';

import { Button } from '@Shared/ui';
import { useGetTheUserInformationQuery } from '@Shared/api/rtkQueryApis/user/UserApi';
import { useExport } from '@Shared/hooks';

const BASE_URL = process.env.SERVER_URL;

const ExportPromotionProducts = ({ sourceId, promotionId }: { sourceId: string; promotionId: string | number }) => {
    const { data: user } = useGetTheUserInformationQuery();
    const { handleDownload, isLoading } = useExport();

    const handleClick = (e: MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        handleDownload(
            `${BASE_URL}/api/publisher/v1/source/${sourceId}/promotion/personal/${promotionId}/export/`,
            'promotion_products_export.csv',
        );
    };

    return (
        <Button
            type="link"
            onClick={handleClick}
            loading={isLoading}
            disabled={!user?.publisher?.legalInfo}
        >
            Скачать
        </Button>
    );
};

export default ExportPromotionProducts;
