import { Card, Row, Typography, Grid } from '@Shared/ui';

import BalanceCard from './BalanceCard';
import PayoutCard from './PayoutCard';

const { Title, Text } = Typography;
const { useBreakpoint } = Grid;

const FinancesInfoCard = () => {
    const breakpoints = useBreakpoint();

    return (
        <Card>
            <Title
                level={3}
                style={{ marginTop: 0 }}
            >
                Финансы
            </Title>
            <Text type="secondary">В этом разделе вы можете пополнять баланс и следить за его историей.</Text>
            <Row
                wrap={false}
                style={{ gap: 20, marginTop: 24, flexDirection: breakpoints.lg ? 'row' : 'column' }}
            >
                <BalanceCard />
                <PayoutCard />
            </Row>
        </Card>
    );
};
export default FinancesInfoCard;
