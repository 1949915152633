export enum FilterEnum {
    LIMIT = 'limit',
    PAGE = 'page',
    MARKETPLACES = 'marketplaces[]',
    STORES = 'stores[]',
    CATEGORIES = 'categories[]',
    FAVORITE = 'favorite',
}

export type FilterObjectType = {
    page: number;
    limit: number;
    stores: string[];
    marketplaces: string[];
    categories: string[];
    favorite: boolean;
};

export const DEFAULT_PAGE_SIZE = 50;
export const DEFAULT_PAGE = 1;

export const defaultFilterObject: Partial<FilterObjectType> = {
    page: DEFAULT_PAGE,
    limit: DEFAULT_PAGE_SIZE,
};
