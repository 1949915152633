import { keyValueSliceGeneric } from '@Shared/utils/redux/publisher/keyValueGenericSlice';
import { SourceDto } from '@OpenApi/typescript-axios';

export type SourcesKeyValueType = {
    isLoading: boolean;
    isError: boolean;
    isFulfilled: boolean;
    isSubmitted: boolean;
    value: { items: SourceDto[] };
};

export const sourcesSlice = keyValueSliceGeneric({
    name: 'sources',
    stateSelector: (state) => state.keyValue.publisher.sources,
});

export const { fetchData, selectState, selectById } = sourcesSlice;

export default sourcesSlice.reducer;
