import { Typography, Space } from '@Shared/ui';
import { MPAvatar } from '@Features/shared';
import { MarketplaceStoreDto2 } from '@Shared/api/full';

const { Text } = Typography;

const MPTitleCell = ({
    marketplaceStore,
    isActive,
}: {
    marketplaceStore: MarketplaceStoreDto2;
    isActive: boolean | undefined;
}) => {
    const slug = marketplaceStore.marketplace.slug;
    return (
        <Space>
            <MPAvatar
                slug={slug}
                isActive={isActive || false}
            />
            <Text>{marketplaceStore.store.title}</Text>
        </Space>
    );
};

export default MPTitleCell;
