import { useEffect } from 'react';
import Joyride from 'react-joyride';
import { theme } from 'antd';

import { useAppSelector, useAppDispatch } from '@Shared/hooks';
import { setRun, nextStep, goToStep, setSteps } from '@Entities/joyride/model/joyrideSlice';
import { useGetTheUserInformationQuery, useAddOnboardingStatusMutation } from '@Shared/api/rtkQueryApis/user';
import { SellerOnboardingStatuses } from '@Shared/model/joyrideTypes';

import { sellerJoyrideSteps } from './sellerSteps';

const SellerJoyRide = () => {
    const dispatch = useAppDispatch();
    const {
        token: { colorPrimary },
    } = theme.useToken();
    const { run, stepIndex, steps, status } = useAppSelector((state) => state.joyride);
    const { data: user, isSuccess: isUserFulfilled } = useGetTheUserInformationQuery();
    const [addOnboardingStatus] = useAddOnboardingStatusMutation();

    useEffect(() => {
        dispatch(setSteps(sellerJoyrideSteps));
    }, [dispatch]);

    useEffect(() => {
        if (isUserFulfilled) {
            const onboardingStatuses = user?.onboarding_statuses || [];
            const hasLegalInfo = user.seller?.legalInfo;
            const hasPositiveBalance = user?.balance || 0 > 0;

            if (!onboardingStatuses.includes(SellerOnboardingStatuses.LEGAL_INFO) && hasLegalInfo) {
                addOnboardingStatus({ status: SellerOnboardingStatuses.LEGAL_INFO });
            }
            if (!onboardingStatuses.includes(SellerOnboardingStatuses.TOP_UP_BALANCE) && hasPositiveBalance) {
                addOnboardingStatus({ status: SellerOnboardingStatuses.TOP_UP_BALANCE });
            }
            if (
                !onboardingStatuses.includes(SellerOnboardingStatuses.CAMPAIGN_LAUNCH) &&
                onboardingStatuses.includes(SellerOnboardingStatuses.MARKETPLACE_STORE_INTEGRATION)
            ) {
                dispatch(setRun(true));
                dispatch(goToStep({ status: SellerOnboardingStatuses.CAMPAIGN_LAUNCH, order: 1 }));
            } else if (
                onboardingStatuses.includes(SellerOnboardingStatuses.CAMPAIGN_LAUNCH) &&
                !onboardingStatuses.includes(SellerOnboardingStatuses.LEGAL_INFO) &&
                status !== SellerOnboardingStatuses.LEGAL_INFO
            ) {
                dispatch(setRun(true));
                dispatch(goToStep({ status: SellerOnboardingStatuses.LEGAL_INFO }));
            } else if (
                onboardingStatuses.includes(SellerOnboardingStatuses.CAMPAIGN_LAUNCH) &&
                onboardingStatuses.includes(SellerOnboardingStatuses.LEGAL_INFO) &&
                !onboardingStatuses.includes(SellerOnboardingStatuses.TOP_UP_BALANCE)
            ) {
                dispatch(setRun(true));
                dispatch(goToStep({ status: SellerOnboardingStatuses.TOP_UP_BALANCE }));
            }
        }
    }, [addOnboardingStatus, dispatch, isUserFulfilled, status, user]);

    const handleJoyrideCallback = (data) => {
        const { action, type, step } = data;

        if (type === 'step:after' && action === 'next') {
            if (step?.pauseAfterNext) {
                dispatch(setRun(false));
            } else {
                dispatch(nextStep());
            }
            if (step?.stepStatus && step?.postStatusToServer) {
                addOnboardingStatus({ status: step.stepStatus });
            }
        }

        if (type === 'tour:end') {
            dispatch(setRun(false));
        }
    };

    return (
        <Joyride
            steps={steps}
            run={run}
            stepIndex={stepIndex}
            continuous={true}
            showSkipButton={false}
            callback={handleJoyrideCallback}
            hideBackButton={true}
            disableCloseOnEsc
            disableScrollParentFix
            disableOverlayClose={true}
            locale={{
                next: 'Далее',
                skip: 'Пропустить',
                last: 'Финиш',
            }}
            styles={{
                options: {
                    zIndex: 1000, // Ensure it appears above other elements
                    primaryColor: colorPrimary,
                },
                tooltip: {
                    borderRadius: 10,
                    fontFamily: 'Rubik',
                },
                buttonNext: {
                    borderRadius: 10,
                    paddingInline: 16,
                },
                buttonBack: {
                    borderRadius: 10,
                    paddingInline: 16,
                },
                buttonClose: {
                    display: 'none', // Hide the close (X) button
                },
            }}
        />
    );
};

export default SellerJoyRide;
