import { createSlice } from '@reduxjs/toolkit';

import { SellerOnboardingStatuses, PublisherOnboardingStatuses, JoyrideStepType } from '@Shared/model/joyrideTypes';

const initialState = {
    run: false,
    stepIndex: 0,
    stepIndexOrder: null as number | null,
    status: null as SellerOnboardingStatuses | PublisherOnboardingStatuses | null,
    steps: [] as JoyrideStepType<SellerOnboardingStatuses | PublisherOnboardingStatuses>[],
};

const joyrideSlice = createSlice({
    name: 'joyride',
    initialState,
    reducers: {
        setSteps: (state, action) => {
            state.steps = action.payload;
        },
        setRun: (state, action) => {
            state.run = action.payload;
        },
        nextStep: (state) => {
            const hasStatusOrder = state.steps[state.stepIndex + 1]?.stepStatusOrder;
            if (hasStatusOrder && state.stepIndexOrder) {
                state.stepIndexOrder += 1;
            } else {
                state.stepIndexOrder = 1;
            }
            state.stepIndex += 1;
        },
        resetTour: (state) => {
            state.stepIndex = 0;
            state.run = false;
        },
        goToStep: (state, action) => {
            const statusOrder = action?.payload?.order;
            const stepIndex = state.steps.findIndex((step) => {
                if (statusOrder) {
                    return step?.stepStatusOrder === statusOrder && step?.stepStatus === action?.payload?.status;
                } else {
                    return step?.stepStatus === action?.payload?.status;
                }
            });
            state.status = action?.payload?.status;
            if (stepIndex !== -1) {
                state.stepIndex = stepIndex;
                state.stepIndexOrder = statusOrder;
            }
        },
    },
});

export const { setSteps, setRun, nextStep, resetTour, goToStep } = joyrideSlice.actions;
export default joyrideSlice.reducer;
