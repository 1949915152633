import { Grid } from '@Shared/ui';

import { DesktopPageHeader, MobilePageHeader } from './headers';

const { useBreakpoint } = Grid;

type PageHeaderProps = {
    menuCollapsed: boolean;
    setMenuCollapsed: (value: boolean) => unknown;
    selectedKey: string;
};

const PageHeader = (props: PageHeaderProps) => {
    const breakpoints = useBreakpoint();

    return breakpoints.md ? <DesktopPageHeader {...props} /> : <MobilePageHeader {...props} />;
};

export default PageHeader;
