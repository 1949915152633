import { useState, useEffect } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';

import { Card } from '@Shared/ui';
import { Breadcrumbs, type BreadcrumbItemType, PageContent } from '@Features/shared';

const pageBreadcrumbs: BreadcrumbItemType[] = [
    {
        path: '/finances',
        title: 'Финансы',
    },
];

const tabList = [
    {
        key: '/finances',
        tab: 'Финансы',
    },
    {
        key: '/finances/manual-verification',
        tab: 'Ручная сверка',
    },
];

const FinancesPage = () => {
    const navigate = useNavigate();
    const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbItemType[]>(pageBreadcrumbs);
    const [activeTabKey, setActiveTabKey] = useState<string>(location?.pathname || tabList[0].key);

    const setTabAndBreadcrumbs = (key: string) => {
        setActiveTabKey(key);
        setBreadcrumbs(() => {
            const tabBreadcrumb = tabList.find((tab) => tab.key === key);
            const isDefaultTab = pageBreadcrumbs.find((el) => el.path === key);
            if (isDefaultTab) {
                return pageBreadcrumbs;
            }
            return [
                ...pageBreadcrumbs,
                {
                    path: tabBreadcrumb!.key,
                    title: tabBreadcrumb!.tab,
                },
            ];
        });
    };

    useEffect(() => {
        setTabAndBreadcrumbs(location.pathname);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    const onTabChange = (key: string) => {
        setTabAndBreadcrumbs(key);
        navigate(key);
    };

    return (
        <PageContent>
            <Breadcrumbs items={breadcrumbs} />

            <Card
                onTabChange={onTabChange}
                activeTabKey={activeTabKey}
                tabList={tabList}
                style={{ width: '100%', paddingTop: 24 }}
            >
                <Outlet />
            </Card>
        </PageContent>
    );
};
export default FinancesPage;
