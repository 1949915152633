import { Typography, Space } from '@Shared/ui';
import { MPAvatar } from '@Features/shared';
import { MarketplaceStore2 } from '@Shared/api/rtkQueryApis/publisherPromotions';

const { Text } = Typography;

const MPTitleCell = ({ marketplaceStore }: { marketplaceStore: MarketplaceStore2 | null | undefined }) => {
    const slug = marketplaceStore?.marketplace?.slug;
    return (
        <Space>
            <MPAvatar slug={slug} />
            <Text>{marketplaceStore?.store?.title}</Text>
        </Space>
    );
};

export default MPTitleCell;
