import { Breadcrumb, Layout, Typography, Space, Row, Col, Card, Radio, theme } from 'antd';

import ExampleTable1 from './ExampleTable1';
import ExampleChart1 from './ExampleChart1';
import ExampleChart2 from './ExampleChart2';
import ExmapleChart3 from './ExampleChart3';

import './styles.scss';

const { Content } = Layout;

const { Title } = Typography;

const DashboardPage = () => {
    const {
        token: { colorBgContainer },
    } = theme.useToken();

    return (
        <Content
            className="page-content"
            style={{
                background: colorBgContainer,
            }}
        >
            <Breadcrumb className="page-breadcrumb">
                <Breadcrumb.Item>Дашбоард</Breadcrumb.Item>
            </Breadcrumb>
            <Space
                direction="vertical"
                style={{ width: '100%' }}
            >
                <Card className="dashboard-card">
                    <Card.Meta
                        title={<Title level={3}>Активность вебмастеров</Title>}
                        description="Список самых эффективных и самых неэфективных вебмастеров"
                    />
                    <p>&nbsp;</p>
                    <Row gutter={32}>
                        <Col span={12}>
                            <Card
                                type="inner"
                                title={<Title level={4}>Мои герои</Title>}
                                extra={
                                    <Radio.Group>
                                        <Radio.Button value="small">По кликам</Radio.Button>
                                        <Radio.Button value="middle">По заказам</Radio.Button>
                                    </Radio.Group>
                                }
                            >
                                <p>Table</p>
                            </Card>
                        </Col>
                        <Col span={12}>
                            <Card
                                type="inner"
                                title={<Title level={4}>Мои отстающие</Title>}
                                extra={
                                    <Radio.Group>
                                        <Radio.Button value="small">По кликам</Radio.Button>
                                        <Radio.Button value="middle">По заказам</Radio.Button>
                                    </Radio.Group>
                                }
                            >
                                <p>Table</p>
                            </Card>
                        </Col>
                    </Row>
                </Card>

                <Card className="dashboard-card">
                    <Card.Meta title={<Title level={3}>Активность по брендам и продуктам</Title>} />
                    <p>&nbsp;</p>
                    <Row gutter={32}>
                        <Col span={8}>
                            <Card>
                                <Card.Meta description="Количество  активных брендов" />
                                <ExampleChart1 />
                            </Card>
                        </Col>
                        <Col span={8}>
                            <Card>
                                <Card.Meta description="Количество  активных брендов" />
                                <ExampleChart2 />
                            </Card>
                        </Col>
                        <Col span={8}>
                            <Card>
                                <Card.Meta description="Количество  активных брендов" />
                                <ExmapleChart3 />
                            </Card>
                        </Col>
                    </Row>
                </Card>

                <Card title={<Title level={3}>Основные действий</Title>}>
                    <Card.Meta
                        description={
                            'В данном блоке у селлера есть возможность посмотреть как сводки по \
                            ожидающимся выплатам и запросам на подключение вебмастеров, так и сразу перейти \
                                в нужные разделы.'
                        }
                    />
                </Card>
                <Card className="dashboard-card">
                    <Row gutter={32}>
                        <Col span={14}>
                            <Card
                                type="inner"
                                title={<Title level={5}>Новые заявки от вебмастеров</Title>}
                            >
                                <Card.Meta description="Вебмастеры ожидающие принятия " />
                                <p>Table</p>
                            </Card>
                        </Col>
                        <Col span={10}>
                            <Card
                                type="inner"
                                title={<Title level={5}>Выплаты</Title>}
                            >
                                <Card.Meta description="Вебмастеры ожидающие принятия" />
                                <p>Chart</p>
                            </Card>
                        </Col>
                    </Row>
                </Card>
                <Card className="dashboard-card">
                    <Row gutter={32}>
                        <Col span={16}>
                            <Card
                                className="dashboard-card-inner"
                                type="inner"
                                title={<Title level={5}>Новые заявки от вебмастеров</Title>}
                            >
                                <ExampleTable1 />
                            </Card>
                        </Col>
                        <Col span={8}>
                            <Card
                                type="inner"
                                title={<Title level={5}>Выплаты</Title>}
                            >
                                <p>Chart</p>
                            </Card>
                        </Col>
                    </Row>
                </Card>
                <Card className="dashboard-card">
                    <Card.Meta title={<Title level={4}>Основные показатели</Title>} />
                    <Card.Meta
                        description={
                            'В данном блоке у селлера должна быть возможность \
                                посмотреть все свои основные показатели за определенный промежуток времени:'
                        }
                    />
                    <p>&nbsp;</p>
                    <Row gutter={32}>
                        <Col span={6}>
                            <Card>
                                <Card.Meta description="Клики" />
                                <p>Chart</p>
                            </Card>
                        </Col>
                        <Col span={6}>
                            <Card>
                                <Card.Meta description="Заказы" />
                                <p>Chart</p>
                            </Card>
                        </Col>
                        <Col span={6}>
                            <Card>
                                <Card.Meta description="Суммы заказов" />
                                <p>Chart</p>
                            </Card>
                        </Col>
                        <Col span={6}>
                            <Card>
                                <Card.Meta description="Суммы коммисий" />
                                <p>Chart</p>
                            </Card>
                        </Col>
                    </Row>
                </Card>

                <Card
                    title={<Title level={4}>Общая сумма комиссий (с валютой)</Title>}
                    extra={
                        <Radio.Group>
                            <Radio.Button value="small">Клики</Radio.Button>
                            <Radio.Button value="middle">Заказы</Radio.Button>
                            <Radio.Button value="sum_middle">Суммы заказов</Radio.Button>
                            <Radio.Button value="sum_com">Суммы коммисий</Radio.Button>
                        </Radio.Group>
                    }
                >
                    <p>Table</p>
                </Card>
            </Space>
        </Content>
    );
};
export default DashboardPage;
