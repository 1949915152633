import { MarketplaceIcons } from '@Shared/assets/images/icons';

const marketPlaceIcons = {
    ozon: MarketplaceIcons.OzonIcon,
    wildberries: MarketplaceIcons.WildberriesIcon,
};

export function getMarketPlaceIconBySlug(slug: string) {
    return marketPlaceIcons[slug];
}
