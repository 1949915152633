import { Typography as _Typography } from 'antd';

const { Title, Paragraph, Text, Link } = _Typography;

export default {
    Title,
    Paragraph,
    Text,
    Link,
};
