import { useState, useEffect } from 'react';
import { type InputProps } from 'antd';

import { Input, Grid } from '@Shared/ui';

const { Search } = Input;
const { useBreakpoint } = Grid;

type SearchFilterProps<FilterObjectType> = {
    filterObject: FilterObjectType;
    setFilterObject: (arg1: FilterObjectType) => void;
    propName?: keyof FilterObjectType;
    inputProps?: InputProps;
};

const SearchFilter = <FilterObjectType,>({
    filterObject,
    setFilterObject,
    propName = 'search' as keyof FilterObjectType,
    inputProps = {},
}: SearchFilterProps<FilterObjectType>) => {
    const breakpoints = useBreakpoint();
    const [searchValue, setSearchValue] = useState<string>(filterObject[propName] as string);

    useEffect(() => {
        setSearchValue(filterObject[propName] as string);
    }, [filterObject, propName]);

    const handleOnSearch = (value: string) => {
        setFilterObject({ ...filterObject, [propName]: value });
    };

    return (
        <Search
            placeholder="Поиск"
            value={searchValue}
            onSearch={handleOnSearch}
            onChange={(e) => setSearchValue(e.target.value)}
            style={{ width: breakpoints.md ? 240 : '100%' }}
            {...inputProps}
        />
    );
};

export default SearchFilter;
