import { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';

import { Space, Button, Row } from '@Shared/ui';
import { useGetSellerPromotionsListQuery, PromotionTypeEnum2 } from '@Shared/api/rtkQueryApis/sellerPromotions';
import {
    FilterEnum,
    FilterObjectType,
    defaultFilterObject,
} from '@Features/seller/promotions/personalPromotionsTab/model/types';
// eslint-disable-next-line max-len
import PersonalPromotionDetailsDrawer from '@Features/seller/promotions/personalPromotionsTab/personalPromotionsDrawer/PersonalPromotionDetailsDrawer';
import { useUpdateSearchParams } from '@Shared/hooks';

import { DeclinePromotionParticipationModal, DeletePromotionModal } from './modals';
import PersonalPromotionsTable from './personalPromotionsTable/PersonalPromotionsTable';
import PromotionsFilters from './personalPromotionsFilters/PersonalPromotionsFilters';

const initialFilterObject = (searchParams: URLSearchParams): FilterObjectType => ({
    page: searchParams.get(FilterEnum.PAGE) || defaultFilterObject.page,
    limit: searchParams.get(FilterEnum.LIMIT) || defaultFilterObject.limit,
    storeId: searchParams.get(FilterEnum.STORE_ID) || '',
    promotionTypes: searchParams.getAll(FilterEnum.PROMOTION_TYPES),
});

const PersonalPromotionsTableBlock = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const updateSearchParams = useUpdateSearchParams();
    const [currentPromotionId, setPromotionId] = useState('');
    const [isDrawerOpen, setDrawerOpen] = useState(false);
    const [filterObject, setFilterObject] = useState<FilterObjectType>(initialFilterObject(searchParams));
    const { data, isFetching } = useGetSellerPromotionsListQuery({
        page: filterObject.page,
        limit: filterObject.limit,
        storeId: filterObject.storeId || '',
        active: filterObject.active || false,
        types: filterObject?.promotionTypes?.length
            ? filterObject.promotionTypes
            : [PromotionTypeEnum2.Coupon, PromotionTypeEnum2.Sale],
    });

    useEffect(() => {
        const filteredFilterObject = { ...filterObject };
        Object.keys(filteredFilterObject).forEach((param) => {
            if (filteredFilterObject[param] === defaultFilterObject[param]) {
                delete filteredFilterObject[param];
            }
        });
        updateSearchParams(filteredFilterObject);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterObject]);

    return (
        <>
            <Space
                direction="vertical"
                size={24}
                style={{ width: '100%' }}
            >
                <Row justify="space-between">
                    <PromotionsFilters
                        filterObject={filterObject}
                        setFilterObject={setFilterObject}
                    />
                    <Button
                        type="primary"
                        onClick={() => navigate('/promotions/personal-promotions/add-personal-promotion')}
                    >
                        Добавить
                    </Button>
                </Row>
                <PersonalPromotionsTable
                    data={data}
                    isLoading={isFetching}
                    setFilterObject={setFilterObject}
                    setPromotionId={setPromotionId}
                    setDrawerOpen={setDrawerOpen}
                />
            </Space>
            <PersonalPromotionDetailsDrawer
                isDrawerOpen={isDrawerOpen}
                setDrawerOpen={setDrawerOpen}
                promotionId={currentPromotionId}
            />
            <DeclinePromotionParticipationModal />
            <DeletePromotionModal />
        </>
    );
};
export default PersonalPromotionsTableBlock;
