import { SellerOnboardingStatuses, JoyrideStepType } from '@Shared/model/joyrideTypes';

export const sellerJoyrideSteps: JoyrideStepType<SellerOnboardingStatuses>[] = [
    {
        target: '#joyride-add-store-button',
        stepStatus: SellerOnboardingStatuses.STORE_CREATION,
        pauseAfterNext: true,
        content: 'Добавьте свой магазин для начала работы с Такпродам',
        disableBeacon: true,
        spotlightPadding: 10,
    },
    {
        target: '#joyride-setup-integration-button',
        stepStatus: SellerOnboardingStatuses.MARKETPLACE_STORE_INTEGRATION,
        stepStatusOrder: 1,
        content:
            // eslint-disable-next-line max-len
            'Настройте интеграцию магазина с маркетплейсом для автоматического получения информации о товарах и заказах',
        disableBeacon: true,
        pauseAfterNext: true,
        spotlightPadding: 10,
    },
    {
        target: '#joyride-setup-integration-extension-link',
        stepStatus: SellerOnboardingStatuses.MARKETPLACE_STORE_INTEGRATION,
        stepStatusOrder: 2,
        content: 'Установите себе расширение для браузера, чтоб данные для интеграции заполнились автоматически',
        disableBeacon: true,
        pauseAfterNext: true,
        spotlightPadding: 10,
    },
    {
        target: '.joyride-product-promotion-menu-item',
        stepStatus: SellerOnboardingStatuses.CAMPAIGN_LAUNCH,
        stepStatusOrder: 1,
        pauseAfterNext: true,
        content:
            'Перейдите в раздел «Продвижение товаров», чтобы настроить рекламную кампанию и обновить каталог товаров',
        disableBeacon: true,
        spotlightPadding: 10,
    },
    {
        target: '#joyride-seller-products-table',
        stepStatus: SellerOnboardingStatuses.CAMPAIGN_LAUNCH,
        stepStatusOrder: 2,
        content:
            // eslint-disable-next-line max-len
            'При интеграции магазина с маркетплейсом товары автоматически добавляются в каталог и ежедневно обновляются в 00:00 (МСК)',
        disableBeacon: true,
        spotlightPadding: 10,
    },
    {
        target: '[data-node-key="/product-promotion/campaigns"]',
        stepStatus: SellerOnboardingStatuses.CAMPAIGN_LAUNCH,
        stepStatusOrder: 3,
        pauseAfterNext: true,
        content: 'Перейдите в «Рекламные кампании», чтобы выбрать товары для продвижения и настроить для них комиссию',
        disableBeacon: true,
        spotlightPadding: 10,
    },
    {
        target: '#joyride-launch-campaign',
        stepStatus: SellerOnboardingStatuses.CAMPAIGN_LAUNCH,
        stepStatusOrder: 4,
        pauseAfterNext: true,
        postStatusToServer: true,
        content:
            // eslint-disable-next-line max-len
            'После того, как вы заполните юридические данные и пополните баланс, вы сможете запустить свою рекламную кампанию здесь',
        disableBeacon: true,
        spotlightPadding: 10,
    },
    {
        target: '#joyride-user-menu-button',
        stepStatus: SellerOnboardingStatuses.LEGAL_INFO,
        pauseAfterNext: true,
        content:
            // eslint-disable-next-line max-len
            'Осталось только заполнить юридические данные для автоматической маркировки рекламы. Сделать это можно в разделе “Юридические данные“',
        disableBeacon: true,
        spotlightPadding: 10,
    },
    {
        target: '.joyride-finances-menu-item',
        stepStatus: SellerOnboardingStatuses.TOP_UP_BALANCE,
        stepStatusOrder: 1,
        pauseAfterNext: true,
        content: 'Пополните баланс, чтобы запустить рекламную кампанию и начать продвижение товаров',
        disableBeacon: true,
        spotlightPadding: 10,
    },
];
