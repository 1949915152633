import { combineReducers } from '@reduxjs/toolkit';

import storesReducer from '@Entities/keyValue/seller/stores/model/storesSlice';
import productsReducer from '@Entities/keyValue/seller/products/model/productsSlice';
import marketplacesReducer from '@Entities/keyValue/seller/marketplaces/model/marketplacesSlice';
import campaignsReducer from '@Entities/keyValue/seller/campaigns/model/campaignsSlice';

export const sellerReducer = combineReducers({
    stores: storesReducer,
    products: productsReducer,
    marketplaces: marketplacesReducer,
    campaigns: campaignsReducer,
});

export type keyValueState = ReturnType<typeof sellerReducer>;
