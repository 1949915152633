import { ReactNode } from 'react';
import { ConfigProvider } from 'antd';
import ru_RU from 'antd/locale/ru_RU';

export default function Theme({ children }: { children: ReactNode }) {
    return (
        <ConfigProvider
            locale={ru_RU}
            theme={{}}
        >
            {children}
        </ConfigProvider>
    );
}
