import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchData, selectById } from '@Entities/keyValue/seller/stores/model/storesSlice';
import { Button, Typography, Modal, Row, Space, Radio } from '@Shared/ui';
import { SellerApi } from '@OpenApi/typescript-axios';
import { KeyValueKeysEnum } from '@Shared/model/seller/types';

import './styles.scss';

const { Text, Title } = Typography;

const sellerApi = new SellerApi();

type DeleteStoreModalProps = {
    open: boolean;
    setOpen: (arg1: boolean) => void;
    storeId: string;
    setDrawerOpen: (arg1: boolean) => void;
};

const DeleteStoreModal = ({ open, setOpen, storeId, setDrawerOpen }: DeleteStoreModalProps) => {
    const dispatch = useDispatch();
    const storeValue = useSelector((state) => selectById(state, storeId));
    const [isDeleteLoading, setDeleteLoading] = useState(false);

    const handleStoreDelete = () => {
        const deleteStore = async () => {
            setDeleteLoading(true);
            try {
                await sellerApi.removeTheStore(storeId);
                setDeleteLoading(false);
                setOpen(false);
                setDrawerOpen(false);
                dispatch(fetchData({ key: KeyValueKeysEnum.getStoresList }));
            } catch (err) {
                setDeleteLoading(false);
                console.error('notify error deletion');
            }
        };
        deleteStore();
    };

    return (
        <Modal
            title={
                <Title
                    level={4}
                    style={{ marginTop: 0 }}
                >
                    Удалить магазин &quot;{storeValue?.title || ''}&quot; ?
                </Title>
            }
            open={open}
            onOk={() => setOpen(false)}
            onCancel={() => setOpen(false)}
            footer={[
                <Row
                    key="action-buttons"
                    style={{ justifyContent: 'space-between' }}
                >
                    <Button onClick={() => setOpen(false)}>Отменить</Button>
                    <Button
                        type="primary"
                        onClick={handleStoreDelete}
                        loading={isDeleteLoading}
                    >
                        Удалить
                    </Button>
                </Row>,
            ]}
        >
            <Row>
                <Text
                    type="secondary"
                    style={{ marginBottom: 16 }}
                >
                    Вы уверены, что хотите удалить карточку магазина? Обратите внимание: вернуть ее потом не получится.
                </Text>
            </Row>
            <Row>
                <Text style={{ marginBottom: 8 }}>Пожалуйста, укажите причины удаления магазина</Text>
            </Row>

            <Radio.Group
            // onChange={onChange}
            // value={value}
            >
                <Space
                    direction="vertical"
                    size="small"
                    style={{ display: 'flex' }}
                >
                    <Radio value={1}>Причина 1</Radio>
                    <Radio value={2}>Причина 2</Radio>
                    <Radio value={3}>Причина 3</Radio>
                    <Radio value={4}>Причина 4</Radio>
                    <Radio value={5}>Другое</Radio>
                </Space>
            </Radio.Group>
        </Modal>
    );
};

export default DeleteStoreModal;
