import { useState } from 'react';
import { theme } from 'antd';

import { Card, Row, Typography, Button, Input, Grid, Space, Checkbox } from '@Shared/ui';
import { useCreatePaymentInvoiceMutation } from '@Shared/api/rtkQueryApis/sellerPayments/SellerPaymentsApi';

const { Title, Text } = Typography;
const { useBreakpoint } = Grid;

const PayoutCard = () => {
    const breakpoints = useBreakpoint();
    const [inputValue, setInputValue] = useState('');
    const [createPaymentInvoice, { isLoading }] = useCreatePaymentInvoiceMutation();
    const {
        token: { colorPrimaryBorder },
    } = theme.useToken();

    const handleTopUp = async () => {
        const res = await createPaymentInvoice({ createInvoiceDto: { amount: +inputValue } });
        const paymentUrl = res.data?.paymentUrl || '';
        window.open(paymentUrl, '_blank');
    };

    return (
        <Card style={{ flex: 1, borderColor: colorPrimaryBorder }}>
            <Space
                direction="vertical"
                size={16}
                style={{ width: '100%' }}
            >
                <div>
                    <Title
                        level={4}
                        style={{ marginTop: 0 }}
                    >
                        Вывод средств
                    </Title>
                    <Text>Средства будут зачислены на ваш счет через 24 часа</Text>
                </div>
                <Row
                    wrap={false}
                    style={{ gap: 12, flexDirection: breakpoints.sm ? 'row' : 'column' }}
                >
                    <Input
                        addonAfter="₽"
                        onChange={(e) => setInputValue(e.target.value)}
                        value={inputValue}
                        placeholder="Введите сумму"
                        disabled
                    />
                    <Button
                        type="primary"
                        onClick={handleTopUp}
                        loading={isLoading}
                        disabled
                    >
                        Вывести
                    </Button>
                </Row>
                <Space>
                    <Checkbox disabled />
                    <Text>Вывести все</Text>
                </Space>
            </Space>
        </Card>
    );
};
export default PayoutCard;
