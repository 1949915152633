import { Alert } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';
import { FC, ReactNode } from 'react';

import { Row } from '@Shared/ui';

export const InfoAlert: FC<{ message: string; action: ReactNode }> = ({ message, action }) => {
    return (
        <Row style={{ marginBottom: 24 }}>
            <Alert
                className="campaigns-tab__legal-info-alert"
                description={message}
                type="info"
                showIcon
                icon={<InfoCircleFilled style={{ color: 'black', opacity: '0.45' }} />}
                banner
                action={action}
            />
        </Row>
    );
};
