import { theme } from 'antd';

import { Typography } from '@Shared/ui';
import { CommissionDto } from '@Shared/api/rtkQueryApis/sellerCommissions/SellerCommissionsApi';
import { formatPrice } from '@Shared/utils/formatPrice';

const { Text } = Typography;

const CommissionCell = ({ commission }: { commission: CommissionDto }) => {
    const {
        token: { colorTextTertiary },
    } = theme.useToken();

    return (
        <>
            <Text>
                {commission.order_product
                    ? formatPrice({
                          price: commission.total_fee || 0,
                          currency: commission.order_product?.currency || {},
                      })
                    : ''}
            </Text>
            {commission.decline_reason ? (
                <Text style={{ color: colorTextTertiary, display: 'block' }}>Отклонена</Text>
            ) : null}
        </>
    );
};

export default CommissionCell;
