import * as yup from 'yup';
import { theme, Space, Form, DatePicker, Select } from 'antd';

import { Collection } from '@Features/shared';

import useYup from './useYup';

const MessagesFilters = () => {
    const yupRules = useYup({
        user: yup.string().nullable(),
        createdAt: yup.date().nullable(),
    });
    const [form] = Form.useForm();
    const {
        token: { paddingMD },
    } = theme.useToken();

    return (
        <Space style={{ padding: `${paddingMD}px 0px` }}>
            <Form
                layout="inline"
                form={form}
                name="MessagesFilters"
            >
                <Form.Item
                    name="user"
                    label="Веб-мастер"
                    rules={yupRules}
                >
                    {
                        <Collection
                            getDataFor="publishers"
                            useInComponent={({ items, isLoading }: { items: unknown[]; isLoading?: boolean }) => (
                                <Select
                                    loading={isLoading}
                                    placeholder="Выберите веб-мастера"
                                    allowClear
                                    style={{ minWidth: '220px' }}
                                    options={items.map((item) => ({
                                        value: item.id,
                                        label: [item.firstname, item.lastname].join(' '),
                                    }))}
                                />
                            )}
                            withInitParams={{
                                page: 1,
                                limit: 50,
                            }}
                        />
                    }
                </Form.Item>
                <Form.Item
                    name="createdAt"
                    label="Дата"
                    rules={yupRules}
                >
                    <DatePicker
                        style={{ minWidth: '180px' }}
                        placeholder="Выберите дату"
                    />
                </Form.Item>
            </Form>
        </Space>
    );
};

export default MessagesFilters;
