import { type NotificationArgsProps } from 'antd';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export enum NotificationType {
    Success = 'success',
    Error = 'error',
    Warning = 'warning',
    Info = 'info',
}

type Notification = {
    open: boolean;
    componentProps: NotificationArgsProps;
};

type ShowNotification = Omit<Notification, 'open'>;

const initialState = {
    open: false,
    componentProps: {},
};

const notificationSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        showNotification: (state, action: PayloadAction<ShowNotification>) => {
            state.open = true;
            state.componentProps = action?.payload.componentProps;
        },
        hideNotification: (state) => {
            state.open = false;
            state.componentProps = {};
        },
    },
});

export const { showNotification, hideNotification } = notificationSlice.actions;
export default notificationSlice.reducer;
