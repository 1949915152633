import { FC } from 'react';
import { ConfigProvider } from 'antd';

import { Button, Flex, Col, Typography, Tag, Card } from '@Shared/ui';
import { SourceDto3, SourceStatusEnum } from '@Shared/api/rtkQueryApis/publisherSources';
import { getSourceStatusTagType } from '@Shared/utils/sources/getSourceStatusTagType';
import './styles.scss';

const { Text, Title } = Typography;

type SourceDetailsProps = {
    source: SourceDto3;
    setModalOpen: (arg1: boolean) => void;
};

export const SourceDetails: FC<SourceDetailsProps> = ({ source, setModalOpen }) => {
    const showComment = source.comment && source.status === SourceStatusEnum.Declined;
    const sourceDetails = [
        {
            term: 'Название площадки:',
            desc: source.title,
        },
        { term: 'Тип площадки:', desc: source.source_type?.title },
        {
            term: 'Ссылка на площадку:',
            desc: (
                <a
                    href={source?.source_url || ''}
                    target="_blank"
                    rel="noreferrer"
                >
                    {source.source_url}
                </a>
            ),
            descParams: { type: 'warning' },
        },
    ];

    // const sourceAudience =
    //     source?.source_type?.type === 'other'
    //         ? [
    //               {
    //                   term: 'Общее количество посетителей:',
    //                   desc: source.source_audience?.audience_total,
    //               },
    //               { term: 'Процент посетителей из России:', desc: source.source_audience?.audience_ru },
    //               {
    //                   term: 'Сред. количество посетителей в день:',
    //                   desc: source.source_audience?.audience_avg,
    //               },
    //           ]
    //         : [
    //               {
    //                   term: 'Кол-во подписчиков:',
    //                   desc: source.source_audience?.audience_total,
    //               },
    //               { term: '% подписчиков из России:', desc: source.source_audience?.audience_ru },
    //               { term: 'Сред. число просмотров в день:', desc: source.source_audience?.audience_avg },
    //               { term: 'Сред. кол-во публикаций в день:', desc: source.source_audience?.posts_per_day },
    //               {
    //                   term: 'Сред. кол-во просмотров на последних 3 публикациях:',
    //                   desc: source.source_audience?.views_on_last_3_posts_avg,
    //               },
    //               {
    //                   term: 'Сред. кол-во лайков на последних 3 публикациях:',
    //                   desc: source.source_audience?.likes_on_last_3_posts_avg,
    //               },
    //               { term: 'Процент мужчин и женщин:', desc: source.source_audience?.gender_distribution },
    //               { term: 'Диапазон возрастов в процентах:', desc: source.source_audience?.age_ranges },
    //           ];

    return (
        <>
            <Col className="source-details__wrap">
                <Title
                    level={4}
                    className="source-details__title"
                >
                    {source.title}
                </Title>
                <Tag color={getSourceStatusTagType(source.status)}>{source.status}</Tag>
                {showComment && (
                    <Card
                        size="small"
                        style={{ marginTop: 10 }}
                        className="source-details__comment-card"
                    >
                        <Text>{source.comment}</Text>
                    </Card>
                )}
                <Title level={5}>Площадка</Title>

                <ConfigProvider
                    theme={{
                        token: {
                            colorWarning: '#FF5F00',
                        },
                    }}
                >
                    <dl className="source-details__details">
                        {sourceDetails.map((detail) => (
                            <>
                                <dt>
                                    <Text
                                        type="secondary"
                                        strong
                                    >
                                        {detail.term}
                                    </Text>
                                </dt>
                                <dd className="source-details__description">
                                    <Text {...detail.descParams}>{detail.desc}</Text>
                                </dd>
                            </>
                        ))}
                    </dl>
                </ConfigProvider>
                <Title level={5}>Контент</Title>
                <dl className="source-details__details">
                    <dt>
                        <Text
                            type="secondary"
                            strong
                        >
                            Тип контента:
                        </Text>
                    </dt>
                    <dd
                        className="source-details__description"
                        style={{ textAlign: 'end' }}
                    >
                        <Text>{(source.source_content_types || []).map((type) => type.title).join(', ')}</Text>
                    </dd>
                </dl>
                {/* <Divider className="source-details__divider" />
                <Title level={5}>Аудитория</Title>
                <dl className="source-details__details">
                    {sourceAudience.map((detail) => (
                        <>
                            <dt>
                                <Text
                                    type="secondary"
                                    strong
                                >
                                    {detail.term}
                                </Text>
                            </dt>
                            <dd className="source-details__description">
                                <Text>{detail.desc}</Text>
                            </dd>
                        </>
                    ))}
                </dl> */}
            </Col>
            <Flex
                vertical
                className="store-details__delete-button"
            >
                <Button
                    danger
                    onClick={() => {
                        setModalOpen(true);
                    }}
                >
                    Удалить площадку
                </Button>
            </Flex>
        </>
    );
};
