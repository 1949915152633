import { PublisherSourcesApi } from './publisherSourcesApi';

PublisherSourcesApi.enhanceEndpoints({
    addTagTypes: ['PublisherProductsList', 'SourcesList', 'addASource', 'updateASource', 'getSource'],
    endpoints: {
        getProductsListWithLinksBySource: {
            providesTags: ['PublisherProductsList'],
        },
        getSourcesList: {
            providesTags: ['SourcesList'],
        },
        addASource: {
            invalidatesTags: ['getSource', 'SourcesList'],
        },
        putAppPrivatePublisherSourceUpdate: {
            invalidatesTags: ['getSource', 'SourcesList'],
        },
        viewASource: {
            providesTags: ['getSource'],
        },
        deleteAppPrivatePublisherSourceDelete: {
            invalidatesTags: ['SourcesList'],
        },
    },
});

export * from './publisherSourcesApi';
