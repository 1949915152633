import { FC } from 'react';
import Icon from '@ant-design/icons';

import { Avatar } from '@Shared/ui';
import { getMarketPlaceIconBySlug } from '@Shared/utils/stores/getMarkeplaceIconBySlug';

import './styles.scss';

type MPAvatarProps = {
    isDisconnected: boolean;
    slug: string;
};

export const MPAvatar: FC<MPAvatarProps> = ({ isDisconnected, slug }) => {
    const MPlogo = slug ? getMarketPlaceIconBySlug(slug) : null;

    return (
        <Avatar
            className={isDisconnected ? 'mp-avatar_disconnected' : ''}
            shape="square"
            size={36}
            icon={<Icon component={() => (MPlogo ? <MPlogo style={{ width: 40, height: 40 }} /> : <></>)} />}
        />
    );
};
