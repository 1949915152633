export enum FormIntegrationsEnum {
    sellerOzonSetupIntegrationForm = 'seller-ozon-setup-integration-form',
    sellerOzonUpdateIntegrationForm = 'seller-ozon-update-integration-form',
    sellerWBSetupIntegrationForm = 'seller-WB-setup-integration-form',
    sellerWBUpdateIntegrationForm = 'seller-WB-update-integration-form',
}

export enum FormKeysEnum {
    sellerOnboardingForm = 'seller-onboarding-form',
    sellerAddStoreForm = 'seller-add-store-form',
    sellerEditStoreForm = 'seller-edit-store-form',
}

export type FormsConfigs = Record<FormKeysEnum, object>;

export enum KeyValueKeysEnum {
    fetchUser = 'fetch-user',
    postUser = 'post-user',
    getStoresList = 'get-stores-list',
    getProductList = 'get-product-list',
    addNewStore = 'add-new-store',
    deleteStore = 'delete-store',
    getMarketplacesList = 'get-marketplaces-list',
    getCampaignsList = 'get-campaigns-list',
}
