import { Breadcrumbs, PageContent, type BreadcrumbItemType } from '@Features/shared';
import { BarterFormCard } from '@Features/seller/productPromotion/campaignsTab/setupCampaign/barter';

const pageBreadcrumbs: BreadcrumbItemType[] = [
    {
        path: '/product-promotion',
        title: 'Продвижение товаров',
    },
    {
        path: 'campaigns',
        title: 'Рекламные кампании',
    },
    {
        path: '/product-promotion/setup-campaign/barter',
        title: 'Настройка рекламной кампании',
    },
];

const CampaignTypeSelectionPage = () => {
    return (
        <PageContent>
            <Breadcrumbs items={pageBreadcrumbs} />
            <BarterFormCard />
        </PageContent>
    );
};
export default CampaignTypeSelectionPage;
