import { RawAxiosRequestConfig } from 'axios';

import { PublisherSourcesApi } from '@OpenApi/typescript-axios';
import { KeyValueKeysEnum } from '@Shared/model/publisher/types';

export const publisherSourcesApi = new PublisherSourcesApi();

export const KeyValueApiInstances = {
    [KeyValueKeysEnum.getSourcesList]: (options: RawAxiosRequestConfig) => publisherSourcesApi.getSourcesList(options),
};
