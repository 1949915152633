import { Link } from 'react-router-dom';
import { theme } from 'antd';

import { Flex, Typography, Skeleton, Row } from '@Shared/ui';
import { useGetTheUserInformationQuery } from '@Shared/api/rtkQueryApis/user';

const { Title, Text } = Typography;

const BalancesHeaderItem = ({ align = 'flex-end' }: { align?: string }) => {
    const { data: user, isLoading } = useGetTheUserInformationQuery();
    const {
        token: { colorPrimary },
    } = theme.useToken();

    return (
        <Flex
            vertical
            align={align}
            justify="center"
            onClick={(e) => e.stopPropagation()}
            style={{ marginRight: 8 }}
        >
            <Text>Баланс</Text>
            {isLoading ? (
                <Skeleton.Input active />
            ) : (
                <Row align="middle">
                    <Link to="/finances">
                        <Title
                            level={5}
                            style={{ margin: 0, color: colorPrimary, fontSize: 14 }}
                        >
                            {user?.balance || 0} ₽
                        </Title>
                    </Link>
                </Row>
            )}
        </Flex>
    );
};
export default BalancesHeaderItem;
