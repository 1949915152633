import { Layout } from 'antd';

import { Row, Col, Card, Flex, Grid, Space, Typography } from '@Shared/ui';
import { Breadcrumbs } from '@Features/shared';
import { NewMessageForm } from '@Features/seller/messenger';

const { Content } = Layout;
const { useBreakpoint } = Grid;

const { Paragraph, Link, Text, Title } = Typography;

const breadcrumbs = [
    {
        path: '/messenger',
        title: 'Мессенджер',
    },
    {
        path: '/messenger/new',
        title: 'Создание сообщения',
    },
];

const NewMessageTab = () => {
    const breakpoints = useBreakpoint();

    return (
        <Content className="page-content">
            <Breadcrumbs items={breadcrumbs} />

            <Row>
                <Col span={18}>
                    <Space
                        direction="vertical"
                        style={{ width: '100%' }}
                    >
                        <Card style={{ width: '100%', background: '#fff' }}>
                            <Flex
                                justify="space-between"
                                gap={10}
                                vertical={!breakpoints.lg}
                                style={{ marginBottom: 24 }}
                            >
                                <Flex
                                    vertical
                                    justify="space-between"
                                >
                                    <Title
                                        level={3}
                                        style={{ marginTop: 0 }}
                                    >
                                        Создание сообщения
                                    </Title>
                                    <Text type="secondary">
                                        Настройте тему письма, выберите одного или несколький адресатов и отправьте свое
                                        сообщение. Вы можете указать, кто из ваших веб-мастеров сможет ответить вам и
                                        писать сообщения в дальнейшем.
                                    </Text>
                                </Flex>
                            </Flex>
                            <NewMessageForm />
                        </Card>
                    </Space>
                </Col>
                <Col
                    span={6}
                    style={{
                        paddingLeft: '10px',
                    }}
                >
                    <Card style={{ width: '100%', background: '#fff' }}>
                        <Title level={3}>FAQ</Title>
                        <Paragraph>
                            Писать вам в чат могут только те веб-мастера, которым вы это разрешите при создании
                            сообщения.
                        </Paragraph>
                        <Paragraph>
                            После удаления диалога он удаляется у обоих, файлы и переписку восстановить нельзя.
                        </Paragraph>
                        <Paragraph>
                            Веб-мастер получает вашу рассылку как личное сообщение и не может увидеть других
                            получателей.
                        </Paragraph>
                        <Paragraph>&nbsp;</Paragraph>
                        <Paragraph>
                            <Link href="#need-help">Нужна помощь</Link>
                        </Paragraph>
                    </Card>
                </Col>
            </Row>
        </Content>
    );
};
export default NewMessageTab;
