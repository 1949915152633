import { Layout, Menu, type MenuProps } from 'antd';
import { useNavigate } from 'react-router-dom';

import { pageMenuItems } from '@Widgets/seller/menuItems';
import { Row, Typography } from '@Shared/ui';
import { LogoShort, Logo } from '@Shared/assets/images';

import './styles.scss';

const { Sider } = Layout;
const { Text } = Typography;

type PageMenuProps = {
    menuCollapsed: boolean;
    selectedKey: string;
};

const darkSecondaryTextColor = 'rgba(255, 255, 255, 0.65)';

export default function PageMenu({ menuCollapsed, selectedKey }: PageMenuProps) {
    const navigate = useNavigate();

    const onClick: MenuProps['onClick'] = (e) => {
        const item = pageMenuItems.find((item) => item.key === e.key);
        if (item && item.href) {
            navigate(item.href);
        }
    };

    return (
        <Sider
            trigger={null}
            collapsible
            collapsed={menuCollapsed}
            width={256}
        >
            <Row style={{ padding: 20, justifyContent: menuCollapsed ? 'center' : 'flex-start' }}>
                {menuCollapsed ? (
                    <LogoShort />
                ) : (
                    <Row
                        style={{ gap: 10 }}
                        wrap={false}
                    >
                        <Logo />
                        <Row
                            style={{
                                paddingLeft: 10,
                                borderLeft: `1px solid ${darkSecondaryTextColor}`,
                                alignItems: 'center',
                            }}
                        >
                            <Text
                                style={{
                                    color: darkSecondaryTextColor,

                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    fontSize: 10,
                                }}
                            >
                                Продавец
                            </Text>
                        </Row>
                    </Row>
                )}
            </Row>
            <Menu
                selectedKeys={[selectedKey]}
                mode="vertical"
                style={{
                    height: '100%',
                    borderRight: 0,
                }}
                onClick={onClick}
                theme="dark"
                items={pageMenuItems}
                className="sidebar-menu"
            />
        </Sider>
    );
}
