import { Switch } from '@Shared/ui';
import { useAppDispatch } from '@Shared/hooks';
import { showModal } from '@Entities/modals/model/modalsSlice';
import { ModalKeysType } from '@Shared/model/modalTypes';
import { usePatchAppPrivateSellerPromotionSetpromotionvisibilityMutation } from '@Shared/api/rtkQueryApis/sellerPromotions';
import { showMessage, MessageType } from '@Entities/messages/model/messagesSlice';

const VisibleCell = ({
    isVisible,
    isActive,
    promotionId,
    promotionTitle,
}: {
    isVisible: boolean;
    isActive: boolean;
    promotionId: string;
    promotionTitle: string;
}) => {
    const dispatch = useAppDispatch();
    const [setPromotionVisibility] = usePatchAppPrivateSellerPromotionSetpromotionvisibilityMutation();

    const handleApprove = async () => {
        if (promotionId) {
            try {
                await setPromotionVisibility({
                    setPromotionVisibilityDto: { visible: true, promotions: [promotionId] },
                }).unwrap();
                dispatch(
                    showMessage({
                        componentProps: {
                            type: MessageType.Success,
                            content: `Вы начали участие в ${promotionTitle || ''}`,
                        },
                    }),
                );
            } catch (err) {
                console.error(err);
            }
        }
    };

    const handleSwitchChange = (checked: boolean) => {
        if (checked) {
            handleApprove();
        } else {
            dispatch(showModal({ modalKey: ModalKeysType.declinePromotionParticipation, params: { promotionId } }));
        }
    };
    return isActive ? (
        <Switch
            onChange={handleSwitchChange}
            onClick={(_, e) => e.stopPropagation()}
            checked={isVisible}
        />
    ) : null;
};

export default VisibleCell;
