import { FC, ReactNode, PropsWithChildren } from 'react';
import { FileOutlined } from '@ant-design/icons';

import { Typography, Empty, Grid } from '@Shared/ui';

const { Title } = Typography;
const { useBreakpoint } = Grid;

const fileOutlined = () => (
    <FileOutlined
        height={28}
        width={28}
    />
);

type EmptyTableProps = PropsWithChildren & {
    icon?: typeof fileOutlined;
    title?: string | false;
    description?: string | false;
};

export const EmptyTable: FC<EmptyTableProps> = ({
    icon = fileOutlined,
    title = 'Здесь пока пусто',
    description = false,
    children,
}: PropsWithChildren<EmptyTableProps>) => {
    const breakpoints = useBreakpoint();

    return (
        <Empty
            style={{ minHeight: 240, padding: breakpoints.md ? 50 : '20px 0' }}
            image={icon}
            imageStyle={{ height: '28px', fontSize: 28 }}
            description={description}
        >
            {title ? <Title level={2}>{title}</Title> : null}
            {children}
        </Empty>
    );
};

export const renderEmpty = ({
    icon: Icon = FileOutlined,
    title,
    description,
    content,
}: Partial<Omit<EmptyTableProps, 'icon'>> & { icon?: typeof FileOutlined; content?: ReactNode | string }) => (
    <EmptyTable
        title={title}
        description={description}
        icon={
            Icon ? (
                <Icon
                    width={28}
                    height={28}
                />
            ) : undefined
        }
    >
        {content}
    </EmptyTable>
);
