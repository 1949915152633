import { useParams } from 'react-router-dom';

import { PageContent, Breadcrumbs } from '@Features/shared';
import { Skeleton } from '@Shared/ui';
import { PersonalPromotionForm } from '@Features/seller/promotions/editPersonalPromotion';
import { useViewThePromotionQuery } from '@Shared/api/rtkQueryApis/sellerPromotions';

const pageBreadcrumbs = [
    {
        path: '/promotions',
        title: 'Скидки на товары',
    },
    {
        path: '/personal-promotions',
        title: 'Мои акции и промокоды',
    },
    {
        path: '/edit-personal-promotion',
        title: 'Изменить персональное предложение',
    },
];

const EditPersonalPromotionPage = () => {
    const { promotionId = '' } = useParams();
    const { data: promotion, isLoading } = useViewThePromotionQuery({ id: promotionId }, { skip: !promotionId });

    return (
        <PageContent>
            <Breadcrumbs items={pageBreadcrumbs} />

            {isLoading ? <Skeleton active /> : <PersonalPromotionForm promotion={promotion} />}
        </PageContent>
    );
};
export default EditPersonalPromotionPage;
