import { Link } from 'react-router-dom';
import { HomeOutlined } from '@ant-design/icons';

import { Breadcrumb, Grid } from '@Shared/ui';

const { useBreakpoint } = Grid;

const items = [
    {
        path: '',
        title: <HomeOutlined />,
    },
    {
        path: '/stores',
        title: 'Магазины',
    },
];

const StoresBreadcrumb = () => {
    const breakpoints = useBreakpoint();

    const itemRender = (currentRoute, params, items, paths) => {
        const isLast = currentRoute?.path === items[items.length - 1]?.path;
        return isLast ? (
            <span>{currentRoute.title}</span>
        ) : (
            <Link to={`${paths.join('/') || '/'}`}>{currentRoute.title}</Link>
        );
    };

    return (
        <Breadcrumb
            itemRender={itemRender}
            items={items}
            style={{ marginBottom: 16, marginLeft: breakpoints.md ? 0 : 24 }}
        />
    );
};

export default StoresBreadcrumb;
