import { Grid } from '@Shared/ui';

import { DesktopPageMenu } from './menus';

const { useBreakpoint } = Grid;

type PageMenuProps = {
    menuCollapsed: boolean;
    selectedKey: string;
};

const PageMenu = (props: PageMenuProps) => {
    const breakpoints = useBreakpoint();

    return breakpoints.md ? <DesktopPageMenu {...props} /> : null;
};

export default PageMenu;
