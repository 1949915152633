import { AdvertisingFormatEnum, PaymentTypeEnum, DeadlineEnum } from '@Shared/api/rtkQueryApis/sellerCampaigns';

// export const AdFormatsMap = {
//     [AdvertisingFormatEnum.Stories]: 'Stories',
//     [AdvertisingFormatEnum.Reels]: 'Reels',
//     [AdvertisingFormatEnum.Post]: 'Post',
// };

export const AdFormatsMap: Record<keyof typeof AdvertisingFormatEnum, string> = {
    Stories: 'Стори',
    Reels: 'Видео',
    Post: 'Пост',
};

// export const PaymentTypesMap = {
//     [PaymentTypeEnum.Certificate]: 'Сертификат WB',
//     [PaymentTypeEnum.PromoCode]: 'Промокод Ozon',
//     [PaymentTypeEnum.TransferToCard]: 'Личный перевод на карту',
// };

export const PaymentTypesMap: Record<keyof typeof PaymentTypeEnum, string> = {
    Certificate: 'Сертификат WB',
    PromoCode: 'Промокод Ozon',
    TransferToCard: 'Личный перевод на карту',
};

export const DeadlineMap = {
    [DeadlineEnum.Month1]: '1 месяц',
    [DeadlineEnum.Month2]: '2 месяца',
    [DeadlineEnum.Month3]: '3 месяца',
};

export type SelectedMPStore = {
    marketplaceSlug: string;
    storeId: string;
    mpStoreId: string;
};
