// import { useState } from 'react';

import { Space } from '@Shared/ui';
import { usePublisherProductStatisticQuery } from '@Shared/api/rtkQueryApis/publisherStatistics/PublisherStatisticsApi';

import StatisticsTable from './statisticsTable/StatisticsTable';
import PromotionsFilters from './statisticsFilters/StatisticsFilters';

const StatisticsTableBlock = () => {
    // const [filterObject, setFilterObject] = useState<object>({});
    const { data, isFetching } = usePublisherProductStatisticQuery();

    return (
        <Space
            direction="vertical"
            size={24}
            style={{ width: '100%' }}
        >
            <PromotionsFilters
            // filterObject={filterObject}
            // setFilterObject={setFilterObject}
            />
            <StatisticsTable
                data={data}
                isLoading={isFetching}
                // setFilterObject={setFilterObject}
            />
        </Space>
    );
};
export default StatisticsTableBlock;
