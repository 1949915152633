import { keyValueSliceGeneric } from '@Shared/utils/redux/seller/keyValueGenericSlice';
import { UserDto } from '@OpenApi/typescript-axios';

export type UserKeyValueType = {
    isLoading: boolean;
    isError: boolean;
    isFulfilled: boolean;
    isSubmitted: boolean;
    value: UserDto;
};

export const userSlice = keyValueSliceGeneric({
    name: 'user',
});

export const { setData, resetState } = userSlice.actions;
export const { fetchData, selectState } = userSlice;

export default userSlice.reducer;
