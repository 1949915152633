/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { RawAxiosRequestConfig } from 'axios';

import { KeyValueApiInstances } from '@Shared/api/publisher/keyValueApi';
import { KeyValueKeysEnum } from '@Shared/model/publisher/types';
import { RootState } from 'src/app/providers/redux/store';

export type BaseStateSchema = {
    value: object | null;
    isLoading: boolean;
    isFulfilled: boolean;
    isError: boolean;
    errors: null | object;
};

export type KeyValueSliceProps = {
    name: string;
    initialState?: BaseStateSchema;
    additionalReducers?: any;
    stateSelector?: (state: RootState) => object;
};

export const defaultKeyValueState: BaseStateSchema = {
    value: null,
    isLoading: false,
    isFulfilled: false,
    isError: false,
    errors: null,
};

export const keyValueSliceGeneric = ({
    name,
    initialState = defaultKeyValueState,
    additionalReducers,
    stateSelector,
}: KeyValueSliceProps): any => {
    const fetchData = createAsyncThunk(
        `${name}/fetchData`,
        async ({
            key,
            body,
            options = {},
            params,
        }: {
            key: KeyValueKeysEnum;
            body?: any;
            options?: RawAxiosRequestConfig | undefined;
            params?: object;
        }) => {
            const fetchKeyValueMethod = KeyValueApiInstances[key];
            const res = await fetchKeyValueMethod(options, body, params);
            return res.data;
        },
    );

    const slice = createSlice({
        name,
        initialState,
        reducers: {
            setData: (state: BaseStateSchema, action: PayloadAction<BaseStateSchema['value']>) => {
                state.value = action.payload;
            },
            resetState: (state: BaseStateSchema) => {
                state.isLoading = false;
                state.isFulfilled = false;
                state.isError = false;
                state.errors = null;
                state.value = [];
            },
            ...additionalReducers,
        },
        extraReducers: (builder) => {
            builder
                .addCase(fetchData.pending, (state) => {
                    state.isLoading = true;
                    state.isFulfilled = false;
                    state.isError = false;
                    state.errors = null;
                })
                .addCase(fetchData.fulfilled, (state, action) => {
                    state.isLoading = false;
                    state.isFulfilled = true;
                    state.isError = false;
                    state.errors = null;
                    state.value = action.payload;
                })
                .addCase(fetchData.rejected, (state, action) => {
                    state.isLoading = false;
                    state.isFulfilled = true;
                    state.isError = true;
                    state.errors = action.error;
                });
        },
    });

    const selectState = (state: RootState) => (stateSelector ? stateSelector(state) : state.keyValue[name]);
    const selectById = (state: RootState, id: string) => selectState(state).value?.items?.find((el) => el.id === id);

    return {
        fetchData,
        slice,
        reducer: slice.reducer,
        actions: slice.actions,
        selectState,
        selectById,
    };
};
