import { theme } from 'antd';

import { Space, Form, Row, Button, Grid } from '@Shared/ui';
import {
    PromotionSelectorCard,
    PromotionDetailsCard,
    PromotionProductsTableCard,
} from '@Features/seller/promotions/editPersonalPromotion';
import { PromotionDto3, useAddAPersonalPromotionMutation } from '@Shared/api/rtkQueryApis/sellerPromotions';

const { useBreakpoint } = Grid;

const PersonalPromotionForm = ({
    promotion,
    isAddPersonalPromotion = false,
}: {
    promotion?: PromotionDto3 | undefined;
    isAddPersonalPromotion?: boolean;
}) => {
    const breakpoints = useBreakpoint();
    const [form] = Form.useForm();
    const {
        token: { paddingLG },
    } = theme.useToken();
    const [addPromotion, { isLoading: isAdding }] = useAddAPersonalPromotionMutation();

    const onFinish = (formValues) => {
        console.log(6666666, formValues);
        const modifiedFormData = { ...formValues, start_date: formValues.period[0], end_date: formValues.period[1] };
        isAddPersonalPromotion ? addPromotion({ personalPromotionDto: modifiedFormData }) : null;
    };

    return (
        <Form
            form={form}
            name="personal-promotions-form"
            onFinish={onFinish}
            // onFinishFailed={onFinishFailed}
            autoComplete="off"
            className="legal-info__form"
            onValuesChange={(val) => {
                console.log(3333, val);
            }}
            // initialValues={initialData}
        >
            <Space
                direction="vertical"
                style={{ width: '100%' }}
            >
                <PromotionSelectorCard
                    promotion={promotion}
                    isAddPersonalPromotion={isAddPersonalPromotion}
                    form={form}
                />
                <PromotionDetailsCard />
                <PromotionProductsTableCard
                    promotionId={promotion?.id || ''}
                    isAddPersonalPromotion={isAddPersonalPromotion}
                    setImportedFileName={(fileName) => {
                        console.log(1233333, fileName);
                        form.setFieldValue('promotion_products_file_id', fileName);
                    }}
                />
            </Space>
            <Row
                justify="space-between"
                wrap={false}
                style={{ marginTop: 24, padding: breakpoints.md ? 0 : `0 ${paddingLG}px`, gap: paddingLG }}
            >
                <Button
                    // onClick={handleBack}
                    style={{ width: breakpoints.md ? 'auto' : '100%' }}
                >
                    Отменить
                </Button>
                <Button
                    htmlType="submit"
                    type="primary"
                    style={{ width: breakpoints.md ? 'auto' : '100%' }}
                    // onClick={handlePromotionUpdate}
                    loading={isAdding}
                >
                    {isAddPersonalPromotion ? 'Добавить' : 'Сохранить'}
                </Button>
            </Row>
        </Form>
    );
};
export default PersonalPromotionForm;
