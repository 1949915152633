import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
    fetchData,
    selectState,
    MarketplacesKeyValueType,
} from '@Entities/keyValue/seller/marketplaces/model/marketplacesSlice';
import {
    selectState as selectStoresState,
    StoresKeyValueType,
} from '@Entities/keyValue/seller/stores/model/storesSlice';
import { MPAvatar } from '@Features/seller/stores/MPAvatar';
import { Button, Typography, Modal, Row, List, Col, Radio, Select, Tag } from '@Shared/ui';
import { KeyValueKeysEnum } from '@Shared/model/seller/types';

import type { RadioChangeEvent } from 'antd';

import './styles.scss';

const { Text, Title } = Typography;

type NewIntegrationModalProps = {
    open: boolean;
    setOpen: (arg1: boolean) => void;
    storeId: string;
    setDrawerOpen: (arg1: boolean) => void;
};

const NewIntegrationModal = ({ open, setOpen, storeId }: NewIntegrationModalProps) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isLoading, value: marketplaces }: MarketplacesKeyValueType = useSelector(selectState);
    const { value: stores }: StoresKeyValueType = useSelector(selectStoresState);
    const [selectedMarketplace, setSelectedMarketplace] = useState('');
    const [selectedStore, setSelectedStore] = useState<string | null>(null);

    useEffect(() => {
        dispatch(
            fetchData({
                key: KeyValueKeysEnum.getMarketplacesList,
            }),
        );
    }, [dispatch]);

    useEffect(() => {
        if (marketplaces?.items.length) {
            setSelectedMarketplace(marketplaces.items[0].slug);
        }
    }, [marketplaces]);

    useEffect(() => {
        const storeInitialValue = stores.items.find((store) => store.id === storeId);
        if (storeInitialValue) {
            setSelectedStore(storeInitialValue.id);
        }
    }, [storeId, stores]);

    const handleMarketplaceChange = (e: RadioChangeEvent) => {
        setSelectedMarketplace(e.target.value);
    };

    const handleStoreChange = (option: string) => {
        setSelectedStore(option);
    };

    const navigateToSetupIntegration = () => {
        navigate('/stores/setup-integration', {
            state: { selectedStoreId: selectedStore, selectedMarketplace },
        });
    };

    const isStoreConnectedToMarketplace = useCallback(
        (marketplace: string) => {
            const store = stores.items.find((store) => store.id === selectedStore);
            const isConnected = store?.marketplaceStores.find((mp) => mp.marketplace_slug === marketplace);

            return isConnected;
        },
        [selectedStore, stores.items],
    );

    const isNextButtonDisabled = marketplaces.items.every((mp) => isStoreConnectedToMarketplace(mp.slug));

    const storesOptions = (stores?.items || []).map((store) => ({
        value: store.id,
        label: store.title,
    }));

    return (
        <Modal
            title={
                <Title
                    level={4}
                    style={{ marginTop: 0 }}
                >
                    Новая интеграция
                </Title>
            }
            open={open}
            loading={isLoading}
            onOk={() => setOpen(false)}
            onCancel={() => setOpen(false)}
            footer={[
                <Row
                    key="action-buttons"
                    style={{ justifyContent: 'space-between' }}
                >
                    <Button onClick={() => setOpen(false)}>Отменить</Button>
                    <Button
                        type="primary"
                        onClick={() => navigateToSetupIntegration()}
                        disabled={isNextButtonDisabled}
                    >
                        Далее
                    </Button>
                </Row>,
            ]}
        >
            <Row>
                <Text
                    type="secondary"
                    style={{ marginBottom: 16 }}
                >
                    Давайте настроим интеграцию вашего магазина с маркетплейсами
                </Text>
            </Row>
            <Row>
                <Title
                    level={5}
                    style={{ marginBottom: 8, marginTop: 0 }}
                >
                    Выберите маркетплейс
                </Title>
            </Row>
            <Radio.Group
                onChange={handleMarketplaceChange}
                value={selectedMarketplace}
                style={{ width: '100%' }}
            >
                <List
                    dataSource={marketplaces?.items || []}
                    className="store-details__list-of-marketplaces"
                    locale={{ emptyText: 'У вас нет доступных маркетплейсов' }}
                    renderItem={(marketplace) => (
                        <List.Item
                            style={{ padding: '8px 0' }}
                            onClick={() => setSelectedMarketplace(marketplace.slug)}
                        >
                            <Row>
                                <Col>
                                    <Radio
                                        value={marketplace.slug}
                                        disabled={isStoreConnectedToMarketplace(marketplace.slug)}
                                    />
                                    <MPAvatar
                                        key={marketplace.slug}
                                        isDisconnected={isStoreConnectedToMarketplace(marketplace.slug)}
                                        slug={marketplace.slug}
                                    />
                                    <Text
                                        style={{ marginLeft: 12 }}
                                        type={isStoreConnectedToMarketplace(marketplace.slug) ? 'secondary' : undefined}
                                    >
                                        {marketplace.title}
                                    </Text>
                                </Col>
                                <Col>
                                    {isStoreConnectedToMarketplace(marketplace.slug) ? <Tag>Подключен</Tag> : null}
                                </Col>
                            </Row>
                        </List.Item>
                    )}
                />
            </Radio.Group>
            <Row style={{ marginBottom: 8, marginTop: 20 }}>
                <Text type="secondary">Магазин для подключения</Text>
            </Row>
            <Row style={{ marginBottom: 24 }}>
                <Select
                    options={storesOptions}
                    onChange={handleStoreChange}
                    style={{ width: '100%' }}
                    value={selectedStore}
                />
            </Row>
        </Modal>
    );
};

export default NewIntegrationModal;
