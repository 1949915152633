import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { selectState, SetupIntegrationFormType, resetForm } from '@Entities/forms/seller/stores/model/setupIntegration';
import { MarketplaceStoreDto2 } from '@OpenApi/typescript-axios';

import { OzonIntegrationForm, WildberriesIntegrationForm } from './marketplaceForms';
import './styles.scss';

type SetupIntegrationFormProps = {
    storeId: string;
    marketplace: MarketplaceStoreDto2 | null;
};

const SetupIntegrationForm = ({ storeId, marketplace = 'ozon' }: SetupIntegrationFormProps) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isSubmitted }: SetupIntegrationFormType = useSelector(selectState);

    const handleBack = useCallback(() => {
        navigate(-1);
    }, [navigate]);

    useEffect(() => {
        if (isSubmitted) {
            dispatch(resetForm());
            handleBack();
        }
    }, [dispatch, handleBack, isSubmitted]);

    const renderForm = {
        ozon: <OzonIntegrationForm storeId={storeId} />,
        wildberries: <WildberriesIntegrationForm storeId={storeId} />,
    };

    return renderForm[marketplace];
};
export default SetupIntegrationForm;
