import { combineReducers } from '@reduxjs/toolkit';

import { sellerReducer } from '@Entities/forms/seller/sellerReducer';
import { publisherReducer } from '@Entities/forms/publisher/publisherReducer';

export const formsReducer = combineReducers({
    seller: sellerReducer,
    publisher: publisherReducer,
});

export type formsState = ReturnType<typeof formsReducer>;
