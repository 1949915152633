import { Card, Typography, Skeleton, Grid } from '@Shared/ui';
import { getBalanceColor } from '@Shared/utils/finances/getBalanceColor';
import { getBalanceInfoText } from '@Shared/utils/finances/getBalanceInfoText';
import { useGetTheUserInformationQuery } from '@Shared/api/rtkQueryApis/user';

const { Title, Text } = Typography;
const { useBreakpoint } = Grid;

const BalanceCard = () => {
    const breakpoints = useBreakpoint();
    const { data: user, isLoading } = useGetTheUserInformationQuery();

    return (
        <Card style={{ width: breakpoints.lg ? 300 : '100%' }}>
            <Title
                level={5}
                style={{ marginTop: 0 }}
            >
                Баланс
            </Title>
            {isLoading ? (
                <Skeleton.Input active />
            ) : (
                <Title
                    level={3}
                    style={{ marginTop: 0, color: getBalanceColor(user?.balance || 0) }}
                >
                    {user?.balance || 0} ₽
                </Title>
            )}

            <Text type="secondary">{getBalanceInfoText(user?.balance || 0, user?.credit || 0)}</Text>
        </Card>
    );
};
export default BalanceCard;
