import { useNavigate } from 'react-router-dom';

import { Flex, Button } from '@Shared/ui';
import {
    PromotionDto2,
    usePatchAppPrivateSellerPromotionSetpromotionvisibilityMutation,
} from '@Shared/api/rtkQueryApis/sellerPromotions';
import { useAppDispatch } from '@Shared/hooks';
import { showMessage, MessageType } from '@Entities/messages/model/messagesSlice';

type PromotionSelectorCardProps = {
    promotion: PromotionDto2 | undefined;
};

const ActionButtons = ({ promotion }: PromotionSelectorCardProps) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [setPromotionVisibility, { isLoading: isVisibilityLoading }] =
        usePatchAppPrivateSellerPromotionSetpromotionvisibilityMutation();

    const handleApprovePromotionVisibility = async () => {
        if (promotion?.id) {
            try {
                try {
                    await setPromotionVisibility({
                        setPromotionVisibilityDto: { visible: true, promotions: [promotion?.id] },
                    });
                    dispatch(
                        showMessage({
                            componentProps: {
                                type: MessageType.Success,
                                content: `Вы начали участие в ${promotion?.title}`,
                            },
                        }),
                    );
                } catch (err) {
                    console.error(err);
                }
            } catch (err) {
                console.error(err);
            }
        }
    };

    const handleDeclinePromotionVisibility = async () => {
        if (promotion?.id) {
            try {
                await setPromotionVisibility({
                    setPromotionVisibilityDto: { visible: false, promotions: [promotion?.id] },
                    notificationOptions: {
                        successMessage: `Заявка на остановку участия в ${promotion?.title}`,
                        successDescription:
                            'Мы пришлем вам уведомление об отключении, когда заявку проверит наш менеджер.',
                    },
                });
            } catch (err) {
                console.error(err);
            }
        }
    };

    return (
        <Flex
            justify="space-between"
            vertical
            gap="small"
            style={{ paddingBottom: 24 }}
        >
            {!promotion?.visible ? (
                <Button
                    type="primary"
                    style={{ width: '100%' }}
                    onClick={handleApprovePromotionVisibility}
                    loading={isVisibilityLoading}
                >
                    Принять участие
                </Button>
            ) : (
                <Button
                    type="primary"
                    ghost
                    style={{ width: '100%' }}
                    onClick={handleDeclinePromotionVisibility}
                    loading={isVisibilityLoading}
                >
                    Остановить участие
                </Button>
            )}
            <Button
                onClick={() => navigate(`/promotions/${promotion?.id}/edit-promotion`)}
                style={{ width: '100%' }}
            >
                Редактировать
            </Button>
        </Flex>
    );
};

export default ActionButtons;
