import Icon from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

import { Button, Tooltip } from '@Shared/ui';
import { PenEdit } from '@Shared/assets/images/icons';

const EditButtonCell = ({ isActive, promotionId }: { isActive: boolean; promotionId: string }) => {
    const navigate = useNavigate();

    return isActive ? (
        <Tooltip
            title="Редактировать товары участвующие в акции и описание"
            placement="topLeft"
        >
            <Button
                type="text"
                onClick={() => navigate(`/promotions/${promotionId}/edit-promotion`)}
            >
                <Icon component={() => <PenEdit style={{ opacity: '0.45' }} />} />
            </Button>
        </Tooltip>
    ) : null;
};

export default EditButtonCell;
