import { emptySplitApi as api } from '../../full/emptyApi';

const injectedRtkApi = api.injectEndpoints({
    endpoints: (build) => ({
        getProductDetails: build.query<GetProductDetailsApiResponse, GetProductDetailsApiArg>({
            query: (queryArg) => ({ url: `/api/seller/v1/product/${queryArg.id}/` }),
        }),
        getProductsList: build.query<GetProductsListApiResponse, GetProductsListApiArg>({
            query: (queryArg) => ({
                url: '/api/seller/v1/product/',
                params: {
                    page: queryArg.page,
                    limit: queryArg.limit,
                    search_string: queryArg.searchString,
                    stores: queryArg.stores,
                    categories: queryArg.categories,
                    marketplaces: queryArg.marketplaces,
                },
            }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as SellerProductsApi };
export type GetProductDetailsApiResponse = /** status 200 Get product details */ ProductDto;
export type GetProductDetailsApiArg = {
    id: string;
};
export type GetProductsListApiResponse = /** status 200 Get products list */ {
    totalCount?: number;
    page?: number;
    limit?: number;
    items?: ProductDto[];
};
export type GetProductsListApiArg = {
    page?: number;
    limit?: number;
    searchString?: string | null;
    stores?: {
        ''?: string[];
    };
    categories?: {
        ''?: number[];
    };
    marketplaces?: {
        ''?: string[];
    };
};
export type Store3 = {
    id: string;
    title: string;
    logo?: string | null;
};
export type Marketplace2 = {
    id: string;
    title: string;
    slug: MarketPlaceSlugEnum4;
    description?: string | null;
};
export type MarketplaceStore3 = {
    id: string;
    store?: Store3 | null;
    marketplace?: Marketplace2 | null;
};
export type Currency = {
    shortCode: string;
    symbol: string;
};
export type ProductCategory = {
    id: number;
    title: string;
};
export type ProductDto = {
    id: string;
    marketplace_store: MarketplaceStore3 | null;
    title: string;
    sku: string;
    description: string;
    price: number | null;
    currency: Currency | null;
    product_category?: ProductCategory | null;
    external_link: string;
    external_id: string;
    image_url?: string | null;
};
export enum MarketPlaceSlugEnum4 {
    Ozon = 'ozon',
    Wildberries = 'wildberries',
}
export const { useGetProductDetailsQuery, useGetProductsListQuery } = injectedRtkApi;
