import { formSliceGeneric } from '@Shared/utils/redux/seller/formsGenericSlice';

export type SetupIntegrationFormType<DtoType = void> = {
    isLoading: boolean;
    isError: boolean;
    isSubmitting: boolean;
    isSubmitted: boolean;
    initialData: DtoType;
    formData: DtoType;
    errors: DtoType;
};

export const SetupIntegrationFormSlice = formSliceGeneric({
    name: 'setupIntegration',
    stateSelector: (state) => state.forms.seller.stores.setupIntegration,
});

export const { updateFormValue, resetForm } = SetupIntegrationFormSlice.actions;
export const { fetchForm, submitForm, selectState } = SetupIntegrationFormSlice;

export default SetupIntegrationFormSlice.reducer;
