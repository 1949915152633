type ValidationErrorType = {
    field: string;
    message: string;
};

export const normalizeErrorsObject = (errors = []) => {
    return errors.reduce((acc, curr: ValidationErrorType) => {
        return { ...acc, [curr.field]: curr.message };
    }, {});
};
