import { CommissionDto } from '@Shared/api/rtkQueryApis/publisherTransactions/PublisherTransactionsApi';
import { Typography } from '@Shared/ui';
import { changeCommissionReasonTextMap } from '@Features/publisher/finances/model/types';

const { Text } = Typography;

type CommentCellProps = {
    commission: CommissionDto;
};

const CommentCell = ({ commission }: CommentCellProps) => {
    return (
        <Text>
            {commission?.change_total_cart_reason
                ? changeCommissionReasonTextMap[commission?.change_total_cart_reason]
                : ''}
        </Text>
    );
};

export default CommentCell;
