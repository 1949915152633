import { SellerCampaignsApi } from './SellerCampaignsApi';

SellerCampaignsApi.enhanceEndpoints({
    addTagTypes: ['CampaignsList', 'Campaign', 'CampaignProducts'],
    endpoints: {
        getCampaignsList: {
            providesTags: ['CampaignsList'],
        },
        patchAppPrivateSellerCampaignActivate: {
            invalidatesTags: ['CampaignsList'],
        },
        viewTheCampaign: {
            providesTags: (result) => (result ? [{ type: 'Campaign' }] : []),
        },
        patchAppPrivateSellerCampaignUpdate: {
            invalidatesTags: ['Campaign', 'CampaignsList'],
        },
        getProductsListForCampaign: {
            providesTags: ['CampaignProducts'],
        },
        patchAppPrivateSellerCampaignChangecommission: {
            invalidatesTags: () => ['CampaignProducts'],
        },
        patchAppPrivateSellerCampaignSetvisibility: {
            invalidatesTags: () => ['CampaignProducts'],
        },
        addABarterCampaign: {
            invalidatesTags: () => ['CampaignsList', 'Campaign'],
        },
        putAppPrivateSellerCampaignUpdatebarter: {
            invalidatesTags: () => ['CampaignsList', 'Campaign'],
        },
    },
});

export * from './SellerCampaignsApi';
