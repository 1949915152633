import { FC } from 'react';
import { RouterProvider } from 'react-router-dom';

import PublisherJoyride from './joyride/PublisherJoyride';

import { publisher_app_router } from '../routes';
import Theme from '../providers/PublisherThemeProvider';

const PublisherApp: FC = () => {
    return (
        <Theme>
            <PublisherJoyride />
            <RouterProvider
                router={publisher_app_router}
                fallbackElement={<div>Page not found</div>}
            />
        </Theme>
    );
};

export default PublisherApp;
