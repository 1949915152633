import { UserApi } from './UserApi';

UserApi.enhanceEndpoints({
    addTagTypes: ['GetUser'],
    endpoints: {
        getTheUserInformation: {
            providesTags: ['GetUser'],
        },
        postAppApiUserSetRoles: {
            invalidatesTags: ['GetUser'],
        },
        addOnboardingStatus: {
            invalidatesTags: ['GetUser'],
        },
    },
});

export * from './UserApi';
