import { keyValueSliceGeneric } from '@Shared/utils/redux/seller/keyValueGenericSlice';
import { CampaignDto } from '@OpenApi/typescript-axios';

export type CampaignsKeyValueType = {
    isLoading: boolean;
    isError: boolean;
    isFulfilled: boolean;
    isSubmitted: boolean;
    value: { items: CampaignDto[] };
};

export const campaignsSlice = keyValueSliceGeneric({
    name: 'campaigns',
    stateSelector: (state) => state.keyValue.seller.campaigns,
});

export const { fetchData, selectState } = campaignsSlice;

export default campaignsSlice.reducer;
