import { Typography } from '@Shared/ui';
import { PromotionDto2 } from '@Shared/api/rtkQueryApis/sellerPromotions';
import { formatDate } from '@Shared/utils';

const { Text } = Typography;

const PeriodCell = ({ promotion }: { promotion: PromotionDto2 | null | undefined }) => {
    const startDate = promotion?.start_date ? formatDate(promotion?.start_date) : '';
    const endDate = promotion?.end_date ? formatDate(promotion?.end_date) : '';
    return (
        <Text>
            {startDate} - {endDate}
        </Text>
    );
};

export default PeriodCell;
