import { type ModalProps } from 'antd';
import { createSlice } from '@reduxjs/toolkit';

import { ModalKeysType } from '@Shared/model/modalTypes';

type ModalStateProps = {
    open: boolean;
    modalKey: ModalKeysType | null;
    componentProps: ModalProps;
    params: object;
};

const initialState: ModalStateProps = {
    open: false,
    modalKey: null,
    componentProps: {},
    params: {},
};

const modalsSlice = createSlice({
    name: 'modals',
    initialState,
    reducers: {
        showModal: (state, action) => {
            state.open = true;
            state.modalKey = action?.payload?.modalKey;
            state.componentProps = action?.payload?.componentProps || {};
            state.params = action?.payload.params || {};
        },
        closeModal: (state) => {
            state.open = false;
            state.modalKey = null;
            state.componentProps = {};
            state.params = {};
        },
    },
});

export const { showModal, closeModal } = modalsSlice.actions;
export default modalsSlice.reducer;
