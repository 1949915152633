import Icon from '@ant-design/icons';

import { showModal } from '@Entities/modals/model/modalsSlice';
import { useAppDispatch } from '@Shared/hooks';
import { Button, Dropdown } from '@Shared/ui';
import { ModalKeysType } from '@Shared/model/modalTypes';
import { CommissionDto } from '@Shared/api/rtkQueryApis/sellerCommissions/SellerCommissionsApi';
import { DeleteDisable, MoneyBagModernAddPlus, SearchLoap, PenEdit } from '@Shared/assets/images/icons';
import { MarketPlaceSlugEnum4 } from '@Shared/api/rtkQueryApis/sellerStores/SellerStoresApi';

import type { MenuProps } from 'antd';

type ActionsMenuProps = {
    commission: CommissionDto;
};

const ActionsMenu = ({ commission }: ActionsMenuProps) => {
    const dispatch = useAppDispatch();

    const items: MenuProps['items'] = [
        {
            key: 'decline',
            label: 'Отклонить',
            disabled: !!commission?.decline_reason || !!commission?.change_total_cart_reason,
            icon: <Icon component={() => <DeleteDisable style={{ opacity: '0.45' }} />} />,
            onClick: () => {
                dispatch(
                    showModal({ modalKey: ModalKeysType.declineCommission, params: { commissionId: commission.id } }),
                );
            },
        },
        {
            key: 'change_sum',
            label: 'Изменить сумму',
            disabled: !!commission?.decline_reason || !!commission?.change_total_cart_reason,
            icon: <Icon component={() => <MoneyBagModernAddPlus style={{ opacity: '0.45' }} />} />,
            onClick: () => {
                dispatch(
                    showModal({ modalKey: ModalKeysType.approveCommission, params: { commissionId: commission.id } }),
                );
            },
        },
        {
            key: 'open_report',
            label: 'Смотреть отчет',
            icon: <Icon component={() => <SearchLoap style={{ opacity: '0.45' }} />} />,
            onClick: () => {
                const MPslug = commission.marketplace_store?.marketplace?.slug || '';
                if (MPslug === MarketPlaceSlugEnum4.Ozon) {
                    window.open(
                        'https://seller.ozon.ru/app/advertisement/vendor-traffic',
                        '_blank',
                        'noopener,noreferrer',
                    );
                } else if (MPslug === MarketPlaceSlugEnum4.Wildberries) {
                    window.open('https://cmp.wildberries.ru/external-traffic', '_blank', 'noopener,noreferrer');
                }
            },
        },
    ];

    return (
        <Dropdown
            menu={{ items }}
            placement="bottomRight"
        >
            <Button type="text">
                <Icon component={() => <PenEdit style={{ opacity: '0.45' }} />} />
            </Button>
        </Dropdown>
    );
};

export default ActionsMenu;
