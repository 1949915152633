import { combineReducers } from '@reduxjs/toolkit';

import addStoreReducer from '@Entities/forms/seller/stores/model/addStoreFormSlice';
import editStoreReducer from '@Entities/forms/seller/stores/model/editStoreFormSlice';
import setupIntegrationReducer from '@Entities/forms/seller/stores/model/setupIntegration';

export const storesReducer = combineReducers({
    addStore: addStoreReducer,
    editStore: editStoreReducer,
    setupIntegration: setupIntegrationReducer,
});

export type storesState = ReturnType<typeof storesReducer>;
