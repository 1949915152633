import { Row, Grid } from '@Shared/ui';
import { FilterObjectType } from '@Features/seller/finances/manualVerification/model/types';
import { DateRangeFilter, SearchFilter, MarketplaceStoreFilter } from '@Features/shared/filters';

const { useBreakpoint } = Grid;

type ManualVerificationFilterProps = {
    filterObject: FilterObjectType;
    setFilterObject: (arg1: FilterObjectType) => void;
};

const ManualVerificationFilters = (props: ManualVerificationFilterProps) => {
    const breakpoints = useBreakpoint();

    return (
        <Row
            wrap
            align="middle"
            style={{
                gap: 12,
                flexDirection: breakpoints.md ? 'row' : 'column',
                alignItems: breakpoints.md ? 'center' : 'flex-start',
            }}
        >
            <MarketplaceStoreFilter<FilterObjectType> {...props} />
            <DateRangeFilter<FilterObjectType> {...props} />
            <SearchFilter<FilterObjectType>
                {...props}
                propName="sku"
                inputProps={{ placeholder: 'Продукт (SKU)' }}
            />
        </Row>
    );
};

export default ManualVerificationFilters;
