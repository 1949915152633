import { Typography, Card } from '@Shared/ui';
import { CopyCardValue } from '@Features/shared';

const { Title, Text } = Typography;

// import AudienceFormItems from './AudienceFormItems';
import './styles.scss';

type VerificationBlockProps = {
    verificationCode: string;
    currentSourceType?: string | undefined;
    isLoading: boolean;
    isAddSourceForm: boolean;
};

const SocialMediaBlock = ({
    verificationCode,
    isAddSourceForm,
}: Pick<VerificationBlockProps, 'verificationCode' | 'isAddSourceForm'>) => {
    return (
        <ol className="source-verification-block__list">
            <li>
                <Text type="secondary">
                    Зайдите на свою площадку и проверьте, что она открыта для всех пользователей.
                </Text>
            </li>
            <li>
                <Text type="secondary">Отредактируйте ее описание: добавьте наш тег. </Text>
            </li>
            <CopyCardValue
                cardValue={verificationCode}
                copyMessageText="Тег верификации успешно скопирован"
            />
            <Text
                type="secondary"
                style={{ paddingTop: 12, display: 'inline-block' }}
            >
                Сразу после проверки тег можно убрать из описания.
            </Text>
            <li>
                <Text type="secondary">
                    Нажмите «{isAddSourceForm ? 'Добавить площадку' : 'Сохранить изменения'}» внизу этой страницы.{' '}
                </Text>
            </li>
            <Text
                type="secondary"
                style={{ marginTop: 12, display: 'inline-block' }}
            >
                Площадка будет добавлена в статусе «На проверке». В среднем проверка занимает от 2 до 48 часов.
            </Text>
        </ol>
    );
};

const OtherBlock = ({
    verificationCode,
    isAddSourceForm,
}: Pick<VerificationBlockProps, 'verificationCode' | 'isAddSourceForm'>) => {
    return (
        <ol className="source-verification-block__list">
            <li>
                <Text type="secondary">Скопируйте мета-тег:</Text>
                <CopyCardValue
                    cardValue={`<meta name="takprodam-verification" content="${verificationCode}">`}
                    copyMessageText="Мета-тег успешно скопирован"
                />
            </li>
            <li>
                <Text type="secondary">
                    {
                        'Зайдите на указанную вами площадку и  вставьте этот мета-тег: - либо между тегами <head> и </head> главной страницы; - либо между тегами <body> и </body> главной страницы.'
                    }
                </Text>
            </li>
            <Text
                type="secondary"
                style={{ marginTop: 12, display: 'inline-block' }}
            >
                Сразу после проверки тег можно убрать.
            </Text>
            <li>
                <Text type="secondary">
                    Нажмите «{isAddSourceForm ? 'Добавить площадку' : 'Сохранить изменения'}» внизу этой страницы.{' '}
                </Text>
            </li>
            <Text
                type="secondary"
                style={{ marginTop: 12, display: 'inline-block' }}
            >
                Площадка будет добавлена в статусе «На проверке». В среднем проверка занимает от 2 до 48 часов.
            </Text>
        </ol>
    );
};

const SourceVerificationBlock = ({
    verificationCode,
    currentSourceType,
    isLoading,
    isAddSourceForm,
}: VerificationBlockProps) => {
    return (
        <Card
            style={{ padding: '8px 0' }}
            loading={isLoading}
        >
            <Title
                level={4}
                style={{ marginTop: 0 }}
            >
                Подтверждение площадки
            </Title>
            <Text type="secondary">
                Подтвердите, что вы владелец площадки и что информация о ней корректна. Это нужно, чтобы никто, кроме
                вас, не мог использовать вашу площадку для получения прибыли.
            </Text>
            <Title level={5}>Для подтверждения площадки:</Title>

            {currentSourceType === 'other' ? (
                <OtherBlock
                    verificationCode={verificationCode}
                    isAddSourceForm={isAddSourceForm}
                />
            ) : (
                <SocialMediaBlock
                    verificationCode={verificationCode}
                    isAddSourceForm={isAddSourceForm}
                />
            )}
        </Card>
    );
};
export default SourceVerificationBlock;
