import { Tag } from '@Shared/ui';
import { CommissionDto } from '@Shared/api/rtkQueryApis/sellerCommissions/SellerCommissionsApi';
import { reasonTextMap, changeCommissionReasonTextMap } from '@Features/seller/finances/manualVerification/model/types';

const getReasonText = (commission: CommissionDto) => {
    if (commission.decline_reason) {
        return reasonTextMap[commission.decline_reason];
    } else if (commission.change_total_cart_reason) {
        return changeCommissionReasonTextMap[commission.change_total_cart_reason];
    }
    return null;
};

const ReasonCell = ({ commission }: { commission: CommissionDto }) => {
    const reasonText = getReasonText(commission);
    return reasonText ? <Tag color="default">{reasonText}</Tag> : null;
};

export default ReasonCell;
