import { formSliceGeneric } from '@Shared/utils/redux/seller/formsGenericSlice';
import { SellersInfoDto } from '@OpenApi/typescript-axios';

export type OnboardingFormType = {
    isLoading: boolean;
    isError: boolean;
    isSubmitting: boolean;
    isSubmitted: boolean;
    initialData: SellersInfoDto;
    formData: SellersInfoDto;
    errors: SellersInfoDto;
};

export const onboardingSlice = formSliceGeneric({
    name: 'onboarding',
    stateSelector: (state) => state.forms.seller.onboarding,
});

export const { setData, resetState } = onboardingSlice.actions;
export const { fetchForm, submitForm, selectState } = onboardingSlice;

export default onboardingSlice.reducer;
