import { Dispatch, SetStateAction } from 'react';

import { Drawer } from '@Shared/ui';
import { useGetProductDetailsQuery } from '@Shared/api/rtkQueryApis/sellerProducts/SellerProductsApi';

import { ProductDetails } from './ProductDetails';

import './styles.scss';

type ProductDrawerProps = {
    isDrawerOpen: boolean;
    setDrawerOpen: Dispatch<SetStateAction<boolean>>;
    productId: string;
};

const ProductDetailsDrawer = ({ isDrawerOpen, setDrawerOpen, productId }: ProductDrawerProps) => {
    const { isFetching, data: product } = useGetProductDetailsQuery({ id: productId }, { skip: !productId });

    return (
        <Drawer
            open={isDrawerOpen}
            loading={isFetching}
            onClose={() => setDrawerOpen(false)}
            title="Детали"
            className="products__drawer"
        >
            {!!product && <ProductDetails product={product} />}
        </Drawer>
    );
};

export default ProductDetailsDrawer;
