import { StarFilled } from '@ant-design/icons';

import { Button } from '@Shared/ui';
import {
    useAddAPersonalPromotionToFavoritesMutation,
    useRemovePersonalPromotionFromFavoritesMutation,
} from '@Shared/api/rtkQueryApis/publisherPromotions';

const FavoriteCell = ({ isFavorite = false, promotionId }: { isFavorite: boolean; promotionId: string }) => {
    const [addAPromotionProductToFavorites] = useAddAPersonalPromotionToFavoritesMutation();
    const [removePromotionProductFromFavorites] = useRemovePersonalPromotionFromFavoritesMutation();

    const toggleProductFavorite = async (e) => {
        e.stopPropagation();
        try {
            if (isFavorite) {
                await removePromotionProductFromFavorites({ promotion: promotionId }).unwrap();
            } else {
                await addAPromotionProductToFavorites({ promotion: promotionId }).unwrap();
            }
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <Button
            type="link"
            id="joyride-products-favorite-cell"
            onClick={toggleProductFavorite}
            icon={<StarFilled style={{ color: isFavorite ? '#FADB14' : '#F5F5F5', fontSize: 20 }} />}
        />
    );
};

export default FavoriteCell;
