import { Row, Col } from '@Shared/ui';
// import { FilterObjectType } from '@Features/seller/promotions/promotionsTab/model/types';

import ExportButton from './ExportButton';

// const { useBreakpoint } = Grid;

// type PromotionsFilterProps = {
//     filterObject: FilterObjectType;
//     setFilterObject: (arg1: FilterObjectType) => void;
// };

const StatisticsFilters = () => {
    // const breakpoints = useBreakpoint();

    return (
        <Row
            justify="space-between"
            style={{ gap: 8 }}
            // style={{ gap: 8, flexDirection: breakpoints.md ? 'row' : 'column' }}
        >
            <Col></Col>
            <Col>
                <Row
                    justify="space-between"
                    align="middle"
                >
                    <ExportButton />
                </Row>
            </Col>
        </Row>
    );
};

export default StatisticsFilters;
