import { FinancesInfoCard, TransactionsTableCard } from '@Features/publisher/finances';
import { Space } from '@Shared/ui';
import { Breadcrumbs, type BreadcrumbItemType, PageContent } from '@Features/shared';

const pageBreadcrumbs: BreadcrumbItemType[] = [
    {
        path: '/finances',
        title: 'Финансы',
    },
];

const FinancesPage = () => {
    return (
        <PageContent>
            <Breadcrumbs items={pageBreadcrumbs} />

            <Space
                direction="vertical"
                style={{ width: '100%' }}
            >
                <FinancesInfoCard />
                <TransactionsTableCard />
            </Space>
        </PageContent>
    );
};
export default FinancesPage;
