import { emptySplitApi as api } from './emptyApi';

const injectedRtkApi = api.injectEndpoints({
    endpoints: (build) => ({
        getCountryList: build.query<GetCountryListApiResponse, GetCountryListApiArg>({
            query: () => ({ url: '/api/country/' }),
        }),
        uploadANewFile: build.mutation<UploadANewFileApiResponse, UploadANewFileApiArg>({
            query: () => ({ url: '/api/private/file/upload/', method: 'POST' }),
        }),
        postAppApiLogin: build.mutation<PostAppApiLoginApiResponse, PostAppApiLoginApiArg>({
            query: (queryArg) => ({ url: '/api/private/login/', method: 'POST', body: queryArg.loginRequestDto }),
        }),
        getProductCategoriesList: build.query<GetProductCategoriesListApiResponse, GetProductCategoriesListApiArg>({
            query: () => ({ url: '/api/product-category/' }),
        }),
        viewPublisherLegalInfo: build.query<ViewPublisherLegalInfoApiResponse, ViewPublisherLegalInfoApiArg>({
            query: () => ({ url: '/api/publisher/v1/legal-info/' }),
        }),
        addPublisherLegalInfo: build.mutation<AddPublisherLegalInfoApiResponse, AddPublisherLegalInfoApiArg>({
            query: (queryArg) => ({
                url: '/api/publisher/v1/legal-info/',
                method: 'POST',
                body: queryArg.legalInfoDto,
            }),
        }),
        addAProductCampaignToFavorites: build.mutation<
            AddAProductCampaignToFavoritesApiResponse,
            AddAProductCampaignToFavoritesApiArg
        >({
            query: (queryArg) => ({
                url: `/api/publisher/v1/product/${queryArg.productCampaign}/favorites/`,
                method: 'POST',
            }),
        }),
        removeProductCampaignFromFavorites: build.mutation<
            RemoveProductCampaignFromFavoritesApiResponse,
            RemoveProductCampaignFromFavoritesApiArg
        >({
            query: (queryArg) => ({
                url: `/api/publisher/v1/product/${queryArg.productCampaign}/favorites/`,
                method: 'DELETE',
            }),
        }),
        getGlobalPromotionsProductsListWithLinksBySource: build.query<
            GetGlobalPromotionsProductsListWithLinksBySourceApiResponse,
            GetGlobalPromotionsProductsListWithLinksBySourceApiArg
        >({
            query: (queryArg) => ({
                url: `/api/publisher/v1/source/${queryArg.source}/promotion/global/product/`,
                params: {
                    page: queryArg.page,
                    limit: queryArg.limit,
                    search_string: queryArg.searchString,
                    marketplaces: queryArg.marketplaces,
                    categories: queryArg.categories,
                    favorite: queryArg.favorite,
                },
            }),
        }),
        getGlobalPromotionsProductsListWithLinksBySourceToCsvFile: build.query<
            GetGlobalPromotionsProductsListWithLinksBySourceToCsvFileApiResponse,
            GetGlobalPromotionsProductsListWithLinksBySourceToCsvFileApiArg
        >({
            query: (queryArg) => ({
                url: `/api/publisher/v1/source/${queryArg.source}/promotion/global/product/export/`,
            }),
        }),
        getGlobalPromotionsProductsDetailsWithLinksBySource: build.query<
            GetGlobalPromotionsProductsDetailsWithLinksBySourceApiResponse,
            GetGlobalPromotionsProductsDetailsWithLinksBySourceApiArg
        >({
            query: (queryArg) => ({
                url: `/api/publisher/v1/source/${queryArg.source}/promotion/global/product/${queryArg.promotionProduct}/`,
            }),
        }),
        getPersonalPromotionsListWithLinksBySource: build.query<
            GetPersonalPromotionsListWithLinksBySourceApiResponse,
            GetPersonalPromotionsListWithLinksBySourceApiArg
        >({
            query: (queryArg) => ({
                url: `/api/publisher/v1/source/${queryArg.source}/promotion/personal/`,
                params: {
                    page: queryArg.page,
                    limit: queryArg.limit,
                    marketplaces: queryArg.marketplaces,
                    promotionTypes: queryArg.promotionTypes,
                    favorite: queryArg.favorite,
                },
            }),
        }),
        exportPersonalPromotionsListWithLinksBySourceToCsvFile: build.query<
            ExportPersonalPromotionsListWithLinksBySourceToCsvFileApiResponse,
            ExportPersonalPromotionsListWithLinksBySourceToCsvFileApiArg
        >({
            query: (queryArg) => ({ url: `/api/publisher/v1/source/${queryArg.source}/promotion/personal/export/` }),
        }),
        exportPersonalPromotionProductsListWithLinksBySourceToCsvFile: build.query<
            ExportPersonalPromotionProductsListWithLinksBySourceToCsvFileApiResponse,
            ExportPersonalPromotionProductsListWithLinksBySourceToCsvFileApiArg
        >({
            query: (queryArg) => ({
                url: `/api/publisher/v1/source/${queryArg.source}/promotion/personal/${queryArg.promotion}/export/`,
            }),
        }),
        getPersonalPromotionsDetailsWithLinksBySource: build.query<
            GetPersonalPromotionsDetailsWithLinksBySourceApiResponse,
            GetPersonalPromotionsDetailsWithLinksBySourceApiArg
        >({
            query: (queryArg) => ({
                url: `/api/publisher/v1/source/${queryArg.source}/promotion/personal/${queryArg.promotion}/`,
            }),
        }),
        addAPersonalPromotionToFavorites: build.mutation<
            AddAPersonalPromotionToFavoritesApiResponse,
            AddAPersonalPromotionToFavoritesApiArg
        >({
            query: (queryArg) => ({
                url: `/api/publisher/v1/promotion/personal/${queryArg.promotion}/favorites/`,
                method: 'POST',
            }),
        }),
        removePersonalPromotionFromFavorites: build.mutation<
            RemovePersonalPromotionFromFavoritesApiResponse,
            RemovePersonalPromotionFromFavoritesApiArg
        >({
            query: (queryArg) => ({
                url: `/api/publisher/v1/promotion/personal/${queryArg.promotion}/favorites/`,
                method: 'DELETE',
            }),
        }),
        addAGlobalPromotionProductToFavorites: build.mutation<
            AddAGlobalPromotionProductToFavoritesApiResponse,
            AddAGlobalPromotionProductToFavoritesApiArg
        >({
            query: (queryArg) => ({
                url: `/api/publisher/v1/promotion/global/product/${queryArg.promotionProduct}/favorites/`,
                method: 'POST',
            }),
        }),
        removeGlobalPromotionProductFromFavorites: build.mutation<
            RemoveGlobalPromotionProductFromFavoritesApiResponse,
            RemoveGlobalPromotionProductFromFavoritesApiArg
        >({
            query: (queryArg) => ({
                url: `/api/publisher/v1/promotion/global/product/${queryArg.promotionProduct}/favorites/`,
                method: 'DELETE',
            }),
        }),
        getSourceContentTypesList: build.query<GetSourceContentTypesListApiResponse, GetSourceContentTypesListApiArg>({
            query: () => ({ url: '/api/publisher/v1/source-content-types/' }),
        }),
        getSourcesList: build.query<GetSourcesListApiResponse, GetSourcesListApiArg>({
            query: () => ({ url: '/api/publisher/v1/source/' }),
        }),
        addASource: build.mutation<AddASourceApiResponse, AddASourceApiArg>({
            query: (queryArg) => ({ url: '/api/publisher/v1/source/', method: 'POST', body: queryArg.sourceDto }),
        }),
        viewASource: build.query<ViewASourceApiResponse, ViewASourceApiArg>({
            query: (queryArg) => ({ url: `/api/publisher/v1/source/${queryArg.source}/` }),
        }),
        putAppPrivatePublisherSourceUpdate: build.mutation<
            PutAppPrivatePublisherSourceUpdateApiResponse,
            PutAppPrivatePublisherSourceUpdateApiArg
        >({
            query: (queryArg) => ({
                url: `/api/publisher/v1/source/${queryArg.source}/`,
                method: 'PUT',
                body: queryArg.sourceDto2,
            }),
        }),
        deleteAppPrivatePublisherSourceDelete: build.mutation<
            DeleteAppPrivatePublisherSourceDeleteApiResponse,
            DeleteAppPrivatePublisherSourceDeleteApiArg
        >({
            query: (queryArg) => ({ url: `/api/publisher/v1/source/${queryArg.source}/`, method: 'DELETE' }),
        }),
        getProductsListWithLinksBySource: build.query<
            GetProductsListWithLinksBySourceApiResponse,
            GetProductsListWithLinksBySourceApiArg
        >({
            query: (queryArg) => ({
                url: `/api/publisher/v1/source/${queryArg.source}/products/`,
                params: {
                    page: queryArg.page,
                    limit: queryArg.limit,
                    search_string: queryArg.searchString,
                    stores: queryArg.stores,
                    marketplaces: queryArg.marketplaces,
                    categories: queryArg.categories,
                    bids: queryArg.bids,
                    favorite: queryArg.favorite,
                },
            }),
        }),
        getProductsDetailsWithLinksBySource: build.query<
            GetProductsDetailsWithLinksBySourceApiResponse,
            GetProductsDetailsWithLinksBySourceApiArg
        >({
            query: (queryArg) => ({
                url: `/api/publisher/v1/source/${queryArg.source}/product/${queryArg.productCampaign}/`,
            }),
        }),
        getAppPrivatePublisherSourceProductlistexport: build.query<
            GetAppPrivatePublisherSourceProductlistexportApiResponse,
            GetAppPrivatePublisherSourceProductlistexportApiArg
        >({
            query: (queryArg) => ({ url: `/api/publisher/v1/source/${queryArg.source}/products/export/` }),
        }),
        getSourceTypesList: build.query<GetSourceTypesListApiResponse, GetSourceTypesListApiArg>({
            query: () => ({ url: '/api/publisher/v1/source-types/' }),
        }),
        publisherProductStatistic: build.query<PublisherProductStatisticApiResponse, PublisherProductStatisticApiArg>({
            query: () => ({ url: '/api/publisher/v1/statistic/product/' }),
        }),
        getPublisherStatistics: build.query<GetPublisherStatisticsApiResponse, GetPublisherStatisticsApiArg>({
            query: () => ({ url: '/api/publisher/v1/statistics/' }),
        }),
        getPublisherTransactionsList: build.query<
            GetPublisherTransactionsListApiResponse,
            GetPublisherTransactionsListApiArg
        >({
            query: (queryArg) => ({
                url: '/api/publisher/v1/transaction/',
                params: {
                    page: queryArg.page,
                    limit: queryArg.limit,
                    createdAtFrom: queryArg.createdAtFrom,
                    createdAtTo: queryArg.createdAtTo,
                },
            }),
        }),
        postAppApiRegister: build.mutation<PostAppApiRegisterApiResponse, PostAppApiRegisterApiArg>({
            query: (queryArg) => ({
                url: '/api/private/register/',
                method: 'POST',
                body: queryArg.userRegistrationDto,
            }),
        }),
        addABarterCampaign: build.mutation<AddABarterCampaignApiResponse, AddABarterCampaignApiArg>({
            query: (queryArg) => ({
                url: '/api/seller/v1/campaign/barter/',
                method: 'POST',
                body: queryArg.barterCampaignDto,
            }),
        }),
        putAppPrivateSellerCampaignUpdatebarter: build.mutation<
            PutAppPrivateSellerCampaignUpdatebarterApiResponse,
            PutAppPrivateSellerCampaignUpdatebarterApiArg
        >({
            query: (queryArg) => ({
                url: `/api/seller/v1/campaign/barter/${queryArg.slug}/`,
                method: 'PUT',
                body: queryArg.barterCampaignDto2,
            }),
        }),
        patchAppPrivateSellerCampaignActivate: build.mutation<
            PatchAppPrivateSellerCampaignActivateApiResponse,
            PatchAppPrivateSellerCampaignActivateApiArg
        >({
            query: (queryArg) => ({ url: `/api/seller/v1/campaign/${queryArg.slug}/activate/`, method: 'PATCH' }),
        }),
        patchAppPrivateSellerCampaignDisable: build.mutation<
            PatchAppPrivateSellerCampaignDisableApiResponse,
            PatchAppPrivateSellerCampaignDisableApiArg
        >({
            query: (queryArg) => ({ url: `/api/seller/v1/campaign/${queryArg.slug}/disable/`, method: 'PATCH' }),
        }),
        viewTheCampaign: build.query<ViewTheCampaignApiResponse, ViewTheCampaignApiArg>({
            query: (queryArg) => ({ url: `/api/seller/v1/campaign/${queryArg.slug}/` }),
        }),
        getCampaignsList: build.query<GetCampaignsListApiResponse, GetCampaignsListApiArg>({
            query: (queryArg) => ({
                url: '/api/seller/v1/campaign/',
                params: { payment_types: queryArg.paymentTypes },
            }),
        }),
        patchAppPrivateSellerCampaignUpdate: build.mutation<
            PatchAppPrivateSellerCampaignUpdateApiResponse,
            PatchAppPrivateSellerCampaignUpdateApiArg
        >({
            query: (queryArg) => ({
                url: `/api/seller/v1/campaign/cpa/${queryArg.slug}/`,
                method: 'PATCH',
                body: queryArg.campaignDto,
            }),
        }),
        getProductsListForCampaign: build.query<
            GetProductsListForCampaignApiResponse,
            GetProductsListForCampaignApiArg
        >({
            query: (queryArg) => ({
                url: `/api/seller/v1/campaign/${queryArg.slug}/product/`,
                params: { page: queryArg.page, limit: queryArg.limit, search_string: queryArg.searchString },
            }),
        }),
        patchAppPrivateSellerCampaignChangecommission: build.mutation<
            PatchAppPrivateSellerCampaignChangecommissionApiResponse,
            PatchAppPrivateSellerCampaignChangecommissionApiArg
        >({
            query: (queryArg) => ({
                url: `/api/seller/v1/campaign/cpa/${queryArg.slug}/product/change-commission/`,
                method: 'PATCH',
                body: queryArg.changeCommissionDto,
            }),
        }),
        patchAppPrivateSellerCampaignSetvisibility: build.mutation<
            PatchAppPrivateSellerCampaignSetvisibilityApiResponse,
            PatchAppPrivateSellerCampaignSetvisibilityApiArg
        >({
            query: (queryArg) => ({
                url: `/api/seller/v1/campaign/cpa/${queryArg.slug}/product/set-visibility/`,
                method: 'PATCH',
                body: queryArg.setVisibilityDto,
            }),
        }),
        getSellerCommissionsList: build.query<GetSellerCommissionsListApiResponse, GetSellerCommissionsListApiArg>({
            query: (queryArg) => ({
                url: '/api/seller/v1/commission/',
                params: {
                    page: queryArg.page,
                    limit: queryArg.limit,
                    createdAtFrom: queryArg.createdAtFrom,
                    createdAtTo: queryArg.createdAtTo,
                    sku: queryArg.sku,
                    marketplaceStoreId: queryArg.marketplaceStoreId,
                },
            }),
        }),
        patchAppPrivateSellerCommissionDecline: build.mutation<
            PatchAppPrivateSellerCommissionDeclineApiResponse,
            PatchAppPrivateSellerCommissionDeclineApiArg
        >({
            query: (queryArg) => ({
                url: `/api/seller/v1/commission/${queryArg.id}/decline/`,
                method: 'PATCH',
                body: queryArg.declineReasonDto,
            }),
        }),
        patchAppPrivateSellerCommissionApprove: build.mutation<
            PatchAppPrivateSellerCommissionApproveApiResponse,
            PatchAppPrivateSellerCommissionApproveApiArg
        >({
            query: (queryArg) => ({
                url: `/api/seller/v1/commission/${queryArg.id}/approve/`,
                method: 'PATCH',
                body: queryArg.changeTotalCartReasonDto,
            }),
        }),
        getAListOfExistingMarketplaceIntegrations: build.query<
            GetAListOfExistingMarketplaceIntegrationsApiResponse,
            GetAListOfExistingMarketplaceIntegrationsApiArg
        >({
            query: () => ({ url: '/api/seller/v1/integration/' }),
        }),
        getAppPrivateSellerIntegrationOzonView: build.query<
            GetAppPrivateSellerIntegrationOzonViewApiResponse,
            GetAppPrivateSellerIntegrationOzonViewApiArg
        >({
            query: (queryArg) => ({ url: `/api/seller/v1/store/${queryArg.store}/integration/ozon/` }),
        }),
        putAppPrivateSellerIntegrationOzonUpdate: build.mutation<
            PutAppPrivateSellerIntegrationOzonUpdateApiResponse,
            PutAppPrivateSellerIntegrationOzonUpdateApiArg
        >({
            query: (queryArg) => ({
                url: `/api/seller/v1/store/${queryArg.store}/integration/ozon/`,
                method: 'PUT',
                body: queryArg.ozonIntegrationDto2,
            }),
        }),
        connectMarketplaceToOzon: build.mutation<ConnectMarketplaceToOzonApiResponse, ConnectMarketplaceToOzonApiArg>({
            query: (queryArg) => ({
                url: `/api/seller/v1/store/${queryArg.store}/integration/ozon/`,
                method: 'POST',
                body: queryArg.ozonIntegrationDto,
                params: { create_campaign_draft: queryArg.createCampaignDraft },
            }),
        }),
        getExistMarketplaceIntegrationWithWildberries: build.query<
            GetExistMarketplaceIntegrationWithWildberriesApiResponse,
            GetExistMarketplaceIntegrationWithWildberriesApiArg
        >({
            query: (queryArg) => ({ url: `/api/seller/v1/store/${queryArg.store}/integration/wildberries/` }),
        }),
        putAppPrivateSellerIntegrationWildberriesUpdate: build.mutation<
            PutAppPrivateSellerIntegrationWildberriesUpdateApiResponse,
            PutAppPrivateSellerIntegrationWildberriesUpdateApiArg
        >({
            query: (queryArg) => ({
                url: `/api/seller/v1/store/${queryArg.store}/integration/wildberries/`,
                method: 'PUT',
                body: queryArg.wildberriesIntegrationDto2,
            }),
        }),
        connectMarketplaceToWildberries: build.mutation<
            ConnectMarketplaceToWildberriesApiResponse,
            ConnectMarketplaceToWildberriesApiArg
        >({
            query: (queryArg) => ({
                url: `/api/seller/v1/store/${queryArg.store}/integration/wildberries/`,
                method: 'POST',
                body: queryArg.wildberriesIntegrationDto,
                params: { create_campaign_draft: queryArg.createCampaignDraft },
            }),
        }),
        viewSellerLegalInfo: build.query<ViewSellerLegalInfoApiResponse, ViewSellerLegalInfoApiArg>({
            query: () => ({ url: '/api/seller/v1/legal-info/' }),
        }),
        addSellerLegalInfo: build.mutation<AddSellerLegalInfoApiResponse, AddSellerLegalInfoApiArg>({
            query: (queryArg) => ({ url: '/api/seller/v1/legal-info/', method: 'POST', body: queryArg.legalInfoDto }),
        }),
        getAListOfAvailableMarketplaces: build.query<
            GetAListOfAvailableMarketplacesApiResponse,
            GetAListOfAvailableMarketplacesApiArg
        >({
            query: () => ({ url: '/api/seller/v1/marketplace/' }),
        }),
        updateProductsInAllIntegrations: build.mutation<
            UpdateProductsInAllIntegrationsApiResponse,
            UpdateProductsInAllIntegrationsApiArg
        >({
            query: () => ({ url: '/api/seller/v1/marketplace-store/update-products/', method: 'POST' }),
        }),
        getCollectionOfMessagesForSeller: build.query<
            GetCollectionOfMessagesForSellerApiResponse,
            GetCollectionOfMessagesForSellerApiArg
        >({
            query: (queryArg) => ({ url: `/api/seller/v1/messenger/threads/${queryArg.threadId}/messages/` }),
        }),
        createNewMessageInThread: build.mutation<CreateNewMessageInThreadApiResponse, CreateNewMessageInThreadApiArg>({
            query: (queryArg) => ({
                url: `/api/seller/v1/messenger/threads/${queryArg.threadId}/messages/`,
                method: 'POST',
                body: queryArg.messageDto,
            }),
        }),
        getCollectionOfReceivedNotifications: build.query<
            GetCollectionOfReceivedNotificationsApiResponse,
            GetCollectionOfReceivedNotificationsApiArg
        >({
            query: () => ({ url: '/api/seller/v1/messenger/notification/' }),
        }),
        createNewNotification: build.mutation<CreateNewNotificationApiResponse, CreateNewNotificationApiArg>({
            query: (queryArg) => ({
                url: '/api/seller/v1/messenger/notification/',
                method: 'POST',
                body: queryArg.notificationDto,
            }),
        }),
        getCollectionOfThreads: build.query<GetCollectionOfThreadsApiResponse, GetCollectionOfThreadsApiArg>({
            query: () => ({ url: '/api/seller/v1/messenger/threads/' }),
        }),
        createThread: build.mutation<CreateThreadApiResponse, CreateThreadApiArg>({
            query: (queryArg) => ({
                url: '/api/seller/v1/messenger/threads/',
                method: 'POST',
                body: queryArg.threadDto,
            }),
        }),
        markThreadAsRead: build.mutation<MarkThreadAsReadApiResponse, MarkThreadAsReadApiArg>({
            query: (queryArg) => ({
                url: `/api/seller/v1/messenger/threads/${queryArg.threadId}/mark-read/`,
                method: 'POST',
            }),
        }),
        getSellersOnboardingForm: build.query<GetSellersOnboardingFormApiResponse, GetSellersOnboardingFormApiArg>({
            query: () => ({ url: '/api/seller/v1/onboarding/' }),
        }),
        sendSellerOnboardingForm: build.mutation<SendSellerOnboardingFormApiResponse, SendSellerOnboardingFormApiArg>({
            query: (queryArg) => ({ url: '/api/seller/v1/onboarding/', method: 'POST', body: queryArg.sellersInfoDto }),
        }),
        createPaymentInvoice: build.mutation<CreatePaymentInvoiceApiResponse, CreatePaymentInvoiceApiArg>({
            query: (queryArg) => ({
                url: '/api/seller/v1/payments/invoice/',
                method: 'POST',
                body: queryArg.createInvoiceDto,
            }),
        }),
        getPaymentTypesList: build.query<GetPaymentTypesListApiResponse, GetPaymentTypesListApiArg>({
            query: () => ({ url: '/api/seller/v1/payment-types/' }),
        }),
        getProductDetails: build.query<GetProductDetailsApiResponse, GetProductDetailsApiArg>({
            query: (queryArg) => ({ url: `/api/seller/v1/product/${queryArg.id}/` }),
        }),
        getProductsList: build.query<GetProductsListApiResponse, GetProductsListApiArg>({
            query: (queryArg) => ({
                url: '/api/seller/v1/product/',
                params: {
                    page: queryArg.page,
                    limit: queryArg.limit,
                    search_string: queryArg.searchString,
                    stores: queryArg.stores,
                    categories: queryArg.categories,
                    marketplaces: queryArg.marketplaces,
                },
            }),
        }),
        addAPersonalPromotion: build.mutation<AddAPersonalPromotionApiResponse, AddAPersonalPromotionApiArg>({
            query: (queryArg) => ({
                url: '/api/seller/v1/promotion/personal/',
                method: 'POST',
                body: queryArg.personalPromotionDto,
            }),
        }),
        updateThePersonalPromotion: build.mutation<
            UpdateThePersonalPromotionApiResponse,
            UpdateThePersonalPromotionApiArg
        >({
            query: (queryArg) => ({
                url: `/api/seller/v1/promotion/personal/${queryArg.id}/`,
                method: 'PUT',
                body: queryArg.personalPromotionDto2,
            }),
        }),
        deleteThePersonalPromotion: build.mutation<
            DeleteThePersonalPromotionApiResponse,
            DeleteThePersonalPromotionApiArg
        >({
            query: (queryArg) => ({ url: `/api/seller/v1/promotion/personal/${queryArg.id}/`, method: 'DELETE' }),
        }),
        getSellerPromotionsList: build.query<GetSellerPromotionsListApiResponse, GetSellerPromotionsListApiArg>({
            query: (queryArg) => ({
                url: '/api/seller/v1/promotion/',
                params: {
                    page: queryArg.page,
                    limit: queryArg.limit,
                    marketplace_slug: queryArg.marketplaceSlug,
                    store_id: queryArg.storeId,
                    active: queryArg.active,
                    types: queryArg.types,
                },
            }),
        }),
        viewThePromotion: build.query<ViewThePromotionApiResponse, ViewThePromotionApiArg>({
            query: (queryArg) => ({ url: `/api/seller/v1/promotion/${queryArg.id}/` }),
        }),
        patchAppPrivateSellerPromotionSetpromotionvisibility: build.mutation<
            PatchAppPrivateSellerPromotionSetpromotionvisibilityApiResponse,
            PatchAppPrivateSellerPromotionSetpromotionvisibilityApiArg
        >({
            query: (queryArg) => ({
                url: '/api/seller/v1/promotion/set-visibility/',
                method: 'PATCH',
                body: queryArg.setPromotionVisibilityDto,
            }),
        }),
        updateTheGlobalPromotion: build.mutation<UpdateTheGlobalPromotionApiResponse, UpdateTheGlobalPromotionApiArg>({
            query: (queryArg) => ({
                url: `/api/seller/v1/promotion/global/${queryArg.id}/`,
                method: 'PATCH',
                body: queryArg.promotionDto,
            }),
        }),
        getProductsListForPromotion: build.query<
            GetProductsListForPromotionApiResponse,
            GetProductsListForPromotionApiArg
        >({
            query: (queryArg) => ({
                url: `/api/seller/v1/promotion/${queryArg.id}/product/`,
                params: { page: queryArg.page, limit: queryArg.limit },
            }),
        }),
        patchAppPrivateSellerPromotionSetpromotionproductvisibility: build.mutation<
            PatchAppPrivateSellerPromotionSetpromotionproductvisibilityApiResponse,
            PatchAppPrivateSellerPromotionSetpromotionproductvisibilityApiArg
        >({
            query: (queryArg) => ({
                url: `/api/seller/v1/promotion/${queryArg.id}/set-visibility/`,
                method: 'PATCH',
                body: queryArg.setPromotionProductVisibilityDto,
            }),
        }),
        getAllPublishers: build.query<GetAllPublishersApiResponse, GetAllPublishersApiArg>({
            query: () => ({ url: '/api/seller/v1/publishers/' }),
        }),
        getSellerStatistics: build.query<GetSellerStatisticsApiResponse, GetSellerStatisticsApiArg>({
            query: () => ({ url: '/api/seller/v1/statistics/' }),
        }),
        getStoresList: build.query<GetStoresListApiResponse, GetStoresListApiArg>({
            query: () => ({ url: '/api/seller/v1/store/' }),
        }),
        createANewStore: build.mutation<CreateANewStoreApiResponse, CreateANewStoreApiArg>({
            query: (queryArg) => ({ url: '/api/seller/v1/store/', method: 'POST', body: queryArg.storeDto }),
        }),
        viewTheStore: build.query<ViewTheStoreApiResponse, ViewTheStoreApiArg>({
            query: (queryArg) => ({ url: `/api/seller/v1/store/${queryArg.store}/` }),
        }),
        putAppPrivateSellerStoreUpdate: build.mutation<
            PutAppPrivateSellerStoreUpdateApiResponse,
            PutAppPrivateSellerStoreUpdateApiArg
        >({
            query: (queryArg) => ({
                url: `/api/seller/v1/store/${queryArg.store}/`,
                method: 'PUT',
                body: queryArg.storeDto2,
            }),
        }),
        removeTheStore: build.mutation<RemoveTheStoreApiResponse, RemoveTheStoreApiArg>({
            query: (queryArg) => ({ url: `/api/seller/v1/store/${queryArg.store}/`, method: 'DELETE' }),
        }),
        getSellerTransactionsList: build.query<GetSellerTransactionsListApiResponse, GetSellerTransactionsListApiArg>({
            query: (queryArg) => ({
                url: '/api/seller/v1/transaction/',
                params: {
                    page: queryArg.page,
                    limit: queryArg.limit,
                    createdAtFrom: queryArg.createdAtFrom,
                    createdAtTo: queryArg.createdAtTo,
                },
            }),
        }),
        getTheUserInformation: build.query<GetTheUserInformationApiResponse, GetTheUserInformationApiArg>({
            query: () => ({ url: '/api/private/user/' }),
        }),
        postAppApiUserSetRoles: build.mutation<PostAppApiUserSetRolesApiResponse, PostAppApiUserSetRolesApiArg>({
            query: (queryArg) => ({ url: '/api/private/user/roles/', method: 'POST', body: queryArg.userRolesDto }),
        }),
        addOnboardingStatus: build.mutation<AddOnboardingStatusApiResponse, AddOnboardingStatusApiArg>({
            query: (queryArg) => ({ url: `/api/private/user/onboarding-status/${queryArg.status}/`, method: 'POST' }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as fullApi };
export type GetCountryListApiResponse = /** status 200 Get country list */ CountryListDto;
export type GetCountryListApiArg = void;
export type UploadANewFileApiResponse = /** status 201 Resource created */ FileDto;
export type UploadANewFileApiArg = void;
export type PostAppApiLoginApiResponse = /** status 200 Returns a user */ User;
export type PostAppApiLoginApiArg = {
    /** User Credentials */
    loginRequestDto: LoginRequestDto;
};
export type GetProductCategoriesListApiResponse = /** status 200 Get product categories list */ ProductCategoryDtoList;
export type GetProductCategoriesListApiArg = void;
export type ViewPublisherLegalInfoApiResponse = /** status 200 Resource response */ LegalInfoDto2;
export type ViewPublisherLegalInfoApiArg = void;
export type AddPublisherLegalInfoApiResponse = /** status 201 Resource created */ LegalInfoDto2;
export type AddPublisherLegalInfoApiArg = {
    legalInfoDto: LegalInfoDto;
};
export type AddAProductCampaignToFavoritesApiResponse = /** status 204 Empty response */ void;
export type AddAProductCampaignToFavoritesApiArg = {
    productCampaign: string;
};
export type RemoveProductCampaignFromFavoritesApiResponse = /** status 204 Resource removed */ void;
export type RemoveProductCampaignFromFavoritesApiArg = {
    productCampaign: string;
};
export type GetGlobalPromotionsProductsListWithLinksBySourceApiResponse =
    /** status 200 Get global promotions products list with links by source */ {
        totalCount?: number;
        page?: number;
        limit?: number;
        items?: PromotionProductDto[];
    };
export type GetGlobalPromotionsProductsListWithLinksBySourceApiArg = {
    source: string;
    page?: number;
    limit?: number;
    searchString?: string | null;
    marketplaces?: {
        ''?: string[];
    };
    categories?: {
        ''?: number[];
    };
    favorite?: boolean;
};
export type GetGlobalPromotionsProductsListWithLinksBySourceToCsvFileApiResponse = unknown;
export type GetGlobalPromotionsProductsListWithLinksBySourceToCsvFileApiArg = {
    source: string;
};
export type GetGlobalPromotionsProductsDetailsWithLinksBySourceApiResponse =
    /** status 200 Get global promotions products details with links by source */ PromotionProductDto;
export type GetGlobalPromotionsProductsDetailsWithLinksBySourceApiArg = {
    source: string;
    promotionProduct: string;
};
export type GetPersonalPromotionsListWithLinksBySourceApiResponse =
    /** status 200 Get personal promotions list with links by source */ {
        totalCount?: number;
        page?: number;
        limit?: number;
        items?: PromotionDto2[];
    };
export type GetPersonalPromotionsListWithLinksBySourceApiArg = {
    source: string;
    page?: number;
    limit?: number;
    marketplaces?: {
        ''?: string[];
    };
    promotionTypes?: {
        ''?: PromotionTypeEnum[];
    };
    favorite?: boolean;
};
export type ExportPersonalPromotionsListWithLinksBySourceToCsvFileApiResponse = unknown;
export type ExportPersonalPromotionsListWithLinksBySourceToCsvFileApiArg = {
    source: string;
};
export type ExportPersonalPromotionProductsListWithLinksBySourceToCsvFileApiResponse = unknown;
export type ExportPersonalPromotionProductsListWithLinksBySourceToCsvFileApiArg = {
    source: string;
    promotion: string;
};
export type GetPersonalPromotionsDetailsWithLinksBySourceApiResponse =
    /** status 200 Get personal promotions details with links by source */ PromotionDto2;
export type GetPersonalPromotionsDetailsWithLinksBySourceApiArg = {
    source: string;
    promotion: string;
};
export type AddAPersonalPromotionToFavoritesApiResponse = /** status 204 Empty response */ void;
export type AddAPersonalPromotionToFavoritesApiArg = {
    promotion: string;
};
export type RemovePersonalPromotionFromFavoritesApiResponse = /** status 204 Resource removed */ void;
export type RemovePersonalPromotionFromFavoritesApiArg = {
    promotion: string;
};
export type AddAGlobalPromotionProductToFavoritesApiResponse = /** status 204 Empty response */ void;
export type AddAGlobalPromotionProductToFavoritesApiArg = {
    promotionProduct: string;
};
export type RemoveGlobalPromotionProductFromFavoritesApiResponse = /** status 204 Resource removed */ void;
export type RemoveGlobalPromotionProductFromFavoritesApiArg = {
    promotionProduct: string;
};
export type GetSourceContentTypesListApiResponse =
    /** status 200 Get source content types list */ SourceContentTypeList;
export type GetSourceContentTypesListApiArg = void;
export type GetSourcesListApiResponse = /** status 200 Get sources list */ SourceDtoList;
export type GetSourcesListApiArg = void;
export type AddASourceApiResponse = /** status 201 Resource created */ SourceDto3;
export type AddASourceApiArg = {
    sourceDto: SourceDto;
};
export type ViewASourceApiResponse = /** status 200 Resource response */ SourceDto3;
export type ViewASourceApiArg = {
    source: string;
};
export type PutAppPrivatePublisherSourceUpdateApiResponse = unknown;
export type PutAppPrivatePublisherSourceUpdateApiArg = {
    source: string;
    sourceDto2: SourceDto2;
};
export type DeleteAppPrivatePublisherSourceDeleteApiResponse = unknown;
export type DeleteAppPrivatePublisherSourceDeleteApiArg = {
    source: string;
};
export type GetProductsListWithLinksBySourceApiResponse =
    /** status 200 Get product campaign list with links by source */ {
        totalCount?: number;
        page?: number;
        limit?: number;
        items?: ProductCampaignDto[];
    };
export type GetProductsListWithLinksBySourceApiArg = {
    source: string;
    page?: number;
    limit?: number;
    searchString?: string | null;
    stores?: {
        ''?: string[];
    };
    marketplaces?: {
        ''?: string[];
    };
    categories?: {
        ''?: number[];
    };
    bids?: {
        ''?: string[];
    };
    favorite?: boolean;
};
export type GetProductsDetailsWithLinksBySourceApiResponse =
    /** status 200 Get products details with links by source */ ProductCampaignDto;
export type GetProductsDetailsWithLinksBySourceApiArg = {
    source: string;
    productCampaign: string;
};
export type GetAppPrivatePublisherSourceProductlistexportApiResponse = unknown;
export type GetAppPrivatePublisherSourceProductlistexportApiArg = {
    source: string;
};
export type GetSourceTypesListApiResponse = /** status 200 Get source types list */ SourceTypeList;
export type GetSourceTypesListApiArg = void;
export type PublisherProductStatisticApiResponse = /** status 200 Resource response */ ProductStatisticListDto;
export type PublisherProductStatisticApiArg = void;
export type GetPublisherStatisticsApiResponse = unknown;
export type GetPublisherStatisticsApiArg = void;
export type GetPublisherTransactionsListApiResponse = /** status 200 Paginated response */ {
    totalCount?: number;
    page?: number;
    limit?: number;
    items?: TransactionDto[];
};
export type GetPublisherTransactionsListApiArg = {
    page?: number;
    limit?: number;
    createdAtFrom?: string | null;
    createdAtTo?: string | null;
};
export type PostAppApiRegisterApiResponse = unknown;
export type PostAppApiRegisterApiArg = {
    userRegistrationDto: UserRegistrationDto;
};
export type AddABarterCampaignApiResponse = /** status 200 Resource response */ CampaignDto2;
export type AddABarterCampaignApiArg = {
    barterCampaignDto: BarterCampaignDto;
};
export type PutAppPrivateSellerCampaignUpdatebarterApiResponse = unknown;
export type PutAppPrivateSellerCampaignUpdatebarterApiArg = {
    slug: string;
    barterCampaignDto2: BarterCampaignDto2;
};
export type PatchAppPrivateSellerCampaignActivateApiResponse = unknown;
export type PatchAppPrivateSellerCampaignActivateApiArg = {
    slug: string;
};
export type PatchAppPrivateSellerCampaignDisableApiResponse = unknown;
export type PatchAppPrivateSellerCampaignDisableApiArg = {
    slug: string;
};
export type ViewTheCampaignApiResponse = /** status 200 Resource response */ CampaignDto2;
export type ViewTheCampaignApiArg = {
    slug: string;
};
export type GetCampaignsListApiResponse = /** status 200 Get campaigns list */ CampaignDtoList;
export type GetCampaignsListApiArg = {
    paymentTypes?: {
        ''?: number[];
    };
};
export type PatchAppPrivateSellerCampaignUpdateApiResponse = unknown;
export type PatchAppPrivateSellerCampaignUpdateApiArg = {
    slug: string;
    campaignDto: CampaignDto;
};
export type GetProductsListForCampaignApiResponse = /** status 200 Get products list for campaign */ {
    totalCount?: number;
    page?: number;
    limit?: number;
    items?: ProductCampaign[];
};
export type GetProductsListForCampaignApiArg = {
    slug: string;
    page?: number;
    limit?: number;
    searchString?: string | null;
};
export type PatchAppPrivateSellerCampaignChangecommissionApiResponse = unknown;
export type PatchAppPrivateSellerCampaignChangecommissionApiArg = {
    slug: string;
    changeCommissionDto: ChangeCommissionDto;
};
export type PatchAppPrivateSellerCampaignSetvisibilityApiResponse = unknown;
export type PatchAppPrivateSellerCampaignSetvisibilityApiArg = {
    slug: string;
    setVisibilityDto: SetVisibilityDto;
};
export type GetSellerCommissionsListApiResponse = /** status 200 Paginated response */ {
    totalCount?: number;
    page?: number;
    limit?: number;
    items?: CommissionDto[];
};
export type GetSellerCommissionsListApiArg = {
    page?: number;
    limit?: number;
    createdAtFrom?: string | null;
    createdAtTo?: string | null;
    sku?: string | null;
    marketplaceStoreId?: string | null;
};
export type PatchAppPrivateSellerCommissionDeclineApiResponse = unknown;
export type PatchAppPrivateSellerCommissionDeclineApiArg = {
    id: string;
    declineReasonDto: DeclineReasonDto;
};
export type PatchAppPrivateSellerCommissionApproveApiResponse = unknown;
export type PatchAppPrivateSellerCommissionApproveApiArg = {
    id: string;
    changeTotalCartReasonDto: ChangeTotalCartReasonDto;
};
export type GetAListOfExistingMarketplaceIntegrationsApiResponse =
    /** status 201 Resource created */ MarketplaceIntegrationListDto;
export type GetAListOfExistingMarketplaceIntegrationsApiArg = void;
export type GetAppPrivateSellerIntegrationOzonViewApiResponse = unknown;
export type GetAppPrivateSellerIntegrationOzonViewApiArg = {
    store: string;
};
export type PutAppPrivateSellerIntegrationOzonUpdateApiResponse = unknown;
export type PutAppPrivateSellerIntegrationOzonUpdateApiArg = {
    store: string;
    ozonIntegrationDto2: OzonIntegrationDto2;
};
export type ConnectMarketplaceToOzonApiResponse = /** status 201 Resource created */ MarketplaceStore;
export type ConnectMarketplaceToOzonApiArg = {
    /** Create a campaign draft */
    createCampaignDraft?: boolean;
    store: string;
    ozonIntegrationDto: OzonIntegrationDto;
};
export type GetExistMarketplaceIntegrationWithWildberriesApiResponse =
    /** status 200 Resource response */ WildberriesIntegrationDto3;
export type GetExistMarketplaceIntegrationWithWildberriesApiArg = {
    store: string;
};
export type PutAppPrivateSellerIntegrationWildberriesUpdateApiResponse = unknown;
export type PutAppPrivateSellerIntegrationWildberriesUpdateApiArg = {
    store: string;
    wildberriesIntegrationDto2: WildberriesIntegrationDto2;
};
export type ConnectMarketplaceToWildberriesApiResponse = /** status 201 Resource created */ MarketplaceStore;
export type ConnectMarketplaceToWildberriesApiArg = {
    /** Create a campaign draft */
    createCampaignDraft?: boolean;
    store: string;
    wildberriesIntegrationDto: WildberriesIntegrationDto;
};
export type ViewSellerLegalInfoApiResponse = /** status 200 Resource response */ LegalInfoDto2;
export type ViewSellerLegalInfoApiArg = void;
export type AddSellerLegalInfoApiResponse = /** status 201 Resource created */ LegalInfoDto2;
export type AddSellerLegalInfoApiArg = {
    legalInfoDto: LegalInfoDto;
};
export type GetAListOfAvailableMarketplacesApiResponse = /** status 201 Resource created */ MarketplaceListDto;
export type GetAListOfAvailableMarketplacesApiArg = void;
export type UpdateProductsInAllIntegrationsApiResponse = unknown;
export type UpdateProductsInAllIntegrationsApiArg = void;
export type GetCollectionOfMessagesForSellerApiResponse = /** status 200 Get collection of messages */ MessageDtoList;
export type GetCollectionOfMessagesForSellerApiArg = {
    threadId: string;
};
export type CreateNewMessageInThreadApiResponse = /** status 201 Create new message in thread */ MessageDto;
export type CreateNewMessageInThreadApiArg = {
    threadId: string;
    messageDto: MessageDto;
};
export type GetCollectionOfReceivedNotificationsApiResponse =
    /** status 200 Get collection of received notifications */ NotificationDtoList;
export type GetCollectionOfReceivedNotificationsApiArg = void;
export type CreateNewNotificationApiResponse = /** status 201 Create new notification */ NotificationDto;
export type CreateNewNotificationApiArg = {
    notificationDto: NotificationDto;
};
export type GetCollectionOfThreadsApiResponse = /** status 200 Get collection of threads */ ThreadDtoList;
export type GetCollectionOfThreadsApiArg = void;
export type CreateThreadApiResponse = /** status 201 Create thread */ ThreadDto2;
export type CreateThreadApiArg = {
    threadDto: ThreadDto;
};
export type MarkThreadAsReadApiResponse = /** status 204 Mark thread as read */ ThreadDto2;
export type MarkThreadAsReadApiArg = {
    threadId: string;
};
export type GetSellersOnboardingFormApiResponse = /** status 200 Resource response */ SellersInfoDto2;
export type GetSellersOnboardingFormApiArg = void;
export type SendSellerOnboardingFormApiResponse = /** status 201 Resource created */ SellersInfoDto2;
export type SendSellerOnboardingFormApiArg = {
    sellersInfoDto: SellersInfoDto;
};
export type CreatePaymentInvoiceApiResponse = /** status 201 Resource created */ ReplenishmentDto;
export type CreatePaymentInvoiceApiArg = {
    createInvoiceDto: CreateInvoiceDto;
};
export type GetPaymentTypesListApiResponse = /** status 200 Get payment types list */ PaymentTypeList;
export type GetPaymentTypesListApiArg = void;
export type GetProductDetailsApiResponse = /** status 200 Get product details */ ProductDto;
export type GetProductDetailsApiArg = {
    id: string;
};
export type GetProductsListApiResponse = /** status 200 Get products list */ {
    totalCount?: number;
    page?: number;
    limit?: number;
    items?: ProductDto[];
};
export type GetProductsListApiArg = {
    page?: number;
    limit?: number;
    searchString?: string | null;
    stores?: {
        ''?: string[];
    };
    categories?: {
        ''?: number[];
    };
    marketplaces?: {
        ''?: string[];
    };
};
export type AddAPersonalPromotionApiResponse = /** status 200 Resource response */ PromotionDto3;
export type AddAPersonalPromotionApiArg = {
    personalPromotionDto: PersonalPromotionDto;
};
export type UpdateThePersonalPromotionApiResponse = /** status 200 Resource response */ PromotionDto3;
export type UpdateThePersonalPromotionApiArg = {
    id: string;
    personalPromotionDto2: PersonalPromotionDto2;
};
export type DeleteThePersonalPromotionApiResponse = /** status 204 Resource removed */ void;
export type DeleteThePersonalPromotionApiArg = {
    id: string;
};
export type GetSellerPromotionsListApiResponse = /** status 200 Paginated response */ {
    totalCount?: number;
    page?: number;
    limit?: number;
    items?: PromotionDto3[];
};
export type GetSellerPromotionsListApiArg = {
    page?: number;
    limit?: number;
    marketplaceSlug?: MarketPlaceSlugEnum | null;
    storeId?: string | null;
    active?: boolean | null;
    types?: {
        ''?: PromotionTypeEnum3[];
    };
};
export type ViewThePromotionApiResponse = /** status 200 Resource response */ PromotionDto3;
export type ViewThePromotionApiArg = {
    id: string;
};
export type PatchAppPrivateSellerPromotionSetpromotionvisibilityApiResponse = unknown;
export type PatchAppPrivateSellerPromotionSetpromotionvisibilityApiArg = {
    setPromotionVisibilityDto: SetPromotionVisibilityDto;
};
export type UpdateTheGlobalPromotionApiResponse = /** status 200 Resource response */ PromotionDto3;
export type UpdateTheGlobalPromotionApiArg = {
    id: string;
    promotionDto: PromotionDto;
};
export type GetProductsListForPromotionApiResponse = /** status 200 Get products list for promotion */ {
    totalCount?: number;
    page?: number;
    limit?: number;
    items?: PromotionProductDto2[];
};
export type GetProductsListForPromotionApiArg = {
    id: string;
    page?: number;
    limit?: number;
};
export type PatchAppPrivateSellerPromotionSetpromotionproductvisibilityApiResponse = unknown;
export type PatchAppPrivateSellerPromotionSetpromotionproductvisibilityApiArg = {
    id: string;
    setPromotionProductVisibilityDto: SetPromotionProductVisibilityDto;
};
export type GetAllPublishersApiResponse = /** status 200 Get all publishers list */ {
    totalCount?: number;
    page?: number;
    limit?: number;
    items?: PublisherDto[];
};
export type GetAllPublishersApiArg = void;
export type GetSellerStatisticsApiResponse = unknown;
export type GetSellerStatisticsApiArg = void;
export type GetStoresListApiResponse = /** status 200 Get stores list */ StoreDtoList;
export type GetStoresListApiArg = void;
export type CreateANewStoreApiResponse = /** status 201 Resource created */ StoreDto3;
export type CreateANewStoreApiArg = {
    storeDto: StoreDto;
};
export type ViewTheStoreApiResponse = /** status 200 Resource response */ StoreDto3;
export type ViewTheStoreApiArg = {
    store: string;
};
export type PutAppPrivateSellerStoreUpdateApiResponse = unknown;
export type PutAppPrivateSellerStoreUpdateApiArg = {
    store: string;
    storeDto2: StoreDto2;
};
export type RemoveTheStoreApiResponse = /** status 204 Resource removed */ void;
export type RemoveTheStoreApiArg = {
    store: string;
};
export type GetSellerTransactionsListApiResponse = /** status 200 Paginated response */ {
    totalCount?: number;
    page?: number;
    limit?: number;
    items?: TransactionDto[];
};
export type GetSellerTransactionsListApiArg = {
    page?: number;
    limit?: number;
    createdAtFrom?: string | null;
    createdAtTo?: string | null;
};
export type GetTheUserInformationApiResponse = /** status 200 Returns the user information */ UserDto;
export type GetTheUserInformationApiArg = void;
export type PostAppApiUserSetRolesApiResponse = unknown;
export type PostAppApiUserSetRolesApiArg = {
    userRolesDto: UserRolesDto;
};
export type AddOnboardingStatusApiResponse = /** status 200 Resource response */ UserDto;
export type AddOnboardingStatusApiArg = {
    status: string;
};
export type CountryDto = {
    id: string;
    title: string;
};
export type CountryListDto = {
    items?: CountryDto[];
};
export type FileDto = {
    fileName: string;
};
export type Marketplace4 = {
    id: string;
    title: string;
    slug: MarketPlaceSlugEnum6;
    description?: string | null;
    deletedAt?: string | null;
    createdAt: string;
    updatedAt: string;
    deleted: boolean;
};
export type PaymentType2 = {
    id: number;
    title: string;
    type: PaymentTypeEnum5;
    defaultFeeType: FeeTypeEnum2;
    defaultFeeValue: string;
    defaultSystemRate?: string;
    defaultMinTotalFee?: string;
};
export type Currency4 = {
    id: string;
    shortCode: string;
    symbol: string;
};
export type Promotion = {
    id: number;
    version?: number;
    marketplaceStore?: MarketplaceStore5 | null;
    externalId: string;
    title: string;
    type: PromotionTypeEnum5;
    coupon?: string | null;
    targetLink?: string | null;
    startDate: string;
    endDate: string;
    description?: string | null;
    participatingProductsCount?: number | null;
    orderAmount?: number | null;
    discountType?: DiscountTypeEnum4 | null;
    discountValue?: string | null;
    active?: boolean;
    visible?: boolean;
    promotionProducts: PromotionProduct[];
    deletedAt?: string | null;
    createdAt: string;
    updatedAt: string;
    deleted: boolean;
};
export type PromotionProduct = {
    id: string;
    version?: number;
    product: Product4;
    promotion: Promotion;
    price?: string | null;
    priceDiscount?: string | null;
    discountPercent?: number | null;
    visible?: boolean;
    createdAt: string;
    updatedAt: string;
    deletedAt?: string | null;
    deleted: boolean;
};
export type ProductCategory4 = {
    id: number;
    title: string;
    deletedAt?: string | null;
    createdAt: string;
    updatedAt: string;
    deleted: boolean;
};
export type Product4 = {
    id: number;
    marketplace_store?: MarketplaceStore5 | null;
    title: string;
    sku: string;
    description: string;
    price: string;
    currency?: Currency4 | null;
    external_link: string;
    external_id: string;
    product_campaigns: ProductCampaign2[];
    promotion_products: PromotionProduct[];
    version?: number;
    product_category?: ProductCategory4 | null;
    image_url?: string | null;
    deletedAt?: string | null;
    createdAt: string;
    updatedAt: string;
    deleted: boolean;
};
export type FeeSetting3 = {
    id: string;
    fee_type?: FeeTypeEnum2;
    fee_value?: string;
    system_rate?: string;
    min_total_fee?: string;
    createdAt: string;
    updatedAt: string;
};
export type ProductCampaign2 = {
    id: string;
    campaign: Campaign2;
    product: Product4;
    fee_setting?: FeeSetting3 | null;
    visible?: boolean;
    favorite?: boolean;
    advertising_guidance?: string | null;
    need_review?: boolean;
    keywords?: string | null;
    deletedAt?: string | null;
    createdAt: string;
    updatedAt: string;
    deleted: boolean;
};
export type Country = {
    id: string;
};
export type LegalInfo = {
    id: string;
    user?: User | null;
    country?: Country | null;
    legalType?: LegalTypeEnum3;
    title: string;
    inn?: string | null;
    taxNumber?: string | null;
    mobilePhone?: string | null;
    deletedAt?: string | null;
    createdAt: string;
    updatedAt: string;
    legalText: string;
    deleted: boolean;
};
export type Publisher = {
    id: string;
    publisherVerificationCode?: string;
    user?: User | null;
    legalInfo?: LegalInfo | null;
    sources: Source[];
    deletedAt?: string | null;
    createdAt: string;
    updatedAt: string;
    deleted: boolean;
};
export type SourceType2 = {
    id: number;
    type: SourceTypeEnum2;
    title: string;
    slug: string;
};
export type SourceContentType2 = {
    id: number;
    title: string;
    slug: string;
};
export type SourceAudience = {
    id: number;
    audienceRu: AudienceRuEnum;
    audienceTotal: AudienceTotalEnum;
    audienceAvg: string;
    postsPerWeek?: string | null;
};
export type SourceCampaignBlacklist = {
    id: string;
    campaign: Campaign2;
    source: Source;
    createdAt: string;
    updatedAt: string;
};
export type Source = {
    id: number;
    publisher?: Publisher | null;
    title: string;
    status?: SourceStatusEnum2;
    sourceType?: SourceType2 | null;
    sourceContentTypes: SourceContentType2[];
    sourceUrl: string;
    comment?: string | null;
    sourceAudience?: SourceAudience | null;
    sourceCampaignFees: SourceCampaignFee2[];
    sourceCampaignBlacklists: SourceCampaignBlacklist[];
    deletedAt?: string | null;
    createdAt: string;
    updatedAt: string;
    deleted: boolean;
};
export type SourceCampaignFee2 = {
    id: string;
    campaign: Campaign2;
    source: Source;
    feeSetting?: FeeSetting3 | null;
    createdAt: string;
    updatedAt: string;
};
export type BarterSetting2 = {
    id: string;
    advertisingFormat?: AdvertisingFormatEnum4[];
    integrationNumber: IntegrationNumberEnum4;
    barterFee?: string;
    paymentType: PaymentTypeEnum6;
    deadline: DeadlineEnum4;
    publisherRequirements?: string | null;
    createdAt: string;
    updatedAt: string;
};
export type Campaign2 = {
    id: number;
    marketplaceStore?: MarketplaceStore5 | null;
    title: string;
    slug: string;
    description?: string | null;
    logo?: string | null;
    marketplace_url?: string | null;
    payment_type?: PaymentType2 | null;
    status?: CampaignStatusEnum2;
    holdTime?: number;
    endDate?: string | null;
    productCampaigns: ProductCampaign2[];
    sourceCampaignFees: SourceCampaignFee2[];
    sourceCampaignBlacklists: SourceCampaignBlacklist[];
    fee_setting?: FeeSetting3 | null;
    barter_setting?: BarterSetting2 | null;
    deletedAt?: string | null;
    createdAt: string;
    updatedAt: string;
    barterProductCampaigns: ProductCampaign2[];
    deleted: boolean;
};
export type MarketplaceStore5 = {
    settings?: {
        [key: string]: string;
    };
    id: string;
    externalId: string;
    store?: Store2 | null;
    marketplace?: Marketplace4 | null;
    status?: MarketplaceStoreStatusEnum;
    successfulProductsSyncDate?: string | null;
    successfulAnalyticsSyncDate?: string | null;
    defaultCampaign?: Campaign2 | null;
    createdAt: string;
    updatedAt: string;
    secretNamespace: string;
};
export type Store2 = {
    id: string;
    seller?: Seller | null;
    title: string;
    slug: string;
    marketplaceStores: MarketplaceStore5[];
    comment?: string | null;
    logo?: string | null;
    deletedAt?: string | null;
    createdAt: string;
    updatedAt: string;
    active: boolean;
    deleted: boolean;
};
export type SellerInfo = {
    id: string;
    website?: string | null;
    revenue?: RevenueRangeEnum | null;
    sellersGoal?: (
        | 'increase_sales'
        | 'obtaining_statistics'
        | 'order_tracking'
        | 'analytics'
        | 'communication_with_webmasters'
        | 'increasing_reviews'
        | 'other'
    )[];
};
export type Seller = {
    id: string;
    user?: User | null;
    stores: Store2[];
    sellerInfo?: SellerInfo | null;
    legalInfo?: LegalInfo | null;
    erid?: string | null;
    deletedAt?: string | null;
    createdAt: string;
    updatedAt: string;
    deleted: boolean;
};
export type Credit = {
    id: number;
    user?: User | null;
    amount: string;
    createdAt: string;
    updatedAt: string;
};
export type User = {
    onboardingStatuses?: string[];
    id: string;
    email: string;
    roles?: string[];
    firstname?: string | null;
    lastname?: string | null;
    seller?: Seller | null;
    publisher?: Publisher | null;
    mitgo?: string | null;
    onboarded?: boolean;
    referrer?: User | null;
    referralCode: string;
    balance?: string;
    credit?: Credit | null;
    deletedAt?: string | null;
    createdAt: string;
    updatedAt: string;
    role: string[];
    verified: boolean;
    fullName: string;
    userType: string;
    realRoles: string[];
    deleted: boolean;
};
export type LoginRequestDto = {
    email: string;
    password: string;
};
export type ProductCategoryDto = {
    id?: number | null;
    title: string | null;
};
export type ProductCategoryDtoList = {
    items?: ProductCategoryDto[];
};
export type LegalInfoDto2 = {
    id?: string | null;
    country: string;
    legal_type: LegalTypeEnum2 | null;
    title: string | null;
    inn?: string | null;
    tax_number?: string | null;
    mobile_phone?: string | null;
};
export type LegalInfoDto = {
    country: string;
    legal_type: LegalTypeEnum | null;
    title: string | null;
    inn?: string | null;
    tax_number?: string | null;
    mobile_phone?: string | null;
};
export type MarketplaceDto2 = {
    id: string;
    title: string;
    slug: MarketPlaceSlugEnum4;
    description?: string | null;
};
export type StoreDto3 = {
    id: string;
    title: string;
    comment?: string | null;
    logo?: string | null;
    marketplaceStores?: MarketplaceStoreDto2[];
    active: boolean;
};
export type MarketplaceStoreDto2 = {
    id: string;
    marketplace: MarketplaceDto2;
    store: StoreDto3;
};
export type PromotionDto3 = {
    id: string;
    type: PromotionTypeEnum4;
    title: string;
    description: string | null;
    marketplace_store: MarketplaceStoreDto2;
    start_date: string;
    end_date: string;
    active?: boolean;
    visible?: boolean;
    orderAmount?: number | null;
    coupon?: string | null;
    discount_type?: DiscountTypeEnum3 | null;
    discountValue?: string | null;
};
export type Store3 = {
    id: string;
    title: string;
    logo?: string | null;
};
export type Marketplace2 = {
    id: string;
    title: string;
    slug: MarketPlaceSlugEnum4;
    description?: string | null;
};
export type MarketplaceStore3 = {
    id: string;
    store?: Store3 | null;
    marketplace?: Marketplace2 | null;
};
export type Currency = {
    shortCode: string;
    symbol: string;
};
export type ProductCategory = {
    id: number;
    title: string;
};
export type ProductDto = {
    id: string;
    marketplace_store: MarketplaceStore3 | null;
    title: string;
    sku: string;
    description: string;
    price: number | null;
    currency: Currency | null;
    product_category?: ProductCategory | null;
    external_link: string;
    external_id: string;
    image_url?: string | null;
};
export type FeeSettingDto = {
    fee_type?: FeeTypeEnum | null;
    fee_value?: number | null;
};
export type PromotionProductDto = {
    id: string;
    price?: number | null;
    price_discount?: number | null;
    price_percent?: number | null;
    promotion?: PromotionDto3 | null;
    product?: ProductDto | null;
    legal_text?: string | null;
    tracking_link?: string | null;
    fee_setting?: FeeSettingDto | null;
    favorite?: boolean;
    tracking_short_link?: string | null;
};
export type PromotionDto2 = {
    id?: number | null;
    title?: string | null;
    description?: string | null;
    marketplace_store?: MarketplaceStore3 | null;
    discount_type?: DiscountTypeEnum3 | null;
    discount_value?: string | null;
    start_date?: string | null;
    end_date?: string | null;
    coupon?: string | null;
    landing_link?: string | null;
    favorite?: boolean;
    legal_text?: string | null;
};
export type SourceContentType = {
    id: number;
    title: string;
    slug: string;
};
export type SourceContentTypeList = {
    items?: SourceContentType[];
};
export type Publisher2 = object;
export type SourceDto3 = {
    id?: string | null;
    publisher?: Publisher2 | null;
    title: string | null;
    status?: SourceStatusEnum | null;
    source_type: number;
    source_content_types?: number[];
    source_url: string | null;
    comment?: string | null;
};
export type SourceDtoList = {
    items?: SourceDto3[];
};
export type SourceDto = {
    title: string | null;
    source_type: number;
    source_content_types?: number[];
    source_url: string | null;
};
export type SourceDto2 = {
    title: string | null;
    source_type: number;
    source_content_types?: number[];
    source_url: string | null;
};
export type PaymentType = {
    id: number;
    title: string;
    type: PaymentTypeEnum3;
};
export type FeeSetting2 = {
    id: string;
    fee_type?: FeeTypeEnum;
    fee_value?: string;
    system_rate?: string;
    min_total_fee?: string;
};
export type SourceCampaignFee = {
    id: string;
};
export type CampaignDto3 = {
    id?: number | null;
    marketplace_store?: MarketplaceStore3 | null;
    title: string | null;
    description?: string | null;
    logo?: string | null;
    marketplace_url?: string | null;
    payment_type?: PaymentType | null;
    status?: CampaignStatusEnum | null;
    hold_time: number | null;
    fee_setting: FeeSetting2 | null;
    source_campaign_fees?: SourceCampaignFee[];
};
export type ProductCampaignDto = {
    id: string;
    campaign?: CampaignDto3 | null;
    product?: ProductDto | null;
    fee_setting?: FeeSettingDto | null;
    visible?: boolean;
    favorite?: boolean;
    tracking_link?: string | null;
    legal_text?: string | null;
    advertising_guidance?: string | null;
    need_review?: boolean;
    keywords?: string | null;
    tracking_short_link?: string | null;
};
export type SourceType = {
    id: number;
    type: SourceTypeEnum;
    title: string;
    slug: string;
};
export type SourceTypeList = {
    items?: SourceType[];
};
export type ProductStatisticDto = {
    title: string;
    external_link: string;
    clicks: number;
    commissions: number;
    amount: number;
};
export type ProductStatisticListDto = {
    items?: ProductStatisticDto[];
};
export type Product = {
    id: number;
    marketplace_store?: MarketplaceStore3 | null;
    title: string;
    sku: string;
    description: string;
    price: string;
    currency?: Currency | null;
    external_link: string;
    external_id: string;
    product_category?: ProductCategory | null;
    image_url?: string | null;
};
export type MpTrafficReportDto = {
    date: string;
};
export type CommissionDto = {
    id: string;
    status: CommissionStatusEnum;
    decline_reason?: CommissionDeclineReasonEnum2 | null;
    change_total_cart_reason?: CommissionChangeTotalCartReasonEnum2 | null;
    marketplace_store?: MarketplaceStore3 | null;
    order_product?: Product | null;
    total_cart?: number | null;
    total_fee?: number | null;
    fee_value?: number | null;
    mp_traffic_report?: MpTrafficReportDto | null;
};
export type TransactionDto = {
    id: string;
    transaction_type: TransactionTypeEnum;
    transaction_amount: number;
    currency: Currency;
    commission?: CommissionDto | null;
    created_at: string;
};
export type UserRegistrationDto = {
    email: string;
    password: string;
    firstName?: string | null;
    lastName?: string | null;
    isSeller?: boolean;
};
export type BarterSettingDto3 = {
    id?: string | null;
    advertising_format?: AdvertisingFormatEnum3[];
    integration_number: IntegrationNumberEnum3 | null;
    barter_fee?: number | null;
    payment_type: PaymentTypeEnum4 | null;
    deadline: DeadlineEnum3 | null;
    publisher_requirements: string | null;
};
export type CampaignDto2 = {
    slug?: string | null;
    marketplace_store?: MarketplaceStore3 | null;
    title: string | null;
    description?: string | null;
    logo?: string | null;
    marketplace_url?: string | null;
    payment_type?: PaymentType | null;
    status?: CampaignStatusEnum | null;
    hold_time: number | null;
    fee_setting: FeeSetting2 | null;
    barter_setting?: BarterSettingDto3 | null;
    product_campaigns?: ProductCampaignDto[];
    source_campaign_fees?: SourceCampaignFee[];
};
export type Store = {
    title: string;
    logo?: string | null;
};
export type Marketplace = {
    title: string;
    slug: MarketPlaceSlugEnum3;
    description?: string | null;
};
export type MarketplaceStore2 = {
    id: string;
    store?: Store | null;
    marketplace?: Marketplace | null;
};
export type BarterSettingDto = {
    advertising_format?: AdvertisingFormatEnum[];
    integration_number: IntegrationNumberEnum | null;
    payment_type: PaymentTypeEnum | null;
    deadline: DeadlineEnum | null;
    publisher_requirements: string | null;
};
export type Currency2 = {
    shortCode: string;
    symbol: string;
};
export type ProductCategory2 = {
    title: string;
};
export type Product2 = {
    id: number;
    marketplace_store?: MarketplaceStore2 | null;
    title: string;
    sku: string;
    description: string;
    price: string;
    currency?: Currency2 | null;
    external_link: string;
    external_id: string;
    product_category?: ProductCategory2 | null;
};
export type BarterProductCampaignDto = {
    product?: Product2 | null;
    advertising_guidance?: string | null;
    need_review?: boolean;
    keywords?: string | null;
};
export type BarterCampaignDto = {
    marketplace_store: MarketplaceStore2 | null;
    title: string | null;
    barter_setting: BarterSettingDto | null;
    product_campaigns?: BarterProductCampaignDto[];
};
export type BarterSettingDto2 = {
    advertising_format?: AdvertisingFormatEnum2[];
    integration_number: IntegrationNumberEnum2 | null;
    payment_type: PaymentTypeEnum2 | null;
    deadline: DeadlineEnum2 | null;
    publisher_requirements: string | null;
};
export type Store4 = {
    title: string;
    logo?: string | null;
};
export type Marketplace3 = {
    title: string;
    slug: MarketPlaceSlugEnum5;
    description?: string | null;
};
export type MarketplaceStore4 = {
    id: string;
    store?: Store4 | null;
    marketplace?: Marketplace3 | null;
};
export type Currency3 = {
    shortCode: string;
    symbol: string;
};
export type ProductCategory3 = {
    title: string;
};
export type Product3 = {
    id: number;
    marketplace_store?: MarketplaceStore4 | null;
    title: string;
    sku: string;
    description: string;
    price: string;
    currency?: Currency3 | null;
    external_link: string;
    external_id: string;
    product_category?: ProductCategory3 | null;
};
export type BarterProductCampaignDto2 = {
    product?: Product3 | null;
    advertising_guidance?: string | null;
    need_review?: boolean;
    keywords?: string | null;
};
export type BarterCampaignDto2 = {
    title: string | null;
    barter_setting: BarterSettingDto2 | null;
    product_campaigns?: BarterProductCampaignDto2[];
};
export type CampaignDtoList = {
    items?: CampaignDto2[];
};
export type FeeSetting = {
    fee_value?: string;
};
export type CampaignDto = {
    title: string | null;
    hold_time: number | null;
    fee_setting: FeeSetting | null;
};
export type BarterSetting = object;
export type Campaign = {
    id: number;
    title: string;
    slug: string;
    description?: string | null;
    logo?: string | null;
    marketplace_url?: string | null;
    payment_type?: PaymentType | null;
    status?: CampaignStatusEnum;
    holdTime?: number;
    fee_setting?: FeeSetting2 | null;
    barter_setting?: BarterSetting | null;
};
export type ProductCampaign = {
    id: string;
    campaign: Campaign;
    product: Product;
    fee_setting?: FeeSetting2 | null;
    visible?: boolean;
    favorite?: boolean;
    advertising_guidance?: string | null;
    need_review?: boolean;
    keywords?: string | null;
};
export type ChangeCommissionDto = {
    select_all?: boolean;
    commission: number | null;
    products?: string[];
};
export type SetVisibilityDto = {
    select_all?: boolean;
    visible?: boolean;
    products?: string[];
};
export type DeclineReasonDto = {
    decline_reason: CommissionDeclineReasonEnum;
};
export type ChangeTotalCartReasonDto = {
    change_total_cart_reason: CommissionChangeTotalCartReasonEnum;
    new_total_cart: number;
};
export type MarketplaceIntegrationDto = {
    id: string;
    store_id: string;
    marketplace_slug: MarketPlaceSlugEnum2;
};
export type MarketplaceIntegrationListDto = {
    items?: MarketplaceIntegrationDto[];
};
export type OzonIntegrationDto2 = {
    organization_id: string | null;
    seller_client_id: string;
    seller_api_key: string;
    performance_client_id: string;
    performance_api_key: string;
};
export type MarketplaceStore = {
    id: string;
};
export type OzonIntegrationDto = {
    organization_id: string | null;
    seller_client_id: string;
    seller_api_key: string;
    performance_client_id: string;
    performance_api_key: string;
};
export type WildberriesIntegrationDto3 = {
    organization_id: string | null;
    wb_token_v3: string;
    validation_key: string;
    seller_device_id: string;
    api_authorization_token: string | null;
};
export type WildberriesIntegrationDto2 = {
    organization_id: string | null;
    wb_token_v3: string;
    validation_key: string;
    seller_device_id: string;
    api_authorization_token: string | null;
};
export type WildberriesIntegrationDto = {
    organization_id: string | null;
    wb_token_v3: string;
    validation_key: string;
    seller_device_id: string;
    api_authorization_token: string | null;
};
export type MarketplaceDto = {
    slug: string;
    title: string;
    description?: string | null;
};
export type MarketplaceListDto = {
    items?: MarketplaceDto[];
};
export type SenderDto2 = {
    id: string;
    email?: string | null;
    firstname?: string | null;
    lastname?: string | null;
};
export type MessageDto2 = {
    id: string;
    sender: SenderDto2;
    body: string;
    isRead?: boolean;
    createdAt: string;
};
export type MessageDtoList = {
    items?: MessageDto2[];
};
export type SenderDto = {
    id: string;
};
export type MessageDto = {
    sender: SenderDto;
    body: string;
};
export type SellerDto = {
    id?: string | null;
    legalInfo?: LegalInfoDto2 | null;
};
export type PublisherDto = {
    id?: string | null;
    publisherVerificationCode: string;
    firstName: string;
    lastName: string;
    avatar?: string | null;
    legalInfo?: LegalInfoDto2 | null;
};
export type UserDto = {
    id?: string | null;
    seller?: SellerDto | null;
    publisher?: PublisherDto | null;
    country?: string | null;
    timezone?: string | null;
    avatar?: string | null;
    roles?: string[];
    onboarded?: boolean;
    email?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    balance?: number;
    credit?: number;
    referral_code?: string;
    onboarding_statuses?: string[];
};
export type NotificationDto2 = {
    id: string;
    createdBy: UserDto | null;
    subject: string;
    body: string;
    createdAt: string;
    read: boolean;
};
export type NotificationDtoList = {
    items?: NotificationDto2[];
};
export type UserDto2 = object;
export type NotificationDto = {
    createdBy: UserDto2 | null;
    subject: string;
    body: string;
    read: boolean;
};
export type ThreadMemberDto3 = {
    id: string;
    read?: boolean;
};
export type ThreadDto3 = {
    id?: string | null;
    createdBy: string;
    subject: string;
    holder: ThreadMemberDto3;
    member: ThreadMemberDto3;
    body: string;
    createdAt: string;
};
export type ThreadDtoList = {
    items?: ThreadDto3[];
};
export type ThreadMemberDto2 = {
    id: string;
    read?: boolean;
};
export type ThreadDto2 = {
    createdBy: string;
    subject: string;
    holder: ThreadMemberDto2;
    member: ThreadMemberDto2;
    body: string;
};
export type ThreadMemberDto = {
    id: string;
    read?: boolean;
};
export type ThreadDto = {
    id?: string | null;
    createdBy: string;
    subject: string;
    holder: ThreadMemberDto;
    member: ThreadMemberDto;
    body: string;
    createdAt: string;
};
export type SellersInfoDto2 = {
    website?: string | null;
    revenue_range?: RevenueRangeEnum2 | null;
    sellers_goal?: (
        | 'increase_sales'
        | 'obtaining_statistics'
        | 'order_tracking'
        | 'analytics'
        | 'communication_with_webmasters'
        | 'increasing_reviews'
        | 'other'
    )[];
};
export type SellersInfoDto = {
    website?: string | null;
    revenue_range?: RevenueRangeEnum | null;
    sellers_goal?: (
        | 'increase_sales'
        | 'obtaining_statistics'
        | 'order_tracking'
        | 'analytics'
        | 'communication_with_webmasters'
        | 'increasing_reviews'
        | 'other'
    )[];
};
export type ReplenishmentDto = {
    paymentUrl: string;
};
export type CreateInvoiceDto = {
    amount?: number | number;
};
export type PaymentTypeList = {
    items?: PaymentType[];
};
export type PersonalPromotionDto = {
    marketplace_store: MarketplaceStore2;
    type: PromotionTypeEnum2;
    title: string;
    description: string;
    start_date: string;
    end_date: string;
    coupon?: string | null;
    discount_type: DiscountTypeEnum;
    discount_value: number;
    promotion_products_file_id: string | null;
};
export type PersonalPromotionDto2 = {
    title: string;
    description: string;
    start_date: string;
    end_date: string;
    coupon?: string | null;
    discount_type: DiscountTypeEnum2;
    discount_value: number;
    promotion_products_file_id: string | null;
};
export type SetPromotionVisibilityDto = {
    select_all?: boolean;
    visible?: boolean;
    promotions?: string[];
};
export type PromotionDto = {
    description: string | null;
};
export type PromotionProductDto2 = {
    id: string;
    product: ProductDto;
    price?: number | null;
    price_discount?: number | null;
    price_percent?: number | null;
    visible?: boolean;
};
export type SetPromotionProductVisibilityDto = {
    select_all?: boolean;
    visible?: boolean;
    promotion_products?: string[];
};
export type StoreDtoList = {
    items?: StoreDto3[];
};
export type StoreDto = {
    title: string;
    comment?: string | null;
    logo?: string | null;
};
export type MarketplaceStoreDto = object;
export type StoreDto2 = {
    title: string;
    comment?: string | null;
    logo?: string | null;
    marketplaceStores?: MarketplaceStoreDto[];
};
export type UserRolesDto = {
    is_publisher?: boolean;
    is_seller?: boolean;
    publisher?: boolean | null;
    seller?: boolean | null;
};
export enum MarketPlaceSlugEnum6 {
    Ozon = 'ozon',
    Wildberries = 'wildberries',
}
export enum MarketplaceStoreStatusEnum {
    Connected = 'connected',
    Disconnected = 'disconnected',
}
export enum PaymentTypeEnum5 {
    Cpa = 'cpa',
    Cpp = 'cpp',
    Barter = 'barter',
    Cpc = 'cpc',
}
export enum FeeTypeEnum2 {
    Fixed = 'fixed',
    Percentage = 'percentage',
}
export enum CampaignStatusEnum2 {
    Pending = 'pending',
    Active = 'active',
    TopUpBalance = 'top_up_balance',
    AddLegalInfo = 'add_legal_info',
    Verifying = 'verifying',
    Disabled = 'disabled',
}
export enum PromotionTypeEnum5 {
    GlobalSale = 'global_sale',
    Sale = 'sale',
    Coupon = 'coupon',
}
export enum DiscountTypeEnum4 {
    Fixed = 'fixed',
    Percentage = 'percentage',
}
export enum LegalTypeEnum3 {
    NaturalPerson = 'natural_person',
    IndividualEntrepreneur = 'individual_entrepreneur',
    Company = 'company',
}
export enum SourceStatusEnum2 {
    Verification = 'verification',
    Approved = 'approved',
    Declined = 'declined',
}
export enum SourceTypeEnum2 {
    SocialNetwork = 'social_network',
    Messenger = 'messenger',
    Other = 'other',
}
export enum AudienceRuEnum {
    $010 = '0_10',
    $1030 = '10_30',
    $3050 = '30_50',
    $5070 = '50_70',
    $70Plus = '70_PLUS',
}
export enum AudienceTotalEnum {
    $02 = '0_2',
    $210 = '2_10',
    $1050 = '10_50',
    $50100 = '50_100',
    $100500 = '100_500',
    $500Plus = '500_PLUS',
}
export enum AdvertisingFormatEnum4 {
    Reels = 'reels',
    Stories = 'stories',
    Post = 'post',
}
export enum IntegrationNumberEnum4 {
    $30 = '30',
    $45 = '45',
    $60 = '60',
}
export enum PaymentTypeEnum6 {
    TransferToCard = 'transfer_to_card',
    Certificate = 'certificate',
    PromoCode = 'promo_code',
}
export enum DeadlineEnum4 {
    Month1 = 'month_1',
    Month2 = 'month_2',
    Month3 = 'month_3',
}
export enum RevenueRangeEnum {
    FirstLevel = 'first_level',
    SecondLevel = 'second_level',
    ThirdLevel = 'third_level',
    FourthLevel = 'fourth_level',
}
export enum LegalTypeEnum2 {
    NaturalPerson = 'natural_person',
    IndividualEntrepreneur = 'individual_entrepreneur',
    Company = 'company',
}
export enum LegalTypeEnum {
    NaturalPerson = 'natural_person',
    IndividualEntrepreneur = 'individual_entrepreneur',
    Company = 'company',
}
export enum PromotionTypeEnum4 {
    GlobalSale = 'global_sale',
    Sale = 'sale',
    Coupon = 'coupon',
}
export enum MarketPlaceSlugEnum4 {
    Ozon = 'ozon',
    Wildberries = 'wildberries',
}
export enum DiscountTypeEnum3 {
    Fixed = 'fixed',
    Percentage = 'percentage',
}
export enum FeeTypeEnum {
    Fixed = 'fixed',
    Percentage = 'percentage',
}
export enum PromotionTypeEnum {
    GlobalSale = 'global_sale',
    Sale = 'sale',
    Coupon = 'coupon',
}
export enum SourceStatusEnum {
    Verification = 'verification',
    Approved = 'approved',
    Declined = 'declined',
}
export enum PaymentTypeEnum3 {
    Cpa = 'cpa',
    Cpp = 'cpp',
    Barter = 'barter',
    Cpc = 'cpc',
}
export enum CampaignStatusEnum {
    Pending = 'pending',
    Active = 'active',
    TopUpBalance = 'top_up_balance',
    AddLegalInfo = 'add_legal_info',
    Verifying = 'verifying',
    Disabled = 'disabled',
}
export enum SourceTypeEnum {
    SocialNetwork = 'social_network',
    Messenger = 'messenger',
    Other = 'other',
}
export enum TransactionTypeEnum {
    Commission = 'commission',
    Replenishment = 'replenishment',
    Payout = 'payout',
    Manual = 'manual',
}
export enum CommissionStatusEnum {
    Pending = 'pending',
    Approved = 'approved',
    Declined = 'declined',
}
export enum CommissionDeclineReasonEnum2 {
    Refusal = 'refusal',
    NotRedeemed = 'not_redeemed',
    Refund = 'refund',
}
export enum CommissionChangeTotalCartReasonEnum2 {
    PartiallyPaid = 'partially_paid',
}
export enum AdvertisingFormatEnum3 {
    Reels = 'reels',
    Stories = 'stories',
    Post = 'post',
}
export enum IntegrationNumberEnum3 {
    $30 = '30',
    $45 = '45',
    $60 = '60',
}
export enum PaymentTypeEnum4 {
    TransferToCard = 'transfer_to_card',
    Certificate = 'certificate',
    PromoCode = 'promo_code',
}
export enum DeadlineEnum3 {
    Month1 = 'month_1',
    Month2 = 'month_2',
    Month3 = 'month_3',
}
export enum MarketPlaceSlugEnum3 {
    Ozon = 'ozon',
    Wildberries = 'wildberries',
}
export enum AdvertisingFormatEnum {
    Reels = 'reels',
    Stories = 'stories',
    Post = 'post',
}
export enum IntegrationNumberEnum {
    $30 = '30',
    $45 = '45',
    $60 = '60',
}
export enum PaymentTypeEnum {
    TransferToCard = 'transfer_to_card',
    Certificate = 'certificate',
    PromoCode = 'promo_code',
}
export enum DeadlineEnum {
    Month1 = 'month_1',
    Month2 = 'month_2',
    Month3 = 'month_3',
}
export enum AdvertisingFormatEnum2 {
    Reels = 'reels',
    Stories = 'stories',
    Post = 'post',
}
export enum IntegrationNumberEnum2 {
    $30 = '30',
    $45 = '45',
    $60 = '60',
}
export enum PaymentTypeEnum2 {
    TransferToCard = 'transfer_to_card',
    Certificate = 'certificate',
    PromoCode = 'promo_code',
}
export enum DeadlineEnum2 {
    Month1 = 'month_1',
    Month2 = 'month_2',
    Month3 = 'month_3',
}
export enum MarketPlaceSlugEnum5 {
    Ozon = 'ozon',
    Wildberries = 'wildberries',
}
export enum CommissionDeclineReasonEnum {
    Refusal = 'refusal',
    NotRedeemed = 'not_redeemed',
    Refund = 'refund',
}
export enum CommissionChangeTotalCartReasonEnum {
    PartiallyPaid = 'partially_paid',
}
export enum MarketPlaceSlugEnum2 {
    Ozon = 'ozon',
    Wildberries = 'wildberries',
}
export enum RevenueRangeEnum2 {
    FirstLevel = 'first_level',
    SecondLevel = 'second_level',
    ThirdLevel = 'third_level',
    FourthLevel = 'fourth_level',
}
export enum PromotionTypeEnum2 {
    GlobalSale = 'global_sale',
    Sale = 'sale',
    Coupon = 'coupon',
}
export enum DiscountTypeEnum {
    Fixed = 'fixed',
    Percentage = 'percentage',
}
export enum DiscountTypeEnum2 {
    Fixed = 'fixed',
    Percentage = 'percentage',
}
export enum MarketPlaceSlugEnum {
    Ozon = 'ozon',
    Wildberries = 'wildberries',
}
export enum PromotionTypeEnum3 {
    GlobalSale = 'global_sale',
    Sale = 'sale',
    Coupon = 'coupon',
}
export const {
    useGetCountryListQuery,
    useUploadANewFileMutation,
    usePostAppApiLoginMutation,
    useGetProductCategoriesListQuery,
    useViewPublisherLegalInfoQuery,
    useAddPublisherLegalInfoMutation,
    useAddAProductCampaignToFavoritesMutation,
    useRemoveProductCampaignFromFavoritesMutation,
    useGetGlobalPromotionsProductsListWithLinksBySourceQuery,
    useGetGlobalPromotionsProductsListWithLinksBySourceToCsvFileQuery,
    useGetGlobalPromotionsProductsDetailsWithLinksBySourceQuery,
    useGetPersonalPromotionsListWithLinksBySourceQuery,
    useExportPersonalPromotionsListWithLinksBySourceToCsvFileQuery,
    useExportPersonalPromotionProductsListWithLinksBySourceToCsvFileQuery,
    useGetPersonalPromotionsDetailsWithLinksBySourceQuery,
    useAddAPersonalPromotionToFavoritesMutation,
    useRemovePersonalPromotionFromFavoritesMutation,
    useAddAGlobalPromotionProductToFavoritesMutation,
    useRemoveGlobalPromotionProductFromFavoritesMutation,
    useGetSourceContentTypesListQuery,
    useGetSourcesListQuery,
    useAddASourceMutation,
    useViewASourceQuery,
    usePutAppPrivatePublisherSourceUpdateMutation,
    useDeleteAppPrivatePublisherSourceDeleteMutation,
    useGetProductsListWithLinksBySourceQuery,
    useGetProductsDetailsWithLinksBySourceQuery,
    useGetAppPrivatePublisherSourceProductlistexportQuery,
    useGetSourceTypesListQuery,
    usePublisherProductStatisticQuery,
    useGetPublisherStatisticsQuery,
    useGetPublisherTransactionsListQuery,
    usePostAppApiRegisterMutation,
    useAddABarterCampaignMutation,
    usePutAppPrivateSellerCampaignUpdatebarterMutation,
    usePatchAppPrivateSellerCampaignActivateMutation,
    usePatchAppPrivateSellerCampaignDisableMutation,
    useViewTheCampaignQuery,
    useGetCampaignsListQuery,
    usePatchAppPrivateSellerCampaignUpdateMutation,
    useGetProductsListForCampaignQuery,
    usePatchAppPrivateSellerCampaignChangecommissionMutation,
    usePatchAppPrivateSellerCampaignSetvisibilityMutation,
    useGetSellerCommissionsListQuery,
    usePatchAppPrivateSellerCommissionDeclineMutation,
    usePatchAppPrivateSellerCommissionApproveMutation,
    useGetAListOfExistingMarketplaceIntegrationsQuery,
    useGetAppPrivateSellerIntegrationOzonViewQuery,
    usePutAppPrivateSellerIntegrationOzonUpdateMutation,
    useConnectMarketplaceToOzonMutation,
    useGetExistMarketplaceIntegrationWithWildberriesQuery,
    usePutAppPrivateSellerIntegrationWildberriesUpdateMutation,
    useConnectMarketplaceToWildberriesMutation,
    useViewSellerLegalInfoQuery,
    useAddSellerLegalInfoMutation,
    useGetAListOfAvailableMarketplacesQuery,
    useUpdateProductsInAllIntegrationsMutation,
    useGetCollectionOfMessagesForSellerQuery,
    useCreateNewMessageInThreadMutation,
    useGetCollectionOfReceivedNotificationsQuery,
    useCreateNewNotificationMutation,
    useGetCollectionOfThreadsQuery,
    useCreateThreadMutation,
    useMarkThreadAsReadMutation,
    useGetSellersOnboardingFormQuery,
    useSendSellerOnboardingFormMutation,
    useCreatePaymentInvoiceMutation,
    useGetPaymentTypesListQuery,
    useGetProductDetailsQuery,
    useGetProductsListQuery,
    useAddAPersonalPromotionMutation,
    useUpdateThePersonalPromotionMutation,
    useDeleteThePersonalPromotionMutation,
    useGetSellerPromotionsListQuery,
    useViewThePromotionQuery,
    usePatchAppPrivateSellerPromotionSetpromotionvisibilityMutation,
    useUpdateTheGlobalPromotionMutation,
    useGetProductsListForPromotionQuery,
    usePatchAppPrivateSellerPromotionSetpromotionproductvisibilityMutation,
    useGetAllPublishersQuery,
    useGetSellerStatisticsQuery,
    useGetStoresListQuery,
    useCreateANewStoreMutation,
    useViewTheStoreQuery,
    usePutAppPrivateSellerStoreUpdateMutation,
    useRemoveTheStoreMutation,
    useGetSellerTransactionsListQuery,
    useGetTheUserInformationQuery,
    usePostAppApiUserSetRolesMutation,
    useAddOnboardingStatusMutation,
} = injectedRtkApi;
