import { closeModal } from '@Entities/modals/model/modalsSlice';
import { selectModals } from '@Entities/keyValue/selectors';
import { useAppDispatch, useAppSelector } from '@Shared/hooks';
import { Modal, Typography, Row } from '@Shared/ui';
import { ModalKeysType } from '@Shared/model/modalTypes';

import DeclineCommissionForm from './DeclineCommissionForm';

const { Title, Text } = Typography;

const DeclineCommissionModal = () => {
    const dispatch = useAppDispatch();
    const modalState = useAppSelector(selectModals);
    const isOpen = modalState.modalKey === ModalKeysType.declineCommission && modalState.open;

    const handleCancel = () => {
        dispatch(closeModal());
    };

    return (
        <Modal
            title={
                <Title
                    level={4}
                    style={{ marginTop: 0 }}
                >
                    Отклонить комиссию?
                </Title>
            }
            open={isOpen}
            onCancel={handleCancel}
            footer={null}
        >
            <>
                <Row>
                    <Text type="secondary">
                        Вы уверены, что хотите отклонить комиссию? После отклонения площадка вправе просить
                        доказательства причин отклонения.
                    </Text>
                </Row>
                <DeclineCommissionForm handleModalClose={handleCancel} />
            </>
        </Modal>
    );
};
export default DeclineCommissionModal;
