import Icon from '@ant-design/icons';
import { theme } from 'antd';

import { Row, Space, Typography, Col, Grid } from '@Shared/ui';
import { ManualVerificationTableBlock } from '@Features/seller/finances/manualVerification';
import { InfoInformationCircle } from '@Shared/assets/images/icons';

const { Text, Link } = Typography;
const { useBreakpoint } = Grid;

const ManualVerificationTab = () => {
    const breakpoints = useBreakpoint();
    const {
        token: { colorBgLayout, padding },
    } = theme.useToken();
    return (
        <Space
            direction="vertical"
            style={{ width: '100%' }}
            size={24}
        >
            <Row
                wrap={false}
                style={{ gap: 10, flexDirection: breakpoints.lg ? 'row' : 'column' }}
            >
                <Col>
                    <Text style={{ fontSize: 16 }}>
                        Здесь можно отклонить комиссию за возвращённый или невыкупленный товар. Вы также можете изменить
                        комиссию в случае неполного выкупа. По любым вопросам обращайтесь в{' '}
                        <Link
                            href={`mailto:${process.env.SUPPORT_EMAIL}`}
                            style={{ fontSize: 16 }}
                        >
                            поддержку
                        </Link>
                        .
                    </Text>
                </Col>
                <Col>
                    <Row
                        wrap={false}
                        align="middle"
                        style={{
                            gap: padding,
                            backgroundColor: colorBgLayout,
                            padding: '10px 20px',
                            borderRadius: 12,
                            width: breakpoints.lg ? 340 : '100%',
                        }}
                    >
                        <Col>
                            <Icon component={() => <InfoInformationCircle />} />
                        </Col>
                        <Col>Комиссии подтверждаются автоматически после истечения времени на отклонение действия</Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <ManualVerificationTableBlock />
            </Row>
        </Space>
    );
};
export default ManualVerificationTab;
