import { MouseEvent } from 'react';
import Icon from '@ant-design/icons';
import { theme, ButtonProps } from 'antd';
import classNames from 'classnames';

import { Button, Typography, Row, Card } from '@Shared/ui';
import { CopyItemLeftTop } from '@Shared/assets/images/icons';
import { useClipboard } from '@Shared/hooks';

const { Text } = Typography;
import './styles.scss';

const CopyCardValue = ({
    cardValue,
    copyMessageText,
    className = '',
    buttonProps,
}: {
    cardValue: string;
    copyMessageText?: string;
    className?: string;
    buttonProps?: ButtonProps;
}) => {
    const { copyTextToClipboard } = useClipboard();

    const {
        token: { colorLightSilver },
    } = theme.useToken();

    const handleCopyLink = (e: MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        copyTextToClipboard(cardValue || '', copyMessageText || '');
    };
    return (
        <Row
            align="middle"
            wrap={false}
            style={{ gap: 8, width: '100%' }}
            className={classNames('copy-card', className)}
        >
            <Card
                className="copy-value-card"
                style={{
                    flex: 1,
                    border: `1px solid ${colorLightSilver}`,
                }}
            >
                <Text>{cardValue}</Text>
            </Card>
            <Button
                onClick={handleCopyLink}
                icon={
                    <Icon
                        component={() => (
                            <CopyItemLeftTop color={buttonProps?.type === 'primary' ? 'white' : '#323232'} />
                        )}
                    />
                }
                {...buttonProps}
            />
        </Row>
    );
};

export default CopyCardValue;
