import { formSliceGeneric, defaultFormState } from '@Shared/utils/redux/publisher/formsGenericSlice';
import { SourceDto3 } from '@OpenApi/typescript-axios';

export type SourceFormType = {
    isLoading: boolean;
    isError: boolean;
    isSubmitting: boolean;
    isSubmitted: boolean;
    initialData: SourceDto3;
    formData: SourceDto3;
    errors: SourceDto3;
};

export const sourceFormSlice = formSliceGeneric({
    name: 'source',
    initialState: defaultFormState,
    stateSelector: (state) => state.forms.publisher.sources.source,
});

export const { updateFormValue, resetForm, resetFormValues } = sourceFormSlice.actions;
export const { fetchForm, submitForm, selectState } = sourceFormSlice;

export default sourceFormSlice.reducer;
