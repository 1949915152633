import { theme } from 'antd';

import { Form, Card, Typography, Row, Input, Radio, QuestionTooltip } from '@Shared/ui';
import { IntegrationNumberEnum } from '@Shared/api/rtkQueryApis/sellerCampaigns';

const { Title, Text, Link } = Typography;

const CampaignMainDetailsCard = ({
    selectedIntegrationNumber,
    isFormDisabled,
}: {
    selectedIntegrationNumber: string;
    isFormDisabled: boolean;
}) => {
    const {
        token: { colorTextSecondary },
    } = theme.useToken();

    return (
        <Card>
            <Title
                type="secondary"
                style={{ fontSize: 18, color: colorTextSecondary, marginTop: 0 }}
                level={4}
            >
                Название кампании и комиссия
            </Title>
            <Row>
                <Form.Item
                    name="title"
                    validateTrigger="onBlur"
                    label="Название кампании"
                    rules={[{ required: true, message: 'Это поле обязательно к заполнению' }]}
                    style={{ marginTop: 20, width: '100%' }}
                >
                    <Input
                        placeholder="Укажите название кампании"
                        disabled={isFormDisabled}
                    />
                </Form.Item>
            </Row>
            <Row style={{ gap: 30 }}>
                <Form.Item
                    name={['barter_setting', 'integration_number']}
                    label="Кол-во интеграций под ключ"
                    rules={[{ required: true, message: '' }]}
                    style={{ marginTop: 20 }}
                >
                    <Radio.Group disabled={isFormDisabled}>
                        {Object.values(IntegrationNumberEnum).map((number) => (
                            <Radio
                                value={number}
                                key={number}
                            >
                                {number}
                            </Radio>
                        ))}
                    </Radio.Group>
                </Form.Item>
                <Form.Item
                    label={
                        <>
                            Комиссия &nbsp;
                            <QuestionTooltip
                                textAlign="left"
                                title="Комиссия рассчитвается автоматически,
                             в зависимости от количества интеграций под ключ. Изменить комиссию нельзя."
                            />
                        </>
                    }
                    validateTrigger="onBlur"
                    style={{ marginTop: 20 }}
                >
                    <Row
                        wrap={false}
                        style={{ gap: 16 }}
                        align="middle"
                    >
                        <Input
                            disabled
                            addonAfter="₽"
                            value={998 * +selectedIntegrationNumber}
                        />
                        <Text
                            type="secondary"
                            style={{ whiteSpace: 'nowrap' }}
                        >
                            998 ₽ / 1 интеграция
                        </Text>
                    </Row>
                </Form.Item>
            </Row>
            <Text type="secondary">
                Если вы хотите заказать другое количество интеграций, напишите в{' '}
                <Link href={`mailto:${process.env.SUPPORT_EMAIL}`}> поддержку</Link>.
            </Text>
        </Card>
    );
};
export default CampaignMainDetailsCard;
