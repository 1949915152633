import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useAppSelector, useAppDispatch, useUpdateSearchParams } from '@Shared/hooks';
import { Space } from '@Shared/ui';
import { useGetProductsListQuery } from '@Shared/api/rtkQueryApis/sellerProducts/SellerProductsApi';
import {
    FilterEnum,
    FilterObjectType,
    defaultFilterObject,
} from '@Features/seller/productPromotion/productsTab/model/types';
import { ProductsDrawer } from '@Features/seller/productPromotion/productsTab';
import { setRun, goToStep } from '@Entities/joyride/model/joyrideSlice';
import { SellerOnboardingStatuses } from '@Shared/model/joyrideTypes';

import ProductsTable from './productsTable/ProductsTable';
import ProductsFilters from './productsFilters/ProductsFilter';

const initialFilterObject = (searchParams: URLSearchParams): FilterObjectType => ({
    page: searchParams.get(FilterEnum.PAGE) || defaultFilterObject.page,
    limit: searchParams.get(FilterEnum.LIMIT) || defaultFilterObject.limit,
    stores: searchParams.getAll(FilterEnum.STORES) || [],
    marketplaces: searchParams.getAll(FilterEnum.MARKETPLACES),
});

const ProductsTableBlock = () => {
    const [searchParams] = useSearchParams();
    const dispatch = useAppDispatch();
    const updateSearchParams = useUpdateSearchParams();
    const [currentProductId, setProductId] = useState('');
    const [isDrawerOpen, setDrawerOpen] = useState(false);
    const { status: onboardingStepStatus, stepIndexOrder } = useAppSelector((state) => state.joyride);
    const [filterObject, setFilterObject] = useState<FilterObjectType>(initialFilterObject(searchParams));
    const { data, isFetching, isSuccess } = useGetProductsListQuery({
        page: filterObject.page,
        limit: filterObject.limit,
        marketplaces: filterObject?.marketplaces?.length ? filterObject.marketplaces : undefined,
        stores: filterObject?.stores?.length ? filterObject.stores : undefined,
    });

    useEffect(() => {
        const filteredFilterObject = { ...filterObject };
        Object.keys(filteredFilterObject).forEach((param) => {
            if (filteredFilterObject[param] === defaultFilterObject[param]) {
                delete filteredFilterObject[param];
            }
        });
        updateSearchParams(filteredFilterObject);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterObject]);

    // joyride onboarding step
    useEffect(() => {
        if (onboardingStepStatus === SellerOnboardingStatuses.CAMPAIGN_LAUNCH) {
            if (stepIndexOrder === 1) {
                dispatch(setRun(false));
                dispatch(goToStep({ status: SellerOnboardingStatuses.CAMPAIGN_LAUNCH, order: 2 }));
            }
            if (isSuccess) {
                dispatch(setRun(true));
            }
        }
    }, [dispatch, isSuccess, onboardingStepStatus, stepIndexOrder]);

    return (
        <>
            <Space
                direction="vertical"
                size={24}
                style={{ width: '100%' }}
            >
                <ProductsFilters
                    filterObject={filterObject}
                    setFilterObject={setFilterObject}
                />
                <ProductsTable
                    data={data}
                    isLoading={isFetching}
                    setFilterObject={setFilterObject}
                    setProductId={setProductId}
                    setDrawerOpen={setDrawerOpen}
                />
            </Space>
            <ProductsDrawer
                isDrawerOpen={isDrawerOpen}
                setDrawerOpen={setDrawerOpen}
                productId={currentProductId}
            />
        </>
    );
};
export default ProductsTableBlock;
