import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { notification } from 'antd';

import { Button } from '@Shared/ui';
import { usePatchAppPrivateSellerCampaignActivateMutation } from '@Shared/api/rtkQueryApis/sellerCampaigns';
import { useGetTheUserInformationQuery } from '@Shared/api/rtkQueryApis/user';
import { showNotification, NotificationType } from '@Entities/notifications/model/notificationSlice';

const LaunchCampaignButton = ({
    campaignId,
    btnText = 'Запустить',
    isGhost = true,
}: {
    btnText?: string;
    isGhost?: boolean;
    campaignId?: string | null;
}) => {
    const navigate = useNavigate();
    const [api, contextHolder] = notification.useNotification();
    const dispatch = useDispatch();
    const { data: user, isLoading: isUserLoading } = useGetTheUserInformationQuery();
    const [activateCampaign, { isLoading }] = usePatchAppPrivateSellerCampaignActivateMutation();

    const handleCampaignLaunch = (e) => {
        e.stopPropagation();
        const launchCampaign = async () => {
            try {
                await activateCampaign({ slug: campaignId!.toString() });
                api.success({
                    message: 'Кампания была успешно запущена',
                });
            } catch (err) {
                api.error({
                    message: 'В процессе запуска кампании что-то пошло не так',
                    description: 'Попробуйте позже',
                });
            }
        };
        const isSellerFilledLegalInfo = user?.seller?.legalInfo;
        if (isSellerFilledLegalInfo) {
            launchCampaign();
        } else {
            dispatch(
                showNotification({
                    componentProps: {
                        duration: 10,
                        type: NotificationType.Warning,
                        message: 'У вас не заполнены юридические данные',
                        description: 'Заполните данные и запустите кампанию',
                        btn: (
                            <Button
                                type="primary"
                                onClick={() => navigate('/legal-info')}
                            >
                                Заполнить
                            </Button>
                        ),
                    },
                }),
            );
        }
    };
    return (
        <Button
            type="primary"
            ghost={isGhost}
            style={{ marginRight: 8 }}
            onClick={handleCampaignLaunch}
            loading={isLoading || isUserLoading}
            id="joyride-launch-campaign"
        >
            {contextHolder}
            {btnText}
        </Button>
    );
};

export default LaunchCampaignButton;
