import { ReactNode } from 'react';

import { DeleteStoreModal, NewIntegrationModal } from '@Features/modals';

type ModalKeys = 'deleteStore' | 'newIntegration' | '';

type StoreModalProps = {
    isModalOpen: boolean;
    setModalOpen: (arg1: boolean) => void;
    modalType: ModalKeys;
    storeId: string;
    setDrawerOpen: (arg1: boolean) => void;
};

const StoreModal = ({ isModalOpen, setModalOpen, modalType, storeId, setDrawerOpen }: StoreModalProps) => {
    const renderModal: Record<ModalKeys, ReactNode> = {
        deleteStore: (
            <DeleteStoreModal
                open={isModalOpen}
                setOpen={setModalOpen}
                storeId={storeId}
                setDrawerOpen={setDrawerOpen}
            />
        ),
        newIntegration: (
            <NewIntegrationModal
                open={isModalOpen}
                setOpen={setModalOpen}
                storeId={storeId}
                setDrawerOpen={setDrawerOpen}
            />
        ),
        '': undefined,
    };

    return renderModal[modalType];
};

export default StoreModal;
