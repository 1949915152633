import { PublisherOnboardingStatuses } from '@Shared/model/joyrideTypes';

export const publisherJoyrideSteps = [
    {
        target: '#joyride-add-source-button',
        stepStatus: PublisherOnboardingStatuses.SOURCE_CREATION,
        content: 'Добавьте свою интернет-площадку, которую хотите монетизировать',
        disableBeacon: true,
        spotlightPadding: 10,
    },
    {
        target: '#joyride-source-status-cell',
        stepStatus: PublisherOnboardingStatuses.SOURCE_VERIFICATION,
        postStatusToServer: true,
        pauseAfterNext: true,
        content: 'В течение 2-48 часов мы проверим вашу площадку и обновим ее статус в этом разделе',
        disableBeacon: true,
        spotlightPadding: 10,
    },
    {
        target: '#joyride-user-menu-button',
        stepStatus: PublisherOnboardingStatuses.LEGAL_INFO,
        pauseAfterNext: true,
        content:
            // eslint-disable-next-line max-len
            'Осталось только заполнить юридические данные для автоматической маркировки рекламы. Сделать это можно в разделе “Юридические данные“',
        disableBeacon: true,
        spotlightPadding: 10,
    },
    {
        target: '#joyride-products-menu-item',
        stepStatus: PublisherOnboardingStatuses.GETTING_STARTED,
        pauseAfterNext: true,
        stepStatusOrder: 1,
        content: 'Перейдите в раздел «Каталог продуктов», чтобы выбрать товары для продвижения',
        disableBeacon: true,
        spotlightPadding: 10,
    },
    {
        target: '#joyride-product-filters',
        stepStatus: PublisherOnboardingStatuses.GETTING_STARTED,
        stepStatusOrder: 2,
        content: 'Для удобства поиска подходящих товаров воспользуйтесь фильтром',
        disableBeacon: true,
        spotlightPadding: 10,
    },
    {
        target: '#joyride-products-favorite-cell',
        stepStatus: PublisherOnboardingStatuses.GETTING_STARTED,
        stepStatusOrder: 3,
        content: 'Добавьте в избранное товары, которые хотите продвигать. Это упростит доступ к ним в дальнейшем',
        disableBeacon: true,
        spotlightPadding: 10,
    },
    {
        target: '#joyride-products-external-link',
        stepStatus: PublisherOnboardingStatuses.GETTING_STARTED,
        stepStatusOrder: 4,
        postStatusToServer: true,
        content:
            // eslint-disable-next-line max-len
            'Скопируйте партнерскую ссылку и разместите ее на своей площадке. Вы получите комиссию за подтвержденные заказы, созданные по этой ссылке',
        disableBeacon: true,
        spotlightPadding: 10,
    },
];
