import { combineReducers } from '@reduxjs/toolkit';

import { storesReducer } from '@Entities/forms/seller/stores/storesReducer';
import onboardingReducer from '@Entities/forms/seller/onboarding/onboardingSlice';

export const sellerReducer = combineReducers({
    onboarding: onboardingReducer,
    stores: storesReducer,
});

export type formsState = ReturnType<typeof sellerReducer>;
