import { theme } from 'antd';

import { formatDate } from '@Shared/utils';
import { Typography } from '@Shared/ui';
import { PromotionDto2 } from '@Shared/api/rtkQueryApis/sellerPromotions';

const { Text } = Typography;

const PeriodCell = ({ promotion }: { promotion: PromotionDto2 }) => {
    const {
        token: { colorTextTertiary, colorText },
    } = theme.useToken();

    const startDate = formatDate(promotion.start_date);
    const endDate = formatDate(promotion.end_date);
    const isActive = promotion.active;
    return (
        <Text style={{ color: isActive ? colorText : colorTextTertiary }}>
            {startDate} - {endDate}
        </Text>
    );
};

export default PeriodCell;
