import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InboxOutlined } from '@ant-design/icons';

import {
    selectState,
    submitForm,
    resetForm,
    AddStoreFormType,
} from '@Entities/forms/seller/stores/model/addStoreFormSlice';
import { fetchData } from '@Entities/keyValue/seller/stores/model/storesSlice';
import { Button, Input, Typography, Form, message, Upload } from '@Shared/ui';
import { FormKeysEnum, KeyValueKeysEnum } from '@Shared/model/seller/types';
import { ModalForm } from '@Features/shared';
import { uploadImageRequest } from '@Shared/utils/uploadImage';
import { sendYmEvent } from '@Shared/utils/analytics/sendYmEvent';
import { useGetTheUserInformationQuery } from '@Shared/api/rtkQueryApis/user';
import { StoreDto3 } from '@Shared/api/rtkQueryApis/sellerStores/SellerStoresApi';

import type { UploadProps } from 'antd';

import './styles.scss';

const { Dragger } = Upload;
const { Text, Title } = Typography;

type AddStoreModalProps = {
    open: boolean;
    setOpen: (arg1: boolean) => void;
};

const AddStoreModal = ({ open, setOpen }: AddStoreModalProps) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const { formData, isSubmitting, isSubmitted, errors }: AddStoreFormType = useSelector(selectState);

    const handleSubmit = (formValues: typeof formData) => {
        const modifiedData = { ...formValues, comment: 'comment' };
        dispatch(
            submitForm({
                key: FormKeysEnum.sellerAddStoreForm,
                withNotification: true,
                notificationOptions: {
                    successMessage: 'Магазин успешно добавлен',
                    errorMessage: 'Ошибка при добавлении магазина',
                },
                formData: modifiedData,
            }),
        );
    };

    const { data: user } = useGetTheUserInformationQuery();

    useEffect(() => {
        if (isSubmitted) {
            setOpen(false);
            dispatch(resetForm());
            form.resetFields();
            dispatch(fetchData({ key: KeyValueKeysEnum.getStoresList }));

            const data = formData as StoreDto3;
            if (data.id) {
                sendYmEvent({
                    name: 'send_add_store_success',
                    o1: 'account;advertiser;store',
                    o2: 'store',
                    advertiserId: user?.seller?.id,
                    keycloakId: user?.id,
                    advcampaignId: data.id,
                });
            }
        }
    }, [dispatch, form, formData, isSubmitted, setOpen, user]);

    const draggerProps: UploadProps = {
        name: 'file',
        maxCount: 1,
        accept: 'image/png, image/svg+xml, image/jpeg',
        customRequest: uploadImageRequest,
        onChange(info) {
            const { status } = info.file;
            if (status === 'done') {
                form.setFieldValue('logo', info.file.response);
            } else if (status === 'error') {
                message.error(`Ошибка загрузки файла ${info.file.name}.`);
            }
        },
    };

    const handleCloseModal = () => {
        form.resetFields();
        setOpen(false);
    };

    return (
        <ModalForm
            formKey={FormKeysEnum.sellerAddStoreForm}
            setOpen={setOpen}
            modalProps={{
                forceRender: true,
                open,
                title: (
                    <Title
                        level={4}
                        style={{ marginTop: 0 }}
                    >
                        Добавить магазин
                    </Title>
                ),
                footer: null,
            }}
            formProps={{ form: form }}
            handleSubmit={handleSubmit}
            handleClose={handleCloseModal}
        >
            <Text
                type="secondary"
                style={{ marginBottom: 8 }}
            >
                Чтобы добавить новый магазин в кабинет, нам нужно немного узнать о нем. Заполните поля ниже.
            </Text>
            <Form.Item
                // hasFeedback
                label="Название магазина"
                name="title"
                validateTrigger="onBlur"
                rules={[{ required: true, message: 'Это поле обязательно к заполнению' }]}
                style={{ marginTop: 20 }}
                validateStatus={errors?.title ? 'error' : ''}
                help={errors?.title || null}
                extra="Это название будет отображаться в каталоге"
            >
                <Input placeholder="Укажите название" />
            </Form.Item>
            <Form.Item
                hasFeedback
                label="Логотип"
                name="logo"
                validateStatus={errors?.logo ? 'error' : ''}
                help={errors?.logo || null}
                extra="Используйте файлы формата – png, jpg, svg. Не более 5 Мб."
            >
                <Dragger {...draggerProps}>
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Щелкните или перетащите файл в эту область для загрузки</p>
                    <p className="ant-upload-hint">Поддержка одиночной или массовой загрузки.</p>
                </Dragger>
            </Form.Item>
            <Form.Item className="action-buttons-block">
                <Button onClick={() => setOpen(false)}>Отменить</Button>
                <Button
                    type="primary"
                    htmlType="submit"
                    loading={isSubmitting}
                >
                    Добавить
                </Button>
            </Form.Item>
        </ModalForm>
    );
};

export default AddStoreModal;
