import { Layout, Space, Alert, theme } from 'antd';

import './styles.scss';

const { Content } = Layout;

const ProductsPage = ({ status = 'Undefined' }: { status?: string | number }) => {
    const {
        token: { colorBgContainer },
    } = theme.useToken();

    return (
        <Content
            className="page-content"
            style={{
                background: colorBgContainer,
            }}
        >
            <Space
                direction="vertical"
                style={{ width: '100%' }}
            >
                <Alert
                    message={`Error ${status}`}
                    description="Page not found"
                    type="error"
                    showIcon
                />
            </Space>
        </Content>
    );
};
export default ProductsPage;
