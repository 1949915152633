import { theme } from 'antd';
import Icon from '@ant-design/icons';

import { Typography, Space, Avatar } from '@Shared/ui';
import { PromotionDto3, PromotionTypeEnum2 } from '@Shared/api/rtkQueryApis/sellerPromotions';
import { CouponSaleDiscount, ShoppingBagDiscount } from '@Shared/assets/images/icons';

const { Text } = Typography;

const PromotionNameCell = ({ promotion }: { promotion: PromotionDto3 }) => {
    const {
        token: { colorPrimaryBg, colorPrimary, colorSuccessBg, colorSuccess, colorBgLayout, colorIcon },
    } = theme.useToken();

    const promotionTypeAvatarStyle = {
        [PromotionTypeEnum2.Coupon]: {
            bgColor: colorPrimaryBg,
            icon: (
                <Icon
                    component={() => (
                        <CouponSaleDiscount style={{ color: promotion.active ? colorPrimary : colorIcon }} />
                    )}
                />
            ),
        },
        [PromotionTypeEnum2.Sale]: {
            bgColor: colorSuccessBg,
            icon: (
                <Icon
                    component={() => (
                        <ShoppingBagDiscount style={{ color: promotion.active ? colorSuccess : colorIcon }} />
                    )}
                />
            ),
        },
    };

    return (
        <Space size={16}>
            <Avatar
                size={40}
                style={{
                    backgroundColor: promotion.active
                        ? promotionTypeAvatarStyle[promotion.type].bgColor
                        : colorBgLayout,
                }}
                icon={promotionTypeAvatarStyle[promotion.type].icon}
            />
            <Text>{promotion?.title || ''}</Text>
        </Space>
    );
};

export default PromotionNameCell;
