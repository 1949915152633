import { ReactElement } from 'react';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

import { MarketplaceStoreStatusEnum } from '@OpenApi/typescript-axios';
import { Tag } from '@Shared/ui';

export const marketplaceStoreStatuses: { [key in MarketplaceStoreStatusEnum]: ReactElement } = {
    [MarketplaceStoreStatusEnum.Connected]: (
        <Tag
            icon={<CheckCircleOutlined />}
            color="success"
            style={{ borderRadius: 8 }}
        >
            Активен
        </Tag>
    ),
    [MarketplaceStoreStatusEnum.Disconnected]: (
        <Tag
            icon={<CloseCircleOutlined />}
            color="error"
            style={{ borderRadius: 8 }}
        >
            Не активен
        </Tag>
    ),
};
