import { Dispatch, SetStateAction } from 'react';

import { renderEmpty } from '@Features/shared';
import { Table, Typography } from '@Shared/ui';
import {
    TransactionDto,
    CommissionDto,
    TransactionTypeEnum,
    GetPublisherTransactionsListApiResponse,
} from '@Shared/api/rtkQueryApis/publisherTransactions/PublisherTransactionsApi';
import { formatDateTime } from '@Shared/utils';
import { FilterObjectType } from '@Features/seller/finances/model/types';
import { transactionsTypeMap } from '@Features/publisher/finances/model/types';

import { TransactionAmountCell, CommissionIdCell, CommentCell } from './cells';

const { Text } = Typography;

type TransactionsTableProps = {
    data: GetPublisherTransactionsListApiResponse | undefined;
    isTransactionsLoading: boolean;
    setFilterObject: Dispatch<SetStateAction<FilterObjectType>>;
};

const columns = [
    {
        title: 'Дата',
        dataIndex: 'created_at',
        key: 'created_at',
        ellipsis: true,
        render: (date: string) => <>{formatDateTime(date)}</>,
    },
    {
        title: 'Тип транзакции',
        dataIndex: 'transaction_type',
        key: 'transaction_type',
        render: (transaction_type: TransactionTypeEnum) => <>{transactionsTypeMap[transaction_type]}</>,
    },
    {
        title: 'ID комиссии',
        dataIndex: 'commission',
        key: 'commission',
        render: (commission: CommissionDto) =>
            commission?.id ? <CommissionIdCell commissionId={commission.id} /> : null,
    },
    {
        title: 'Комментарий',
        dataIndex: 'commission',
        key: 'comment',
        render: (commission: CommissionDto) => (commission?.id ? <CommentCell commission={commission} /> : null),
    },
    {
        title: 'Сумма',
        dataIndex: 'transaction_amount',
        key: 'transaction_amount',
        render: (_: unknown, transaction: TransactionDto) => <TransactionAmountCell transaction={transaction} />,
    },
];

const TransactionsTable = ({ data, isTransactionsLoading, setFilterObject }: TransactionsTableProps) => {
    return (
        <Table
            dataSource={data?.items}
            loading={isTransactionsLoading}
            columns={columns}
            rowKey="id"
            scroll={{ x: '1000px' }}
            pagination={{
                showSizeChanger: true,
                hideOnSinglePage: true,
                pageSizeOptions: ['10', '25', '50'],
                pageSize: data?.limit,
                position: ['bottomLeft'],
                current: data?.page,
                total: data?.totalCount,
                onChange: (page, pageSize) =>
                    setFilterObject((prevFilterObject) => {
                        const hasPageSizeChanged = pageSize !== +prevFilterObject.limit;
                        return {
                            ...prevFilterObject,
                            page: hasPageSizeChanged ? 1 : page,
                            limit: pageSize,
                        };
                    }),
            }}
            locale={{
                emptyText: renderEmpty({
                    content: <Text>По выбранным параметрам не найдено ни одной транзакции</Text>,
                }),
            }}
        />
    );
};

export default TransactionsTable;
