import { RawAxiosRequestConfig } from 'axios';

import { PublisherSourcesApi, SourceDto2 } from '@OpenApi/typescript-axios/index';
import { FormKeysEnum } from '@Shared/model/publisher/types';

export const publisherSourcesApi = new PublisherSourcesApi();

export const FormsApiInstances = {
    [FormKeysEnum.addSourceForm]: {
        submit: (formData: SourceDto2, options: RawAxiosRequestConfig) =>
            publisherSourcesApi.addASource(formData, options),
    },
    [FormKeysEnum.updateSourceForm]: {
        fetch: (options: RawAxiosRequestConfig, params: { sourceId: string }) =>
            publisherSourcesApi.viewASource(params.sourceId, options),
        submit: (formData: SourceDto2, options: RawAxiosRequestConfig, params: { sourceId: string }) =>
            publisherSourcesApi.putAppPrivatePublisherSourceUpdate(params.sourceId, formData, options),
    },
};
