import { useMemo, Dispatch, SetStateAction } from 'react';

import { renderEmpty } from '@Features/shared';
import { Table, Typography, Grid, QuestionTooltip } from '@Shared/ui';
import { type ProductCampaignDto, GetProductsListWithLinksBySourceApiResponse } from '@Shared/api/full';
import { PriceCell, ProductNameCell } from '@Features/shared/TableCells/products';
import { FilterObjectType } from '@Features/publisher/products/model/types';

import { FavoriteCell, TrackingLinksCell } from './cells';

import './styles.scss';

const { Text } = Typography;
const { useBreakpoint } = Grid;

type ProductCatalogTableProps = {
    data: GetProductsListWithLinksBySourceApiResponse | undefined;
    isProductsLoading: boolean;
    setFilterObject: Dispatch<SetStateAction<FilterObjectType>>;
    setCurrentProductId: (arg1: string) => void;
    setDrawerOpen: (arg1: boolean) => void;
};

const ProductCatalogTable = ({
    data,
    isProductsLoading,
    setFilterObject,
    setCurrentProductId,
    setDrawerOpen,
}: ProductCatalogTableProps) => {
    const breakpoints = useBreakpoint();

    const columns = useMemo(
        () => [
            {
                width: 48,
                render: (_: unknown, product: ProductCampaignDto) => (
                    <FavoriteCell
                        isFavorite={product?.favorite || false}
                        productId={product.id}
                        storeId={
                            product?.product?.marketplace_store?.store?.id ||
                            product.product?.marketplace_store?.marketplace?.id
                        }
                    />
                ),
            },
            {
                title: 'Название товара',
                dataIndex: 'title',
                key: 'title',
                width: 260,
                ellipsis: true,
                render: (_: unknown, { product }: ProductCampaignDto) => <ProductNameCell product={product} />,
            },
            {
                title: (
                    <>
                        Стоимость &nbsp;
                        <QuestionTooltip
                            title="Цена товара на маркетплейсе может отличаться. 
                        Вы получите комиссию от той цены, по которой товар будет куплен."
                        />
                    </>
                ),
                dataIndex: 'price',
                key: 'price',
                render: (_: unknown, { product }: ProductCampaignDto) => <PriceCell product={product} />,
            },
            {
                title: 'Комиссия',
                dataIndex: 'price',
                key: 'price',
                render: (_: unknown, productCampaign: ProductCampaignDto) => (
                    <>{(productCampaign?.fee_setting?.fee_value || 0) * 100}%</>
                ),
            },
            {
                title: 'Категория',
                dataIndex: 'category',
                key: 'category',
                render: (_, { product }: ProductCampaignDto) => <>{product?.product_category?.title || ''}</>,
            },
            {
                title: 'Магазин',
                key: 'store',
                render: (_: unknown, { product }: ProductCampaignDto) => product?.marketplace_store?.store?.title,
            },
            {
                title: 'Маркетплейс',
                key: 'marketplace',
                render: (_: unknown, { product }: ProductCampaignDto) => product?.marketplace_store?.marketplace?.title,
            },
            {
                title: 'Партнерская ссылка',
                dataIndex: 'tracking_link',
                key: 'tracking_link',
                width: breakpoints.sm ? 265 : 150,
                fixed: 'right',
                render: (_: unknown, productCampaign: ProductCampaignDto) => (
                    <TrackingLinksCell productCampaign={productCampaign} />
                ),
            },
        ],
        [breakpoints],
    );

    return (
        <Table
            dataSource={data?.items?.map((item) => ({ ...item, key: item.id }))}
            loading={isProductsLoading}
            className="product-campaign-table"
            columns={columns}
            rowKey="id"
            scroll={{ x: '1300px' }}
            pagination={{
                showSizeChanger: true,
                hideOnSinglePage: true,
                pageSizeOptions: ['10', '25', '50'],
                pageSize: data?.limit,
                position: ['bottomLeft'],
                current: data?.page,
                total: data?.totalCount,
                onChange: (page, pageSize) =>
                    setFilterObject((prevFilterObject) => {
                        const hasPageSizeChanged = pageSize !== +prevFilterObject.limit;
                        return {
                            ...prevFilterObject,
                            page: hasPageSizeChanged ? 1 : page,
                            limit: pageSize,
                        };
                    }),
            }}
            rowClassName={(record) => (record.favorite ? 'product-campaign-table__favorite' : '')}
            onRow={(record) => {
                return {
                    onClick: () => {
                        setCurrentProductId(record.id);
                        setDrawerOpen(true);
                    },
                };
            }}
            locale={{
                emptyText: renderEmpty({
                    content: <Text>У вас пока нет доступных продуктов для продвижения</Text>,
                }),
            }}
        />
    );
};

export default ProductCatalogTable;
