import { theme } from 'antd';

import { Badge, Typography } from '@Shared/ui';

const { Text } = Typography;

const StatusCell = ({ isActive }: { isActive: boolean }) => {
    const {
        token: { colorTextTertiary, colorText },
    } = theme.useToken();

    return (
        <Badge
            status={isActive ? 'success' : 'default'}
            text={
                <Text style={{ color: isActive ? colorText : colorTextTertiary }}>
                    {isActive ? 'Доступна' : 'Завершена'}
                </Text>
            }
        />
    );
};

export default StatusCell;
