import { theme } from 'antd';
import { useNavigate } from 'react-router-dom';
import { InfoCircleFilled } from '@ant-design/icons';

import { SellersPromotionsTableBlock } from '@Features/publisher/promotions';
import { Space, Typography, Row, Alert, Button } from '@Shared/ui';
import { useGetTheUserInformationQuery } from '@Shared/api/rtkQueryApis/user/UserApi';

const { Text } = Typography;

const SellersPromotionsTab = () => {
    const navigate = useNavigate();
    const { data: user } = useGetTheUserInformationQuery();
    const {
        token: { fontSizeLG },
    } = theme.useToken();
    return (
        <Space
            direction="vertical"
            style={{ width: '100%' }}
            size={24}
        >
            {!user?.publisher?.legalInfo && (
                <Row>
                    <Alert
                        style={{ alignItems: 'center', width: '100%' }}
                        // eslint-disable-next-line max-len
                        description="Чтобы продолжить работу, укажите юридические данные"
                        type="info"
                        showIcon
                        icon={<InfoCircleFilled style={{ color: 'black', opacity: '0.45' }} />}
                        banner
                        action={
                            <Button
                                type="primary"
                                onClick={() => navigate('/legal-info')}
                            >
                                Указать данные
                            </Button>
                        }
                    />
                </Row>
            )}
            <Text
                type="secondary"
                style={{ fontSize: fontSizeLG }}
            >
                В данном разделе вы можете найти промокоды и специальные акции от продавцов. Перейдите в детали акции,
                чтоб больше узнать о ней
            </Text>
            <SellersPromotionsTableBlock />
        </Space>
    );
};
export default SellersPromotionsTab;
