import { theme } from 'antd';

import { Card, Typography, Skeleton, Grid } from '@Shared/ui';
import { useGetTheUserInformationQuery } from '@Shared/api/rtkQueryApis/user';

const { Title, Text } = Typography;
const { useBreakpoint } = Grid;

const BalanceCard = () => {
    const {
        token: { colorPrimary },
    } = theme.useToken();
    const breakpoints = useBreakpoint();
    const { data: user, isLoading } = useGetTheUserInformationQuery();

    return (
        <Card style={{ width: breakpoints.lg ? 300 : '100%' }}>
            <Title
                level={4}
                style={{ marginTop: 0 }}
            >
                Баланс
            </Title>
            {isLoading ? (
                <Skeleton.Input active />
            ) : (
                <Title
                    level={3}
                    style={{ marginTop: 0, color: colorPrimary }}
                >
                    {user?.balance || 0} ₽
                </Title>
            )}

            <Text type="secondary">Средства, доступные для вывода</Text>
        </Card>
    );
};
export default BalanceCard;
