import { useState } from 'react';
import { Table } from 'antd';
import { MessageOutlined } from '@ant-design/icons';

import { type ThreadDto3 } from '@Shared/api/full';
import { renderEmpty } from '@Features/shared';

import MessageDrawer from './MessageDrawer';

const { Column } = Table;

type MessagesTableProps = {
    items: ThreadDto3[];
    isLoading?: boolean;
};

const MessagesTable = ({ items = [], isLoading }: MessagesTableProps) => {
    const [openData, setOpen] = useState<object | undefined>();

    return (
        <>
            <Table
                loading={isLoading}
                dataSource={items}
                pagination={{ pageSize: 4, position: ['bottomLeft'] }}
                onRow={(record) => ({
                    onClick: () => {
                        setOpen(record);
                    },
                })}
                locale={{
                    emptyText: renderEmpty({
                        icon: MessageOutlined,
                        content: 'Вы пока не отправили ни одного сообщения',
                    }),
                }}
            >
                <Column
                    title="Веб-мастер"
                    dataIndex="webmaster"
                    key="webmaster"
                />
                <Column
                    title="Тема сообщения"
                    dataIndex="subject"
                    key="subject"
                />
                <Column
                    title="Дата"
                    dataIndex="date"
                    key="date"
                />
            </Table>
            <MessageDrawer
                openData={openData}
                setOpen={setOpen}
            />
        </>
    );
};

export default MessagesTable;
