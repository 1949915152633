import { Typography, Space } from '@Shared/ui';
import { MPAvatar } from '@Features/shared';
import { MarketplaceStore3 } from '@Shared/api/full';

const { Text } = Typography;

const MPTitleCell = ({
    marketplaceStore,
    isActive = true,
}: {
    marketplaceStore: MarketplaceStore3 | null | undefined;
    isActive?: boolean;
}) => {
    const slug = marketplaceStore?.marketplace?.slug;
    return (
        <Space>
            <MPAvatar
                slug={slug}
                isActive={isActive}
            />
            <Text>{marketplaceStore?.store?.title}</Text>
        </Space>
    );
};

export default MPTitleCell;
