export enum FilterEnum {
    CREATED_AT_FROM = 'createdAtFrom',
    CREATED_AT_TO = 'createdAtTo',
    LIMIT = 'limit',
    PAGE = 'page',
}

export type FilterObjectType = {
    createdAtFrom: string;
    createdAtTo: string;
    page: number;
    limit: number;
};

export const DEFAULT_PAGE_SIZE = 50;
export const DEFAULT_PAGE = 1;
