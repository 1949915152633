import { useEffect } from 'react';

import { selectModals } from '@Entities/keyValue/selectors';
import { useAppDispatch, useAppSelector } from '@Shared/hooks';
import { Form, Row, Button, Grid, Select, Alert } from '@Shared/ui';
import { showMessage, MessageType } from '@Entities/messages/model/messagesSlice';
import {
    usePatchAppPrivateSellerCommissionDeclineMutation,
    DeclineReasonDto,
    CommissionDeclineReasonEnum,
} from '@Shared/api/rtkQueryApis/sellerCommissions';
import { normalizeErrorsObject } from '@Shared/utils/normalizeErrorsObject';
import { reasonTextMap } from '@Features/seller/finances/manualVerification/model/types';

const { useBreakpoint } = Grid;

const DeclineCommissionForm = ({ handleModalClose }: { handleModalClose: () => void }) => {
    const breakpoints = useBreakpoint();
    const [form] = Form.useForm();
    const [declineCommission, { isLoading, error }] = usePatchAppPrivateSellerCommissionDeclineMutation();
    const dispatch = useAppDispatch();

    const modalState = useAppSelector(selectModals);

    const handleCancel = () => {
        form.resetFields();
        handleModalClose();
    };

    const handleDeclineCommission = async (formValues: DeclineReasonDto) => {
        const commissionId = modalState.params.commissionId;
        if (commissionId) {
            const res = await declineCommission({ id: commissionId, declineReasonDto: formValues });
            if (res.data) {
                dispatch(
                    showMessage({
                        componentProps: {
                            type: MessageType.Success,
                            content: 'Комиссия успешно отклонена',
                        },
                    }),
                );
                handleCancel();
            }
        }
    };

    const reasonOptions = Object.values(CommissionDeclineReasonEnum || {}).map((reasonKey) => ({
        value: reasonKey,
        label: reasonTextMap[reasonKey],
    }));

    useEffect(() => {
        const errors = normalizeErrorsObject(error?.data?.errors);
        const formErrors = Object.keys(errors).map((field) => ({ name: field, errors: [errors[field]] }));
        form.setFields(formErrors);
    }, [error, form]);

    return (
        <Form
            form={form}
            name="decline-commission"
            onFinish={handleDeclineCommission}
            autoComplete="off"
        >
            <Form.Item
                labelCol={{ span: 24 }}
                label="Выберите причину отклонения комиссии"
                name="decline_reason"
                validateTrigger={['onBlur', 'onChange']}
                rules={[{ required: true, message: 'Это поле обязательно к заполнению' }]}
                style={{ marginTop: 20 }}
            >
                <Select
                    style={{ width: '100%' }}
                    options={reasonOptions}
                    placeholder="Причина отклонения"
                />
            </Form.Item>
            <Alert
                message="За ложные отклонения взимается штраф 1000 ₽"
                type="warning"
                banner
                style={{ padding: 20, marginBottom: 24 }}
            />
            <Row
                style={{
                    justifyContent: 'space-between',
                    flexDirection: breakpoints.sm ? 'row' : 'column-reverse',
                    gap: 10,
                }}
            >
                <Button
                    onClick={handleCancel}
                    style={{ flex: breakpoints.sm ? 'unset' : 1 }}
                >
                    Отменить
                </Button>
                <Button
                    type="primary"
                    htmlType="submit"
                    style={{ flex: breakpoints.sm ? 'unset' : 1 }}
                    loading={isLoading}
                >
                    Отклонить
                </Button>
            </Row>
        </Form>
    );
};
export default DeclineCommissionForm;
