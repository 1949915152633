import { useEffect, useCallback, useRef } from 'react';
import { useBeforeUnload, useBlocker } from 'react-router-dom';

function usePrompt(onLocationChange, hasUnsavedChanges) {
    const blocker = useBlocker(hasUnsavedChanges ? onLocationChange : false);
    const prevState = useRef(blocker.state);

    useEffect(() => {
        if (blocker.state === 'blocked') {
            blocker.reset();
        }
        prevState.current = blocker.state;
    }, [blocker]);
}

export const ConfirmLeavePrompt = ({
    hasUnsavedChanges,
    allowsPaths = [],
}: {
    hasUnsavedChanges: boolean;
    allowsPaths?: string[];
}) => {
    const onLocationChange = useCallback(
        ({ nextLocation }) => {
            if (!allowsPaths.includes(nextLocation.pathname) && hasUnsavedChanges) {
                return !window.confirm('У вас есть несохраненные данные. Вы уверены, что хотите покинуть страницу?');
            }
            return false;
        },
        [allowsPaths, hasUnsavedChanges],
    );

    usePrompt(onLocationChange, hasUnsavedChanges);
    useBeforeUnload(
        useCallback(
            (event) => {
                if (hasUnsavedChanges) {
                    event.preventDefault();
                    event.returnValue = '';
                }
            },
            [hasUnsavedChanges],
        ),
        { capture: true },
    );

    return null;
};
