import { useEffect } from 'react';
import { theme } from 'antd';

import { Select, Row, Typography } from '@Shared/ui';
import { useGetSourcesListQuery } from '@Shared/api/rtkQueryApis/publisherSources';

const { Title } = Typography;

type SourceSelectBlockProps = {
    selectedSourceId: string;
    setSourceId: (arg1: string) => void;
};

const SourceSelectBlock = ({ setSourceId, selectedSourceId }: SourceSelectBlockProps) => {
    const { data: sources, isLoading: isSourcesLoading } = useGetSourcesListQuery();
    const {
        token: { colorBgContainer, padding, borderRadiusLG },
    } = theme.useToken();

    const sourcesOptions = sources?.items?.map((source) => ({ value: source.id, label: source.title }));

    useEffect(() => {
        if (sources?.items?.length) {
            setSourceId(sources?.items[0]?.id || '');
        }
    }, [sources, setSourceId]);

    return (
        <Row
            style={{
                background: colorBgContainer,
                padding: padding,
                borderRadius: borderRadiusLG,
                gap: 8,
            }}
            align="middle"
            wrap={false}
        >
            <Title
                level={5}
                style={{ margin: 0, whiteSpace: 'nowrap' }}
            >
                Ваша площадка
            </Title>
            <Select
                value={selectedSourceId}
                style={{ width: '100%', overflow: 'hidden' }}
                loading={isSourcesLoading}
                options={sourcesOptions}
                onChange={setSourceId}
            />
        </Row>
    );
};

export default SourceSelectBlock;
