import { Dispatch, SetStateAction } from 'react';

import { Drawer } from '@Shared/ui';
import { useGetPersonalPromotionsDetailsWithLinksBySourceQuery } from '@Shared/api/rtkQueryApis/publisherPromotions';

import { SellersPromotionDetails } from './SellersPromotionDetails';

type ProductDrawerProps = {
    isDrawerOpen: boolean;
    setDrawerOpen: Dispatch<SetStateAction<boolean>>;
    promotionId: string;
    selectedSourceId: string;
};

const SellersPromotionDetailsDrawer = ({
    isDrawerOpen,
    setDrawerOpen,
    promotionId,
    selectedSourceId,
}: ProductDrawerProps) => {
    const { isFetching, data: promotionProduct } = useGetPersonalPromotionsDetailsWithLinksBySourceQuery(
        { promotion: promotionId, source: selectedSourceId },
        { skip: !promotionId || !selectedSourceId },
    );

    return (
        <Drawer
            open={isDrawerOpen}
            loading={isFetching}
            onClose={() => setDrawerOpen(false)}
            title="Информация"
        >
            {!!promotionProduct && (
                <SellersPromotionDetails
                    promotion={promotionProduct}
                    sourceId={selectedSourceId}
                />
            )}
        </Drawer>
    );
};

export default SellersPromotionDetailsDrawer;
