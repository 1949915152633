import { closeModal } from '@Entities/modals/model/modalsSlice';
import { selectModals } from '@Entities/keyValue/selectors';
import { useAppDispatch, useAppSelector } from '@Shared/hooks';
import { Modal, Typography, Row, Button, Grid } from '@Shared/ui';
import { ModalKeysType } from '@Shared/model/modalTypes';
import {
    useViewThePromotionQuery,
    usePatchAppPrivateSellerPromotionSetpromotionvisibilityMutation,
} from '@Shared/api/rtkQueryApis/sellerPromotions';

const { Title, Text } = Typography;
const { useBreakpoint } = Grid;

const DeclinePromotionParticipationModal = () => {
    const dispatch = useAppDispatch();
    const breakpoints = useBreakpoint();
    const modalState = useAppSelector(selectModals);
    const promotionId = modalState?.params?.promotionId;
    const { data: promotion, isLoading } = useViewThePromotionQuery({ id: promotionId }, { skip: !promotionId });
    const [setPromotionVisibility, { isLoading: isVisibilityLoading }] =
        usePatchAppPrivateSellerPromotionSetpromotionvisibilityMutation();
    const isOpen = modalState.modalKey === ModalKeysType.declinePromotionParticipation && modalState.open;

    const handleCancel = () => {
        dispatch(closeModal());
    };

    const handleDecline = async () => {
        if (promotionId) {
            try {
                await setPromotionVisibility({
                    setPromotionVisibilityDto: { promotions: [promotionId] },
                    notificationOptions: {
                        successMessage: `Заявка на остановку участия в ${promotion?.title}`,
                        successDescription:
                            'Мы пришлем вам уведомление об отключении, когда заявку проверит наш менеджер.',
                    },
                });
                handleCancel();
            } catch (err) {
                console.error(err);
            }
        }
    };

    return (
        <Modal
            title={
                promotion?.title ? (
                    <Title
                        level={4}
                        style={{ marginTop: 0 }}
                    >
                        {`Остановить участия в "${promotion?.title}"`}
                    </Title>
                ) : null
            }
            open={isOpen}
            loading={isLoading}
            onCancel={handleCancel}
            footer={
                <Row
                    justify="space-between"
                    style={{ flexDirection: breakpoints.sm ? 'row' : 'column', gap: 8 }}
                >
                    <Button
                        onClick={handleCancel}
                        style={{ width: breakpoints.sm ? 'auto' : '100%' }}
                    >
                        Отменить
                    </Button>
                    <Button
                        type="primary"
                        loading={isVisibilityLoading}
                        onClick={handleDecline}
                        style={{ width: breakpoints.sm ? 'auto' : '100%' }}
                    >
                        Отправить заявку
                    </Button>
                </Row>
            }
        >
            <Text>
                После остановки участия в акции действующие паблишеры получат об этом уведомление, а новые паблишеры не
                смогут к ней подключиться. Когда менеджер проверит информацию, он остановит ваше учасие в акции, и мы
                отправим вам уведомление об этом.
            </Text>
        </Modal>
    );
};
export default DeclinePromotionParticipationModal;
