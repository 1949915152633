import { useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { FileOutlined } from '@ant-design/icons';

import { SelectedMPStore } from '@Features/seller/productPromotion/campaignsTab/setupCampaign/barter/model/types';
import { useGetProductsListQuery } from '@Shared/api/rtkQueryApis/sellerProducts/SellerProductsApi';
import { Select, Spin, Avatar, Space, Typography } from '@Shared/ui';

const { Text } = Typography;

type SearchProductDropdownProps = {
    selectedMPStore: SelectedMPStore | null;
    value?: number;
    onChange?: (arg1: number) => void;
    isFormDisabled: boolean;
};

const SearchProductDropdown = ({ selectedMPStore, value, onChange, isFormDisabled }: SearchProductDropdownProps) => {
    const [searchValue, setSearchValue] = useState('');
    const { data: products, isFetching } = useGetProductsListQuery(
        {
            page: 1,
            limit: 50,
            marketplaces: [selectedMPStore?.marketplaceSlug],
            stores: [selectedMPStore?.storeId],
            searchString: searchValue,
        },
        { skip: !selectedMPStore?.marketplaceSlug || !selectedMPStore.storeId },
    );

    const handleSearch = useDebouncedCallback((value) => {
        setSearchValue(value);
    }, 500);

    const options = products?.items?.map((product) => ({
        label: `${product?.title || ''}, ${product.sku || ''}`,
        value: product.id,
        imageUrl: product.image_url,
        sku: product.sku,
    }));

    return (
        <Select
            value={value}
            onChange={onChange}
            showSearch
            disabled={isFormDisabled}
            placeholder="Начните вводить название/артикул для поиска нужных товаров"
            onSearch={handleSearch}
            loading={isFetching}
            allowClear
            notFoundContent={isFetching ? <Spin size="small" /> : 'Не найдено'}
            filterOption={false}
            options={isFetching ? [] : options}
            optionRender={(option) => (
                <Space>
                    <Avatar
                        shape="square"
                        icon={option?.data.imageUrl ? null : <FileOutlined />}
                        src={option?.data.imageUrl}
                        style={{ float: 'left', marginRight: 8 }}
                    />
                    <Text>{option?.label}</Text>
                </Space>
            )}
        />
    );
};

export default SearchProductDropdown;
