import { FormInstance } from 'antd';

import { Card, Typography, Form, Select, Space } from '@Shared/ui';
import { PromotionDto3 } from '@Shared/api/rtkQueryApis/sellerPromotions';
import { MarketplaceStoreDropdown } from '@Features/shared';
import { PromotionTypeOptions } from '@Features/seller/promotions/personalPromotionsTab/model/types';

const { Title, Text } = Typography;

type PromotionSelectorCardProps = {
    promotion: PromotionDto3 | undefined;
    isAddPersonalPromotion?: boolean;
    form: FormInstance;
};

const PromotionSelectorCard = ({ isAddPersonalPromotion, form }: PromotionSelectorCardProps) => {
    const selectedMPStore = Form.useWatch('marketplace_store', form);

    return (
        <Card>
            <Title
                level={3}
                style={{ marginTop: 0 }}
            >
                {isAddPersonalPromotion ? 'Добавить персональное предложение' : 'Изменить персональное предложение'}
            </Title>
            <Space
                direction="vertical"
                style={{ width: '100%' }}
            >
                <Text type="secondary">
                    {isAddPersonalPromotion
                        ? 'Здесь вы можете добавить акцию или промокод для магазина на Ozon.'
                        : // eslint-disable-next-line max-len
                          'Здесь вы можете добавить акцию или промокод для магазина на Ozon. Сначала отредактируйте вашу акцию или промокод на Ozon, а затем продублируйте эти изменения на Такпродам.'}
                </Text>
                {isAddPersonalPromotion ? (
                    <ol style={{ margin: 0, paddingLeft: 16 }}>
                        <li>
                            <Text type="secondary">
                                Создайте промокод на Ozon и выберете товары, которые будут участвовать в акции, по
                                инструкции. Промокод или акция должна быть доступна всем покупателям.
                            </Text>
                        </li>
                        <li>
                            <Text type="secondary">
                                Вставьте в указанные ниже поля информацию об акции или промокоде с Ozon.
                            </Text>
                        </li>
                        <li>
                            <Text type="secondary">
                                Загрузите товары, которые участвуют в акции, используя XLS-файл с Ozon.
                            </Text>
                        </li>
                    </ol>
                ) : null}
            </Space>
            <Form.Item
                label="Связка магазина и маркетплейса"
                name="marketplace_store"
                validateTrigger={['onBlur', 'onChange']}
                rules={[{ required: true }]}
                style={{ marginTop: 20 }}
            >
                <MarketplaceStoreDropdown
                    filterOptions={(options) => options.filter((option) => option.marketplaceSlug === 'ozon')}
                    selectValue={selectedMPStore}
                    setInitialValue={(options = []) => {
                        if (options.length) {
                            console.log(1111, options[0].value);
                            form.setFieldValue('marketplace_store', options[0].value);
                            console.log(6666, form.getFieldsValue());
                        }
                    }}
                />
            </Form.Item>
            <Form.Item
                label="Тип акции"
                name="type"
                validateTrigger={['onBlur', 'onChange']}
                rules={[{ required: true }]}
                style={{ marginTop: 20 }}
            >
                <Select
                    style={{ width: '100%' }}
                    options={PromotionTypeOptions}
                />
            </Form.Item>
        </Card>
    );
};

export default PromotionSelectorCard;
