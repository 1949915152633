import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation, Outlet } from 'react-router-dom';
import { Layout, theme } from 'antd';

import { StoresBreadcrumb } from '@Features/seller/stores';
import { fetchData as fetchStores } from '@Entities/keyValue/seller/stores/model/storesSlice';
import { fetchData as fetchMarketplaces } from '@Entities/keyValue/seller/marketplaces/model/marketplacesSlice';
import { KeyValueKeysEnum } from '@Shared/model/seller/types';
import { Space, Card, Grid } from '@Shared/ui';

import './styles.scss';

const { Content } = Layout;
const { useBreakpoint } = Grid;

const tabList = [
    {
        key: '/stores',
        tab: 'Магазины',
    },
    {
        key: '/stores/marketplaces',
        tab: 'Маркетплейсы',
    },
];

const StoresPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const breakpoints = useBreakpoint();
    const [activeTabKey, setActiveTabKey] = useState<string>(location?.pathname || '/stores');

    useEffect(() => {
        dispatch(
            fetchStores({
                key: KeyValueKeysEnum.getStoresList,
            }),
        );
    }, [dispatch]);

    useEffect(() => {
        dispatch(
            fetchMarketplaces({
                key: KeyValueKeysEnum.getMarketplacesList,
            }),
        );
    }, [dispatch]);

    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const onTabChange = (key: string) => {
        setActiveTabKey(key);
        navigate(key);
    };

    return (
        <Content
            className="page-content stores-page"
            style={{
                background: colorBgContainer,
                padding: breakpoints.md ? 24 : '24px 0',
            }}
        >
            <StoresBreadcrumb />
            <Space
                direction="vertical"
                style={{ width: '100%' }}
            >
                <Card
                    style={{ width: '100%', background: '#fff', paddingTop: 24 }}
                    tabList={tabList}
                    activeTabKey={activeTabKey}
                    onTabChange={onTabChange}
                >
                    <Outlet />
                </Card>
            </Space>
        </Content>
    );
};
export default StoresPage;
