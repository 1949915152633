import { useEffect, Dispatch, SetStateAction } from 'react';

import { dayjs } from '@Shared/utils';
import { Card, Typography, Form, Input, Grid, DatePicker } from '@Shared/ui';
import { PromotionDto2 } from '@Shared/api/rtkQueryApis/sellerPromotions';
import { DEFAULT_DATE_FORMAT } from '@Shared/constants';

const { Title } = Typography;
const { TextArea } = Input;
const { useBreakpoint } = Grid;
const { RangePicker } = DatePicker;

type PromotionSelectorCardProps = {
    promotion: PromotionDto2 | undefined;
    setPromotionFormData: Dispatch<SetStateAction<{}>>;
};

const PromotionDetailsCard = ({ promotion, setPromotionFormData }: PromotionSelectorCardProps) => {
    const [form] = Form.useForm();
    const breakpoints = useBreakpoint();

    useEffect(() => {
        if (promotion) {
            const initialData = {
                title: promotion.title,
                description: promotion.description,
                period: [dayjs(promotion.start_date), dayjs(promotion.end_date)],
                orderAmount: promotion.orderAmount,
                discountValue: promotion.discountValue,
            };
            form.setFieldsValue(initialData);
            setPromotionFormData(initialData);
        }
    }, [form, promotion, setPromotionFormData]);
    return (
        <Card>
            <Title
                level={3}
                style={{ marginTop: 0 }}
            >
                Информация об акции
            </Title>
            <Form
                form={form}
                name="promotion-info"
                initialValues={{ title: promotion?.id }}
                onValuesChange={(data) => setPromotionFormData(data)}
                labelAlign="left"
                labelCol={{ style: { width: 220 } }}
                wrapperCol={{ style: { flexDirection: breakpoints.lg ? 'column' : 'row' } }}
            >
                <Form.Item
                    label="Название акции"
                    name="title"
                    style={{ marginTop: 20 }}
                >
                    <Input disabled />
                </Form.Item>
                <Form.Item
                    label="Описание акции"
                    name="description"
                    style={{ marginTop: 20 }}
                >
                    <TextArea
                        placeholder="Введите описание акции"
                        rows={4}
                    />
                </Form.Item>
                <Form.Item
                    label="Продолжительность акции"
                    name="period"
                    style={{ marginTop: 20 }}
                >
                    <RangePicker
                        format={DEFAULT_DATE_FORMAT}
                        disabled
                        allowEmpty
                        style={{ width: breakpoints.lg ? 320 : '100%' }}
                    />
                </Form.Item>
                {promotion?.orderAmount !== null ? (
                    <Form.Item
                        label="Минимальный заказ"
                        name="orderAmount"
                        style={{ marginTop: 20 }}
                    >
                        <Input
                            disabled
                            suffix="₽"
                            style={{ width: breakpoints.lg ? 320 : '100%' }}
                        />
                    </Form.Item>
                ) : null}
                {promotion?.discountValue ? (
                    <Form.Item
                        label="Скидка"
                        name="discountValue"
                        style={{ marginTop: 20 }}
                    >
                        <Input
                            disabled
                            suffix="%"
                            style={{ width: breakpoints.lg ? 320 : '100%' }}
                        />
                    </Form.Item>
                ) : null}
            </Form>
        </Card>
    );
};

export default PromotionDetailsCard;
