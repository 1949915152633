import { MouseEvent } from 'react';
import Icon from '@ant-design/icons';
import { theme } from 'antd';

import { Button, Typography, Row, Col, QuestionTooltip } from '@Shared/ui';
import { CopyItemLeftTop } from '@Shared/assets/images/icons';
import { useClipboard } from '@Shared/hooks';

const { Text, Title } = Typography;

const AdMarkingBlock = ({
    copyMessageText = 'Данные для маркировки рекламы успешно скопированы',
    legalText = '',
    tooltipInfoText = '',
}: {
    copyMessageText?: string;
    legalText: string;
    tooltipInfoText?: string;
}) => {
    const { copyTextToClipboard } = useClipboard();

    const {
        token: { colorFillAlter, padding, borderRadius, colorTextTertiary },
    } = theme.useToken();

    const handleCopyText = (e: MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        copyTextToClipboard(legalText || '', copyMessageText || '');
    };

    return (
        <Row style={{ gap: 8 }}>
            <Title
                level={5}
                style={{ margin: 0 }}
            >
                Данные для маркировки рекламы{' '}
                {tooltipInfoText ? (
                    <>
                        &nbsp;
                        <QuestionTooltip title={tooltipInfoText} />
                    </>
                ) : null}
            </Title>
            <Row
                wrap={false}
                justify="space-between"
                align="middle"
                style={{
                    background: colorFillAlter,
                    padding: padding,
                    borderRadius: borderRadius,
                    width: '100%',
                    gap: 8,
                }}
            >
                <Text>{legalText || 'Нет данных'}</Text>
                <Col style={{ width: 40 }}>
                    <Button
                        type="primary"
                        ghost
                        disabled={!legalText}
                        onClick={handleCopyText}
                        icon={<Icon component={() => <CopyItemLeftTop />} />}
                    />
                </Col>
            </Row>
            <Text style={{ color: colorTextTertiary }}>
                Укажите эти данные в публикации для соблюдения закона о Рекламе
            </Text>
        </Row>
    );
};

export default AdMarkingBlock;
