import Icon from '@ant-design/icons';

import {
    InternetWebLinkIcon,
    MediaLibraryPlaylistIcon,
    BookLegalLow,
    SingleUser,
    ExitLogOut,
    CreditCardUp,
    LinkSearchLoap,
    StatisticsAnalytics,
    SaleDiscountPromotion,
} from '@Shared/assets/images/icons';

export const pageMenuItems = [
    {
        key: 'sources',
        href: '/sources',
        icon: <Icon component={() => <InternetWebLinkIcon />} />,
        label: 'Площадки',
    },
    {
        key: 'products',
        href: '/products',
        icon: <Icon component={() => <MediaLibraryPlaylistIcon />} />,
        id: 'joyride-products-menu-item',
        label: 'Каталог продуктов',
    },
    {
        key: 'promotions',
        href: '/promotions',
        label: 'Акции и промокоды',
        icon: <Icon component={() => <SaleDiscountPromotion />} />,
    },
    {
        key: 'finances',
        href: '/finances',
        label: 'Финансы',
        icon: <Icon component={() => <CreditCardUp />} />,
    },
    {
        key: 'statistics',
        href: '/statistics',
        label: 'Статистика',
        icon: <Icon component={() => <StatisticsAnalytics />} />,
    },
];

export const userMenuItems = [
    {
        key: 'profile',
        label: 'Профиль',
        disabled: true,
        icon: <Icon component={() => <SingleUser />} />,
    },
    {
        key: 'legal-info',
        href: '/legal-info',
        label: 'Юридические данные',
        icon: <Icon component={() => <BookLegalLow style={{ opacity: '0.45' }} />} />,
    },
    {
        key: 'ref-link',
        modal: 'ref-link',
        label: 'Ссылка-приглашение',
        icon: <Icon component={() => <LinkSearchLoap style={{ opacity: '0.45' }} />} />,
    },
    {
        type: 'divider',
    },
    {
        key: 'logout',
        label: 'Выйти',
        onClick: () => (location.href = `${process.env.SERVER_URL}/logout-init`),
        icon: <Icon component={() => <ExitLogOut />} />,
    },
];
