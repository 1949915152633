import { theme } from 'antd';

import { Form, Card, Typography, Radio, QuestionTooltip } from '@Shared/ui';
import { DeadlineEnum } from '@Shared/api/rtkQueryApis/sellerCampaigns';
import { DeadlineMap } from '@Features/seller/productPromotion/campaignsTab/setupCampaign/barter/model/types';

const { Title } = Typography;

const DeadlineCard = ({ isFormDisabled }: { isFormDisabled: boolean }) => {
    const {
        token: { colorTextSecondary },
    } = theme.useToken();

    return (
        <Card style={{ width: '100%' }}>
            <Title
                type="secondary"
                style={{ fontSize: 18, color: colorTextSecondary, marginTop: 0 }}
                level={4}
            >
                <>
                    Желаемый срок реализации &nbsp;
                    <QuestionTooltip title="Период, в который должна выйти вся ваша реклама" />
                </>
            </Title>
            <Form.Item
                name={['barter_setting', 'deadline']}
                style={{ marginTop: 20 }}
            >
                <Radio.Group disabled={isFormDisabled}>
                    {Object.values(DeadlineEnum).map((paymentType) => (
                        <Radio
                            value={paymentType}
                            key={paymentType}
                        >
                            {DeadlineMap[paymentType]}
                        </Radio>
                    ))}
                </Radio.Group>
            </Form.Item>
        </Card>
    );
};
export default DeadlineCard;
