import { keyValueSliceGeneric } from '@Shared/utils/redux/seller/keyValueGenericSlice';
import { StoreDto3 } from '@OpenApi/typescript-axios';

export type StoresKeyValueType = {
    isLoading: boolean;
    isError: boolean;
    isFulfilled: boolean;
    isSubmitted: boolean;
    value: { items: StoreDto3[] };
};

export const storesSlice = keyValueSliceGeneric({
    name: 'stores',
    stateSelector: (state) => state.keyValue.seller.stores,
});

export const { setData, resetState } = storesSlice.actions;
export const { fetchData, selectState, selectById } = storesSlice;

export default storesSlice.reducer;
