import { ProductCampaignFavoritesApi } from './ProductCampaignFavoritesApi';

ProductCampaignFavoritesApi.enhanceEndpoints({
    addTagTypes: ['PublisherProductsList'],
    endpoints: {
        addAProductCampaignToFavorites: {
            invalidatesTags: ['PublisherProductsList'],
        },
        removeProductCampaignFromFavorites: {
            invalidatesTags: ['PublisherProductsList'],
        },
    },
});

export * from './ProductCampaignFavoritesApi';
