import { RawAxiosRequestConfig } from 'axios';

import {
    SellersOnboardingApi,
    SellersInfoDto,
    SellersIntegrationsApi,
    SellerApi,
    StoreDto,
    StoreDto2,
    OzonIntegrationDto,
    WildberriesIntegrationDto,
} from '@OpenApi/typescript-axios/index';
import { FormKeysEnum, FormIntegrationsEnum } from '@Shared/model/seller/types';

const sellersOnboardingApi = new SellersOnboardingApi();
const sellerApi = new SellerApi();
const sellersIntegrationsApi = new SellersIntegrationsApi();

const FormsIntegrationsApi = {
    [FormIntegrationsEnum.sellerOzonSetupIntegrationForm]: {
        submit: (
            formData: OzonIntegrationDto,
            options: RawAxiosRequestConfig,
            params: { storeId: string; createCampaignDraft: boolean },
        ) =>
            sellersIntegrationsApi.connectMarketplaceToOzon(
                params.storeId,
                formData,
                params?.createCampaignDraft || false,
                options,
            ),
    },
    [FormIntegrationsEnum.sellerOzonUpdateIntegrationForm]: {
        fetch: (options: RawAxiosRequestConfig, params: { storeId: string }) =>
            sellersIntegrationsApi.getAppPrivateSellerIntegrationOzonView(params.storeId, options),
        submit: (formData: OzonIntegrationDto, options: RawAxiosRequestConfig, params: { storeId: string }) =>
            sellersIntegrationsApi.putAppPrivateSellerIntegrationOzonUpdate(params.storeId, formData, options),
    },
    [FormIntegrationsEnum.sellerWBSetupIntegrationForm]: {
        submit: (
            formData: WildberriesIntegrationDto,
            options: RawAxiosRequestConfig,
            params: { storeId: string; createCampaignDraft: boolean },
        ) =>
            sellersIntegrationsApi.connectMarketplaceToWildberries(
                params.storeId,
                formData,
                params?.createCampaignDraft || false,
                options,
            ),
    },
    [FormIntegrationsEnum.sellerWBUpdateIntegrationForm]: {
        fetch: (options: RawAxiosRequestConfig, params: { storeId: string }) =>
            sellersIntegrationsApi.getExistMarketplaceIntegrationWithWildberries(params.storeId, options),
        submit: (formData: WildberriesIntegrationDto, options: RawAxiosRequestConfig, params: { storeId: string }) =>
            sellersIntegrationsApi.putAppPrivateSellerIntegrationWildberriesUpdate(params.storeId, formData, options),
    },
};

export const FormsApiInstances = {
    [FormKeysEnum.sellerOnboardingForm]: {
        fetch: (options: RawAxiosRequestConfig) => sellersOnboardingApi.getSellersOnboardingForm(options),
        submit: (formData: SellersInfoDto, options: RawAxiosRequestConfig) =>
            sellersOnboardingApi.sendSellerOnboardingForm(formData, options),
    },
    [FormKeysEnum.sellerAddStoreForm]: {
        submit: (formData: StoreDto, options: RawAxiosRequestConfig) => sellerApi.createANewStore(formData, options),
    },
    [FormKeysEnum.sellerEditStoreForm]: {
        fetch: (options: RawAxiosRequestConfig, params: { storeId: string }) =>
            sellerApi.viewTheStore(params.storeId, options),
        submit: (formData: StoreDto2, options: RawAxiosRequestConfig, params: { storeId: string }) =>
            sellerApi.putAppPrivateSellerStoreUpdate(params.storeId, formData, options),
    },
    ...FormsIntegrationsApi,
};
