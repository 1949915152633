import { Dispatch, SetStateAction } from 'react';

import { FilterObjectType } from '@Features/seller/productPromotion/productsTab/model/types';
import { QuestionTooltip, Table, Typography } from '@Shared/ui';
import { ProductNameCell } from '@Features/shared/TableCells';
import { ProductDto, GetProductsListApiResponse } from '@Shared/api/rtkQueryApis/sellerProducts/SellerProductsApi';
import { formatPrice } from '@Shared/utils/formatPrice';

import { EmptyProductsTableView } from './EmptyProductsTableView';

const { Link } = Typography;

type ProductsTableProps = {
    data: GetProductsListApiResponse | undefined;
    isLoading: boolean;
    setFilterObject: Dispatch<SetStateAction<FilterObjectType>>;
    setProductId: Dispatch<SetStateAction<string>>;
    setDrawerOpen: Dispatch<SetStateAction<boolean>>;
};

const columns = [
    {
        title: 'Название товара',
        dataIndex: 'title',
        key: 'title',
        ellipsis: true,
        render: (_: unknown, product: ProductDto) => (
            <ProductNameCell
                product={product}
                showCategory
            />
        ),
    },
    {
        title: 'Артикул',
        dataIndex: 'sku',
        key: 'sku',
    },
    {
        title: (
            <>
                Стоимость &nbsp;
                <QuestionTooltip
                    title="Цена товара на маркетплейсе может отличаться. 
                    Вы получите комиссию от той цены, по которой товар будет куплен."
                />
            </>
        ),
        dataIndex: 'price',
        key: 'price',
        render: (price: string, product: ProductDto) => formatPrice({ price: price, currency: product.currency || {} }),
    },
    {
        title: 'Магазин',
        dataIndex: 'marketplace_store',
        key: 'store',
        ellipsis: true,
        render: (marketplace_store: { store: { title: unknown } }) => <>{marketplace_store?.store?.title}</>,
    },
    {
        title: 'Маркетплейс',
        dataIndex: 'marketplace_store',
        key: 'marketplace_store',
        ellipsis: true,
        render: (marketplace_store: { marketplace: { title: unknown } }) => (
            <>{marketplace_store?.marketplace?.title}</>
        ),
    },
    {
        title: 'Ссылка на МП',
        dataIndex: 'external_link',
        key: 'external_link',
        ellipsis: true,
        render: (external_link: string) => (
            <Link
                onClick={(e) => {
                    e.stopPropagation();
                }}
                target="_blank"
                href={external_link}
            >
                {external_link}
            </Link>
        ),
    },
];

const ProductsTable = ({ data, isLoading, setFilterObject, setProductId, setDrawerOpen }: ProductsTableProps) => {
    return (
        <Table
            dataSource={data?.items}
            loading={isLoading}
            rowKey="id"
            columns={columns}
            scroll={{ x: '1000px' }}
            className="products-table"
            id="joyride-seller-products-table"
            pagination={{
                showSizeChanger: true,
                hideOnSinglePage: true,
                pageSizeOptions: ['10', '25', '50'],
                pageSize: data?.limit,
                position: ['bottomLeft'],
                current: data?.page,
                total: data?.totalCount,
                onChange: (page, pageSize) =>
                    setFilterObject((prevFilterObject) => {
                        const hasPageSizeChanged = pageSize !== +prevFilterObject.limit;
                        return {
                            ...prevFilterObject,
                            page: hasPageSizeChanged ? 1 : page,
                            limit: pageSize,
                        };
                    }),
            }}
            locale={{
                emptyText: <EmptyProductsTableView />,
            }}
            onRow={(record) => {
                return {
                    onClick: () => {
                        setProductId(record.id);
                        setDrawerOpen(true);
                    },
                };
            }}
        />
    );
};

export default ProductsTable;
