import { Dayjs } from 'dayjs';

import {
    DEFAULT_DATE_FORMAT,
    DEFAULT_DATE_TIME_FORMAT,
    DEFAULT_DATE_TIME_WITH_SECONDS_FORMAT,
} from '@Shared/constants';
import { dayjs } from '@Shared/utils';

export function formatDate(dateString: string | number | Date | Dayjs, format = DEFAULT_DATE_FORMAT) {
    const date = dayjs(dateString);

    return date.format(format);
}

export function formatDateTime(dateString: string | number | Date | Dayjs, seconds?: boolean) {
    return formatDate(dateString, seconds ? DEFAULT_DATE_TIME_WITH_SECONDS_FORMAT : DEFAULT_DATE_TIME_FORMAT);
}

export function convertToGmtZero(dateStr: string, inputFormat = DEFAULT_DATE_TIME_WITH_SECONDS_FORMAT) {
    // Parse the date string in the local timezone specified
    const localDate = dayjs(dateStr, inputFormat);
    // Convert to UTC
    const utcDate = localDate.utc();
    // Return formatted UTC date
    return utcDate.format('YYYY-MM-DD HH:mm:ss');
}
