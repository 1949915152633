import { FC, PropsWithChildren } from 'react';
import { Layout, theme } from 'antd';

import { Grid } from '@Shared/ui';

const { Content } = Layout;
const { useBreakpoint } = Grid;

interface PageContentProps {}

export const PageContent: FC<PageContentProps> = ({ children }: PropsWithChildren<PageContentProps>) => {
    const breakpoints = useBreakpoint();
    const {
        token: { colorBgContainer },
    } = theme.useToken();

    return (
        <Content
            className="page-content"
            style={{
                background: colorBgContainer,
                padding: breakpoints.md ? 24 : '24px 0',
            }}
        >
            {children}
        </Content>
    );
};
