import { emptySplitApi as api } from '../../full/emptyApi';

const injectedRtkApi = api.injectEndpoints({
    endpoints: (build) => ({
        createPaymentInvoice: build.mutation<CreatePaymentInvoiceApiResponse, CreatePaymentInvoiceApiArg>({
            query: (queryArg) => ({
                url: '/api/seller/v1/payments/invoice/',
                method: 'POST',
                body: queryArg.createInvoiceDto,
            }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as SellerPaymentsApi };
export type CreatePaymentInvoiceApiResponse = /** status 201 Resource created */ ReplenishmentDto;
export type CreatePaymentInvoiceApiArg = {
    createInvoiceDto: CreateInvoiceDto;
};
export type ReplenishmentDto = {
    paymentUrl: string;
};
export type CreateInvoiceDto = {
    amount?: number | number;
};
export const { useCreatePaymentInvoiceMutation } = injectedRtkApi;
