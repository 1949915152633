import { FinancesInfoBlock, TransactionsTableBlock } from '@Features/seller/finances';
import { Space } from '@Shared/ui';

const FinancesTab = () => {
    return (
        <Space
            direction="vertical"
            style={{ width: '100%' }}
            size={24}
        >
            <FinancesInfoBlock />
            <TransactionsTableBlock />
        </Space>
    );
};
export default FinancesTab;
