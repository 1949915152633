import { useEffect, ReactNode } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
    selectState,
    SetupIntegrationFormType,
    submitForm,
    resetForm,
} from '@Entities/forms/seller/stores/model/setupIntegration';
import { fetchData } from '@Entities/keyValue/seller/stores/model/storesSlice';
import { Form, Typography, Input, Button } from '@Shared/ui';
import { FormIntegrationsEnum, KeyValueKeysEnum } from '@Shared/model/seller/types';
import { OzonIntegrationDto } from '@OpenApi/typescript-axios';
import { useGetIntegrationDataFromExtension } from '@Shared/hooks';

const { Title } = Typography;

type StoreDetailsDrawerProps = {
    setOpen: (arg1: boolean) => void;
    storeId: string;
    setDrawerExtraContent: (arg1: ReactNode) => void;
};

const OzonSetupIntegrationForm = ({ setOpen, storeId, setDrawerExtraContent }: StoreDetailsDrawerProps) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    useGetIntegrationDataFromExtension(form);
    const { initialData, isSubmitted, isSubmitting, formData, errors }: SetupIntegrationFormType<OzonIntegrationDto> =
        useSelector(selectState);

    useEffect(() => {
        setDrawerExtraContent(
            <Button
                type="primary"
                onClick={() => form.submit()}
                loading={isSubmitting}
            >
                Сохранить
            </Button>,
        );
    }, [form, isSubmitting, setDrawerExtraContent, storeId]);

    const handleSubmit = (formValues: typeof formData) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { organization_id, ...restFormData } = formValues;
        dispatch(
            submitForm({
                key: FormIntegrationsEnum.sellerOzonUpdateIntegrationForm,
                withNotification: true,
                notificationOptions: {
                    successMessage: 'Интеграция была успешно обновлена',
                    errorMessage: 'Ошибка при попытке обновлении интеграции',
                },
                formData: restFormData,
                params: { storeId },
            }),
        );
    };

    const onFinish = (formData) => {
        handleSubmit(formData);
    };

    const onFinishFailed = (errorInfo) => {
        console.error('Failed:', errorInfo);
    };

    useEffect(() => {
        const isDataEmpty = !Object.keys(initialData).length;
        if (isDataEmpty) {
            form.resetFields();
        } else {
            form.setFieldsValue(initialData);
        }
    }, [form, initialData]);

    useEffect(() => {
        if (isSubmitted) {
            setOpen(false);
            dispatch(resetForm());
            dispatch(fetchData({ key: KeyValueKeysEnum.getStoresList }));
        }
    }, [dispatch, isSubmitted, setOpen]);

    return (
        <Form
            form={form}
            name="integration-ozon"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
            autoComplete="off"
        >
            <Title
                style={{ marginBottom: 8 }}
                level={4}
            >
                Настройка интеграции
            </Title>
            <Form.Item
                hasFeedback
                label="ID организации"
                name="organization_id"
                validateTrigger="onBlur"
                rules={[{ required: true, message: 'Это поле обязательно к заполнению' }]}
                style={{ marginTop: 20 }}
                validateStatus={errors?.organization_id ? 'error' : ''}
                help={errors?.organization_id || null}
            >
                <Input
                    placeholder="Укажите ID организации"
                    disabled
                />
            </Form.Item>
            <Title
                style={{ marginBottom: 8 }}
                level={5}
            >
                Seller API
            </Title>
            <Form.Item
                hasFeedback
                label="Client ID"
                name="seller_client_id"
                validateTrigger="onBlur"
                rules={[{ required: true, message: 'Это поле обязательно к заполнению' }]}
                style={{ marginTop: 20 }}
                validateStatus={errors?.seller_client_id ? 'error' : ''}
                help={errors?.seller_client_id || null}
            >
                <Input placeholder="Укажите Client ID" />
            </Form.Item>
            <Form.Item
                hasFeedback
                label="API key"
                name="seller_api_key"
                validateTrigger="onBlur"
                rules={[{ required: true, message: 'Это поле обязательно к заполнению' }]}
                style={{ marginTop: 20 }}
                validateStatus={errors?.seller_api_key ? 'error' : ''}
                help={errors?.seller_api_key || null}
            >
                <Input placeholder="Укажите API key" />
            </Form.Item>
            <Title
                style={{ marginBottom: 8 }}
                level={5}
            >
                Perfomance API
            </Title>
            <Form.Item
                hasFeedback
                label="Client ID"
                name="performance_client_id"
                validateTrigger="onBlur"
                rules={[{ required: true, message: 'Это поле обязательно к заполнению' }]}
                style={{ marginTop: 20 }}
                validateStatus={errors?.performance_client_id ? 'error' : ''}
                help={errors?.performance_client_id || null}
            >
                <Input placeholder="Укажите Client ID" />
            </Form.Item>
            <Form.Item
                hasFeedback
                label="Client Secret"
                name="performance_api_key"
                validateTrigger="onBlur"
                rules={[{ required: true, message: 'Это поле обязательно к заполнению' }]}
                style={{ marginTop: 20 }}
                validateStatus={errors?.performance_api_key ? 'error' : ''}
                help={errors?.performance_api_key || null}
            >
                <Input placeholder="Укажите Client Secret" />
            </Form.Item>
        </Form>
    );
};

export default OzonSetupIntegrationForm;
