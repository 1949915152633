import { useNavigate } from 'react-router-dom';

import { SourceDetails } from '@Features/publisher/sources/SourceDetails';
import { Drawer, Button } from '@Shared/ui';
import { useViewASourceQuery, SourceStatusEnum } from '@Shared/api/rtkQueryApis/publisherSources';

type ProductDrawerProps = {
    isDrawerOpen: boolean;
    setDrawerOpen: (arg1: boolean) => void;
    setModalOpen: (arg1: boolean) => void;
    sourceId: string;
};

export const SourceDrawer = ({ isDrawerOpen, setDrawerOpen, sourceId, setModalOpen }: ProductDrawerProps) => {
    const navigate = useNavigate();
    const { isFetching, data: source } = useViewASourceQuery({ source: sourceId }, { skip: !sourceId });

    return (
        <Drawer
            open={isDrawerOpen}
            loading={isFetching}
            onClose={() => setDrawerOpen(false)}
            title="Детали Площадки"
            className="sources-table__drawer"
            extra={
                source?.status === SourceStatusEnum.Declined ? (
                    <Button
                        type="primary"
                        ghost
                        disabled={isFetching}
                        onClick={() => {
                            navigate('/sources/edit-source', {
                                state: { selectedSourceId: source?.id || '' },
                            });
                        }}
                    >
                        Изменить
                    </Button>
                ) : null
            }
        >
            {!!source && (
                <SourceDetails
                    source={source}
                    setModalOpen={setModalOpen}
                />
            )}
        </Drawer>
    );
};
