import { useEffect } from 'react';

export const useGetIntegrationDataFromExtension = (form) => {
    useEffect(() => {
        const listener = (e: MessageEvent) => {
            const { type, data } = e.data;
            if (type !== 'set-integration-form-data') {
                return;
            }

            form.setFieldsValue(data.dto);
        };
        window.addEventListener('message', listener);
        return () => window.removeEventListener('message', listener);
    }, [form]);
};
