import { useState } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';

import { Breadcrumbs, PageContent, type BreadcrumbItemType } from '@Features/shared';
import { Card } from '@Shared/ui';

const pageBreadcrumbs: BreadcrumbItemType[] = [
    {
        path: '/products',
        title: 'Каталог продуктов',
    },
    {
        path: '/',
        title: 'Продукты',
    },
];

const tabList = [
    {
        key: '/products',
        tab: 'Каталог продуктов',
    },
];

const ProductsPage = () => {
    const navigate = useNavigate();
    const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbItemType[]>(pageBreadcrumbs);
    const [activeTabKey, setActiveTabKey] = useState<string>(location?.pathname || tabList[0].key);

    const onTabChange = (key: string) => {
        setActiveTabKey(key);
        setBreadcrumbs(() => {
            const tabBreadcrumb = tabList.find((tab) => tab.key === key);
            return [
                ...pageBreadcrumbs,
                {
                    path: tabBreadcrumb!.key,
                    title: tabBreadcrumb!.tab,
                },
            ];
        });
        navigate(key);
    };

    return (
        <PageContent>
            <Breadcrumbs items={breadcrumbs} />
            <Card
                onTabChange={onTabChange}
                activeTabKey={activeTabKey}
                tabList={tabList}
                style={{ width: '100%', background: '#fff' }}
            >
                <Outlet />
            </Card>
        </PageContent>
    );
};
export default ProductsPage;
