import OzonIcon from './marketplaces/ozon.svg';
import WildberriesIcon from './marketplaces/wildberries.svg';
import YandexMarketIcon from './marketplaces/yandex-market.svg';
import InteractionIcon from './interaction.svg';
import InternetWebLinkIcon from './browsers-internet-web-link-right-bottom.svg';
import BrowserCursorClick from './browser-cursor-click.svg';
import NotesPagesNotification from './notes-pages-notification.svg';
import FileBlankCopyExchange from './file-blank-copy-exchange.svg';
import DashboardPublisherIcon from './dashboard-publisher.svg';
import MediaLibraryPlaylistIcon from './media-library-playlist-favorite.svg';
import CopyItemLeftTop from './copy-item-left-top.svg';
import MailboxDownload from './mailbox-download.svg';
import BookLegalLow from './book-legal-low.svg';
import ExitLogOut from './exit-log-out.svg';
import SingleUser from './single-user.svg';
import CreditCardUp from './credit-cards-up.svg';
import ItemsStarsSquare from './items-stars-square.svg';
import ShoppingBag from './shopping-bag.svg';
import Information from './information.svg';
import InfoInformationCircle from './info-information-circle.svg';
import LinkSearchLoap from './link-search-loap.svg';
import StatisticsAnalytics from './statistics-analytics.svg';
import ToolsHammer from './tools-hammer.svg';
import DownloadSaveUpload from './download-save-upload.svg';
import DeleteDisable from './delete-disable.svg';
import MoneyBagModernAddPlus from './money-bag-modern-add-plus.svg';
import SearchLoap from './search-loap.svg';
import PenEdit from './pen-edit.svg';
import SaleDiscountPromotion from './sale-discount-promotion.svg';
import QuestionCircle from './question-circle.svg';
import CouponSaleDiscount from './coupon-sale-discount.svg';
import ShoppingBagDiscount from './shopping-bag-discount.svg';

const MarketplaceIcons = {
    OzonIcon,
    WildberriesIcon,
    YandexMarketIcon,
};

export {
    MarketplaceIcons,
    InteractionIcon,
    InternetWebLinkIcon,
    DashboardPublisherIcon,
    BrowserCursorClick,
    NotesPagesNotification,
    FileBlankCopyExchange,
    MediaLibraryPlaylistIcon,
    CopyItemLeftTop,
    MailboxDownload,
    BookLegalLow,
    ExitLogOut,
    SingleUser,
    CreditCardUp,
    ItemsStarsSquare,
    ShoppingBag,
    Information,
    InfoInformationCircle,
    LinkSearchLoap,
    StatisticsAnalytics,
    ToolsHammer,
    DownloadSaveUpload,
    DeleteDisable,
    MoneyBagModernAddPlus,
    SearchLoap,
    PenEdit,
    SaleDiscountPromotion,
    QuestionCircle,
    CouponSaleDiscount,
    ShoppingBagDiscount,
};
