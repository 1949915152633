import { emptySplitApi as api } from '../../full/emptyApi';

const injectedRtkApi = api.injectEndpoints({
    endpoints: (build) => ({
        addAProductCampaignToFavorites: build.mutation<
            AddAProductCampaignToFavoritesApiResponse,
            AddAProductCampaignToFavoritesApiArg
        >({
            query: (queryArg) => ({
                url: `/api/publisher/v1/product/${queryArg.productCampaign}/favorites/`,
                method: 'POST',
            }),
        }),
        removeProductCampaignFromFavorites: build.mutation<
            RemoveProductCampaignFromFavoritesApiResponse,
            RemoveProductCampaignFromFavoritesApiArg
        >({
            query: (queryArg) => ({
                url: `/api/publisher/v1/product/${queryArg.productCampaign}/favorites/`,
                method: 'DELETE',
            }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as ProductCampaignFavoritesApi };
export type AddAProductCampaignToFavoritesApiResponse = /** status 204 Empty response */ void;
export type AddAProductCampaignToFavoritesApiArg = {
    productCampaign: string;
};
export type RemoveProductCampaignFromFavoritesApiResponse = /** status 204 Resource removed */ void;
export type RemoveProductCampaignFromFavoritesApiArg = {
    productCampaign: string;
};
export const { useAddAProductCampaignToFavoritesMutation, useRemoveProductCampaignFromFavoritesMutation } =
    injectedRtkApi;
