import { FC, ReactNode, PropsWithChildren } from 'react';
import Icon from '@ant-design/icons';
import { theme } from 'antd';

import { ToolsHammer } from '@Shared/assets/images/icons';
import { Typography, Empty, Grid } from '@Shared/ui';

const { Title, Text } = Typography;
const { useBreakpoint } = Grid;

type SectionUnderDevelopmentProps = PropsWithChildren & {
    icon?: ReactNode;
    extraText?: string | false;
    description?: string | false;
};

const SectionUnderDevelopment: FC<SectionUnderDevelopmentProps> = ({
    icon = <Icon component={() => <ToolsHammer />} />,
    extraText = false,
    description = 'Раздел находится в разработке',
    children,
}: PropsWithChildren<SectionUnderDevelopmentProps>) => {
    const breakpoints = useBreakpoint();
    const {
        token: { colorBorderSecondary },
    } = theme.useToken();

    return (
        <Empty
            style={{
                minHeight: 240,
                padding: breakpoints.md ? 50 : 20,
                border: `1px solid ${colorBorderSecondary}`,
                borderRadius: 10,
                margin: 0,
            }}
            image={icon}
            imageStyle={{ height: 32 }}
            description={
                <Title
                    level={2}
                    style={{ marginTop: 16 }}
                >
                    {description}
                </Title>
            }
        >
            {extraText ? <Text style={{ display: 'inline-block' }}>{extraText}</Text> : null}
            {children}
        </Empty>
    );
};

export default SectionUnderDevelopment;
