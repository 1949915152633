/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'src/app/providers/redux/store';
import { FormKeysEnum } from '@Shared/model/seller/types';
import { FormsApiInstances } from '@Shared/api/seller/formsApi';

import type { RawAxiosRequestConfig } from 'axios';

export type BaseFormState = {
    initialData: object;
    formData: object;
    isLoading: boolean;
    isSubmitting: boolean;
    isFulfilled: boolean;
    isSubmitted: boolean;
    isError: boolean;
    errors: null | object;
};

export type formSliceProps = {
    name: string;
    initialState?: BaseFormState;
    additionalReducers?: any;
    stateSelector?: (state: RootState) => object;
};

export const defaultFormState: BaseFormState = {
    isLoading: false,
    isSubmitting: false,
    isFulfilled: false,
    isSubmitted: false,
    isError: false,
    errors: null,
    formData: {},
    initialData: {},
};

export const formSliceGeneric = ({
    name,
    initialState = defaultFormState,
    additionalReducers,
    stateSelector,
}: formSliceProps): any => {
    const fetchForm = createAsyncThunk(
        `${name}/fetchForm`,
        async ({
            key,
            options = {},
            params,
        }: {
            key: FormKeysEnum;
            options?: RawAxiosRequestConfig | undefined;
            params?: object;
        }) => {
            const fetchFormMethod = FormsApiInstances[key].fetch;
            const res = await fetchFormMethod(options, params);
            return res.data;
        },
    );

    type submitFormArgs = {
        key: FormKeysEnum;
        options?: RawAxiosRequestConfig | undefined;
        formData: any;
        params?: object;
    };

    const submitForm = createAsyncThunk(
        `${name}/submitForm`,
        async ({ key, options = {}, formData, params }: submitFormArgs, { rejectWithValue }: any) => {
            const submitFormMethod = FormsApiInstances[key].submit;
            try {
                const res = await submitFormMethod(formData, options, params);
                if (res?.response?.status >= 400) {
                    throw res.response;
                }

                return res.data;
            } catch (err) {
                return rejectWithValue(err?.response?.data || err.data);
            }
        },
    );

    const slice = createSlice({
        name,
        initialState,
        reducers: {
            updateFormValue: (state: BaseFormState, action: PayloadAction<BaseFormState['formData']>) => {
                state.formData = { ...state.formData, ...action.payload };
            },
            resetForm: (state: BaseFormState) => {
                state.isLoading = false;
                state.isFulfilled = false;
                state.isError = false;
                state.errors = null;
                state.isSubmitting = false;
                state.isSubmitted = false;
            },
            ...additionalReducers,
        },
        extraReducers: (builder) => {
            builder
                .addCase(fetchForm.pending, (state) => {
                    state.isLoading = true;
                    state.isFulfilled = false;
                    state.isError = false;
                    state.errors = null;
                })
                .addCase(fetchForm.fulfilled, (state, action) => {
                    state.isLoading = false;
                    state.isFulfilled = true;
                    state.isError = false;
                    state.errors = null;
                    state.initialData = action.payload;
                    state.formData = action.payload;
                })
                .addCase(fetchForm.rejected, (state, action) => {
                    state.isLoading = false;
                    state.isFulfilled = true;
                    state.isError = true;
                    state.errors = action.error;
                })
                .addCase(submitForm.pending, (state) => {
                    state.isSubmitting = true;
                    state.isSubmitted = false;
                    state.isError = false;
                    state.errors = null;
                })
                .addCase(submitForm.fulfilled, (state, action) => {
                    state.isLoading = false;
                    state.isSubmitted = true;
                    state.isSubmitting = false;
                    state.isError = false;
                    state.errors = null;
                    state.initialData = action.payload;
                    state.formData = action.payload;
                })
                .addCase(submitForm.rejected, (state, action) => {
                    state.isLoading = false;
                    state.isSubmitting = false;
                    state.isSubmitted = false;
                    state.isFulfilled = true;
                    state.isError = true;
                    state.errors = (action.payload?.errors || []).reduce((acc, curr) => {
                        return { ...acc, [curr.field]: curr.message };
                    }, {});
                });
        },
    });

    const selectState = (state: RootState) => (stateSelector ? stateSelector(state) : state.forms[name]);

    return {
        fetchForm,
        submitForm,
        slice,
        reducer: slice.reducer,
        actions: slice.actions,
        selectState,
    };
};
