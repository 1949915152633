import {
    CommissionDeclineReasonEnum2,
    CommissionChangeTotalCartReasonEnum2,
} from '@Shared/api/rtkQueryApis/sellerCommissions';

export enum FilterEnum {
    CREATED_AT_FROM = 'createdAtFrom',
    CREATED_AT_TO = 'createdAtTo',
    LIMIT = 'limit',
    PAGE = 'page',
    SKU = 'sku',
    MARKETPLACE_STORE_ID = 'marketplaceStoreId',
}

export type FilterObjectType = {
    createdAtFrom: string;
    createdAtTo: string;
    page: number;
    limit: number;
    sku: string;
    marketplaceStoreId: string;
};

export const DEFAULT_PAGE_SIZE = 50;
export const DEFAULT_PAGE = 1;

export const reasonTextMap = {
    [CommissionDeclineReasonEnum2.NotRedeemed]: 'Невыкуп',
    [CommissionDeclineReasonEnum2.Refund]: 'Возврат',
    [CommissionDeclineReasonEnum2.Refusal]: 'Отказ',
};

export const changeCommissionReasonTextMap = {
    [CommissionChangeTotalCartReasonEnum2.PartiallyPaid]: 'Частичный выкуп',
};
