import { theme } from 'antd';

import { Typography, QuestionTooltip, Row } from '@Shared/ui';
import { TransactionDto } from '@Shared/api/rtkQueryApis/publisherTransactions/PublisherTransactionsApi';
import { formatPrice } from '@Shared/utils/formatPrice';

const { Text } = Typography;

type TransactionAmountCellProps = {
    transaction: TransactionDto;
};

const TransactionAmountCell = ({ transaction }: TransactionAmountCellProps) => {
    const {
        token: { colorSuccess, colorTextTertiary },
    } = theme.useToken();

    const formattedAmount = formatPrice({
        price: +transaction.transaction_amount,
        currency: transaction.currency,
        signDisplay: 'exceptZero',
    });
    return (
        <>
            <Text style={{ color: +transaction.transaction_amount > 0 ? colorSuccess : 'auto' }}>
                {formattedAmount}
            </Text>
            {transaction?.commission?.change_total_cart_reason ? (
                <Row
                    align="middle"
                    wrap={false}
                    style={{ gap: 6 }}
                >
                    <Text style={{ color: colorTextTertiary, display: 'block' }}>Измененная </Text>
                    <QuestionTooltip
                        title="Комиссия изменена продавцом по причине частичного выкупа"
                        tooltipPlacement="bottom"
                        iconSize="20px"
                    />
                </Row>
            ) : null}
        </>
    );
};

export default TransactionAmountCell;
