import { MouseEvent } from 'react';
import { theme } from 'antd';
import Icon from '@ant-design/icons';

import { Button, Grid, Typography } from '@Shared/ui';
import { CopyItemLeftTop } from '@Shared/assets/images/icons';
import { useClipboard } from '@Shared/hooks';

const { useBreakpoint } = Grid;
const { Text } = Typography;

const CouponCell = ({ coupon }: { coupon: string | null }) => {
    const { copyTextToClipboard } = useClipboard();
    const breakpoints = useBreakpoint();
    const {
        token: { borderRadiusLG },
    } = theme.useToken();

    const handleCopyText = (e: MouseEvent<HTMLElement>, link: string, message: string) => {
        e.stopPropagation();
        copyTextToClipboard(link || '', message);
    };

    return coupon ? (
        <Button
            size="small"
            iconPosition="end"
            style={{
                borderRadius: borderRadiusLG,
                width: breakpoints.sm ? 'auto' : '100%',
                minWidth: 36,
                minHeight: 36,
                maxWidth: '100%',
            }}
            type="dashed"
            onClick={(e) => handleCopyText(e, coupon || '', 'Промокод успешно скопирован')}
            icon={<Icon component={() => <CopyItemLeftTop />} />}
        >
            <Text style={{ display: 'block', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                {breakpoints.md ? coupon : null}
            </Text>
        </Button>
    ) : null;
};

export default CouponCell;
