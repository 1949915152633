import { Card, Typography, Form, Select, Grid } from '@Shared/ui';
import { PromotionDto2 } from '@Shared/api/rtkQueryApis/sellerPromotions';
import { StoreMarketplaceSelectors } from '@Features/shared';

const { Title } = Typography;
const { useBreakpoint } = Grid;

type PromotionSelectorCardProps = {
    promotion: PromotionDto2 | undefined;
};

const PromotionSelectorCard = ({ promotion }: PromotionSelectorCardProps) => {
    const breakpoints = useBreakpoint();

    const initialValues = { title: promotion?.id };
    const promotionsOptions = [{ value: promotion?.id, label: promotion?.title }];

    return (
        <Card>
            <Title
                level={3}
                style={{ marginTop: 0 }}
            >
                Изменить акцию
            </Title>
            <StoreMarketplaceSelectors
                storeId={promotion?.marketplace_store?.store?.id || ''}
                marketplaceSlug={promotion?.marketplace_store.marketplace.slug || ''}
                disabled
            />
            <Form
                name="promotion-selectors"
                className="promotion-selector__form"
                key={JSON.stringify(initialValues)}
                initialValues={initialValues}
                labelAlign="left"
                labelCol={{ style: { width: 220 } }}
                wrapperCol={{ style: { flexDirection: breakpoints.lg ? 'column' : 'row' } }}
            >
                <Form.Item
                    label="Акция"
                    name="title"
                    validateTrigger={['onBlur', 'onChange']}
                    rules={[{ required: true }]}
                    style={{ marginTop: 20 }}
                >
                    <Select
                        style={{ width: '100%' }}
                        showSearch={false}
                        disabled
                        options={promotionsOptions}
                    />
                </Form.Item>
            </Form>
        </Card>
    );
};

export default PromotionSelectorCard;
