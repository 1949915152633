import { FC } from 'react';
import { FileOutlined } from '@ant-design/icons';

import { Empty, Typography, Row } from '@Shared/ui';

const { Title, Text } = Typography;

export const EmptyCampaignsTableView: FC = () => {
    return (
        <Empty
            image={
                <FileOutlined
                    height="32"
                    width="32"
                />
            }
            imageStyle={{ height: 32 }}
            description={null}
        >
            <Title level={2}>Здесь пока пусто</Title>
            <Row
                justify="center"
                style={{ marginBottom: 24 }}
            >
                <Text>В этом разделе будут храниться ваши рекламные кампании.</Text>
            </Row>
        </Empty>
    );
};
