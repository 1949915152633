import { UpdateProductsBtn, ProductsTableBlock } from '@Features/seller/productPromotion/productsTab';
import { Flex, Grid, Typography, Space } from '@Shared/ui';

import './styles.scss';

const { Text } = Typography;
const { useBreakpoint } = Grid;

const ProductsTab = () => {
    const breakpoints = useBreakpoint();

    return (
        <Space
            direction="vertical"
            style={{ width: '100%' }}
            size={24}
        >
            <Flex
                justify="space-between"
                gap={10}
                vertical={!breakpoints.lg}
            >
                <Flex
                    vertical
                    justify="space-between"
                >
                    <Text type="secondary">
                        В этом разделе представлен общий список товаров из ваших магазинов на определенном маркетплейсе.
                    </Text>
                </Flex>
                <UpdateProductsBtn />
            </Flex>
            <Text type="secondary">
                Автоматически список продуктов актуализируется для всех ваших магазинов раз в сутки в 00:00 (МСК). Если
                вам необходимо актуализировать список вручную, то вы можете это сделать по кнопке &quot;Обновить список
                продуктов&quot; раз в сутки
            </Text>
            <ProductsTableBlock />
        </Space>
    );
};
export default ProductsTab;
