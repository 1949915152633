import { useState, useMemo } from 'react';
import { Layout, theme } from 'antd';
import { useLocation } from 'react-router-dom';

import { SourceForm, EditSourceBreadcrumb } from '@Features/publisher/sources';
import { Button, Typography, Space, Card, Row, Col, Grid } from '@Shared/ui';
import {
    SourceDto3,
    usePutAppPrivatePublisherSourceUpdateMutation,
    useViewASourceQuery,
} from '@Shared/api/rtkQueryApis/publisherSources';
import { sendYmEvent } from '@Shared/utils/analytics/sendYmEvent';
import { useGetTheUserInformationQuery } from '@Shared/api/rtkQueryApis/user';
import { normalizeErrorsObject } from '@Shared/utils/normalizeErrorsObject';

import './styles.scss';

const { Content } = Layout;
const { Text, Title } = Typography;
const { useBreakpoint } = Grid;

const EditSourcePage = () => {
    const breakpoints = useBreakpoint();
    const location = useLocation();
    const [selectedSourceId] = useState(location.state?.selectedSourceId || null);
    const { data: sourceData, isLoading: isSourceDataLoading } = useViewASourceQuery(
        { source: selectedSourceId },
        { skip: !selectedSourceId },
    );
    const [updateSource, { isLoading: isUpdateSourceLoading, error: updateSourceError, isSuccess }] =
        usePutAppPrivatePublisherSourceUpdateMutation();

    const initialData = useMemo(
        () => ({
            ...sourceData,
            source_type: sourceData?.source_type?.id + '',
            source_content_types: (sourceData?.source_content_types || []).map((type) => type.id),
        }),
        [sourceData],
    );

    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const { data: user } = useGetTheUserInformationQuery();

    const handleSubmit = async (formData: SourceDto3, sourceSlug?: string) => {
        try {
            updateSource({
                sourceDto2: formData,
                source: selectedSourceId,
                notificationOptions: {
                    successMessage: 'Площадка на проверке',
                    successDescription: 'В среднем проверка площадки занимает от 2 до 48 часов',
                },
            }).unwrap();

            sendYmEvent({
                name: 'send_edit_adspace_success',
                o1: 'account;publisher;adspace',
                o2: 'edit adspace',
                o4: sourceSlug,
                publisherId: user?.publisher?.id,
                keycloakId: user?.id,
                websiteId: formData.id,
            });
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <Content
            className="page-content"
            style={{
                background: colorBgContainer,
                padding: breakpoints.md ? 24 : '24px 0',
            }}
        >
            <EditSourceBreadcrumb />

            <Row
                gutter={[10, 10]}
                wrap={false}
                style={{
                    flexDirection: breakpoints.lg ? 'row' : 'column',
                    rowGap: breakpoints.md ? 10 : 0,
                    flexWrap: breakpoints.lg ? 'nowrap' : 'wrap',
                }}
            >
                <Col flex="auto">
                    <SourceForm
                        isFormDataLoading={isSourceDataLoading}
                        isSubmitting={isUpdateSourceLoading}
                        isSubmitted={isSuccess}
                        errors={normalizeErrorsObject(updateSourceError?.data?.errors)}
                        initialData={initialData}
                        handleSubmit={handleSubmit}
                        isAddSourceForm={false}
                    />
                </Col>
                <Col style={{ flex: breakpoints.lg ? '320px 0 0' : 'auto' }}>
                    <Card style={{ width: '100%', background: '#fff' }}>
                        <Space
                            direction="vertical"
                            size="middle"
                        >
                            <Title
                                type="secondary"
                                level={3}
                            >
                                FAQ
                            </Title>
                            <Text type="secondary">Подробнее о площадках здесь</Text>
                            <Button type="link">Нужна помощь?</Button>
                        </Space>
                    </Card>
                </Col>
            </Row>
        </Content>
    );
};
export default EditSourcePage;
