import { emptySplitApi as api } from '../../full/emptyApi';

const injectedRtkApi = api.injectEndpoints({
    endpoints: (build) => ({
        publisherProductStatistic: build.query<PublisherProductStatisticApiResponse, PublisherProductStatisticApiArg>({
            query: () => ({ url: '/api/publisher/v1/statistic/product/' }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as PublisherStatisticsApi };
export type PublisherProductStatisticApiResponse = /** status 200 Resource response */ ProductStatisticListDto;
export type PublisherProductStatisticApiArg = void;
export type ProductStatisticDto = {
    title: string;
    external_link: string;
    clicks: number;
    commissions: number;
    amount: number;
};
export type ProductStatisticListDto = {
    items?: ProductStatisticDto[];
};
export const { usePublisherProductStatisticQuery } = injectedRtkApi;
