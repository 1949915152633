import { emptySplitApi as api } from '../../full/emptyApi';

const injectedRtkApi = api.injectEndpoints({
    endpoints: (build) => ({
        getTheUserInformation: build.query<GetTheUserInformationApiResponse, GetTheUserInformationApiArg>({
            query: () => ({ url: '/api/private/user/' }),
        }),
        postAppApiUserSetRoles: build.mutation<PostAppApiUserSetRolesApiResponse, PostAppApiUserSetRolesApiArg>({
            query: (queryArg) => ({ url: '/api/private/user/roles/', method: 'POST', body: queryArg.userRolesDto }),
        }),
        addOnboardingStatus: build.mutation<AddOnboardingStatusApiResponse, AddOnboardingStatusApiArg>({
            query: (queryArg) => ({ url: `/api/private/user/onboarding-status/${queryArg.status}/`, method: 'POST' }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as UserApi };
export type GetTheUserInformationApiResponse = /** status 200 Returns the user information */ UserDto;
export type GetTheUserInformationApiArg = void;
export type PostAppApiUserSetRolesApiResponse = unknown;
export type PostAppApiUserSetRolesApiArg = {
    userRolesDto: UserRolesDto;
};
export type AddOnboardingStatusApiResponse = /** status 200 Resource response */ UserDto;
export type AddOnboardingStatusApiArg = {
    status: string;
};
export type LegalInfoDto2 = {
    id?: string | null;
    country: string;
    legal_type: LegalTypeEnum2 | null;
    title: string | null;
    inn?: string | null;
    tax_number?: string | null;
    mobile_phone?: string | null;
};
export type SellerDto = {
    id?: string | null;
    legalInfo?: LegalInfoDto2 | null;
};
export type PublisherDto = {
    id?: string | null;
    publisherVerificationCode: string;
    firstName: string;
    lastName: string;
    avatar?: string | null;
    legalInfo?: LegalInfoDto2 | null;
};
export type UserDto = {
    id?: string | null;
    seller?: SellerDto | null;
    publisher?: PublisherDto | null;
    country?: string | null;
    timezone?: string | null;
    avatar?: string | null;
    roles?: string[];
    onboarded?: boolean;
    email?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    balance?: number;
    credit?: number;
    referral_code?: string;
    onboarding_statuses?: string[];
};
export type UserRolesDto = {
    is_publisher?: boolean;
    is_seller?: boolean;
    publisher?: boolean | null;
    seller?: boolean | null;
};
export enum LegalTypeEnum2 {
    NaturalPerson = 'natural_person',
    IndividualEntrepreneur = 'individual_entrepreneur',
    Company = 'company',
}
export const { useGetTheUserInformationQuery, usePostAppApiUserSetRolesMutation, useAddOnboardingStatusMutation } =
    injectedRtkApi;
