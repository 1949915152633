import { SellerLegalInfoApi } from './SellerLegalInfoApi';

SellerLegalInfoApi.enhanceEndpoints({
    addTagTypes: ['GetLegalInfo', 'GetUser'],
    endpoints: {
        viewSellerLegalInfo: {
            providesTags: ['GetLegalInfo'],
        },
        addSellerLegalInfo: {
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    dispatch(SellerLegalInfoApi.util.invalidateTags(['GetLegalInfo', 'GetUser']));
                } catch (error) {
                    console.error('Failed to addSellerLegalInfo, no invalidation:', error);
                }
            },
        },
    },
});

export * from './SellerLegalInfoApi';
