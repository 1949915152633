import { emptySplitApi as api } from '../../full/emptyApi';

const injectedRtkApi = api.injectEndpoints({
    endpoints: (build) => ({
        viewSellerLegalInfo: build.query<ViewSellerLegalInfoApiResponse, ViewSellerLegalInfoApiArg>({
            query: () => ({ url: '/api/seller/v1/legal-info/' }),
        }),
        addSellerLegalInfo: build.mutation<AddSellerLegalInfoApiResponse, AddSellerLegalInfoApiArg>({
            query: (queryArg) => ({ url: '/api/seller/v1/legal-info/', method: 'POST', body: queryArg.legalInfoDto }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as SellerLegalInfoApi };
export type ViewSellerLegalInfoApiResponse = /** status 200 Resource response */ LegalInfoDto2;
export type ViewSellerLegalInfoApiArg = void;
export type AddSellerLegalInfoApiResponse = /** status 201 Resource created */ LegalInfoDto2;
export type AddSellerLegalInfoApiArg = {
    legalInfoDto: LegalInfoDto;
};
export type LegalInfoDto2 = {
    id?: string | null;
    country: string;
    legal_type: LegalTypeEnum2 | null;
    title: string | null;
    inn?: string | null;
    tax_number?: string | null;
    mobile_phone?: string | null;
};
export type LegalInfoDto = {
    country: string;
    legal_type: LegalTypeEnum | null;
    title: string | null;
    inn?: string | null;
    tax_number?: string | null;
    mobile_phone?: string | null;
};
export enum LegalTypeEnum2 {
    NaturalPerson = 'natural_person',
    IndividualEntrepreneur = 'individual_entrepreneur',
    Company = 'company',
}
export enum LegalTypeEnum {
    NaturalPerson = 'natural_person',
    IndividualEntrepreneur = 'individual_entrepreneur',
    Company = 'company',
}
export const { useViewSellerLegalInfoQuery, useAddSellerLegalInfoMutation } = injectedRtkApi;
