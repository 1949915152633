import { FC, useState, useEffect } from 'react';

import { ENTITIES } from '@Shared/api/full/entities';

export type CollectionProps<T, K> = {
    useInComponent: FC<{ items: T[]; isLoading?: boolean }>;
    getDataFor: keyof typeof ENTITIES;
    withInitParams?: K;
    filtersFrom?: string;
    filtersComponent?: FC | null;
};

const useGetDataFor = (entity: keyof typeof ENTITIES, params?: unknown) => {
    const { getCollection } = ENTITIES[entity];
    const queryResult = getCollection(params);
    return queryResult;
};

// tableHeader?
// filters?
// collection component:
//  fake items?
// pagitaion?

export default function Collection<T, K>({
    getDataFor: entity,
    useInComponent: Component,
    withInitParams: initParams,
}: CollectionProps<T, K>) {
    const [items, setItems] = useState<T[]>([]);
    const params = initParams;
    const { data, isFetching } = useGetDataFor(entity, params);

    useEffect(() => {
        if (data && data.items) {
            setItems(data.items);
        }
    }, [data]);

    return (
        <Component
            items={items}
            isLoading={isFetching}
        />
    );
}
