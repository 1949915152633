import { MouseEventHandler } from 'react';
import { message, theme } from 'antd';
import Icon from '@ant-design/icons';

import { Typography, Row, Button } from '@Shared/ui';
import { CopyItemLeftTop } from '@Shared/assets/images/icons';

const { Text } = Typography;

type TransactionAmountCellProps = {
    commissionId: string;
};

const CommissionIdCell = ({ commissionId }: TransactionAmountCellProps) => {
    const [messageApi, contextHolder] = message.useMessage();
    const {
        token: { colorPrimary },
    } = theme.useToken();

    const handleCopyLink = (e: MouseEventHandler<HTMLElement>, commissionId: string) => {
        e.stopPropagation();
        navigator.clipboard.writeText(commissionId);
        messageApi.open({
            type: 'success',
            content: 'ID комиссии успешно скопирован',
        });
    };
    return (
        <>
            {contextHolder}
            <Row
                wrap={false}
                align="middle"
            >
                <Text
                    style={{ color: colorPrimary, textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
                >
                    {commissionId}
                </Text>
                <Button
                    type="link"
                    onClick={(e) => handleCopyLink(e, commissionId)}
                    icon={<Icon component={() => <CopyItemLeftTop />} />}
                />
            </Row>
        </>
    );
};

export default CommissionIdCell;
