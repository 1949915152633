import { theme, FormInstance } from 'antd';

import { Form, Card, Typography, Input, Radio, Button, Space, QuestionTooltip } from '@Shared/ui';
import { SelectedMPStore } from '@Features/seller/productPromotion/campaignsTab/setupCampaign/barter/model/types';

import SearchProductDropdown from './SearchProductsDropdown';

const { Title, Text } = Typography;
const { TextArea } = Input;

type ProductCampaignsCardProps = {
    selectedMPStore: SelectedMPStore | null;
    form: FormInstance;
    isFormDisabled: boolean;
};

const ProductCampaignsCard = ({ selectedMPStore, form, isFormDisabled }: ProductCampaignsCardProps) => {
    const {
        token: { colorTextSecondary, margin },
    } = theme.useToken();

    const validateNoDuplicates = (_, value) => {
        const productCampaigns = form.getFieldValue('product_campaigns') || [];
        const duplicateIndices = productCampaigns.reduce((acc, item, index) => {
            if (item && item.product === value) {
                acc.push(index);
            }
            return acc;
        }, []);

        // Check if the current product is duplicated
        if (duplicateIndices.length > 1) {
            return Promise.reject(new Error('Нельзя выбрать один и тот же товар несколько раз'));
        }
        return Promise.resolve();
    };

    return (
        <Card>
            <Space
                direction="vertical"
                style={{ width: '100%' }}
                size={16}
            >
                <Title
                    type="secondary"
                    style={{ fontSize: 18, color: colorTextSecondary, margin: 0 }}
                    level={4}
                >
                    Товары, которые будут участвовать в РК
                </Title>
                <Text type="secondary">
                    В РК могут участвовать до 4 товаров, которые относятся к выбранной связке магазин-маркетплейс.
                </Text>
                <Form.List name="product_campaigns">
                    {(fields, { add, remove }) => (
                        <div style={{ display: 'flex', rowGap: 16, flexDirection: 'column' }}>
                            {fields.map((field, index) => (
                                <Card
                                    size="small"
                                    key={field.key}
                                    style={{ background: 'rgba(250, 250, 250, 1)' }}
                                >
                                    <Form.Item
                                        label={`Товар ${index + 1}`}
                                        name={[field.name, 'product']}
                                        rules={[
                                            { required: true, message: 'Это поле обязательно к заполнению' },
                                            { validator: validateNoDuplicates },
                                        ]}
                                        value={field?.value?.product} // Pass the current value of the field
                                    >
                                        <SearchProductDropdown
                                            selectedMPStore={selectedMPStore}
                                            isFormDisabled={isFormDisabled}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label="Что нужно подчеркнуть о товаре в рекламе?"
                                        rules={[{ required: true, message: 'Это поле обязательно к заполнению' }]}
                                        name={[field.name, 'advertising_guidance']}
                                    >
                                        <TextArea
                                            placeholder="Нужно подчеркнуть, что товар лучше, чем у конкурентов"
                                            disabled={isFormDisabled}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label={
                                            <>
                                                Ключевое слово для выкупа товара на МП &nbsp;
                                                <QuestionTooltip
                                                    title="Фраза, по которой блогер найдет и выкупит ваш товар.
                                                 Это способствует органическому продвижению карточки."
                                                />
                                            </>
                                        }
                                        name={[field.name, 'keywords']}
                                        rules={[
                                            { max: 255, message: 'Это поле не может содержать более 255 символов' },
                                        ]}
                                    >
                                        <Input
                                            placeholder="Введите ключевое слово"
                                            disabled={isFormDisabled}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label="Необходимо ли оставить отзыв на маркетплейсе?"
                                        name={[field.name, 'need_review']}
                                        rules={[{ required: true, message: 'Необходимо выбрать опцию' }]}
                                    >
                                        <Radio.Group
                                            defaultValue={false}
                                            disabled={isFormDisabled}
                                        >
                                            <Radio
                                                value={false}
                                                key="1"
                                            >
                                                Нет
                                            </Radio>
                                            <Radio
                                                value={true}
                                                key="2"
                                            >
                                                Да
                                            </Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                    {fields.length > 1 ? (
                                        <Button
                                            onClick={() => remove(field.name)}
                                            danger
                                            block
                                            disabled={isFormDisabled}
                                        >
                                            Удалить товар
                                        </Button>
                                    ) : null}
                                </Card>
                            ))}

                            {fields.length < 4 ? (
                                <Button
                                    onClick={() => add({ need_review: false })}
                                    block
                                    style={{ marginTop: margin }}
                                    disabled={isFormDisabled}
                                >
                                    + Добавить еще товар
                                </Button>
                            ) : null}
                        </div>
                    )}
                </Form.List>
            </Space>
        </Card>
    );
};
export default ProductCampaignsCard;
