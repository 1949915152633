import { theme } from 'antd';

import { Typography } from '@Shared/ui';
import { Currency } from '@Shared/api/rtkQueryApis/sellerTransactions/SellerTransactionsApi';

const { Text } = Typography;

type TransactionAmountCellProps = {
    transaction_amount: number | string;
    currency: Currency;
};

const TransactionAmountCell = ({ transaction_amount, currency }: TransactionAmountCellProps) => {
    const {
        token: { colorSuccess },
    } = theme.useToken();

    const formattedAmount = new Intl.NumberFormat('ru-RU', {
        style: 'currency',
        currency: currency?.shortCode,
        signDisplay: 'exceptZero',
        trailingZeroDisplay: 'stripIfInteger',
    }).format(Number(transaction_amount));
    return <Text style={{ color: +transaction_amount > 0 ? colorSuccess : 'auto' }}>{formattedAmount}</Text>;
};

export default TransactionAmountCell;
