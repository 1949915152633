import { ReactNode } from 'react';
import { ModalProps, FormProps } from 'antd';

import { Modal, Form } from '@Shared/ui';

type formDataType = { isLoading?: boolean; isSubmitting?: boolean; isSubmitted?: boolean };

type ModalFormProps = formDataType & {
    modalProps?: ModalProps;
    setOpen: (arg1: boolean) => void;
    formProps?: FormProps;
    formKey: string;
    children: ReactNode;
    handleSubmit: (arg1: object) => void;
    handleClose?: () => void;
};

const ModalForm = ({
    isLoading,
    modalProps,
    formProps,
    formKey,
    children,
    handleSubmit,
    setOpen,
    handleClose,
}: ModalFormProps) => {
    const onFinish: FormProps['onFinish'] = (formData) => {
        handleSubmit(formData);
    };

    const onFinishFailed: FormProps['onFinishFailed'] = (errorInfo) => {
        console.error('Failed:', errorInfo);
    };

    const handleCancel = () => {
        if (handleClose) {
            handleClose();
        } else {
            setOpen(false);
        }
    };

    const handleOk = () => {
        setOpen(false);
    };

    return (
        <Modal
            loading={isLoading}
            onCancel={handleCancel}
            onOk={handleOk}
            {...modalProps}
        >
            <Form
                name={formKey}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                layout="vertical"
                autoComplete="off"
                {...formProps}
            >
                {children}
            </Form>
        </Modal>
    );
};

export default ModalForm;
