import { SellerCommissionsApi } from './SellerCommissionsApi';

SellerCommissionsApi.enhanceEndpoints({
    addTagTypes: ['CommissionsList'],
    endpoints: {
        getSellerCommissionsList: {
            providesTags: ['CommissionsList'],
        },
        patchAppPrivateSellerCommissionDecline: {
            invalidatesTags: ['CommissionsList'],
        },
        patchAppPrivateSellerCommissionApprove: {
            invalidatesTags: ['CommissionsList'],
        },
    },
});

export * from './SellerCommissionsApi';
