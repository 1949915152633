import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { HomeOutlined } from '@ant-design/icons';

import { Breadcrumb, Grid } from '@Shared/ui';

const { useBreakpoint } = Grid;

export type BreadcrumbItemType = {
    path: string;
    title: ReactNode;
};

export type BreadcrumbPropsType = {
    items: BreadcrumbItemType[];
};

const defaultBreadcrumbs: BreadcrumbItemType[] = [
    {
        path: '',
        title: <HomeOutlined />,
    },
];

export const Breadcrumbs = (props: BreadcrumbPropsType) => {
    const { items } = props;

    const breakpoints = useBreakpoint();
    const itemRender = (
        currentRoute: { title: string; path: string },
        params: unknown,
        items: string | unknown[],
        paths: unknown[],
    ) => {
        const isLast = currentRoute?.path === items[items.length - 1]?.path;

        return isLast ? (
            <span>{currentRoute.title}</span>
        ) : (
            <Link to={`${paths.join('/') || '/'}`}>{currentRoute.title}</Link>
        );
    };

    return (
        <Breadcrumb
            itemRender={itemRender}
            items={[...defaultBreadcrumbs, ...items]}
            style={{ marginBottom: 16, marginLeft: breakpoints.md ? 0 : 24 }}
        />
    );
};
