import { StarFilled } from '@ant-design/icons';

import { Button } from '@Shared/ui';
import {
    useAddAGlobalPromotionProductToFavoritesMutation,
    useRemoveGlobalPromotionProductFromFavoritesMutation,
} from '@Shared/api/rtkQueryApis/publisherPromotions';

const FavoriteCell = ({
    isFavorite = false,
    promotionProductId,
}: {
    isFavorite: boolean;
    promotionProductId: string;
}) => {
    const [addAPromotionProductToFavorites] = useAddAGlobalPromotionProductToFavoritesMutation();
    const [removePromotionProductFromFavorites] = useRemoveGlobalPromotionProductFromFavoritesMutation();

    const toggleProductFavorite = async (e) => {
        e.stopPropagation();
        if (isFavorite) {
            await removePromotionProductFromFavorites({ promotionProduct: promotionProductId });
        } else {
            await addAPromotionProductToFavorites({ promotionProduct: promotionProductId });
        }
    };

    return (
        <Button
            type="link"
            id="joyride-products-favorite-cell"
            onClick={toggleProductFavorite}
            icon={<StarFilled style={{ color: isFavorite ? '#FADB14' : '#F5F5F5', fontSize: 20 }} />}
        />
    );
};

export default FavoriteCell;
