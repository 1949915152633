import { Button } from '@Shared/ui';
import { MailboxDownload } from '@Shared/assets/images/icons';
import { useExport } from '@Shared/hooks';
import { sendYmEvent } from '@Shared/utils/analytics/sendYmEvent';
import { useGetTheUserInformationQuery } from '@Shared/api/rtkQueryApis/user';

const BASE_URL = process.env.SERVER_URL;

const ExportButton = () => {
    const { handleDownload, isLoading } = useExport();

    const { data: user } = useGetTheUserInformationQuery();

    const handleClick = () => {
        sendYmEvent({
            name: 'click_download_statistics',
            o1: 'account;publisher;statistics',
            o2: 'statistics',
            publisherId: user?.publisher?.id,
            keycloakId: user?.id,
        });

        handleDownload(`${BASE_URL}/api/publisher/v1/statistics/`, 'statistics_export.csv');
    };

    return (
        <Button
            onClick={handleClick}
            loading={isLoading}
            icon={<MailboxDownload />}
        />
    );
};

export default ExportButton;
