import { useDispatch } from 'react-redux';

import { Button } from '@Shared/ui';
import { DownloadSaveUpload } from '@Shared/assets/images/icons';
import { showNotification, NotificationType } from '@Entities/notifications/model/notificationSlice';
import { sendYmEvent } from '@Shared/utils/analytics/sendYmEvent';
import { useGetTheUserInformationQuery } from '@Shared/api/rtkQueryApis/user';

const BASE_URL = process.env.SERVER_URL;

const DownloadStatisticsButton = () => {
    const { data: user } = useGetTheUserInformationQuery();

    const dispatch = useDispatch();
    const handleDownload = async () => {
        sendYmEvent({
            name: 'click_download_statistics',
            o1: 'account;advertiser;statistics',
            o2: 'statistics',
            advertiserId: user?.seller?.id,
            keycloakId: user?.id,
        });

        const response = await fetch(`${BASE_URL}/api/seller/v1/statistics/`, {
            credentials: 'include',
        });
        if (response.ok) {
            const blob = await response.blob();
            const downloadUrl = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.download = 'statistics_export.csv';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(downloadUrl);
        } else {
            dispatch(
                showNotification({
                    componentProps: {
                        type: NotificationType.Error,
                        message: 'Произошла ошибка при попытке скачивания статистики',
                    },
                }),
            );
        }
    };

    return (
        <Button
            onClick={handleDownload}
            icon={<DownloadSaveUpload />}
            iconPosition="start"
            type="primary"
        >
            Скачать
        </Button>
    );
};

export default DownloadStatisticsButton;
