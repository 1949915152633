import { useMemo, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { RollbackOutlined } from '@ant-design/icons';

import { NotificationType, showNotification } from '@Entities/notifications/model/notificationSlice';
import {
    CampaignContext,
    CampaignTypeCard,
    cpaCampaignType,
    ApplyActionProps,
    CampaignProductsTableBlock,
    ActionType,
} from '@Features/seller/productPromotion/campaignsTab/campaignTypeSelection';
import { CampaignMainDetailsForm } from '@Features/seller/productPromotion';
import LaunchCampaignButton from '@Features/seller/productPromotion/campaignsTab/campaignsTable/LaunchCampaignButton';
import { Breadcrumbs, PageContent, type BreadcrumbItemType, StoreMarketplaceSelectors } from '@Features/shared';
import {
    useViewTheCampaignQuery,
    usePatchAppPrivateSellerCampaignChangecommissionMutation,
    usePatchAppPrivateSellerCampaignSetvisibilityMutation,
} from '@Shared/api/rtkQueryApis/sellerCampaigns';
import { Button, Card, Col, Flex, Grid, Row, Skeleton, Typography, Space } from '@Shared/ui';

const { Title, Paragraph } = Typography;
const { useBreakpoint } = Grid;

const pageBreadcrumbs: BreadcrumbItemType[] = [
    {
        path: '/product-promotion',
        title: 'Продвижение товаров',
    },
    {
        path: 'campaigns',
        title: 'Рекламные кампании',
    },
    {
        path: '/product-promotion/setup-campaign/cpa',
        title: 'Настройка рекламной кампании',
    },
];

const CampaignTypeSelectionPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const breakpoints = useBreakpoint();
    const { slug = '' } = useParams();
    const {
        data: campaignData,
        isLoading: isCampaignLoading,
        isError: isCampaignError,
    } = useViewTheCampaignQuery({ slug });

    const [patchAppPrivateSellerCampaignChangecommission] = usePatchAppPrivateSellerCampaignChangecommissionMutation();
    const [patchAppPrivateSellerCampaignSetvisibility] = usePatchAppPrivateSellerCampaignSetvisibilityMutation();

    const [selectedMarketplace, setSelectedMarketplace] = useState(
        campaignData?.marketplace_store?.marketplace?.slug || '',
    );
    const [selectedStoreId, setSelectedStoreId] = useState(campaignData?.marketplace_store?.store?.id || '');

    useEffect(() => {
        if (campaignData) {
            setSelectedMarketplace(campaignData?.marketplace_store?.marketplace?.slug || '');
            setSelectedStoreId(campaignData?.marketplace_store?.store?.id || '');
        }
    }, [campaignData]);

    const mainDetails = useMemo(() => {
        if (!campaignData || isCampaignLoading || isCampaignError) {
            return {};
        }
        return {
            ...campaignData,
            fee_type: campaignData?.fee_setting?.fee_type,
            fee_value: Math.floor((campaignData?.fee_setting?.fee_value || 0) * 100),
            min_total_fee: campaignData?.fee_setting?.min_total_fee || 10,
            fee_value_addon: campaignData?.fee_setting?.fee_type === 'fixed' ? '₽' : '%',
        };
    }, [campaignData, isCampaignLoading, isCampaignError]);

    const applyAction = async ({
        producstsIds = [],
        action = ActionType.changeCommission,
        commission = 10,
    }: ApplyActionProps) => {
        if (!producstsIds.length) {
            dispatch(
                showNotification({
                    componentProps: {
                        type: NotificationType.Error,
                        message: 'Не выбраны товары',
                    },
                }),
            );
            return false;
        }

        if (action === ActionType.showStart || action === ActionType.showStop) {
            await patchAppPrivateSellerCampaignSetvisibility({
                slug,
                setVisibilityDto: {
                    select_all: false,
                    visible: action === ActionType.showStart ? true : false,
                    products: producstsIds,
                },
                notificationOptions: { successMessage: 'Действие успешно', errorMessage: 'Произошла ошибка' },
            }).unwrap();
        } else if (action === ActionType.defaultCommission || action === ActionType.changeCommission) {
            await patchAppPrivateSellerCampaignChangecommission({
                slug,
                changeCommissionDto: {
                    select_all: false,
                    commission: Number(
                        action === ActionType.defaultCommission ? campaignData?.fee_setting?.fee_value : commission,
                    ),
                    products: producstsIds,
                },
            }).unwrap();
        }
    };

    return (
        <PageContent>
            <Breadcrumbs items={pageBreadcrumbs} />
            <Card
                className="campaign-type-selection__card"
                style={{ outline: '2px solid raised' }}
            >
                <Row
                    align="top"
                    style={{ columnGap: 16, rowGap: 24 }}
                    wrap={false}
                >
                    <Title
                        level={3}
                        style={{ marginTop: 4 }}
                    >
                        Настройка рекламной кампании
                    </Title>
                    <Button
                        icon={<RollbackOutlined />}
                        iconPosition="end"
                        type="link"
                        onClick={() => navigate(-1)}
                    >
                        {breakpoints.lg ? 'Назад' : ''}
                    </Button>
                </Row>

                <Paragraph
                    type="secondary"
                    style={{
                        paddingBottom: 'var(--ant-padding-lg) 0',
                    }}
                >
                    Здесь вы указываете комиссию, которую будете платить паблишерам за продвижение ваших товаров из
                    активных рекламных кампаний. Если вы скрыли товар из каталога, но кто-то успел купить его по
                    партнерской ссылке, за эту продажу тоже будет начислена комиссия. Минимальный размер комиссии 10% от
                    стоимости товара, но не меньше 10 рублей.
                </Paragraph>

                <Row
                    gutter={[24, 24]}
                    wrap={breakpoints.lg ? false : true}
                >
                    <Col
                        xs={24}
                        lg={{ flex: '264px' }}
                        style={{ height: 'fit-content' }}
                    >
                        <CampaignTypeCard type={cpaCampaignType} />
                    </Col>
                    <Col
                        xs={24}
                        lg={{ flex: 'auto' }}
                    >
                        <Card
                            bordered
                            className="campaign-type-selection__type-card"
                        >
                            {isCampaignLoading ? (
                                <Skeleton
                                    title={false}
                                    active
                                />
                            ) : (
                                <CampaignMainDetailsForm initialData={mainDetails} />
                            )}
                        </Card>
                    </Col>
                </Row>

                <Title
                    level={4}
                    style={{ marginTop: 'var(--ant-margin-lg)' }}
                >
                    Выберите связку своего магазина и маркетплейса
                </Title>
                <Paragraph
                    type="secondary"
                    style={{
                        paddingBottom: 'var(--ant-padding-lg) 0',
                    }}
                >
                    Всем товарам из маркетплейса в связке будет присвоена комиссия по умолчанию.
                </Paragraph>
                <StoreMarketplaceSelectors
                    disabled={!!selectedStoreId && !!selectedMarketplace}
                    storeId={selectedStoreId}
                    marketplaceSlug={selectedMarketplace}
                    setSelectedMarketplace={setSelectedMarketplace}
                    setSelectedStoreId={setSelectedStoreId}
                />
                <CampaignContext.Provider
                    value={{
                        fee_setting: campaignData?.fee_setting,
                        applyAction,
                        storeId: selectedStoreId,
                    }}
                >
                    <Space
                        direction="vertical"
                        style={{ width: '100%', marginTop: 24 }}
                    >
                        {campaignData?.slug ? <CampaignProductsTableBlock campaignSlug={slug} /> : null}
                        <Flex justify="space-between">
                            <Button onClick={() => navigate(-1)}>Назад</Button>

                            {campaignData?.slug && campaignData?.status !== 'active' && (
                                <LaunchCampaignButton
                                    isGhost={false}
                                    btnText="Запустить кампанию"
                                    campaignId={campaignData.slug}
                                />
                            )}
                        </Flex>
                    </Space>
                </CampaignContext.Provider>
            </Card>
        </PageContent>
    );
};
export default CampaignTypeSelectionPage;
