import { FileOutlined } from '@ant-design/icons';
import { theme } from 'antd';

import { Avatar, Typography } from '@Shared/ui';
import { type ProductDto } from '@Shared/api/full';

const { Text } = Typography;

export const ProductNameCell = ({
    product,
    showCategory = false,
}: {
    product: ProductDto | null | undefined;
    showCategory?: boolean;
}) => {
    const {
        token: { marginSM },
    } = theme.useToken();

    return (
        <>
            <Avatar
                shape="square"
                icon={product?.image_url ? null : <FileOutlined />}
                src={product?.image_url}
                style={{ float: 'left', marginRight: marginSM }}
            />
            <Text strong>{product?.title}</Text>
            {showCategory && product?.product_category?.title ? (
                <>
                    <br />
                    <Text type="secondary">{product?.product_category?.title}</Text>
                </>
            ) : null}
        </>
    );
};
