import { Row, Col, Grid } from '@Shared/ui';
import { FilterObjectType } from '@Features/seller/finances/model/types';
import { DateRangeFilter } from '@Features/shared/filters';

import ExportButton from './ExportButton';

const { useBreakpoint } = Grid;

type ProductsFilterProps = {
    filterObject: FilterObjectType;
    setFilterObject: (arg1: FilterObjectType) => void;
};

const TransactionsFilters = (props: ProductsFilterProps) => {
    const breakpoints = useBreakpoint();

    return (
        <Row
            justify="space-between"
            wrap={false}
            style={{ gap: 8 }}
        >
            <Col>
                <Row
                    wrap
                    align="middle"
                    style={{
                        gap: 12,
                        flexDirection: breakpoints.md ? 'row' : 'column',
                        alignItems: breakpoints.md ? 'center' : 'flex-start',
                    }}
                >
                    <DateRangeFilter {...props} />
                </Row>
            </Col>
            <Col>
                <Row
                    justify="space-between"
                    align="middle"
                >
                    <ExportButton />
                </Row>
            </Col>
        </Row>
    );
};

export default TransactionsFilters;
