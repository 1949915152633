import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Space, Typography, Row, Card, Button, Upload, Form } from '@Shared/ui';
import { useGetProductsListForPromotionQuery } from '@Shared/api/rtkQueryApis/sellerPromotions';
import {
    FilterEnum,
    FilterObjectType,
    defaultFilterObject,
} from '@Features/seller/promotions/editPromotion/productsTable/types';
import { useUpdateSearchParams } from '@Shared/hooks';

import PromotionProductsTable from './productsTable/PromotionProductsTable';

import type { UploadProps } from 'antd';

const { Text, Title } = Typography;

const initialFilterObject = (searchParams: URLSearchParams): FilterObjectType => ({
    page: searchParams.get(FilterEnum.PAGE) || defaultFilterObject.page,
    limit: searchParams.get(FilterEnum.LIMIT) || defaultFilterObject.limit,
});

const PromotionsTableBlock = ({
    promotionId = '',
    setImportedFileName,
    isAddPersonalPromotion,
}: {
    promotionId: string;
    setImportedFileName: (agr1: string) => void;
    isAddPersonalPromotion: boolean;
}) => {
    const [searchParams] = useSearchParams();
    const updateSearchParams = useUpdateSearchParams();
    const [filterObject, setFilterObject] = useState<FilterObjectType>(initialFilterObject(searchParams));
    const { data: products, isFetching } = useGetProductsListForPromotionQuery(
        {
            id: promotionId,
            page: filterObject.page,
            limit: filterObject.limit,
        },
        { skip: !promotionId },
    );

    useEffect(() => {
        const filteredFilterObject = { ...filterObject };
        Object.keys(filteredFilterObject).forEach((param) => {
            if (filteredFilterObject[param] === defaultFilterObject[param]) {
                delete filteredFilterObject[param];
            }
        });
        updateSearchParams(filteredFilterObject);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterObject]);

    const uploadFileRequest = async (options) => {
        const { onSuccess, onError, file } = options;

        const fmData = new FormData();
        fmData.append('file', file);
        try {
            const res = await fetch(`${process.env.SERVER_URL}/api/private/file/upload/`, {
                credentials: 'include',
                method: 'POST',
                body: fmData,
            });
            const data = await res.json();
            onSuccess(data?.fileName);
        } catch (err) {
            onError({ err });
        }
    };

    const props: UploadProps = {
        name: 'file',
        customRequest: uploadFileRequest,
        accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
        onChange(info) {
            console.log(444555555, info);
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                console.log(`${info.file.name} file uploaded successfully`);
                setImportedFileName(info.file.response);
            } else if (info.file.status === 'error') {
                console.log(`${info.file.name} file upload failed.`);
            }
        },
    };

    return (
        <Card>
            <Space
                direction="vertical"
                size={24}
                style={{ width: '100%' }}
            >
                <Row style={{ flexDirection: 'column' }}>
                    <Title
                        level={4}
                        style={{ marginTop: 0 }}
                    >
                        Добавить товары
                    </Title>
                    <Text type="secondary">
                        Импортируйте товары, участвующие в акции на Ozon, и настройте их видимость для своих паблишеров.
                    </Text>
                </Row>
                <Form.Item name="promotion_products_file_id">
                    <Upload {...props}>
                        <Button>Импортировать</Button>
                    </Upload>
                </Form.Item>
                {!isAddPersonalPromotion ? (
                    <PromotionProductsTable
                        data={products}
                        isLoading={isFetching}
                        setFilterObject={setFilterObject}
                        promotionId={promotionId}
                    />
                ) : null}
            </Space>
        </Card>
    );
};
export default PromotionsTableBlock;
