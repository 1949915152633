import { MoreOutlined, EyeOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Dropdown } from 'antd';
import { useNavigate } from 'react-router-dom';

import { Button } from '@Shared/ui';
import { SourceStatusEnum } from '@Shared/api/rtkQueryApis/publisherSources';

import type { MenuProps } from 'antd';

type ActionsMenuProps = {
    currentSourceId: string;
    setCurrentSourceId: (arg1: string) => void;
    setDrawerOpen: (arg1: boolean) => void;
    setDeletionModalOpen: (arg1: boolean) => void;
    sourceStatus?: SourceStatusEnum | null;
};

const ActionsMenu = ({
    setDrawerOpen,
    setDeletionModalOpen,
    currentSourceId,
    setCurrentSourceId,
    sourceStatus,
}: ActionsMenuProps) => {
    const navigate = useNavigate();

    const items: MenuProps['items'] = [
        {
            key: 'Посмотреть',
            label: 'Посмотреть',
            icon: <EyeOutlined />,
            onClick: () => {
                setDrawerOpen(true);
            },
        },
        ...(sourceStatus === SourceStatusEnum.Declined
            ? [
                  {
                      key: 'Изменить',
                      label: 'Изменить',
                      icon: <EditOutlined />,
                      onClick: () => {
                          navigate('/sources/edit-source', {
                              state: { selectedSourceId: currentSourceId },
                          });
                      },
                  },
              ]
            : []),
        {
            type: 'divider',
        },
        {
            key: 'Удалить',
            label: 'Удалить',
            icon: <DeleteOutlined />,
            danger: true,
            onClick: ({ domEvent }) => {
                domEvent.stopPropagation();
                setCurrentSourceId(currentSourceId);
                setDeletionModalOpen(true);
            },
        },
    ];

    return (
        <Dropdown
            menu={{ items }}
            placement="bottomRight"
        >
            <Button type="text">
                <MoreOutlined />
            </Button>
        </Dropdown>
    );
};

export default ActionsMenu;
