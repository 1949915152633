import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Space, Typography, Row, Card } from '@Shared/ui';
import { useGetProductsListForPromotionQuery } from '@Shared/api/rtkQueryApis/sellerPromotions';
import {
    FilterEnum,
    FilterObjectType,
    defaultFilterObject,
} from '@Features/seller/promotions/editPromotion/productsTable/types';
import { useUpdateSearchParams } from '@Shared/hooks';

import PromotionProductsTable from './productsTable/PromotionProductsTable';

const { Text, Title } = Typography;

const initialFilterObject = (searchParams: URLSearchParams): FilterObjectType => ({
    page: searchParams.get(FilterEnum.PAGE) || defaultFilterObject.page,
    limit: searchParams.get(FilterEnum.LIMIT) || defaultFilterObject.limit,
});

const PromotionsTableBlock = ({ promotionId = '' }: { promotionId: string }) => {
    const [searchParams] = useSearchParams();
    const updateSearchParams = useUpdateSearchParams();
    const [filterObject, setFilterObject] = useState<FilterObjectType>(initialFilterObject(searchParams));
    const { data: products, isFetching } = useGetProductsListForPromotionQuery(
        {
            id: promotionId,
            page: filterObject.page,
            limit: filterObject.limit,
        },
        { skip: !promotionId },
    );

    useEffect(() => {
        const filteredFilterObject = { ...filterObject };
        Object.keys(filteredFilterObject).forEach((param) => {
            if (filteredFilterObject[param] === defaultFilterObject[param]) {
                delete filteredFilterObject[param];
            }
        });
        updateSearchParams(filteredFilterObject);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterObject]);

    return (
        <Card>
            <Space
                direction="vertical"
                size={24}
                style={{ width: '100%' }}
            >
                <Row style={{ flexDirection: 'column' }}>
                    <Title
                        level={4}
                        style={{ marginTop: 0 }}
                    >
                        Добавить товары
                    </Title>
                    <Text type="secondary">
                        Здесь вы можете выбрать, какие товары показывать паблишерам в рамках данной акции.
                    </Text>
                </Row>
                <PromotionProductsTable
                    data={products}
                    isLoading={isFetching}
                    setFilterObject={setFilterObject}
                    promotionId={promotionId}
                />
            </Space>
        </Card>
    );
};
export default PromotionsTableBlock;
