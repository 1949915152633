import { useLocation, useNavigate } from 'react-router-dom';

export const useUpdateSearchParams = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const convertObjectToSearchParams = (paramsObj) => {
        const searchParams = new URLSearchParams();

        Object.keys(paramsObj).forEach((key) => {
            if (Array.isArray(paramsObj[key])) {
                paramsObj[key].forEach((item) => searchParams.append(`${key}[]`, item));
            } else {
                if (paramsObj[key]) {
                    searchParams.append(key, paramsObj[key]);
                }
            }
        });

        return searchParams.toString();
    };

    const updateSearchParams = (paramsObj) => {
        const searchParamsString = convertObjectToSearchParams(paramsObj);

        navigate(
            {
                pathname: location.pathname,
                search: `?${searchParamsString}`,
            },
            { replace: true },
        );
    };

    return updateSearchParams;
};
