import { Button, Typography, Modal, Row } from '@Shared/ui';

const { Text, Title } = Typography;

type DeleteStoreModalProps = {
    open: boolean;
    setOpen: (arg1: boolean) => void;
    campaignId: string;
};

const CancelCampaignModal = ({ open, setOpen }: DeleteStoreModalProps) => {
    // const dispatch = useDispatch();
    // const [isCancelLoading, setCancelLoading] = useState(false);

    const handleCampaignCancel = (e: { preventDefault: () => void }) => {
        // const cancelCampaign = async () => {
        //     setCancelLoading(true);
        //     try {
        //         await sellerCampaignsApi.patchAppPrivateSellerCampaignDisable(campaignId);
        //         dispatch(
        //             showNotification({
        //                 type: NotificationType.Success,
        //                 message: 'Заявка на отключение кампании успешно отправлена',
        //                 description:
        //                     'Мы пришлем вам уведомление об отключении после того, как заявку проверит наш менеджер',
        //             }),
        //         );
        //         setCancelLoading(false);
        //         dispatch(fetchData({ key: KeyValueKeysEnum.getCampaignsList }));
        //         setOpen(false);
        //     } catch (err) {
        //         setCancelLoading(false);
        //         dispatch(
        //             showNotification({
        //                 type: NotificationType.Error,
        //                 message: 'В процессе отключения кампании что-то пошло не так',
        //                 description: 'Попробуйте позже',
        //             }),
        //         );
        //     }
        // };
        // cancelCampaign();
        e.preventDefault();
        window.location.href = `mailto:${process.env.CANCEL_CAMPAIGN_EMAIL}`;
        setOpen(false);
    };

    return (
        <Modal
            title={
                <Title
                    level={4}
                    style={{ marginTop: 0 }}
                >
                    Отключить рекламную кампанию
                </Title>
            }
            open={open}
            onOk={() => setOpen(false)}
            onCancel={() => setOpen(false)}
            footer={[
                <Row
                    key="action-buttons"
                    style={{ justifyContent: 'space-between' }}
                >
                    <Button onClick={() => setOpen(false)}>Отменить</Button>
                    <Button
                        type="primary"
                        onClick={handleCampaignCancel}
                        // loading={isCancelLoading}
                    >
                        Отправить заявку
                    </Button>
                </Row>,
            ]}
        >
            <Row>
                <Text
                    type="secondary"
                    style={{ marginBottom: 16 }}
                >
                    При отключении рекламной кампании все реферальные ссылки больше не будут подключаться новые
                    партнеры.
                    <br /> После уведомления текущих работающих партнеров и проверки, рекламная кампания будет
                    остановлена.
                </Text>
            </Row>
        </Modal>
    );
};

export default CancelCampaignModal;
