import { createSelector, createDraftSafeSelector } from '@reduxjs/toolkit';

import { RootState } from '../../app/providers/redux/store';

const selectSelf = (state: RootState) => state;

export const selectKeyValue = createDraftSafeSelector(selectSelf, (state) => state.keyValue);

export const selectNotifications = createDraftSafeSelector(selectSelf, (state) => state.notifications);
export const selectModals = createDraftSafeSelector(selectSelf, (state) => state.modals);
export const selectMessages = createDraftSafeSelector(selectSelf, (state) => state.messages);

export const selectSeller = createDraftSafeSelector(selectSelf, (state) => {
    return state.keyValue.seller;
});

export const selectSellerKey = (key: string) =>
    createSelector(selectSeller, (seller) => {
        return key in seller && seller[key];
    });

export const selectSellerKeyValue = (key: string, defaultValue?: unknown) =>
    createSelector(selectSellerKey(key), (sellerKey) => sellerKey?.value || defaultValue);
