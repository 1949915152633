import { RawAxiosRequestConfig } from 'axios';

import {
    UserApi,
    UserRolesDto,
    SellerApi,
    SellersIntegrationsApi,
    ProductApi,
    SellerCampaignsApi,
} from '@OpenApi/typescript-axios';
import { KeyValueKeysEnum } from '@Shared/model/seller/types';

export const userApi = new UserApi();
export const sellerApi = new SellerApi();
export const productApi = new ProductApi();
export const sellersIntegrationsApi = new SellersIntegrationsApi();
export const sellerCampaignsApi = new SellerCampaignsApi();

export const KeyValueApiInstances = {
    [KeyValueKeysEnum.fetchUser]: (options: RawAxiosRequestConfig) =>
        userApi.getTheUserInformation({
            ...options,
            headers: { ...options.headers, 'X-Referer': document.location.href },
        }),
    [KeyValueKeysEnum.postUser]: (options: RawAxiosRequestConfig, body: UserRolesDto) =>
        userApi.postAppApiUserSetRoles(body, options),
    [KeyValueKeysEnum.getStoresList]: (options: RawAxiosRequestConfig) => sellerApi.getStoresList(options),
    [KeyValueKeysEnum.getProductList]: (options: RawAxiosRequestConfig, body: null, params?: object) => {
        const { page, limit, stores, marketplaces, categories } = params;
        return productApi.getProductsList(page, limit, stores, categories, marketplaces, options);
    },
    [KeyValueKeysEnum.getMarketplacesList]: (options: RawAxiosRequestConfig) =>
        sellersIntegrationsApi.getAListOfAvailableMarketplaces(options),
    [KeyValueKeysEnum.getCampaignsList]: (
        options: RawAxiosRequestConfig,
        body: null,
        params: { paymentTypes: number[] },
    ) => sellerCampaignsApi.getCampaignsList(params?.paymentTypes || [], options),
};
