import { Grid, Row, Col } from '@Shared/ui';

import { CampaignTypeCard } from './CampaignTypeCard';
import { campaignTypes } from './campaignTypes';

const { useBreakpoint } = Grid;

export const CampaignTypeSelection = () => {
    const breakpoints = useBreakpoint();

    return (
        <Row
            gutter={[24, 24]}
            style={{
                display: 'grid',
                justifyContent: 'flex-start',
                gridTemplateColumns: breakpoints.lg ? 'repeat(auto-fit, 340px)' : breakpoints.md ? '1fr 1fr' : '1fr',
            }}
        >
            {campaignTypes.map((type) => (
                <Col key={type.title}>
                    <CampaignTypeCard type={type} />
                </Col>
            ))}
        </Row>
    );
};
