// Генерация UUID (uu_id)
export function uuidv4(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = (Math.random() * 16) | 0;
        const v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}

// Генерация hit_id
export function generateHitId(): string {
    const now = new Date();
    const tzo = -now.getTimezoneOffset();
    const dif = tzo >= 0 ? '+' : '-';
    const pad = (num: number): string => {
        const norm = Math.abs(Math.floor(num));
        return (norm < 10 ? '0' : '') + norm;
    };
    return (
        now.getFullYear() +
        '-' +
        pad(now.getMonth() + 1) +
        '-' +
        pad(now.getDate()) +
        'T' +
        pad(now.getHours()) +
        ':' +
        pad(now.getMinutes()) +
        ':' +
        pad(now.getSeconds()) +
        '.' +
        pad(now.getMilliseconds()) +
        dif +
        pad(tzo / 60) +
        ':' +
        pad(tzo % 60)
    );
}

// Генерация datetime_greenwich
export function generateDatetimeGreenwich(): string {
    const date = new Date();

    const year = date.getUTCFullYear();
    const month = date.getUTCMonth() + 1; // Месяцы начинаются с 0
    const day = date.getUTCDate();
    const hour = date.getUTCHours();
    const minute = date.getUTCMinutes();
    const seconds = date.getUTCSeconds();

    return `${year}-${pad(month)}-${pad(day)} ${pad(hour)}:${pad(minute)}:${pad(seconds)}`;
}

function pad(num: number): string {
    return num < 10 ? '0' + num : num.toString();
}

// Получение URL с тегами
export function getUrlTags(): string {
    return window.location.href;
}
