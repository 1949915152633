import { theme } from 'antd';

import { PersonalPromotionsTableBlock } from '@Features/seller/promotions';
import { Space, Typography } from '@Shared/ui';

const { Text, Link } = Typography;

const PersonalPromotionsTab = () => {
    const {
        token: { fontSizeLG },
    } = theme.useToken();
    return (
        <Space
            direction="vertical"
            style={{ width: '100%' }}
            size={24}
        >
            <Text
                type="secondary"
                style={{ fontSize: fontSizeLG }}
            >
                В этом разделе вы можете добавлять свои персональные предложения, которые используете на Ozon.
            </Text>
            <Text
                type="secondary"
                style={{ fontSize: fontSizeLG }}
            >
                Сначала разместите предложение на Ozon в разделе{' '}
                <Link
                    href="https://seller.ozon.ru/app/highlights/my-highlights/list"
                    target="_blank"
                >
                    Цены и акции → Мои акции
                </Link>
                , затем добавьте его сюда. Как разместить предложение на Ozon?
            </Text>
            <PersonalPromotionsTableBlock />
        </Space>
    );
};
export default PersonalPromotionsTab;
