import { FC, useEffect, useState } from 'react';
import { FileOutlined } from '@ant-design/icons';

import { Empty, Row, Typography } from '@Shared/ui';
import { useGetStoresListQuery } from '@Shared/api/rtkQueryApis/sellerStores/SellerStoresApi';

const { Link, Title } = Typography;

type EmptyProductsTableText = 'noStore' | 'noMP';

const emptyText: { [key in EmptyProductsTableText]: string } = {
    noStore: 'Добавьте свой магазин и настройте хотя бы одну интеграцию',
    noMP: 'Настройте хотя бы одну интеграцию',
};

export const EmptyProductsTableView: FC = () => {
    const [emptyView, setEmptyView] = useState<EmptyProductsTableText | null>();
    const { data: stores } = useGetStoresListQuery();

    useEffect(() => {
        if (!stores?.items?.length) {
            setEmptyView('noStore');
        } else if (!stores.items.find((item) => !!item.marketplaceStores?.length)) {
            setEmptyView('noMP');
        } else {
            setEmptyView(null);
        }
    }, [stores]);

    return (
        <Empty
            image={
                <FileOutlined
                    height="32"
                    width="32"
                />
            }
            imageStyle={{ height: 32 }}
            description={null}
        >
            <Title level={2}>Здесь пока пусто</Title>
            <Row
                justify="center"
                style={{ marginBottom: 24 }}
            >
                {!!emptyView && emptyText[emptyView]} <Link href={'/stores'}> Перейти в раздел “Магазины”</Link>
            </Row>
        </Empty>
    );
};
