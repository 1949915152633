import { SyncOutlined } from '@ant-design/icons';

import { Button } from '@Shared/ui';
import { useUpdateProductsInAllIntegrationsMutation } from '@Shared/api/rtkQueryApis/sellerIntegrations';

const UpdateProductsBtn = () => {
    const [updateProducts, { isLoading }] = useUpdateProductsInAllIntegrationsMutation();
    const handleUUpdateProductsClick = () => {
        updateProducts({
            notificationOptions: {
                successMessage: 'Каталог обновляется',
                successDescription: 'Вы увидите актуальные товары в течение часа',
            },
        });
    };

    return (
        <Button
            disabled={isLoading}
            icon={<SyncOutlined />}
            iconPosition="start"
            type="primary"
            loading={isLoading}
            ghost={true}
            onClick={handleUUpdateProductsClick}
        >
            Обновить списки товаров
        </Button>
    );
};

export default UpdateProductsBtn;
