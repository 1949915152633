import { useEffect, useState } from 'react';
import { Layout, theme } from 'antd';
import { useLocation } from 'react-router-dom';

import { useAppSelector, useAppDispatch } from '@Shared/hooks';
import { SetupIntegrationForm, IntegrationFormSelectors, SetupIntegrationBreadcrumb } from '@Features/seller/stores';
import { fetchData } from '@Entities/keyValue/seller/stores/model/storesSlice';
import { KeyValueKeysEnum } from '@Shared/model/seller/types';
import { Typography, Space, Card, Row, Col, Grid } from '@Shared/ui';
import { SellerOnboardingStatuses } from '@Shared/model/joyrideTypes';
import { setRun, goToStep } from '@Entities/joyride/model/joyrideSlice';

import './styles.scss';

const { Content } = Layout;
const { Text, Title, Link } = Typography;
const { useBreakpoint } = Grid;

const SetupIntegrationPage = () => {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const breakpoints = useBreakpoint();
    const { status: onboardingStepStatus, stepIndexOrder } = useAppSelector((state) => state.joyride);
    const [selectedMarketplace, setSelectedMarketplace] = useState(location.state?.selectedMarketplace || null);
    const [selectedStoreId, setSelectedStoreId] = useState(location.state?.selectedStoreId || null);

    useEffect(() => {
        dispatch(
            fetchData({
                key: KeyValueKeysEnum.getStoresList,
            }),
        );
    }, [dispatch]);

    // joyride onboarding step
    useEffect(() => {
        if (onboardingStepStatus === SellerOnboardingStatuses.MARKETPLACE_STORE_INTEGRATION && stepIndexOrder === 1) {
            dispatch(setRun(true));
            dispatch(goToStep({ status: SellerOnboardingStatuses.MARKETPLACE_STORE_INTEGRATION, order: 2 }));
        }
    }, [dispatch, onboardingStepStatus, stepIndexOrder]);

    const {
        token: { colorBgContainer },
    } = theme.useToken();

    return (
        <Content
            className="page-content setup-integration-page"
            style={{
                background: colorBgContainer,
                padding: breakpoints.md ? 24 : '24px 0',
            }}
        >
            <SetupIntegrationBreadcrumb />

            <Row
                gutter={[10, 10]}
                wrap={false}
                style={{ flexWrap: breakpoints.lg ? 'nowrap' : 'wrap', rowGap: breakpoints.md ? 10 : 0 }}
            >
                <Col flex="auto">
                    <Card style={{ width: '100%', background: '#fff' }}>
                        <Title level={3}>Настройка интеграции</Title>
                        <Text type="secondary">
                            Здесь вы сможете настроить интеграцию с магазином. Подробнее об условиях маркетплейса вы
                            можете почитать в разделе помощи.
                        </Text>
                        <br />
                        <Text type="secondary">
                            Для получения информации по заказам, совершенных в рамках ваших рекламных кампаний,
                            заполните поля ниже.
                        </Text>
                        <IntegrationFormSelectors
                            storeId={selectedStoreId}
                            marketplace={selectedMarketplace}
                            setSelectedMarketplace={setSelectedMarketplace}
                            setSelectedStoreId={setSelectedStoreId}
                        />
                        <Text
                            type="secondary"
                            style={{ display: 'inline-block', marginTop: 16 }}
                        >
                            Вы можете заполнить данные дя интеграции автоматически. Для этого воспользуйтесь нашим{' '}
                            <Link
                                href="https://chromewebstore.google.com/detail/takprodam-setup-integrati/badfglhijdjoojbcajconecighmnkndi"
                                target="_blank"
                                id="joyride-setup-integration-extension-link"
                            >
                                Расширением для браузера
                            </Link>
                            , запустите его и вернитесь на страницу настройки интеграции
                        </Text>
                        <SetupIntegrationForm
                            storeId={selectedStoreId}
                            marketplace={selectedMarketplace}
                        />
                    </Card>
                </Col>
                <Col style={{ flex: breakpoints.lg ? '320px 0 0' : 'auto' }}>
                    <Card style={{ width: '100%', background: '#fff' }}>
                        <Space
                            direction="vertical"
                            size="middle"
                        >
                            <Title
                                type="secondary"
                                level={3}
                            >
                                FAQ
                            </Title>
                            <Text type="secondary">
                                В этом разделе хранится список ваших магазинов и маркетплейсов подключенных к ним.
                            </Text>
                            <Link
                                href="https://support.mitgo.com/ru/knowledge-base/article/%D0%BA%D0%B0%D0%BA-%D0%BD%D0%B0%D1%87%D0%B0%D1%82%D1%8C-%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D1%83-%D1%81-%D1%82%D0%B0%D0%BA%D0%BF%D1%80%D0%BE%D0%B4%D0%B0%D0%BC"
                                target="_blank"
                            >
                                Как начать работу с Такпродам?
                            </Link>
                            {selectedMarketplace === 'wildberries' ? (
                                <Link
                                    href="https://support.mitgo.com/ru/knowledge-base/article/%D0%BA%D0%B0%D0%BA-%D0%BD%D0%B0%D1%81%D1%82%D1%80%D0%BE%D0%B8%D1%82%D1%8C-%D0%B8%D0%BD%D1%82%D0%B5%D0%B3%D1%80%D0%B0%D1%86%D0%B8%D1%8E-%D1%81-wildberries"
                                    target="_blank"
                                >
                                    Как настроить интеграцию с Wildberries?
                                </Link>
                            ) : null}
                            {selectedMarketplace === 'ozon' ? (
                                <Link
                                    href="https://support.mitgo.com/ru/knowledge-base/article/%D0%BA%D0%B0%D0%BA-%D0%BD%D0%B0%D1%81%D1%82%D1%80%D0%BE%D0%B8%D1%82%D1%8C-%D0%B8%D0%BD%D1%82%D0%B5%D0%B3%D1%80%D0%B0%D1%86%D0%B8%D1%8E-%D1%81-ozon"
                                    target="_blank"
                                >
                                    Как настроить интеграцию с Ozon?
                                </Link>
                            ) : null}
                            <Link
                                onClick={() => {
                                    window.location.href = `mailto:${process.env.SUPPORT_EMAIL}`;
                                }}
                            >
                                Нужна помощь?
                            </Link>
                        </Space>
                    </Card>
                </Col>
            </Row>
        </Content>
    );
};
export default SetupIntegrationPage;
