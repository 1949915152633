import { MouseEvent } from 'react';
import { theme } from 'antd';
import Icon from '@ant-design/icons';

import { Typography, Row, Button } from '@Shared/ui';
import { CopyItemLeftTop } from '@Shared/assets/images/icons';
import { useClipboard } from '@Shared/hooks';

const { Text } = Typography;

type TransactionAmountCellProps = {
    commissionId: string;
};

const CommissionIdCell = ({ commissionId }: TransactionAmountCellProps) => {
    const { copyTextToClipboard } = useClipboard();
    const {
        token: { colorPrimary },
    } = theme.useToken();

    const handleCopyLink = (e: MouseEvent<HTMLElement>, commissionId: string) => {
        e.stopPropagation();
        copyTextToClipboard(commissionId || '', 'ID комиссии успешно скопирован');
    };
    return (
        <Row
            wrap={false}
            align="middle"
        >
            <Text style={{ color: colorPrimary, textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                {commissionId}
            </Text>
            <Button
                type="link"
                onClick={(e) => handleCopyLink(e, commissionId)}
                icon={<Icon component={() => <CopyItemLeftTop />} />}
            />
        </Row>
    );
};

export default CommissionIdCell;
