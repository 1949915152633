import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { PlusCircleOutlined } from '@ant-design/icons';
import { Layout, theme } from 'antd';

import { useAppDispatch } from '@Shared/hooks';
import { SourcesTable, DeleteSourceModal } from '@Features/publisher/sources';
import { Card, Flex, Grid, Space, Typography, Button } from '@Shared/ui';
import { useGetSourcesListQuery } from '@Shared/api/rtkQueryApis/publisherSources';
import { useGetTheUserInformationQuery, useAddOnboardingStatusMutation } from '@Shared/api/rtkQueryApis/user';
import { SourceDrawer } from '@Features/publisher/sources/SourceDetailsDrawer';
import { Breadcrumbs, type BreadcrumbItemType } from '@Features/shared';
import { goToStep, setRun } from '@Entities/joyride/model/joyrideSlice';
import { PublisherOnboardingStatuses } from '@Shared/model/joyrideTypes';
import { sendYmEvent } from '@Shared/utils/analytics/sendYmEvent';

const { Content } = Layout;
const { Text, Title } = Typography;
const { useBreakpoint } = Grid;

const pageBreadcrumbs: BreadcrumbItemType[] = [
    {
        path: '/sources',
        title: 'Площадки',
    },
];

const SourcesPage = () => {
    const breakpoints = useBreakpoint();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [isDrawerOpen, setDrawerOpen] = useState(false);
    const [isDeletionModalOpen, setDeletionModalOpen] = useState(false);
    const [currentSourceId, setCurrentSourceId] = useState('');
    const { data: sources, isFetching, isSuccess: isSourcesFulfilled } = useGetSourcesListQuery();
    const { data: user, isSuccess: isUserFulfilled } = useGetTheUserInformationQuery();
    const [addOnboardingStatus] = useAddOnboardingStatusMutation();

    useEffect(() => {
        if (location?.state?.initialSourceId) {
            setCurrentSourceId(location.state.initialSourceId);
            setDrawerOpen(true);
            window.history.replaceState({}, '');
        }
    }, [location]);

    // onboarding joy-ride
    useEffect(() => {
        if (isUserFulfilled && isSourcesFulfilled) {
            const onboardingStatuses = user?.onboarding_statuses || [];
            if (!onboardingStatuses.includes(PublisherOnboardingStatuses.SOURCE_CREATION)) {
                if (sources?.items?.length) {
                    addOnboardingStatus({ status: PublisherOnboardingStatuses.SOURCE_CREATION });
                } else {
                    dispatch(setRun(true));
                    dispatch(goToStep({ status: PublisherOnboardingStatuses.SOURCE_CREATION }));
                }
            } else if (!onboardingStatuses.includes(PublisherOnboardingStatuses.SOURCE_VERIFICATION)) {
                dispatch(setRun(true));
                dispatch(goToStep({ status: PublisherOnboardingStatuses.SOURCE_VERIFICATION }));
            }
        }
    }, [addOnboardingStatus, dispatch, isSourcesFulfilled, isUserFulfilled, sources?.items?.length, user]);

    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const handleClickAddSource = () => {
        sendYmEvent({
            name: 'click_add_adspace',
            o1: 'account;publisher;adspace',
            o2: 'add adspace',
            publisherId: user?.publisher?.id,
            keycloakId: user?.id,
        });

        navigate('/sources/add-source');
    };

    return (
        <Content
            className="page-content sources-page"
            style={{
                background: colorBgContainer,
                padding: breakpoints.md ? 24 : '24px 0',
            }}
        >
            <Breadcrumbs items={pageBreadcrumbs} />

            <Space
                direction="vertical"
                style={{ width: '100%' }}
            >
                <Card style={{ width: '100%' }}>
                    <Flex
                        justify="space-between"
                        align={breakpoints.lg ? 'center' : breakpoints.sm ? 'flex-end' : 'unset'}
                        gap={10}
                        vertical={!breakpoints.lg}
                        style={{ marginBottom: 24 }}
                    >
                        <Flex
                            vertical
                            justify="space-between"
                        >
                            <Title
                                level={3}
                                style={{ marginTop: 0 }}
                            >
                                Список площадок
                            </Title>
                            <Text
                                type="secondary"
                                style={{ fontSize: 16 }}
                            >
                                В этом разделе вы можете добавлять свои площадки, чтобы продвигать на них товары
                                продавцов и получать прибыль.
                            </Text>
                            <Text
                                type="secondary"
                                style={{ display: 'inline-block', marginTop: 10, fontSize: 16 }}
                            >
                                Площадка — это любая онлайн-платформа (веб-сайт, канал в соц. сетях или мессенджерах,
                                приложение, расширение), которую вы хотите монетизировать.
                            </Text>
                        </Flex>
                        <Button
                            icon={<PlusCircleOutlined />}
                            iconPosition="start"
                            type="primary"
                            ghost
                            onClick={handleClickAddSource}
                            id="joyride-add-source-button"
                        >
                            Добавить площадку
                        </Button>
                    </Flex>
                    <SourcesTable
                        data={sources?.items || []}
                        isLoading={isFetching}
                        setDrawerOpen={setDrawerOpen}
                        setDeletionModalOpen={setDeletionModalOpen}
                        setCurrentSourceId={setCurrentSourceId}
                    />
                    <SourceDrawer
                        isDrawerOpen={isDrawerOpen}
                        setDrawerOpen={setDrawerOpen}
                        sourceId={currentSourceId}
                        setModalOpen={setDeletionModalOpen}
                    />
                    <DeleteSourceModal
                        sourceId={currentSourceId}
                        open={isDeletionModalOpen}
                        setOpen={setDeletionModalOpen}
                        setDrawerOpen={setDrawerOpen}
                    />
                </Card>
            </Space>
        </Content>
    );
};
export default SourcesPage;
