import { createElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import { UserOutlined, MenuFoldOutlined, MenuUnfoldOutlined, BellOutlined } from '@ant-design/icons';

import { showModal } from '@Entities/modals/model/modalsSlice';
import { useAppDispatch } from '@Shared/hooks';
import { Flex, Button } from '@Shared/ui';
import { userMenuItems } from '@Widgets/seller/menuItems';
import BalancesHeaderItem from '@Widgets/seller/BalancesHeaderItem';

import RefLinkModal from '../RefLinkModal';
import './styles.scss';

const { Header } = Layout;

const headerItems = [
    {
        key: 'notifications',
        label: false,
        disabled: true,
        className: 'hide-title-content',
        icon: createElement(BellOutlined),
    },
    {
        key: 'user',
        label: false,
        id: 'joyride-user-menu-button',
        children: userMenuItems,
        className: 'hide-title-content',
        icon: <UserOutlined />,
    },
];

type DesktopPageHeaderProps = {
    menuCollapsed: boolean;
    setMenuCollapsed: (value: boolean) => unknown;
};

export default function DesktopPageHeader({ menuCollapsed, setMenuCollapsed }: DesktopPageHeaderProps) {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const onClick = (e) => {
        const item = userMenuItems.find((item) => item.key === e.key);
        if (item && item.href) {
            navigate(item.href);
        } else if (item && item.modal) {
            dispatch(showModal({ modalKey: item.modal }));
        }
    };
    const toggleCollapsed = () => {
        setMenuCollapsed(!menuCollapsed);
    };

    return (
        <Header className="page-header">
            <RefLinkModal />
            <Flex
                justify="space-between"
                style={{ padding: '0 16px' }}
            >
                <div style={{ marginRight: 'auto' }}>
                    <Button
                        type="text"
                        onClick={toggleCollapsed}
                    >
                        {menuCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                    </Button>
                </div>
                <BalancesHeaderItem />
                <div>
                    <Menu
                        selectedKeys={[]}
                        mode="horizontal"
                        items={headerItems}
                        onClick={onClick}
                        className="desktop-header-menu"
                    />
                </div>
            </Flex>
        </Header>
    );
}
