import { useState, useEffect } from 'react';
import { theme } from 'antd';

import { Form, Card, Typography, Input, Checkbox, Radio, QuestionTooltip } from '@Shared/ui';
import { AdvertisingFormatEnum } from '@Shared/api/rtkQueryApis/sellerCampaigns';
import { AdFormatsMap } from '@Features/seller/productPromotion/campaignsTab/setupCampaign/barter/model/types';

const { Title } = Typography;
const { TextArea } = Input;

type AdFormatCardProps = {
    publisherRequirementsValue: string | null;
    onPublisherRequirementsChange: (arg1: string | null) => void;
    isFormDisabled: boolean;
};

const AdFormatCard = ({
    publisherRequirementsValue,
    onPublisherRequirementsChange,
    isFormDisabled,
}: AdFormatCardProps) => {
    const [selectedRequirementsType, setSelectedRequirementsType] = useState<string | null>(null);

    useEffect(() => {
        if (publisherRequirementsValue) {
            setSelectedRequirementsType('manual');
        }
    }, [publisherRequirementsValue]);

    const {
        token: { colorTextSecondary },
    } = theme.useToken();

    const adFormatOptions = Object.keys(AdFormatsMap).map((adFormat) => ({
        value: AdvertisingFormatEnum[adFormat as keyof typeof AdvertisingFormatEnum],
        label: AdFormatsMap[adFormat as keyof typeof AdFormatsMap],
    }));

    return (
        <Card>
            <Title
                type="secondary"
                style={{ fontSize: 18, color: colorTextSecondary, marginTop: 0 }}
                level={4}
            >
                Формат рекламы и портрет блоггера
            </Title>
            <Form.Item
                name={['barter_setting', 'advertising_format']}
                validateTrigger="onBlur"
                label="Формат рекламы"
                rules={[{ required: true, message: 'Необходимо выбрать хотя бы 1 опцию' }]}
                style={{ marginTop: 20 }}
            >
                <Checkbox.Group
                    options={adFormatOptions}
                    disabled={isFormDisabled}
                />
            </Form.Item>
            <Form.Item
                label={
                    <>
                        Портрет блоггера &nbsp;
                        <QuestionTooltip
                            title="Любые важные детали о блогере мечты -- аудитория, площадка, охваты, содержание блога и так
                             далее."
                        />
                    </>
                }
                required
                style={{ marginTop: 20 }}
            >
                <Radio.Group
                    value={selectedRequirementsType}
                    disabled={isFormDisabled}
                >
                    <Radio
                        value={null}
                        key="1"
                        onChange={() => {
                            setSelectedRequirementsType(null);
                            onPublisherRequirementsChange(null);
                        }}
                    >
                        Подобрать с помощью Такпродам
                    </Radio>
                    <Radio
                        value="manual"
                        key="2"
                        onChange={() => setSelectedRequirementsType('manual')}
                    >
                        Описать самостоятельно
                    </Radio>
                </Radio.Group>
            </Form.Item>

            {selectedRequirementsType === 'manual' ? (
                <Form.Item
                    name={['barter_setting', 'publisher_requirements']}
                    rules={[{ required: true, message: 'Это поле обязательно к заполнению' }]}
                >
                    <TextArea
                        rows={4}
                        // eslint-disable-next-line max-len
                        placeholder="Опишите в свободной форме, какой блоггер вам нужен на рекламу с указанием желаемого охвата аудитории..."
                        value={publisherRequirementsValue || ''}
                        onChange={(e) => onPublisherRequirementsChange(e.target.value)}
                        disabled={isFormDisabled}
                    />
                </Form.Item>
            ) : null}
        </Card>
    );
};
export default AdFormatCard;
