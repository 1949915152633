import React, { useEffect } from 'react';
import { Drawer } from 'antd';

type MessageDrawerProps = {
    openData?: object & { key: string };
    setOpen: (flag: boolean) => void;
};

export default function MessageDrawer({ openData, setOpen }: MessageDrawerProps) {
    const [loading, setLoading] = React.useState<boolean>(true);

    const showLoading = () => {
        setLoading(true);

        setTimeout(() => {
            setLoading(false);
        }, 1000);
    };

    useEffect(() => {
        showLoading();
    }, [openData?.key]);

    return (
        <Drawer
            title="Ответы на рассылку"
            loading={loading || !openData}
            open={Boolean(openData)}
            onClose={() => setOpen(false)}
        >
            MessageDrawer
            <pre>{JSON.stringify(openData, null, 2)}</pre>
        </Drawer>
    );
}
