import { useEffect, ReactNode, SetStateAction, Dispatch } from 'react';
import { useSelector } from 'react-redux';
import { PlusCircleOutlined } from '@ant-design/icons';

import { selectState, EditStoreFormType } from '@Entities/forms/seller/stores/model/editStoreFormSlice';
import { Button, Flex, Col } from '@Shared/ui';
import { MarketplaceStoreDto } from '@OpenApi/typescript-axios';
import { ModalKeys, DrawerKeys } from '@Shared/model/seller/stores/storesPageTypes';

import MarketplacesListBlock from './MarketplacesListBlock';
import AboutStoreBlock from './AboutStoreBlock';
import './styles.scss';

type StoreDetailsFormProps = {
    setDrawerContentType: (arg1: DrawerKeys) => void;
    setSelectedMarketplace: Dispatch<SetStateAction<MarketplaceStoreDto | null>>;
    storeId: string;
    setModalOpen: (arg1: boolean) => void;
    setModalType: (arg1: ModalKeys) => void;
    setDrawerExtraContent: (arg1: ReactNode) => void;
    setDrawerTitle: (arg1: ReactNode) => void;
};

const StoreDetailsForm = ({
    setDrawerContentType,
    setSelectedMarketplace,
    setModalType,
    setModalOpen,
    storeId,
    setDrawerExtraContent,
    setDrawerTitle,
}: StoreDetailsFormProps) => {
    const { initialData }: EditStoreFormType = useSelector(selectState);

    useEffect(() => {
        setDrawerExtraContent(<></>);
    }, [setDrawerExtraContent]);

    useEffect(() => {
        setDrawerTitle(<>Интеграции</>);
    }, [setDrawerTitle]);

    return (
        <>
            <Col style={{ overflowY: 'auto', overflowX: 'hidden' }}>
                <AboutStoreBlock
                    storeData={initialData}
                    setDrawerContentType={setDrawerContentType}
                />
                <MarketplacesListBlock
                    marketplaceStores={initialData.marketplaceStores}
                    setDrawerContentType={setDrawerContentType}
                    setSelectedMarketplace={setSelectedMarketplace}
                    setDrawerTitle={setDrawerTitle}
                    storeId={storeId}
                />
                <Button
                    icon={<PlusCircleOutlined />}
                    iconPosition="start"
                    type="link"
                    onClick={() => {
                        setModalOpen(true);
                        setModalType('newIntegration');
                    }}
                >
                    Добавить новую интеграцию
                </Button>
            </Col>
            <Flex
                vertical
                className="store-details__delete-button"
            >
                <Button
                    danger
                    onClick={() => {
                        setModalOpen(true);
                        setModalType('deleteStore');
                    }}
                >
                    Удалить магазин
                </Button>
            </Flex>
        </>
    );
};

export default StoreDetailsForm;
