// import { useDispatch } from 'react-redux';

import { Button } from '@Shared/ui';
import { MailboxDownload } from '@Shared/assets/images/icons';
// import { showNotification, NotificationType } from '@Entities/notifications/model/notificationSlice';

// const BASE_URL = process.env.SERVER_URL;

const ExportButton = () => {
    // const dispatch = useDispatch();
    // const handleDownload = async () => {
    //     if (sourceId) {
    //         const response = await fetch(`${BASE_URL}/api/publisher/v1/source/${sourceId}/products/export/`, {
    //             credentials: 'include',
    //         });
    //         if (response.ok) {
    //             const blob = await response.blob();
    //             const downloadUrl = URL.createObjectURL(blob);
    //             const link = document.createElement('a');
    //             link.href = downloadUrl;
    //             link.download = 'products_export.csv';
    //             document.body.appendChild(link);
    //             link.click();
    //             document.body.removeChild(link);
    //             URL.revokeObjectURL(downloadUrl);
    //         } else {
    //             dispatch(
    //                 showNotification({
    //                     componentProps: {
    //                         type: NotificationType.Error,
    //                         message: 'Произошла ошибка при попытке экспорта продуктов',
    //                     },
    //                 }),
    //             );
    //         }
    //     }
    // };

    return (
        <Button
            disabled
            // onClick={handleDownload}
            icon={<MailboxDownload />}
        />
    );
};

export default ExportButton;
