import { Row, Typography, Grid } from '@Shared/ui';

import BalanceCard from './BalanceCard';
import TopUpBalanceCard from './TopUpBalanceCard';

const { Text } = Typography;
const { useBreakpoint } = Grid;

const FinancesInfoBlock = () => {
    const breakpoints = useBreakpoint();

    return (
        <Row style={{ flexDirection: 'column' }}>
            <Text type="secondary">В этом разделе вы можете пополнять баланс и следить за его историей.</Text>
            <Row
                wrap={false}
                style={{ gap: 20, marginTop: 24, flexDirection: breakpoints.lg ? 'row' : 'column' }}
            >
                <BalanceCard />
                <TopUpBalanceCard />
            </Row>
        </Row>
    );
};
export default FinancesInfoBlock;
