import { Dispatch, ReactNode, SetStateAction } from 'react';
import { useDispatch } from 'react-redux';

import { fetchForm } from '@Entities/forms/seller/stores/model/setupIntegration';
import { MPAvatarWithBadge } from '@Features/seller/stores/MPAvatar';
import { marketplaceStoreStatuses } from '@Features/seller/stores/storesTable/drawerContent/marketplaceStoreStatus';
import { Button, Col, Divider, List, Row, Space, Typography } from '@Shared/ui';
import { MarketplaceStoreDto } from '@OpenApi/typescript-axios';
import { FormIntegrationsEnum } from '@Shared/model/seller/types';
import { DrawerKeys } from '@Shared/model/seller/stores/storesPageTypes';

import './styles.scss';

const { Title, Text } = Typography;

const ApiKeyBySlug = {
    ozon: FormIntegrationsEnum.sellerOzonUpdateIntegrationForm,
    wildberries: FormIntegrationsEnum.sellerWBUpdateIntegrationForm,
};

const MarketplacesListBlock = ({
    marketplaceStores,
    setDrawerContentType,
    setSelectedMarketplace,
    storeId,
    setDrawerTitle,
}: {
    marketplaceStores: MarketplaceStoreDto[];
    setDrawerContentType: (arg1: DrawerKeys) => void;
    setSelectedMarketplace: Dispatch<SetStateAction<MarketplaceStoreDto | null>>;
    storeId: string;
    setDrawerTitle: (arg1: ReactNode) => void;
}) => {
    const dispatch = useDispatch();
    const handleFetchIntegrationForm = (marketplace_slug: string) => {
        dispatch(
            fetchForm({
                key: ApiKeyBySlug[marketplace_slug],
                params: {
                    storeId,
                },
            }),
        );
    };

    return (
        <Space
            direction="vertical"
            style={{ marginTop: 32, width: '100%' }}
        >
            <Title
                style={{ marginTop: 0, marginBottom: 8 }}
                level={4}
            >
                Маркетплейсы
            </Title>
            <Col>
                <Divider style={{ margin: 0 }} />
                <List
                    dataSource={marketplaceStores}
                    className="store-details__list-of-marketplaces"
                    split
                    locale={{ emptyText: 'У вас нет настроенных интеграций' }}
                    renderItem={(marketplace) => (
                        <List.Item style={{ padding: '8px 0' }}>
                            <Row wrap={false}>
                                <Col style={{ display: 'flex', alignItems: 'center' }}>
                                    <MPAvatarWithBadge
                                        key={marketplace.marketplace_slug}
                                        slug={marketplace.marketplace_slug}
                                        status={marketplace.status}
                                    />
                                    <Text className="marketplace-title">{marketplace.marketplace_title}</Text>
                                </Col>
                                <Col style={{ display: 'flex', alignItems: 'center' }}>
                                    <Col>{marketplaceStoreStatuses[marketplace.status]}</Col>
                                    <Col>
                                        {' '}
                                        <Button
                                            type="link"
                                            onClick={() => {
                                                handleFetchIntegrationForm(marketplace?.marketplace_slug);
                                                setSelectedMarketplace(marketplace);
                                                setDrawerContentType('setupIntegration');
                                                setDrawerTitle(
                                                    <Button
                                                        type="text"
                                                        onClick={() => setDrawerContentType('storeDetails')}
                                                    >
                                                        Назад
                                                    </Button>,
                                                );
                                            }}
                                        >
                                            Настроить
                                        </Button>
                                    </Col>
                                </Col>
                            </Row>
                        </List.Item>
                    )}
                />
                <Divider style={{ margin: 0 }} />
            </Col>
        </Space>
    );
};

export default MarketplacesListBlock;
