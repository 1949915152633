import { combineReducers } from '@reduxjs/toolkit';

import userReducer from '@Entities/keyValue/user/userSlice';
import { sellerReducer } from '@Entities/keyValue/seller/sellerReducer';
import { publisherReducer } from '@Entities/keyValue/publisher/publisherReducer';

export const keyValueReducer = combineReducers({
    user: userReducer,
    seller: sellerReducer,
    publisher: publisherReducer,
});

export type keyValueState = ReturnType<typeof keyValueReducer>;
