import { Link } from 'react-router-dom';
import { Layout, theme, Button, Radio, Tag } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';

import { MessagesTable, MessagesFilters } from '@Features/seller/messenger';
import { Collection, Breadcrumbs } from '@Features/shared';
import { Card, Flex, Grid, Space, Typography } from '@Shared/ui';

const { Text, Title } = Typography;
const { Content } = Layout;
const { useBreakpoint } = Grid;

const breadcrumbs = [
    {
        path: '/messenger',
        title: 'Мессенджер',
    },
];

const MessagesTab = () => {
    const breakpoints = useBreakpoint();

    const {
        token: { colorBgContainer },
    } = theme.useToken();

    return (
        <Content
            className="page-content"
            style={{
                background: colorBgContainer,
                padding: breakpoints.md ? 24 : '24px 0',
            }}
        >
            <Breadcrumbs items={breadcrumbs} />
            <Space
                direction="vertical"
                style={{ width: '100%' }}
            >
                <Card style={{ width: '100%', background: '#fff' }}>
                    <Flex
                        vertical
                        justify="space-between"
                    >
                        <Title
                            level={3}
                            style={{ marginTop: 0 }}
                        >
                            Мессенджер
                        </Title>
                        <Text type="secondary">
                            В этом разделе вы можете отправлять сообщения своим продавцам и просматривать историю
                            диалогов.
                        </Text>
                    </Flex>
                    <Flex
                        justify="space-between"
                        gap={10}
                        vertical={!breakpoints.lg}
                        style={{ marginTop: 24 }}
                    >
                        <Radio.Group
                            optionType="button"
                            buttonStyle="solid"
                            value="all"
                        >
                            <Radio.Button value="all">Все</Radio.Button>
                            <Radio.Button value="unread">
                                Непрочитанные{' '}
                                <Tag
                                    color="red"
                                    bordered={false}
                                >
                                    21
                                </Tag>
                            </Radio.Button>
                        </Radio.Group>
                        <Link to="/messenger/new/">
                            <Button
                                icon={<PlusCircleOutlined />}
                                iconPosition="start"
                                type="primary"
                                ghost
                            >
                                Создать сообщение
                            </Button>
                        </Link>
                    </Flex>

                    <MessagesFilters />

                    <Collection
                        getDataFor="threads"
                        useInComponent={MessagesTable}
                    />
                </Card>
            </Space>
        </Content>
    );
};
export default MessagesTab;
