import { Button, Typography, Modal, Row } from '@Shared/ui';
import {
    useViewASourceQuery,
    useDeleteAppPrivatePublisherSourceDeleteMutation,
} from '@Shared/api/rtkQueryApis/publisherSources';

import './styles.scss';

const { Text, Title } = Typography;

type DeleteSourceModalProps = {
    open: boolean;
    setOpen: (arg1: boolean) => void;
    sourceId: string;
    setDrawerOpen: (arg1: boolean) => void;
};

const DeleteSourceModal = ({ open, setOpen, sourceId, setDrawerOpen }: DeleteSourceModalProps) => {
    const { isFetching: isSourceLoading, data: source } = useViewASourceQuery(
        { source: sourceId },
        { skip: !sourceId },
    );
    const [deleteSource, { isLoading: isSourceDeleting }] = useDeleteAppPrivatePublisherSourceDeleteMutation();

    const handleSourceDelete = async () => {
        try {
            await deleteSource({
                source: sourceId,
                notificationOptions: {
                    successMessage: 'Площадка удалена',
                    successDescription: 'Ваши ссылки на товары продавцов теперь неактивны',
                },
            });
            setDrawerOpen(false);
            setOpen(false);
        } catch (err) {
            return;
        }
    };

    const handleCancel = () => {
        setOpen(false);
    };

    return (
        <Modal
            title={
                <Title
                    level={4}
                    style={{ marginTop: 0 }}
                >
                    Удалить площадку «{source?.title || ''}» ?
                </Title>
            }
            loading={isSourceLoading}
            open={open}
            onOk={handleCancel}
            onCancel={handleCancel}
            footer={[
                <Row
                    key="action-buttons"
                    style={{ justifyContent: 'space-between' }}
                >
                    <Button onClick={handleCancel}>Отменить</Button>
                    <Button
                        type="primary"
                        onClick={handleSourceDelete}
                        loading={isSourceDeleting}
                    >
                        Удалить
                    </Button>
                </Row>,
            ]}
        >
            <Row>
                <Text
                    type="secondary"
                    style={{ marginBottom: 16 }}
                >
                    Вы действительно хотите удалить свою площадку? Ваши ссылки на товары продавцов станут неактивными.
                </Text>
            </Row>
        </Modal>
    );
};

export default DeleteSourceModal;
