export type FormsConfigs = Record<FormKeysEnum, object>;

export enum KeyValueKeysEnum {
    getSourcesList = 'get-sources-list',
}

export enum FormKeysEnum {
    addSourceForm = 'add-source-form',
    updateSourceForm = 'update-source-form',
}
