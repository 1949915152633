import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { theme } from 'antd';

import { Row, Button, Grid } from '@Shared/ui';
import {
    useUpdateTheGlobalPromotionMutation,
    PromotionDto3,
    usePatchAppPrivateSellerPromotionSetpromotionvisibilityMutation,
} from '@Shared/api/rtkQueryApis/sellerPromotions';
import { useAppDispatch } from '@Shared/hooks';
import { showMessage, MessageType } from '@Entities/messages/model/messagesSlice';

type PromotionSelectorCardProps = {
    promotion: PromotionDto3 | undefined;
    promotionInfoFormData: Partial<PromotionDto3>;
    promotionId: string;
};

const { useBreakpoint } = Grid;

const ActionButtons = ({ promotion, promotionInfoFormData, promotionId }: PromotionSelectorCardProps) => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useAppDispatch();
    const breakpoints = useBreakpoint();
    const {
        token: { paddingLG },
    } = theme.useToken();
    const [editWithApprove] = useState(location?.state?.editWithApprove);
    const [updatePromotionInfo, { isLoading: isUpdating }] = useUpdateTheGlobalPromotionMutation();
    const [setPromotionVisibility, { isLoading: isVisibilityLoading }] =
        usePatchAppPrivateSellerPromotionSetpromotionvisibilityMutation();

    const handleBack = () => {
        navigate(-1);
    };

    const handlePromotionUpdate = async () => {
        try {
            await updatePromotionInfo({
                id: promotionId,
                promotionDto: { description: promotionInfoFormData?.description },
                notificationOptions: {
                    // eslint-disable-next-line max-len
                    successMessage: `Список товаров для участия в "${promotion?.title}" и ее описание успешно сохранены.`,
                },
            });
            if (editWithApprove) {
                try {
                    await setPromotionVisibility({
                        setPromotionVisibilityDto: { visible: true, promotions: [promotionId] },
                    });
                    dispatch(
                        showMessage({
                            componentProps: {
                                type: MessageType.Success,
                                content: `Вы начали участие в ${promotion?.title}`,
                            },
                        }),
                    );
                } catch (err) {
                    console.error(err);
                }
            }
            handleBack();
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <Row
            justify="space-between"
            wrap={false}
            style={{ marginTop: 24, padding: breakpoints.md ? 0 : `0 ${paddingLG}px`, gap: paddingLG }}
        >
            <Button
                onClick={handleBack}
                style={{ width: breakpoints.md ? 'auto' : '100%' }}
            >
                Отменить
            </Button>
            <Button
                type="primary"
                style={{ width: breakpoints.md ? 'auto' : '100%' }}
                onClick={handlePromotionUpdate}
                loading={isUpdating || isVisibilityLoading}
            >
                {editWithApprove ? 'Сохранить и подтвердить участие' : 'Сохранить'}
            </Button>
        </Row>
    );
};

export default ActionButtons;
