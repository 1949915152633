import { isFulfilled, isRejectedWithValue, Middleware } from '@reduxjs/toolkit';

import { NotificationType, showNotification } from '@Entities/notifications/model/notificationSlice';

type NotificationOptions = {
    successMessage?: string;
    successDescription?: string;
    errorMessage?: string;
    errorDescription?: string;
};

interface CustomAction {
    type: string;
    payload?: {
        status?: number;
        message?: string;
        data?: {
            message?: string;
        };
    };
    error?: {
        message?: string;
    };
    meta?: {
        arg?: {
            notificationOptions?: NotificationOptions;
            originalArgs?: {
                notificationOptions?: NotificationOptions;
            };
        };
    };
}

export const errorMiddleware: Middleware =
    ({ dispatch }) =>
    (next) =>
    async (action: unknown) => {
        const customAction = action as CustomAction;

        const notificationOptions =
            customAction?.meta?.arg?.notificationOptions || customAction?.meta?.arg?.originalArgs?.notificationOptions;

        if (isRejectedWithValue(customAction) || customAction?.error) {
            if (customAction?.payload?.status === 401) {
                const sourceUrlParam = new URLSearchParams(location.search).get('source');
                if (sourceUrlParam) {
                    window.location.href = `${process.env.SERVER_URL}/connect/mitgo?source=${sourceUrlParam}`;
                } else {
                    window.location.href = `${process.env.SERVER_URL}/connect/mitgo`;
                }
            }
            if (customAction?.payload?.status === 422) {
                return next(action);
            }
            dispatch(
                showNotification({
                    componentProps: {
                        type: NotificationType.Error,
                        message:
                            notificationOptions?.errorMessage ||
                            customAction?.payload?.data?.message ||
                            customAction?.payload?.message ||
                            customAction?.error?.message ||
                            'An error occurred!',
                        description: notificationOptions?.errorDescription || null,
                    },
                }),
            );
        } else if (isFulfilled(customAction) && notificationOptions?.successMessage) {
            dispatch(
                showNotification({
                    componentProps: {
                        type: NotificationType.Success,
                        message: notificationOptions?.successMessage || 'Success!',
                        description: notificationOptions?.successDescription || '',
                    },
                }),
            );
        }

        return next(action);
    };
