import { keyValueSliceGeneric } from '@Shared/utils/redux/seller/keyValueGenericSlice';
import { MarketplaceDto } from '@OpenApi/typescript-axios';

export type MarketplacesKeyValueType = {
    isLoading: boolean;
    isError: boolean;
    isFulfilled: boolean;
    isSubmitted: boolean;
    value: { items: MarketplaceDto[] };
};

export const marketplacesSlice = keyValueSliceGeneric({
    name: 'marketplaces',
    stateSelector: (state) => state.keyValue.seller.marketplaces,
});

export const { setData, resetState } = marketplacesSlice.actions;
export const { fetchData, selectState, selectById } = marketplacesSlice;

export default marketplacesSlice.reducer;
