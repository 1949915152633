import { useNavigate } from 'react-router-dom';
import Icon from '@ant-design/icons';

import { Card, Space, Row, Typography, Button } from '@Shared/ui';

import { type CampaignType } from './types';
import './styles.scss';

const { Title, Text } = Typography;

export const CampaignTypeCard = ({
    type: { disabled, title, icon, description, navigate: typeNavigate, hasButton },
}: {
    type: CampaignType;
}) => {
    const navigate = useNavigate();

    return (
        <Card
            bordered={false}
            className="campaign-type-selection__type-card"
        >
            <Space
                direction="vertical"
                align="start"
                size="small"
                className="campaign-type-selection__type-card__content"
            >
                <Space
                    direction="vertical"
                    align="start"
                    size="small"
                >
                    <span
                        style={{
                            color: disabled ? 'unset' : 'var(--ant-color-link)',
                            opacity: disabled ? '0.45' : '1',
                        }}
                    >
                        <Icon component={() => icon} />
                    </span>

                    <Row>
                        <Title
                            level={5}
                            style={{ margin: 0 }}
                        >
                            {title}
                        </Title>
                    </Row>
                    <Row>
                        <Text
                            type="secondary"
                            style={{ fontSize: 12 }}
                        >
                            {description}
                        </Text>
                    </Row>
                </Space>
                {hasButton &&
                    (disabled ? (
                        <Button
                            type="dashed"
                            disabled
                        >
                            Скоро
                        </Button>
                    ) : (
                        <Button
                            type="primary"
                            onClick={() => {
                                typeNavigate && navigate(typeNavigate);
                            }}
                        >
                            Настроить
                        </Button>
                    ))}
            </Space>
        </Card>
    );
};
