import { PaymentTypeEnum3 } from '@Shared/api/rtkQueryApis/sellerCampaigns';

export function getCampaignEditUrlType(campaignType: PaymentTypeEnum3 | undefined, slug?: string | null | undefined) {
    let url = '';
    if (slug) {
        switch (campaignType) {
            case PaymentTypeEnum3.Cpa:
                url = `/product-promotion/setup-campaign/cpa/${slug}`;
                break;
            case PaymentTypeEnum3.Barter:
                url = `/product-promotion/setup-campaign/barter/${slug}`;
                break;
            case PaymentTypeEnum3.Cpp:
                url = `/product-promotion/setup-campaign/cpp/${slug}`;
                break;
        }
    }

    return url;
}
