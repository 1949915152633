import { Button } from '@Shared/ui';
import { MailboxDownload } from '@Shared/assets/images/icons';
import { useGetTheUserInformationQuery } from '@Shared/api/rtkQueryApis/user/UserApi';
import { useExport } from '@Shared/hooks';

const BASE_URL = process.env.SERVER_URL;

const ExportButton = ({ sourceId }: { sourceId: string }) => {
    const { data: user } = useGetTheUserInformationQuery();
    const { handleDownload, isLoading } = useExport();

    const handleClick = () => {
        handleDownload(
            `${BASE_URL}/api/publisher/v1/source/${sourceId}/promotion/personal/export/`,
            'promotion_products_export.csv',
        );
    };

    return (
        <Button
            onClick={handleClick}
            loading={isLoading}
            disabled={!user?.publisher?.legalInfo}
            icon={<MailboxDownload />}
        />
    );
};

export default ExportButton;
