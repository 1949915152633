import { useNavigate } from 'react-router';
import { Flex, Checkbox, Form, Input, Button } from 'antd';
import * as yup from 'yup';

import { useCreateThreadMutation } from '@Shared/api/full';

import useYup from './useYup';

import type { ThreadDto, ThreadMemberDto } from '@Shared/api/full';

const NewMessageForm = () => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const yupRules = useYup({
        member: yup.string().required(),
        subject: yup.string().required(),
        body: yup.string().required(),
        // files: yup.array(),
        agreement: yup.string().oneOf(['on', 'off']),
    });
    const [createThread, createThreadRes] = useCreateThreadMutation();
    const { isError, error, isLoading } = createThreadRes;

    console.log('createThreadRes:', createThreadRes);
    if (isError) {
        console.log('error:', error);
    }
    const handleSubmit = (values: Partial<ThreadDto>) => {
        console.log('handleSubmit:', values);

        const CURRENT_USER: ThreadMemberDto = { id: 'currentUserId' };
        console.log('will create thread call', createThread, CURRENT_USER);
        // createThread({
        //     threadDto: {
        //         createdBy: CURRENT_USER.id,
        //         holder: CURRENT_USER,
        //         createdAt: new Date().toDateString(),
        //         ...values,
        //     },
        // });
    };

    return (
        <Form
            layout="horizontal"
            form={form}
            name="form1"
            labelCol={{ span: 6 }}
            labelAlign="left"
            wrapperCol={{ span: 18 }}
            onFinish={handleSubmit}
            disabled={isLoading}
        >
            <Form.Item
                required
                name="member"
                label="Вебмастер"
                rules={yupRules}
            >
                <Input placeholder="Введите имя вебмастера" />
            </Form.Item>
            <Form.Item
                required
                name="subject"
                label="Тема сообщения"
                rules={yupRules}
            >
                <Input placeholder="Введите тему сообщения" />
            </Form.Item>
            <Form.Item
                required
                name="body"
                label="Текст сообщения"
                rules={yupRules}
            >
                <Input placeholder="Введите текст сообщения" />
            </Form.Item>
            {/* <Form.Item
                required
                name="files"
                label="Файлы"
                rules={yupRules}
            >
                <Input placeholder="Прикрепите файлы" />
            </Form.Item> */}
            <Form.Item
                name="agreement"
                rules={yupRules}
                className="labelNoDots"
                label={
                    <Button
                        htmlType="button"
                        onClick={() => navigate('/messenger')}
                    >
                        Cancel
                    </Button>
                }
            >
                <Flex
                    justify="flex-end"
                    align="center"
                >
                    <Checkbox>Даю согласие на продолжение чата</Checkbox>

                    <Button
                        type="primary"
                        htmlType="submit"
                        disabled={isLoading}
                        loading={isLoading}
                    >
                        Создать сообщение
                    </Button>
                </Flex>
            </Form.Item>
        </Form>
    );
};

export default NewMessageForm;
