import { useState, useEffect } from 'react';
import { theme } from 'antd';

import { Card, Row, Typography, Button, Input, Grid, Form } from '@Shared/ui';
import { useCreatePaymentInvoiceMutation } from '@Shared/api/rtkQueryApis/sellerPayments/SellerPaymentsApi';
import { normalizeErrorsObject } from '@Shared/utils/normalizeErrorsObject';

const { Title, Text } = Typography;
const { useBreakpoint } = Grid;

const TopUpBalanceCard = () => {
    const breakpoints = useBreakpoint();
    const [form] = Form.useForm();
    const [inputValue, setInputValue] = useState('');
    const [createPaymentInvoice, { isLoading, error }] = useCreatePaymentInvoiceMutation();
    const {
        token: { colorPrimaryBorder },
    } = theme.useToken();

    const handleTopUp = async () => {
        const res = await createPaymentInvoice({ createInvoiceDto: { amount: +inputValue } });
        const paymentUrl = res.data?.paymentUrl || '';
        if (paymentUrl) {
            window.open(paymentUrl, '_blank');
        }
    };

    useEffect(() => {
        const errors = normalizeErrorsObject(error?.data?.errors);
        const formErrors = Object.keys(errors).map((field) => ({ name: field, errors: [errors[field]] }));
        form.setFields(formErrors);
    }, [error, form]);

    return (
        <Card style={{ flex: 1, borderColor: colorPrimaryBorder }}>
            <Title
                level={5}
                style={{ marginTop: 0 }}
            >
                Пополнение баланса
            </Title>
            <Form
                form={form}
                name="top-up-balance"
                autoComplete="off"
            >
                <Form.Item
                    name="amount"
                    validateTrigger="onBlur"
                    style={{ marginTop: 20 }}
                >
                    <Row
                        wrap={false}
                        style={{ gap: 12, flexDirection: breakpoints.sm ? 'row' : 'column' }}
                    >
                        <Input
                            addonAfter="₽"
                            onChange={(e) => setInputValue(e.target.value)}
                            value={inputValue}
                            placeholder="Введите сумму"
                        />

                        <Button
                            type="primary"
                            onClick={handleTopUp}
                            loading={isLoading}
                        >
                            Пополнить
                        </Button>
                    </Row>
                </Form.Item>
            </Form>
            <Text type="secondary">
                Минимальная сумма пополнения 1000 ₽. <br /> Комиссия платёжной системы и НДС будут добавлены в сумму
                оплаты.
            </Text>
        </Card>
    );
};
export default TopUpBalanceCard;
