import { FC } from 'react';
import { theme } from 'antd';

import { Card, Typography, Button } from '@Shared/ui';

import './styles.scss';

const { Title, Text } = Typography;

interface WelcomeRoleCardProps {
    title: string;
    description: string;
    imageSrc: string;
    onSelect: () => void;
}

const WelcomeRoleCard: FC<WelcomeRoleCardProps> = ({ title, description, imageSrc, onSelect }) => {
    const {
        token: { boxShadowTertiary, colorTextSecondary, colorTextHeading },
    } = theme.useToken();

    return (
        <Card
            className="welcome-role-card"
            style={{ boxShadow: boxShadowTertiary }}
            cover={
                <img
                    className="welcome-role-card-image"
                    alt={title}
                    src={imageSrc}
                />
            }
        >
            <Title
                style={{
                    margin: '0 0 8px',
                    color: colorTextHeading,
                }}
                level={4}
            >
                {title}
            </Title>
            <Text style={{ color: colorTextSecondary }}>{description}</Text>
            <Button
                onClick={onSelect}
                type="primary"
                size="large"
                block
                style={{ marginTop: '16px' }}
            >
                Выбрать
            </Button>
        </Card>
    );
};

export default WelcomeRoleCard;
