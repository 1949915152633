import { theme } from 'antd';

import { Select, Row, Typography } from '@Shared/ui';
import { type SourceDtoList } from '@Shared/api/rtkQueryApis/publisherSources';

const { Title } = Typography;

type SourceSelectBlockProps = {
    selectedSourceId: string;
    setSourceId: (arg1: string) => void;
    isSourcesLoading: boolean;
    sources: SourceDtoList | undefined;
};

const SourceSelectBlock = ({ setSourceId, selectedSourceId, isSourcesLoading, sources }: SourceSelectBlockProps) => {
    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const sourcesOptions = sources?.items?.map((source) => ({ value: source.id, label: source.title }));

    return (
        <Row
            style={{
                background: colorBgContainer,
                padding: 'var(--ant-padding)',
                borderRadius: 'var(--ant-border-radius-lg)',
                gap: 8,
            }}
            align="middle"
            wrap={false}
        >
            <Title
                level={5}
                style={{ margin: 0, whiteSpace: 'nowrap' }}
            >
                Ваша площадка
            </Title>
            <Select
                value={selectedSourceId}
                style={{ width: '100%', overflow: 'hidden' }}
                loading={isSourcesLoading}
                options={sourcesOptions}
                onChange={setSourceId}
            />
        </Row>
    );
};

export default SourceSelectBlock;
