import { PublisherPromotionsApi } from './PublisherPromotionsApi';

PublisherPromotionsApi.enhanceEndpoints({
    addTagTypes: ['PublisherGlobalPromotionProductsList', 'PublisherPersonalPromotionProductsList'],
    endpoints: {
        getGlobalPromotionsProductsListWithLinksBySource: {
            providesTags: ['PublisherGlobalPromotionProductsList'],
        },
        addAGlobalPromotionProductToFavorites: {
            invalidatesTags: ['PublisherGlobalPromotionProductsList'],
        },
        removeGlobalPromotionProductFromFavorites: {
            invalidatesTags: ['PublisherGlobalPromotionProductsList'],
        },
        getPersonalPromotionsListWithLinksBySource: {
            providesTags: ['PublisherPersonalPromotionProductsList'],
        },
        addAPersonalPromotionToFavorites: {
            invalidatesTags: ['PublisherPersonalPromotionProductsList'],
        },
        removePersonalPromotionFromFavorites: {
            invalidatesTags: ['PublisherPersonalPromotionProductsList'],
        },
    },
});

export * from './PublisherPromotionsApi';
