import { useLocation } from 'react-router-dom';
import { useCallback, useEffect } from 'react';

import { sendYmEvent } from '@Shared/utils/analytics/sendYmEvent';
import { useGetTheUserInformationQuery } from '@Shared/api/rtkQueryApis/user';

export const useSendPageViewEvent = (o1: string) => {
    const location = useLocation();

    const { data: user } = useGetTheUserInformationQuery();

    const sendToPageView = useCallback(() => {
        const keycloakId = user?.id || '-';

        sendYmEvent({
            name: 'page_view',
            o1,
            keycloakId,
            advertiserId: user?.seller?.id,
            publisherId: user?.publisher?.id,
        });
    }, [o1, user]);

    useEffect(() => {
        sendToPageView();
    }, [location.pathname, sendToPageView]);

    return { sendToPageView };
};
