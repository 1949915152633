import { useAppDispatch } from '@Shared/hooks';
import { showMessage, MessageType } from '@Entities/messages/model/messagesSlice';

export const useClipboard = () => {
    const dispatch = useAppDispatch();

    const copyTextToClipboard = async (text: string, message: string | null) => {
        try {
            await navigator.clipboard.writeText(text || '');
            if (message) {
                dispatch(
                    showMessage({
                        componentProps: {
                            type: MessageType.Success,
                            content: message,
                        },
                    }),
                );
            }
        } catch (error) {
            dispatch(
                showMessage({
                    componentProps: {
                        type: MessageType.Error,
                        content: 'Ошибка при попытке копирования текста',
                    },
                }),
            );
        }
    };

    return { copyTextToClipboard };
};
