export enum FilterEnum {
    LIMIT = 'limit',
    PAGE = 'page',
    MARKETPLACES = 'marketplaces[]',
    FAVORITE = 'favorite',
    PROMOTION_TYPES = 'promotionTypes[]',
}

export type FilterObjectType = {
    page: number;
    limit: number;
    marketplaces: string[];
    favorite: boolean;
    promotionTypes: string[];
};

export const DEFAULT_PAGE_SIZE = 50;
export const DEFAULT_PAGE = 1;

export const defaultFilterObject: Partial<FilterObjectType> = {
    page: DEFAULT_PAGE,
    limit: DEFAULT_PAGE_SIZE,
};
