import { keyValueSliceGeneric } from '@Shared/utils/redux/seller/keyValueGenericSlice';
import { ProductDto } from '@OpenApi/typescript-axios';

export type ProductsKeyValueType = {
    isLoading: boolean;
    isError: boolean;
    isFulfilled: boolean;
    isSubmitted: boolean;
    value: { items: ProductDto[] };
};

export const productsSlice = keyValueSliceGeneric({
    name: 'products',
    stateSelector: (state) => state.keyValue.seller.products,
});

export const { fetchData, selectState } = productsSlice;

export default productsSlice.reducer;
