import { SellerIntegrationsApi } from './SellerIntegrationsApi';

SellerIntegrationsApi.enhanceEndpoints({
    addTagTypes: ['CampaignsList'],
    endpoints: {
        connectMarketplaceToWildberries: {
            invalidatesTags: ['CampaignsList'],
        },
        connectMarketplaceToOzon: {
            invalidatesTags: ['CampaignsList'],
        },
    },
});

export * from './SellerIntegrationsApi';
