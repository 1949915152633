import { theme } from 'antd';

import { PromotionsTableBlock } from '@Features/seller/promotions';
import { Space, Typography } from '@Shared/ui';

const { Text } = Typography;

const PromotionsTab = () => {
    const {
        token: { fontSizeLG },
    } = theme.useToken();
    return (
        <Space
            direction="vertical"
            style={{ width: '100%' }}
            size={24}
        >
            <Text
                type="secondary"
                style={{ fontSize: fontSizeLG }}
            >
                В этом разделе есть все предстоящие и уже прошедшие акции от маркетплесов. Настраивайте видимость
                товаров и описание акций и принимайте в них участие
            </Text>
            <PromotionsTableBlock />
        </Space>
    );
};
export default PromotionsTab;
