import { formSliceGeneric, defaultFormState } from '@Shared/utils/redux/seller/formsGenericSlice';
import { StoreDto } from '@OpenApi/typescript-axios';

const initialData = {
    title: '',
    description: '',
};

export type AddStoreFormType = {
    isLoading: boolean;
    isError: boolean;
    isSubmitting: boolean;
    isSubmitted: boolean;
    initialData: StoreDto;
    formData: StoreDto;
    errors: StoreDto;
};

export const addStoreFormSlice = formSliceGeneric({
    name: 'addStore',
    initialState: { ...defaultFormState, initialData, formData: initialData },
    stateSelector: (state) => state.forms.seller.stores.addStore,
});

export const { updateFormValue, resetForm } = addStoreFormSlice.actions;
export const { fetchForm, submitForm, selectState } = addStoreFormSlice;

export default addStoreFormSlice.reducer;
