import { useNavigate } from 'react-router-dom';

import { closeModal } from '@Entities/modals/model/modalsSlice';
import { selectModals } from '@Entities/keyValue/selectors';
import { useAppDispatch, useAppSelector } from '@Shared/hooks';
import { Modal, Typography, Row, Button, Col, Grid } from '@Shared/ui';
import { ModalKeysType } from '@Shared/model/modalTypes';
import {
    useViewThePromotionQuery,
    usePatchAppPrivateSellerPromotionSetpromotionvisibilityMutation,
} from '@Shared/api/rtkQueryApis/sellerPromotions';
import { showMessage, MessageType } from '@Entities/messages/model/messagesSlice';

const { Title, Text } = Typography;
const { useBreakpoint } = Grid;

const ApprovePromotionParticipationModal = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const breakpoints = useBreakpoint();
    const modalState = useAppSelector(selectModals);
    const promotionId = modalState?.params?.promotionId;
    const { data: promotion, isLoading } = useViewThePromotionQuery({ id: promotionId }, { skip: !promotionId });
    const [setPromotionVisibility, { isLoading: isVisibilityLoading }] =
        usePatchAppPrivateSellerPromotionSetpromotionvisibilityMutation();
    const isOpen = modalState.modalKey === ModalKeysType.approvePromotionParticipation && modalState.open;

    const handleCancel = () => {
        dispatch(closeModal());
    };

    const handleApprove = async () => {
        if (promotionId) {
            try {
                await setPromotionVisibility({
                    setPromotionVisibilityDto: { visible: true, promotions: [promotionId] },
                });
                dispatch(
                    showMessage({
                        componentProps: {
                            type: MessageType.Success,
                            content: `Вы начали участие в ${promotion?.title}`,
                        },
                    }),
                );
                handleCancel();
            } catch (err) {
                console.error(err);
            }
        }
    };

    return (
        <Modal
            title={
                promotion?.title ? (
                    <Title
                        level={4}
                        style={{ marginTop: 0 }}
                    >
                        {`Принять участие в "${promotion?.title}"`}
                    </Title>
                ) : null
            }
            open={isOpen}
            loading={isLoading}
            onCancel={handleCancel}
            footer={
                <Row
                    justify="space-between"
                    wrap={false}
                    style={{ flexDirection: breakpoints.sm ? 'row' : 'column', gap: 8 }}
                >
                    <Col>
                        <Button
                            onClick={handleCancel}
                            style={{ width: breakpoints.sm ? 'auto' : '100%' }}
                        >
                            Отменить
                        </Button>
                    </Col>
                    <Col style={{ display: 'flex', flexDirection: breakpoints.sm ? 'row' : 'column', gap: 8 }}>
                        <Button
                            type="link"
                            style={{ width: breakpoints.sm ? 'auto' : '100%' }}
                            onClick={() => {
                                handleCancel();
                                navigate(`/promotions/${modalState.params.promotionId}/edit-promotion`, {
                                    state: { editWithApprove: true },
                                });
                            }}
                        >
                            Настройка
                        </Button>
                        <Button
                            type="primary"
                            style={{ width: breakpoints.sm ? 'auto' : '100%' }}
                            onClick={handleApprove}
                            loading={isVisibilityLoading}
                        >
                            Подтвердить участие
                        </Button>
                    </Col>
                </Row>
            }
        >
            <Text>
                После подтверждения вы сможете остановить участие в акции через отправку заявки. Вы можете настроить
                видимость товаров, которые участвуют в акции и ее описание сейчас или же в любой момент после
                подтверждения участия.
            </Text>
        </Modal>
    );
};
export default ApprovePromotionParticipationModal;
