export enum ActionType {
    showStop = 'showStop',
    showStart = 'showStart',
}

export type ApplyActionProps = {
    productsIds: string[];
    action: ActionType;
    commission: number;
};

export const actionOptions = [
    { value: ActionType.showStop, label: 'Не показывать паблишерам' },
    { value: ActionType.showStart, label: 'Показывать паблишерам' },
];

export enum FilterEnum {
    LIMIT = 'limit',
    PAGE = 'page',
    MARKETPLACE_SLUG = 'marketplaceSlug',
    STORE_ID = 'storeId',
    ACTIVE = 'active',
}

export type FilterObjectType = {
    page: number;
    limit: number;
    sku: string;
    marketplaceSlug: string;
    storeId: string;
    active: boolean;
};

export const DEFAULT_PAGE_SIZE = 50;
export const DEFAULT_PAGE = 1;

export const defaultFilterObject: Partial<FilterObjectType> = {
    page: DEFAULT_PAGE,
    limit: DEFAULT_PAGE_SIZE,
};
